import firebase from "firebase/app";
import {
  AuthenticationProvider,
  AuthenticationProviderType,
  ThirdPartyAuthenticationProvider,
  ThirdPartyAuthenticationProviderType,
  User,
} from "../exco-auth";
import { Injectable } from "@not-the-droids/exco-ts-inject";

export abstract class FirebaseWebAuthenticationProvider<T extends AuthenticationProviderType> implements AuthenticationProvider<T> {
  public abstract get type(): T;
  public delegate?: FirebaseWebAuthenticationProviderDelegate;

  protected async signInWithCrediential(credential: firebase.auth.AuthCredential): Promise<User> {
    await this.delegate!.firebaseAuth.signInWithCredential(credential);
    return this.delegate!.currentUser!;
  }

  protected async signUpWithEmailPassword(email: string, password:string): Promise<User> {
    await this.delegate!.firebaseAuth.createUserWithEmailAndPassword(email, password);
    await this.delegate?.firebaseAuth.currentUser!.sendEmailVerification();
    return this.delegate!.currentUser!;
  }
}
export interface FirebaseWebAuthenticationProviderDelegate {
  readonly firebaseAuth: firebase.auth.Auth;
  readonly currentUser: User | undefined;

}

type FirebaseWebThirdPartyAuthenticationProviderType = "google" | "facebook" | "twitter";
type FirebaseWebThirdParty = typeof firebase.auth.TwitterAuthProvider | typeof firebase.auth.GoogleAuthProvider | typeof firebase.auth.FacebookAuthProvider;
export class FirebaseWebThirdPartyAuthenticationProvider<T extends FirebaseWebThirdPartyAuthenticationProviderType>
  extends FirebaseWebAuthenticationProvider<T>
  implements ThirdPartyAuthenticationProvider<T> {

    constructor(public readonly type: T) {
    super();
  }

  public async signIn(): Promise<User> {
    await this.getThirdPartyCredentials();
    return this.delegate!.currentUser!;
  }

  private firebaseProviders: Record<FirebaseWebThirdPartyAuthenticationProviderType, FirebaseWebThirdParty> = {
    facebook: firebase.auth.FacebookAuthProvider,
    google: firebase.auth.GoogleAuthProvider,
    twitter: firebase.auth.TwitterAuthProvider,
  }

  private async getThirdPartyCredentials() {
    const thirdPartyProvider = this.firebaseProviders[this.type];
    if (!thirdPartyProvider) throw new Error("Invalid Third Party Provider");
    return await this.delegate!.firebaseAuth.signInWithPopup(new thirdPartyProvider());
  }
}

export type FirebaseWebThirdPartyAuthenticationProviders = {
  // @ts-ignore
  [T in ThirdPartyAuthenticationProviderType]?: FirebaseWebThirdPartyAuthenticationProvider<T> | undefined;
};

export const FirebaseWebThirdPartyAuthenticationProvidersInjectable: Injectable<FirebaseWebThirdPartyAuthenticationProviders> = {};
