import { Inject } from "@not-the-droids/exco-ts-inject";
import React from "react";
import {
  StyleSheet,
  View,
} from "react-native";
import {
  Icon,
  ConditionalWrapper,
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute, Screen} from "../routes";
import { IconName, Palette, Spacing } from "./styles";

interface Props {
  history: History;
}

interface CreateProps {
  icon: IconName,
  name: string,
  screen: Screen,
  isSelected?: boolean,
}

export class SideBarMenuItemFactory {
  static inject: Inject<SideBarMenuItemFactory> = (injector) => {
    return () =>
      new SideBarMenuItemFactory({
        history: injector.get(HistoryInjectable)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <SideBarMenuItem {...this.props} {...props} />;
  }
}

class SideBarMenuItem extends React.Component<Props & CreateProps> {
  readonly handleNavigation = () => {
    const { history, screen } = this.props;
    history.push(getRoute(screen).path);
  }

  render() {
    const { icon, name, isSelected } = this.props;
    return (
      <ConditionalWrapper
        condition={!!isSelected}
        wrapper={(children) => (
          <View style={styles.selectedContainer}>
            {decoratorPlacements.map((placement, index) => <Decorator key={index} placement={placement}/>)}
            {children}
          </View>
        )}
      >
        <StyledTouchableOpacity style={styles.touchable} activeOpacity={isSelected ? 1 : 0.6} onPress={this.handleNavigation}>
          <Icon name={icon} size={24} type={isSelected ? "secondary" : "white" } style={styles.icon} />
          <StyledText colorMode={isSelected ? "dark" : "light" }>{name}</StyledText>
        </StyledTouchableOpacity>
      </ConditionalWrapper>
    )
  }
}

const decoratorPlacements = ["top", "bottom"] as const;
type DecoratorPlacement = typeof decoratorPlacements[number];
interface DecoratorProps {
  placement: DecoratorPlacement;
}

const Decorator: React.FunctionComponent<DecoratorProps> = (props) => {
  const { placement } = props;
  const containerStyles = placement === "top" ? styles.topDecorator : styles.bottomDecorator;
  return (
    <View style={[styles.selectedDecoratorContainer, containerStyles]}>
      <View style={styles.row}>
        <View style={styles.selectedDecoratorGrid}/>
        <View style={[styles.selectedDecoratorGrid, placement === "bottom" && styles.coloredDecoratorGrid]}/>
      </View>
      <View style={styles.row}>
        <View style={styles.selectedDecoratorGrid}/>
        <View style={[styles.selectedDecoratorGrid, placement === "top" && styles.coloredDecoratorGrid]}/>
      </View>
      <View style={styles.selectedDecorator} />
    </View>
  )
}

export type MenuItem = CreateProps;

const styles = StyleSheet.create({
  selectedContainer: {
    marginLeft: -24,
    paddingLeft: 24,
    marginRight: -Spacing.Sidebar,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    backgroundColor: Palette.White,
  },
  selectedDecoratorContainer: {
    width: 50,
    height: 50,
    position: "absolute",
    right: 0,
  },
  topDecorator: {
    top: -50,
  },
  bottomDecorator: {
    bottom: -50,
  },
  selectedDecorator: {
    width: 50,
    height: 50,
    borderRadius: 52.5,
    position: "absolute",
    backgroundColor: Palette.Primary100Pct,
  },
  selectedDecoratorGrid: {
    flex: 1,
    height: 25,
    backgroundColor: Palette.Primary100Pct,
  },
  coloredDecoratorGrid: {
    backgroundColor: Palette.White,
  },
  row: {
    flexDirection: "row",
  },
  touchable: {
    height: 56,
    flexDirection: "row",
    alignItems: "center",
    // margin below is to enable clicking of menu item on the padded margin part of the sidebar - EY
    marginLeft: -Spacing.Sidebar,
    paddingLeft: Spacing.Sidebar,
    marginRight: -Spacing.Sidebar,
  },
  icon:{
    marginRight: 16,
  },
});
