import React, { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { Card, CircleIcon, StyledText } from "./controls";

interface Props {
  onBackPress: () => void;
  subtitle: string;
  title: string;
  userType?: string;
  backButtonHidden?: boolean;
  footer?: React.ReactNode;
}

export const SummaryLayout: FunctionComponent<Props> = ({
  onBackPress,
  backButtonHidden,
  title,
  subtitle,
  children,
  userType,
  footer
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={[styles.header, styles.row]}>
          {!backButtonHidden && userType!== "owner" && (
            <CircleIcon
              icon="chevron-left"
              iconSize={24}
              size={48}
              backgroundColor="accent"
              onPress={onBackPress}
              style={styles.backButton}
            />
          )}
          <View>
            <StyledText variant="heading3" isBold={true}>
              {title}
            </StyledText>
            <StyledText>{subtitle}</StyledText>
          </View>
          {/*<StyledButton variant="secondary" text="Print" iconRight={{ name: "printer", mode: "color" }} onPress={() => {}}/>*/}
        </View>
      </View>
      <Card style={styles.card}>{children}</Card>
      {footer}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 800,
    marginLeft: 80,
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
  },
  section: {
    marginBottom: 32,
  },
  header: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: {
    position: "absolute",
    left: -70,
  },
  card:{
    padding: 32
  }
});
