import { FunctionComponent } from "react";
import { ActivityIndicator } from "react-native";
import { CenteredLayout } from "../layouts/CenteredLayout";
import { Palette } from "./styles";

export const LoadingIndicator: FunctionComponent = () => {
  return (
    <CenteredLayout>
      <ActivityIndicator size="large" color={Palette.Secondary100Pct}/>
    </CenteredLayout>
  );
};


