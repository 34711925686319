
import {
  differenceInCalendarDays,
  formatDistanceToNowStrict,
  isPast,
} from "date-fns";
import { MilestoneCompletionType } from "../../../data-model/Budget";
import { Address } from "../../../data-model/Project";
import { TagType } from "../components/controls/Tag";

export const formatArrayToString = (
  array: ReadonlyArray<string | undefined>,
  separator: string
): string => {
  return array.filter((a) => a).join(separator);
};

//TODO: eval if state/zip can include nbsp
export const formatFullAddressToString = (address: Address): string => {
  if (address) {
    const addressArray = [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.zip,
    ];

    return formatArrayToString(addressArray, ", ");
  } else {
    return "";
  }
};

export const structureAddress = (address: Address): string => {
  if (address) {
    const addressArr = [
      address.line1.length && address.line1 !== "undefined undefined"
        ? `${capitalizeFirstLetter(address.line1)},`
        : "",
      address.line2 ? ` ${capitalizeFirstLetter(address.line2)},` : "",
      address.city ? ` ${capitalizeFirstLetter(address.city)},` : "",
      address.state ? ` ${capitalizeFirstLetter(address.state)}` : "",
      address.zip ? ` ${capitalizeFirstLetter(address.zip)}` : "",
    ];
    return addressArr.join("").trim();
  } else {
    return "";
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

export const isEmptyString = (string: string | null | undefined): boolean => {
  return !string || string === "";
};

type PhoneFormats = "hyphenDash" | "period";

export const formatPhoneNumber = (
  phoneNumberString: string,
  format: PhoneFormats = "period"
) => {
  var cleaned = phoneNumberString.replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    switch (format) {
      case "hyphenDash":
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      case "period":
        return match[1] + "." + match[2] + "." + match[3];
      default:
        return match[1] + match[2] + match[3];
    }
  }
  return phoneNumberString;
};

export const formatDateLongMonth = (date: Date): string => {
  date = new Date(date);
  return date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatPaymentPhaseDateTextAndTag = (phaseStartDate: Date) => {
  phaseStartDate = new Date(phaseStartDate);
  const isInPast = isPast(phaseStartDate);
  const tagType = isInPast ? "dark" : "light";
  const tagText = isInPast ? "Started" : "Not Started";
  const startDistance = differenceInCalendarDays(phaseStartDate, new Date());
  const startDistanceText = formatDistanceToNowStrict(phaseStartDate, {
    unit: "day",
  });
  const startsText = isInPast
    ? `Started ${startDistanceText} ago`
    : `Starts in ${startDistanceText}`;

  return {
    isInPast,
    tagType: tagType as "dark" | "light",
    tagText,
    startDistance,
    startsText,
  };
};

const statusDetails: Record<MilestoneCompletionType, TagType> = {
  "not-started": "light",
  "in-progress": "dark",
  "needs-review": "warning",
  "completed": "affirm",
};

export const formatCompletionTagProps = (completionStatus: MilestoneCompletionType) => {
  const formatCompletionStatus = (completionStatus: MilestoneCompletionType) => {
    let completionStr = String(completionStatus);
    let completionStrArr = completionStr.split('-');
    return completionStrArr.map(capitalizeFirstLetter).join(' ')
  }

  return {
    type: statusDetails[completionStatus],
    text: formatCompletionStatus(completionStatus)
  };
}

