import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Redirect } from "react-router-dom";
import {
  ContractorModel,
  CreateContractorParams,
  CreateOwnerParams,
  OwnerModel,
  UploadVerificationParams,
  UserModel,
  VerificationModel
} from "../../../data-model";
import BusinessDetails from "../components/BusinessDetails";
import {
  Icon,
  Page,
  Screen,
  SideBar,
  StyledText
} from "../components/controls";
import PersonalDetails from "../components/PersonalDetails";
import { Palette } from "../components/styles";
import { VerificationFactory } from "../components/Verification";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { InjectedFactoryComponent } from "../InjectorContext";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  history: History;
  contractorModel: ContractorModel;
  ownerModel: OwnerModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
  verificationModel: VerificationModel;
}

@observer
export class SignUpScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <SignUpScreen
        history={injector.get(HistoryInjectable)()}
        contractorModel={injector.get(ContractorModel)()}
        ownerModel={injector.get(OwnerModel)()}
        userModel={injector.get(UserModel)()}
        userViewModel={injector.get(UserViewModel)()}
        verificationModel={injector.get(VerificationModel)()}
      />
    );
  };

  @observable private activeStep: number = 0;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  @computed get isPropertyOwner() {
    return this.currentUser?.userType === 'owner';
  }

  @computed get isContractor() {
    return this.currentUser?.userType === 'contractor';
  }

  @computed get signUpSteps() {
    return this.isContractor
      ? ["Login Details", "Business Details", "Verification", "Finish"]
      : ["Login Details", "Personal Details"];
  }

  onUpdatePersonalDetails = async (params: CreateOwnerParams) => {
    try {
      await this.props.ownerModel.createOwner(params);
      await this.props.userViewModel.refreshCurrentUser();
      this.props.history.push("/dashboard");
    } catch (e) {
      // TODO: Display error message
      console.log(e);
    }
  };

  onUpdateBusinessDetails = async (params: CreateContractorParams) => {
    try {
      await this.props.contractorModel.createContractor(params);
      this.onNextStep();
    } catch (e) {
      // TODO: Display error message
      console.log(e);
    }
  };

  onUploadVerification = async (params: UploadVerificationParams) => {
    try {
      await this.props.verificationModel.uploadVerification(params);
      await this.props.userViewModel.refreshCurrentUser();
      // this.onNextStep();
      this.props.history.push(getRoute("dashboard").path);
    } catch (e) {
      console.log(e);
    }
  };

  onNextStep = () => {
    this.activeStep = this.activeStep + 1;
  };

  onPreviousStep = () => {
    this.activeStep = this.activeStep - 1;
  };

  returnIcon = (index: number) => {
    if (index < this.activeStep + 1) {
      return (
        <View style={styles.selectedIconContainer}>
          <Icon
            name="check"
            type="white"
            size={8}
            style={styles.selectedIconStyle}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.stepNumberContainer}>
          <Text style={styles.stepNumberText}>{index + 1}</Text>
        </View>
      );
    }
  };

  contractorSteps = [
    <BusinessDetails onUpdateBusinessDetails={this.onUpdateBusinessDetails} />,
    <InjectedFactoryComponent
      factory={VerificationFactory}
      props={{
        currentUserId: this.currentUser?.id,
        onPreviousStep: this.onPreviousStep,
        onUploadVerification: this.onUploadVerification,
      }}
    />,
    <div>Sign up complete! Verification pending ...</div>,
  ];

  ownerSteps = [
    <PersonalDetails onUpdatePersonalDetails={this.onUpdatePersonalDetails} />,
  ];

  renderSignUpSteps() {
    return (
      <View>
        {this.signUpSteps.map((step, index) => {
          return (
            <View style={styles.stepsContainer} key={index}>
              {this.returnIcon(index)}
              <StyledText
                variant="heading2"
                style={[
                  styles.stepText,
                  index === this.activeStep + 1 && styles.active,
                ]}
                key={index}
              >
                {step}
              </StyledText>
            </View>
          );
        })}
      </View>
    );
  }

  renderCurrentPage() {
    const renderActiveStep = this.isContractor
      ? this.contractorSteps
      : this.ownerSteps;
    return renderActiveStep[this.activeStep];
  }

  render() {
    if (
      (this.isPropertyOwner && this.currentUser?.owner) ||
      (this.isContractor && this.currentUser?.contractor)
    ) {
      return (
        <Redirect to={getRoute("dashboard").path} />
      );
    }

    return (
      <Screen>
        <SideBar footer="copyright">{this.renderSignUpSteps()}</SideBar>
        <Page variant="main" withBorder={true}>
          {this.renderCurrentPage()}
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  stepsContainer: {
    flexDirection: "row",
    marginBottom: 8,
  },
  stepText: {
    color: Palette.White,
    marginBottom: 10,
    fontSize: 16,
  },
  active: {
    color: Palette.Secondary100Pct,
  },
  stepNumberContainer: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: Palette.Secondary100Pct,
    borderStyle: "solid",
    borderRadius: 20,
    textAlign: "center",
    marginTop: 4,
    marginBottom: 4,
    marginRight: 8,
    paddingTop: 2,
  },
  stepNumberText: {
    fontSize: 12,
    color: Palette.Secondary100Pct,
  },
  selectedIconStyle: {
    width: 10,
    height: 10,
    alignSelf: "center",
    justifyContent: "center",
  },
  selectedIconContainer: {
    width: 24,
    height: 24,
    borderRadius: 16,
    marginTop: 4,
    marginBottom: 4,
    marginRight: 8,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Affirm100Pct,
  },
});

// Unused var - TS
// const styles = StyleSheet.create({
//   heading: {
//     Placeholder: remove when done with placeholder text - EY
//     marginBottom: 16,
//   },
// });

// TJ - Leaving original wireframe implementation here for reference

// import { Authenticator } from "../exco-lib/exco-auth";
// import { Inject } from "@not-the-droids/exco-ts-inject";
// import { observable } from "mobx";
// import { observer } from "mobx-react";
// import React from "react";
// import { StyleSheet, View } from "react-native";
// import {
//   CreateContractorParams,
//   CreateOwnerParams,
//   UploadVerificationParams,
// } from "../../../data-model";
// import {} from "../../../data-model/VerificationModel";
// import {
//   ContractorModel,
//   OwnerModel,
//   UserModel,
//   VerificationModel,
// } from "../../../data-model";
// import BusinessDetails from "../components/BusinessDetails";
// import PersonalDetails from "../components/PersonalDetails";
// import { SignUpInput } from "../components/SignUpInput";
// import Verification from "../components/Verification";
// import { History, HistoryInjectable } from "../HistoryInjectable";
// // import ProgressSteps from "../components/ProgressSteps";

// interface Props {
//   authenticator: Authenticator;
//   history: History;
//   contractorModel: ContractorModel;
//   ownerModel: OwnerModel;
//   userModel: UserModel;
//   verificationModel: VerificationModel;
// }

// type HistoryLocationState = {
//   user?: "owner" | "contractor";
// };

// @observer
// export class SignUpScreen extends React.Component<Props> {
//   static inject: Inject<React.ReactElement> = (injector) => {
//     return () => (
//       <SignUpScreen
//         authenticator={injector.get(Authenticator)()}
//         history={injector.get(HistoryInjectable)()}
//         contractorModel={injector.get(ContractorModel)()}
//         ownerModel={injector.get(OwnerModel)()}
//         userModel={injector.get(UserModel)()}
//         verificationModel={injector.get(VerificationModel)()}
//       />
//     );
//   };

//   @observable private activeStep: number = 0;
//   @observable private userId: string = "";

//   get emailAuthenticator() {
//     return this.props.authenticator.email!;
//   }

//   get locationState() {
//     return this.props.history.location.state as HistoryLocationState;
//   }

//   get currentUser() {
//     return this.props.authenticator.currentUser;
//   }

//   get isPropertyOwner() {
//     if (this.locationState) return this.locationState.user === "owner";
//     return this.props.history.push("/");
//   }

//   get isContractor() {
//     if (this.locationState) return this.locationState.user === "contractor";
//     return this.props.history.push("/");
//   }

//   signUpSteps = this.isContractor
//     ? ["Login Details", "Business Details", "Verification", "Finish"]
//     : ["Login Details", "Personal Details"];

//   renderSignUpSteps() {
//     return (
//       <View></View>
//       // <ProgressSteps
//       //   steps={this.signUpSteps}
//       //   activeStep={this.activeStep}
//       //   style={localStyle.signUp}
//       // />
//     );
//   }

//   onSignUp = async (name: string, email: string, password: string) => {
//     try {
//       await this.emailAuthenticator.signUp(email, password);
//     } catch (e) {
//       console.log(e);
//       return;
//     }

//     try {
//       const user = await this.props.userModel.createUser({
//         name,
//       });
//       this.userId = user.id;
//     } catch (e) {
//       console.log(e);
//     }
//     this.onNextStep();
//   };

//   onUpdatePersonalDetails = async (params: CreateOwnerParams) => {
//     try {
//       await this.props.ownerModel.createOwner(params);
//       this.props.history.push("/home");
//     } catch (e) {
//       // TODO: Display error message
//       console.log(e);
//     }
//   };

//   onUpdateBusinessDetails = async (params: CreateContractorParams) => {
//     try {
//       await this.props.contractorModel.createContractor(params);
//       this.onNextStep();
//     } catch (e) {
//       // TODO: Display error message
//       console.log(e);
//     }
//   };

//   onUploadVerification = async (params: UploadVerificationParams) => {
//     try {
//       await this.props.verificationModel.uploadVerification(params);
//       this.onNextStep();
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   onNextStep = () => {
//     this.activeStep = this.activeStep + 1;
//   };

//   onPreviousStep = () => {
//     this.activeStep = this.activeStep - 1;
//   };

//   contractorSteps = [
//     <SignUpInput onSignUp={this.onSignUp} />,
//     <BusinessDetails onUpdateBusinessDetails={this.onUpdateBusinessDetails} />,
//     <Verification
//       currentUserId={this.currentUser?.id} // TODO: Currently uses firebase uid, prefix file url with db user id instead?
//       onPreviousStep={this.onPreviousStep}
//       onUploadVerification={this.onUploadVerification}
//     />,
//     <div>Verification pending ...</div>,
//   ];

//   ownerSteps = [
//     <SignUpInput onSignUp={this.onSignUp} />,
//     <PersonalDetails onUpdatePersonalDetails={this.onUpdatePersonalDetails} />,
//   ];

//   renderCurrentPage() {
//     const renderActiveStep = this.isContractor
//       ? this.contractorSteps
//       : this.ownerSteps;
//     return renderActiveStep[this.activeStep];
//   }

//   render() {
//     return (
//       <View style={localStyle.container}>
//         {this.renderSignUpSteps()}
//         <View style={localStyle.currentPage}>{this.renderCurrentPage()}</View>
//       </View>
//     );
//   }
// }

// const localStyle = StyleSheet.create({
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     height: "100vh",
//     alignItems: "center",
//   },
//   currentPage: {
//     marginTop: 40,
//   },
//   signUp: {
//     height: 40,
//     margin: 40,
//   },
// });
