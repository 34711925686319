import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet } from "react-native";
import { Project } from "../../../data-model";

import { StyledButton, StyledText } from "./controls";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";

import { withInjectedFactory } from "../InjectorContext";
import { PaymentModel } from "../../../data-model/PaymentModel";
import { Palette } from "./styles";

import { SummaryLayout } from "./SummaryLayout";
import CheckBox from "./CheckBox";
import { formatCurrencyToString } from "../utils/Numbers";

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  paymentModel: PaymentModel;
}

interface CreateProps {
  project: Project;
}

export class ProjectPaymentsPaymentViewFactory {
  static inject: Inject<ProjectPaymentsPaymentViewFactory> = (injector) => {
    return () =>
      new ProjectPaymentsPaymentViewFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        paymentModel: injector.get(PaymentModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectPaymentsPaymentView {...this.props} {...props} />;
  }
}

@observer
class ProjectPaymentsPaymentView extends React.Component<CreateProps & Props> {
  @observable private _paymentLoading: boolean = false;
  @computed get selectedPhases() {
    return this.props.projectDetailsFlow.selectedPaymentPhases;
  }

  @computed get paymentTotal() {
    const { paymentData } = this.props.projectDetailsFlow;
    if(!paymentData) return 0;
    const filtered = paymentData.phases.filter((phase: any) =>
      this.selectedPhases.includes(phase.id)
    );
    return filtered.reduce(
      (acc: number, phase: any) => acc + Number(phase.cost),
      0
    );
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  private handleBackClick = () => {
    const { projectDetailsFlow } = this.props;
    projectDetailsFlow.paymentView = "dashboard";
  };

  private handleCheckBoxClick = (id: string, newValue: boolean) => {
    const { projectDetailsFlow } = this.props;
    if (newValue) projectDetailsFlow.selectedPaymentPhases.push(id);
    else
      projectDetailsFlow.selectedPaymentPhases =
        projectDetailsFlow.selectedPaymentPhases.filter((x) => x !== id);
  };

  private pay = async () => {
    const { project, paymentModel, projectDetailsFlow } =
      this.props;
    try {
      this._paymentLoading = true;
      const paymentIntent = await paymentModel.fundPhasesInProject({
        projectId: project.id,
        phaseIds: projectDetailsFlow.selectedPaymentPhases,
      });
      const paymentConfirmation = await this.props.paymentModel.pay(paymentIntent.clientSecret);
     
      projectDetailsFlow.paymentConfirmation = {
        total: paymentIntent.total,
        id: paymentConfirmation.checkout_id.split("_")[1],
        date: new Date(),
      }
      projectDetailsFlow.paymentView = "confirmation";

    } catch (e) {
      console.log(e);
    } finally {
      this._paymentLoading = false;
    }
  };

  private renderFooter = () => {
    return (
      <>
        <View style={styles.footerPriceContainer}>
          <StyledText isMedium style={styles.text}>
            Total paying today:
          </StyledText>
          <StyledText isBold={true} variant="heading2" style={styles.priceText}>
            {formatCurrencyToString(this.paymentTotal)}
          </StyledText>
        </View>
        <StyledButton
          disabled={
            this.props.projectDetailsFlow.selectedPaymentPhases.length === 0 ||
            this._paymentLoading
          }
          style={styles.payButton}
          variant="primary"
          onPress={this.pay}
          text={"Continue"}
        />
      </>
    );
  };

  render() {
    const { projectDetailsFlow } = this.props;
    return (
      <>
        <SummaryLayout
          title="Select Phase"
          subtitle="Please select a part of the Kitchen Remodal project to apply your payment to. You may select the entire project or individual phases."
          onBackPress={this.handleBackClick}
          backButtonHidden={false}
          footer={this.renderFooter()}
        >
          {projectDetailsFlow.paymentData?.phases.map((phase: any) => {
            const disabled = !!phase.payment || Number(phase.cost) === 0;
            const selected = this.selectedPhases.includes(phase.id);
            return (
              <View
                style={[styles.phaseContainer, { opacity: disabled ? 0.5 : 1 }]}
                key={phase.id}
              >
                <CheckBox
                  disabled={disabled}
                  value=""
                  checked={this.selectedPhases.includes(phase.id)}
                  onChange={() => this.handleCheckBoxClick(phase.id, !selected)}
                />
                <StyledText>{phase.name}</StyledText>
                <StyledText style={styles.phaseCost}>
                  {formatCurrencyToString(phase.cost)}
                </StyledText>
              </View>
            );
          })}
        </SummaryLayout>

        {/* {this.renderSubmitButton()} */}
      </>
    );
  }
}

const styles = StyleSheet.create({
  phaseContainer: {
    flexDirection: "row",
    height: 50,
    borderWidth: 1,
    borderColor: Palette.Primary100Pct,
  },
  phaseCost: {
    alignSelf: "flex-end",
  },
  footerPriceContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
  },
  text: {
    fontSize: 16,
  },
  priceText: {
    position: "absolute",
    right: 30,
  },
  payButton: {
    width: 200,
  },
});

export const InjectedProjectPaymentsPaymentView = withInjectedFactory(
  ProjectPaymentsPaymentViewFactory
);
