import React, { FunctionComponent } from "react";
import { Image, StyleSheet, View } from "react-native";
import { Icon, StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";

interface Props {
  onPress?: () => void;
  borderColor?: string;
  name?: string;
  photoURL?: string;
  colorIndex?: number;
}

const colors = [
  { background: Palette.Secondary25Pct, text: Palette.Secondary100Pct },
  { background: Palette.Primary25Pct, text: Palette.Primary100Pct },
  { background: Palette.Affirm25Pct, text: Palette.Affirm100Pct },
  { background: Palette.Warning25Pct, text: Palette.Warning100Pct },
];

export const UserThumbnailPhoto: FunctionComponent<Props> = ({
  onPress,
  borderColor,
  name,
  photoURL,
  colorIndex = 0,
}) => {
  const color = colors[colorIndex % colors.length];
  const WrapperComponent = onPress ? StyledTouchableOpacity : View;

  return (
    <WrapperComponent
      style={[
        styles.container,
        {
          borderColor,
          borderWidth: borderColor ? 2 : 0,
          backgroundColor: color.background,
        },
      ]}
      onPress={onPress}
    >
      <>
        {photoURL && photoURL !== "" && (
          <Image style={styles.image} source={{ uri: photoURL }} />
        )}
        {/* Show name even if we don't have photo in case it doesn't load*/}
        {name && (
          <StyledText
            variant={"body"}
            isBold={true}
            style={{ color: color.text }}
          >
            {name.substring(0, 1).toUpperCase()}
          </StyledText>
        )}
        {!name && <Icon name="user" size={14} />}
      </>
    </WrapperComponent>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: "center",
    width: 32,
    height: 48,
    borderRadius: 16,
    backgroundColor: "#d33",
  },
  image: {
    width: 32,
    height: 48,
    borderRadius: 16,
    backgroundColor: Palette.White,
    position: "absolute",
  },
});
