import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  Budget,
  CreateBudgetParams,
  DeleteBudgetMilestoneParams,
  DeleteBudgetPhaseParams,
  UpdateBudgetContractParams,
  UpdateBudgetContractUrlsParams,
  UpdateBudgetMilestoneParams,
  UpdateBudgetParams,
  UpdateBudgetPhasesParams,
} from "./Budget";

export abstract class BudgetModel {
  static inject?: Inject<BudgetModel> = undefined;

  public abstract getBudgetById(id: string): Promise<Budget>;
  public abstract getBudgetByProjectId(id: string): Promise<Budget>;
  public abstract createBudget(params: CreateBudgetParams): Promise<any>;
  public abstract updateMilestone(
    params: UpdateBudgetMilestoneParams
  ): Promise<void>;
  public abstract deleteMilestone(
    params: DeleteBudgetMilestoneParams
  ): Promise<void>;
  public abstract updatePhases(params: UpdateBudgetPhasesParams): Promise<void>;
  public abstract updateBudget(params: UpdateBudgetParams): Promise<void>;
  public abstract updateBudgetContract(
    params: UpdateBudgetContractParams
  ): Promise<void>;
  public abstract updateBudgetContractUrls(
    params: UpdateBudgetContractUrlsParams
  ): Promise<void>;
  public abstract updateBudgetSignature(params: any): Promise<void>;
  public abstract updateDisclosure(params: any): Promise<void>;
  public abstract completeContract(id: string): Promise<void>;
  public abstract submitBudget(id: string): Promise<void>;
  public abstract addFile(budgetId: string, params: any): Promise<void>;
  public abstract getFilesById(params: any): Promise<void>;
  public abstract deleteFile(budgetId: string, id: string): Promise<void>;
  public abstract addMilestoneFile(milestoneId: string, params: any): Promise<void>;
  public abstract getMilestoneFilesById(params: any): Promise<void>;
  public abstract deleteMilestoneFile(milestoneId: string, id: string): Promise<void>;
  public abstract deletePhase(params: DeleteBudgetPhaseParams): Promise<void>;
}
