import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 70.5h69M64.5 13.5h-57v57h57v-57zM1.5 13.5h69M61.5 7.5h-51v6h51v-6zM43.5 1.5h-15v6h15v-6z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M40.5 58.5h-9v12h9v-12zM16.5 61.5h9M46.5 61.5h9M16.5 49.5h15M40.5 49.5h15M16.5 40.5h15M40.5 40.5h15M16.5 31.5h15M40.5 31.5h15M16.5 22.5h15M40.5 22.5h15"
      />
    </svg>
  );
}

export default memo(Icon);
