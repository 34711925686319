import React from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { useHover } from "react-native-web-hooks";
import {
  Icon,
  StyledText,
  StyledTouchableOpacity,
} from "../components/controls";
import { Counter, CounterProps } from "./Counter";
import {  IconName, Palette } from "./styles";

interface Props extends ViewProps {
  isSelected: boolean;
  name: string;
  counter?: CounterProps;
  icon?: IconName;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const ProjectOption: React.FunctionComponent<Props> = (props) => {
  const { counter, icon, isSelected, name, onPress, style } = props;
  const ref = React.useRef(null);

  const hasIcon = !!icon;
  const containerStyle = hasIcon ? styles.containerWithIcon : (!!counter ? styles.containerWithCounter : styles.containerWithText);
  const selectedStyle = isSelected ? styles.selectedContainer : undefined;
  const selectedStyledWithCounter = (isSelected && !!counter)? styles.selectedContainerWithCounter : undefined;
  const contentWrapperStyle = hasIcon ? styles.contentWrapperWithIcon : styles.contentWrapperWithText;
  const selectedIconContainerStyle = hasIcon ? styles.absoluteIcon : styles.inlineIcon;
  const hoverStyle = useHover(ref) ? styles.hover : undefined;
  const WrapperComponent = onPress ? StyledTouchableOpacity : View;

  return (
    <View ref={ref}>
      <WrapperComponent
        style={[
          styles.container,
          containerStyle,
          selectedStyle,
          selectedStyledWithCounter,
          hoverStyle,
          style,
        ]}
        disabled={isSelected && !!counter}
        onPress={onPress}
      >
        <View style={contentWrapperStyle}>
          {
            isSelected && (
              <View style={[styles.selectedIconContainer, selectedIconContainerStyle]}>
                {<Icon name="check" type="white" size={14}/>}
              </View>
            )
          }
          {
            icon && (
              <Icon name={icon} type={isSelected ? "white" : "primary"} size={72} style={styles.largeIcon}/>
            )
          }
          <StyledText variant="body" colorMode={isSelected ? "light" : "dark"} style={styles.text}>{name}</StyledText>
        </View>
        {
          (counter && counter.number > 0) && (
            <Counter {...counter} />
          )
        }
      </WrapperComponent>
    </View>
  );
}

const sharedRowCenterStyle: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const styles = StyleSheet.create({
  container: {
    minHeight: 56,
    paddingHorizontal: 24,
    marginRight: 24,
    marginBottom: 24,
    borderWidth: 1,
    borderRadius: 10,
    overflow:'hidden',
    borderColor: Palette.Primary25Pct,
  },
  hover: {
    borderColor: Palette.Primary75Pct,
  },
  text: {
    paddingVertical: 16,
  },
  containerWithIcon: {
    width: 144,
  },
  containerWithText: {
    ...sharedRowCenterStyle,
    width: 288,
  },
  containerWithCounter: {
    ...sharedRowCenterStyle,
    paddingHorizontal: 16,
    paddingVertical: 8,
    minWidth: 228,
    width: "auto",
  },
  selectedContainer: {
    borderColor: Palette.Primary75Pct,
    backgroundColor: Palette.Primary75Pct,
  },
  selectedContainerWithCounter: {
    justifyContent: "space-between",
  },
  selectedIconContainer: {
    width: 22,
    height: 22,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Affirm100Pct,
  },
  contentWrapperWithIcon: {
    alignItems: "center",
    flexDirection: "column",
  },
  contentWrapperWithText: {
    ...sharedRowCenterStyle,
    flexWrap: "wrap",
  },
  absoluteIcon: {
    position: "absolute",
    top: 12,
    left: -12,
  },
  inlineIcon: {
    marginRight: 10,

  },
  largeIcon: {
    marginTop: 24,
  },
});