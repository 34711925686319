export enum Palette {
  Affirm100Pct = "#8CC646",
  Affirm25Pct = "#E2F1D1",
  Accent = "#0D78CB",
  Accent25Pct = "#C2DDF2",
  Accent10Pct = "#E7F2FA",

  // blue
  Primary100Pct = "#2C4A62",
  Primary90Pct = "#415C72",
  Primary75Pct = "#617789",
  Primary50Pct = "#95A4B0",
  Primary25Pct = "#CAD2D8",
  Primary10Pct = "#EAEDEF",
  Primary5Pct = "#F4F6F7",
  // orange
  Secondary100Pct = "#E2A154",
  Secondary50Pct = "#F0D0A9",
  Secondary25Pct = "#F8E7D4",
  Secondary10Pct = "#FCF6EE",
  // red
  Warning100Pct = "#FF5555",
  Warning25Pct = "#FFD4D4",
  White = "#FFFFFF",
}
