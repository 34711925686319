import { Inject } from "@not-the-droids/exco-ts-inject";
// import { Owner } from "./Owner";

// export interface CreateOwnerParams extends Partial<Owner> {}

export abstract class SignatureModel {
  static inject?: Inject<SignatureModel> = undefined;
  public abstract generateDocument(options:any): Promise<any>;
  public abstract generateNewSignUrls(options:any): Promise<any>;
  public abstract getDocument(options:any): Promise<any>;
}
