import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, configure, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { FullScreenLoadingView } from "./components/FullScreenLoadingView";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { withTitle } from "./components/TitleComponent";
import { History, HistoryInjectable } from "./HistoryInjectable";
import { withInjectedFactory, withInjectedProps } from "./InjectorContext";
import { FullLayout } from "./layouts/FullLayout";
import { getRoute } from "./routes";
import { DashboardScreenFactory } from "./screens/DashboardScreen";
import { InvitationsScreenFactory } from "./screens/InvitationsScreen";
import { LandingScreen } from "./screens/LandingScreen";
import { NotFoundScreen } from "./screens/NotFoundScreen";
import { ProfileScreen } from "./screens/ProfileScreen";
import { ProjectCrudScreenFactory } from "./screens/ProjectCrudScreen";
import { ProjectScreenFactory } from "./screens/ProjectScreen";
import { ProjectsScreen } from "./screens/ProjectsScreen";
import { SignUpScreen } from "./screens/SignUpScreen";
import { VerifyContractorsScreen } from "./screens/VerifyContractorsScreen";
import { VerifyEmailScreen } from "./screens/VerifyEmailScreen";
import { UserViewModel } from "./viewModels/UserViewModel";

interface Props {
  userViewModel: UserViewModel;
  history: History;
}

configure({
  enforceActions: "never",
});

const LandingScreenWithTitle = withTitle(withInjectedProps(LandingScreen));
const VerifyEmailScreenWithTitle = withTitle(
  withInjectedProps(VerifyEmailScreen),
  "Verify Email"
);
const SignUpScreenWithTitle = withTitle(
  withInjectedProps(SignUpScreen),
  "Sign Up"
);
const NotFoundScreenWithTitle = withTitle(
  withInjectedProps(NotFoundScreen),
  "Not Found"
);
const ProfileScreenWithTitle = withTitle(
  withInjectedProps(ProfileScreen),
  "Profile"
);
const VerifyContractorsScreenWithTitle = withTitle(
  withInjectedProps(VerifyContractorsScreen),
  "Verify Contractors"
);
const ProjectsScreenWithTitle = withTitle(
  withInjectedProps(ProjectsScreen),
  "Projects"
);

const DashboardScreenWithTitleFactory = withTitle(
  withInjectedFactory(DashboardScreenFactory),
  "Dashboard"
);

const InvitationsScreenWithTitleFactory = withTitle(
  withInjectedFactory(InvitationsScreenFactory),
  "Invitations"
);
const ProjectScreenWithTitleFactory = withTitle(
  withInjectedFactory(ProjectScreenFactory),
  "Project"
);
const NewProjectScreenWithTitleFactory = withTitle(
  withInjectedFactory(ProjectCrudScreenFactory),
  "New Project"
);
const EditProjectScreenWithTitleFactory = withTitle(
  withInjectedFactory(ProjectCrudScreenFactory),
  "Edit Project"
);

@observer
export class App extends React.Component<Props> {
  static inject: Inject<ReactElement> = (injector) => {
    return () => (
      <App
        userViewModel={injector.get(UserViewModel)()}
        history={injector.get(HistoryInjectable)()}
      />
    );
  };

  @computed get initialized() {
    return this.props.userViewModel.initialized;
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.props.userViewModel.initialize();
  }

  render() {
    if (!this.initialized) {
      return <FullScreenLoadingView />;
    }

    return (
      <FullLayout>
        <Router history={this.props.history}>
          <Switch>
            <Route path="/" exact component={LandingScreenWithTitle} />
            <ProtectedRoute
              exact
              path={getRoute("verifyEmail").path}
              component={VerifyEmailScreenWithTitle}
              options={{
                // NOTE: these are required here to prevent infinite redirect
                bypassUserVerification: true,
                bypassUserSetup: true,
              }}
            />
            <ProtectedRoute
              path={getRoute("signUp").path}
              component={SignUpScreenWithTitle}
              options={{
                // NOTE: this is required here to prevent infinite redirect
                bypassUserSetup: true,
              }}
            />
            <ProtectedRoute
              key="dashboard"
              path={getRoute("dashboard").path}
              render={({ match }) => (
                <DashboardScreenWithTitleFactory
                  key={"dashboard"}
                  currentPath={match.path}
                />
              )}
            />
            <ProtectedRoute
              key="project"
              path={getRoute("project").path}
              render={(props) => (
                <ProjectScreenWithTitleFactory
                  projectId={props.match.params.id!}
                />
              )}
            />
            <ProtectedRoute
              key="projects"
              exact
              path={getRoute("projects").path}
              render={() => <ProjectsScreenWithTitle />}
            />
            <ProtectedRoute
              path={getRoute("newProject").path}
              render={() => <NewProjectScreenWithTitleFactory />}
            />
            <ProtectedRoute
              path={getRoute("editProject").path}
              render={(props) => (
                <EditProjectScreenWithTitleFactory
                  projectId={props.match.params.id!}
                />
              )}
            />
            <ProtectedRoute
              path={getRoute("invitations").path}
              render={({ match }) => (
                <InvitationsScreenWithTitleFactory currentPath={match.path} />
              )}
            />
            {/* TODO: settings screen not being used for V1 - EY */}
            {/* <ProtectedRoute
              exact
              path={getRoute("settings").path}
              component={withTitle(
                withInjectedProps(SettingsScreen),
                "Settings"
              )}
            /> */}
            <ProtectedRoute
              exact
              path={getRoute("verifyContractors").path}
              component={VerifyContractorsScreenWithTitle}
            />
            <ProtectedRoute
              path={getRoute("profile").path}
              component={ProfileScreenWithTitle}
            />
            <Route exact path={getRoute("login").path}>
              <Redirect to="/" />
            </Route>
            <Route>
              <NotFoundScreenWithTitle />
            </Route>
          </Switch>
        </Router>
      </FullLayout>
    );
  }
}

export default withInjectedProps(App);
