import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 24'
    >
      <g
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        clipPath='url(#clip0_1553_12926)'
      >
        <path d='M16 16l-4-4-4 4M12 12v9'/>
        <path d='M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3'/>
        <path d='M16 16l-4-4-4 4'/>
      </g>
      <defs>
        <clipPath id='clip0_1553_12926'>
          <path fill='#fff' d='M0 0H24V24H0z'/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(Icon);
