import { useMemo } from "react";
import { StyleSheet, StyleProp, ViewStyle } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Palette } from "../styles";
import { StyledText } from "./StyledText";
import { v4 as uuidv4 } from "uuid";
import { formatDateLongMonth } from "../../utils/Strings";
import { Icon } from ".";

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  placeholder?: string;
  value?: Date | null;
  onChange: (date: Date) => void;
};

export const DateInput: React.FunctionComponent<Props> = ({
  containerStyle,
  placeholder = "Select Date",
  onChange,
  value,
}) => {
  const hasDate = !!value;
  const inputID = useMemo(() => uuidv4(), []);

  const handleFakeInputPress = () => {
    (document.getElementById(inputID) as any)?.showPicker();
  };

  const handleDateChange = (string: string) => {
    const date = new Date(string);
    onChange(date);
  };

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={handleFakeInputPress}
    >
      <input
        id={inputID}
        type={"date"}
        onChange={(e) => handleDateChange(e.target.value)}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          opacity: 0,
          width: 0,
          height: 0,
        }}
      />

      <Icon name="calendar" width={20} height={20} type={"accent"} />

      <StyledText
        style={[
          styles.text,
          { color: hasDate ? Palette.Primary100Pct : Palette.Primary25Pct },
        ]}
        variant="body"
        isMedium
      >
        {hasDate ? formatDateLongMonth(value) : placeholder}
      </StyledText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    maxWidth: 170,
    borderRadius: 4,
    borderColor: Palette.Primary25Pct,
    borderWidth: 1,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 10,
  },
  text: {
    marginLeft: 10,
  },
});
