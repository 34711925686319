import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, observable, makeObservable } from "mobx";
import { Budget, BudgetMilestone, PaymentPhase } from "../../../data-model/Budget";
import { Bid, BidLine } from "../../../data-model/Bid";
import { parsePriceInput, parseSavedPriceToInput } from "../utils/Numbers";

// types to handle input validation regarding the decimal is inputs.

export interface PaymentDetail  {
  name: string;
  date: Date;
  type: string;
  amount: number;
}
export interface ProjectPayment {
  phases: PaymentPhase[];
  nextPayment: PaymentPhase | undefined;
  phasesPreview: PaymentPhase[];
  payments: PaymentDetail[];
};

export type BudgetOptionsRequired = "Yes" | "No" | "Without";

export type BidLineInput = Omit<BidLine, "cost"> & {
  cost: string;
  workAreas?: string[];
};

export type BidInput = Omit<Bid, "lines"> & {
  lines: BidLineInput[];
};

export const mapBidToBidInput = (bid: Bid): BidInput => ({
  ...bid,
  lines: bid.lines.map((line) => ({
    ...line,
    cost: parseSavedPriceToInput(line.cost),
  })),
});

export const mapBidInputToBid = (bid: BidInput): Bid => ({
  ...bid,
  lines: bid.lines.map((line) => ({
    ...line,
    cost: parsePriceInput(line.cost),
  })),
});

export class ProjectDetailsFlow {
  constructor() {
    makeObservable(this);
  }
  static inject: Inject<ProjectDetailsFlow> = singletonInject((injector) => {
    return () => new ProjectDetailsFlow();
  });

  @observable public bid: Bid | undefined;
  // Section: bid flow
  @observable public isBidEditView: boolean = false;
  @observable public bidInput: BidInput | undefined;
  @observable public bidLinesAdded: BidLineInput[] = [];
  @observable public bidLinesDeleted: BidLineInput[] = [];

  @computed get projectedTotal(): number {
    let totalCost: number = 0;
    if (!this.bidInput) {
      return totalCost;
    }
    this.bidInput.lines?.forEach((bidLine) => {
      totalCost += parsePriceInput(bidLine.cost);
    });
    this.bidLinesAdded.forEach((bidLine) => {
      totalCost += parsePriceInput(bidLine.cost);
    });
    return totalCost;
  }

  // Section: budget
  @observable public isBudgetEditView: boolean = true;
  @observable public budget: Budget | undefined;
  @observable public userSawLastBudgetMilestone: boolean = false;
  @observable public arePermitsRequired: BudgetOptionsRequired = "Yes";
  @observable public isInspectionRequired: BudgetOptionsRequired = "Yes";
  @observable public selectedOrderIndex: number = -1;
  @observable public selectedMilestone?: BudgetMilestone;

  @computed public get phasesTotalCost(): number {
    let cost: number = 0;
    if (this.budget?.phases) {
      cost = this.budget.phases.reduce(
        (previousValue, currentValue) =>
          previousValue + Number(currentValue.cost),
        0
      );
    }
    return Math.round((cost + Number.EPSILON) * 100) / 100;
  }

  @computed public get phasesTotalDays(): number {
    return (
      this.budget?.phases?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.numDays,
        0
      ) || 0
    );
  }

  // Section: Payments
  @observable public paymentView:
    | "dashboard"
    | "make-payment"
    | "confirmation" = "dashboard";
  @observable public paymentData: ProjectPayment | undefined = undefined;
  @observable public paymentConfirmation: any = undefined;
  @observable public selectedPaymentPhases: string[] = [];
}
