import { Snackbar } from "react-native-paper";
import { StyleSheet, Text } from "react-native";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Palette } from "./styles/Palette";
import { Notification, NotificationType } from "../NotificationInjectable";
import { Inject } from "@not-the-droids/exco-ts-inject";

interface Props {
  notification: Notification;
}

interface CreateProps {}
export class ToastFactory {
  static inject: Inject<ToastFactory> = (injector) => {
    return () =>
      new ToastFactory({
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <Toast {...this.props} {...props} />;
  }
}

const Toast = observer((props: Props) => {
  const { notification } = props;

  const getColor = (notificationType: NotificationType) => {
    switch (notificationType) {
      case "error":
        return Palette.Warning100Pct;
      case "success":
        return Palette.Affirm100Pct;
      default:
        return "transparent";
    }
  };

  let { message, type } = toJS(notification.notification);

  return (
    <div id="toast-container">
      <Snackbar
        visible={!!message}
        duration={2000}
        onDismiss={() => notification.resetNotification()}
        style={{
          backgroundColor: getColor(type as NotificationType),
        }}
      >
        <Text style={styles.content}>{message}</Text>
      </Snackbar>
    </div>
  );
});

const styles = StyleSheet.create({
  content: {
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
  },
});

export default Toast;
