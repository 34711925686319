import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { StyledTouchableOpacity } from "./StyledTouchableOpacity";
import { Icon, IconSize } from "./Icon";
import { IconName, Palette } from "../styles";

type Size = 20 | 32 | 48 | 144;
type BackgroundColor = "accent" | "affirm" | "secondary";
type BackgroundColors = Record<BackgroundColor, StyleProp<ViewStyle>>;

interface Props {
  backgroundColor: BackgroundColor;
  icon: IconName;
  iconSize: IconSize;
  onPress?: () => void;
  size: Size;
  style?: StyleProp<ViewStyle>;
}

export const CircleIcon: React.FunctionComponent<Props> = (props) => {
  const { backgroundColor, icon, iconSize, onPress, size, style } = props;
  const sizeStyle = { width: size, height: size };
  const backgroundColorStyle = backgroundColors[backgroundColor];
  const WrapperComponent = onPress ? StyledTouchableOpacity : View;
  return (
    <WrapperComponent
      style={[styles.container, backgroundColorStyle, sizeStyle, style]}
      onPress={onPress}
    >
      <Icon name={icon} type={"white"} size={iconSize} />
    </WrapperComponent>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
});

const backgroundColors: BackgroundColors = {
  accent: {
    backgroundColor: Palette.Accent,
  },
  affirm: {
    backgroundColor: Palette.Affirm100Pct,
  },
  secondary: {
    backgroundColor: Palette.Secondary100Pct,
  },
};
