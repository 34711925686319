import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Icon, StyledButton, StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";
import { BudgetMilestone, BudgetPhase, MilestoneCompletionType } from "../../../data-model";
import { formatCurrencyToString } from "../utils/Numbers";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { withInjectedFactory } from "../InjectorContext";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { Tag } from "./controls/Tag";
import { formatCompletionTagProps } from "../utils/Strings";


interface CreateProps {
  milestones: Array<BudgetMilestone>;
  onAddMilestonePress: () => void;
  phase: BudgetPhase;
}

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
}

export class ManagementPhaseItemFactory {
  static inject: Inject<ManagementPhaseItemFactory> = (injector) => {
    return () =>
      new ManagementPhaseItemFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ManagementPhaseItem {...this.props} {...props} />;
  }
}

@observer
export class ManagementPhaseItem extends React.Component<CreateProps & Props> {
  constructor(props: CreateProps & Props) {
    super(props);
    makeObservable(this);
  }

  @observable private isOpen: boolean = false;

  @computed private get milestoneItems() {
    const { milestones, projectDetailsFlow: {selectedOrderIndex}, projectWidgetManager } = this.props;
    return (
      milestones.map((milestone) => {
        const isSelected = selectedOrderIndex === milestone.orderIndex;
        const tasksCompleted = milestone.tasks.reduce((previousValue, currentTask) => previousValue + (currentTask.completed ? 1 : 0), 0);
        const numMessages = projectWidgetManager.commentsByTag[milestone.name]?.comments.length || 0;
        return (
          <StyledTouchableOpacity
            key={"milestonItems" + milestone.orderIndex}
            style={[milestoneStyles.milestoneArea, isSelected && milestoneStyles.milestoneBorderDark]} 
            onPress={() => this.handleSelectedMilestone(milestone.orderIndex, milestone)}
          >
            <View style={milestoneStyles.milestoneInfo}>
              {/* Left Container */}
              <View style={styles.itemContainer}>
                <StyledText variant="body" isBold={true}>
                  {milestone.name}
                </StyledText>
              </View>
              
              {/* Right Container */}
              <View style={styles.itemContainer}>
                <View style={[milestoneStyles.iconText, {marginRight: "1.2vw"}]}>
                  <Icon name="checklist" type="gray" size={20}/>
                  <StyledText variant="body" colorMode="gray" isBold>
                    {`${tasksCompleted}/${milestone.tasks.length}`}
                  </StyledText>
                </View>
                <View style={[milestoneStyles.iconText, {marginRight: "1.2vw"}]}>
                  <Icon name="message-circle" type="gray" size={20}/>
                  <StyledText variant="body2" colorMode="gray" isBold>
                    {numMessages}
                  </StyledText>
                </View>
                <Tag {...formatCompletionTagProps(milestone.completion)}/>
              </View>
            </View>
          </StyledTouchableOpacity>
        );
      })
    );
  }

  readonly handlePhaseCompletion = () => {
    const { milestones } = this.props;
    const completionSet: Set<MilestoneCompletionType> = new Set();
    for (let i = 0; i < milestones.length; i++) {
      completionSet.add(milestones[i].completion);
    }

    let completionStatus: MilestoneCompletionType = "completed"
    if (completionSet.has("needs-review")) {
      completionStatus = "needs-review";
    } else if (completionSet.has("in-progress")) {
      completionStatus = "in-progress";
    } else if (completionSet.has("not-started")) {
      if (completionSet.has("completed")) {
        completionStatus = "in-progress"
      } else {
        completionStatus = "not-started";
      }
    }

    return completionStatus;
  }

  readonly handleSelectedMilestone = (orderIndex: number, milestone: BudgetMilestone) => {
    const { projectDetailsFlow } = this.props;
    projectDetailsFlow.selectedOrderIndex = orderIndex;
    projectDetailsFlow.selectedMilestone = milestone;
  }

  render() {
    const { phase } = this.props;
    const colorMode = this.isOpen ? "light" : "dark"
    return (
      <>
        {/* Head */}
        <StyledTouchableOpacity style={[styles.phase, this.isOpen && styles.phaseBorderDark]} onPress={() => this.isOpen = !this.isOpen}>
          <View style={styles.phaseInfo}>
            {/* Left Container */}
            <View style={styles.itemContainer}>
              {
                this.isOpen ? (
                  <Icon name="phase-arrow-down" size={20} type={"white"}/>
                ) : (
                  <Icon name="phase-arrow-right" size={20}/>
                )
              }
              <StyledText variant="body" colorMode={colorMode} isBold={true} style={{marginLeft: "0.5vw"}}>
                {phase.name}
              </StyledText>
            </View>
            
            {/* Right Container */}
            <View style={styles.itemContainer}>
              <Tag {...formatCompletionTagProps(this.handlePhaseCompletion())} style={{marginRight: "1.2vw"}}/>
              <StyledText variant="body" colorMode="gray" style={{marginRight: "2vw"}}>
                {phase.numDays !== null ? `${phase.numDays} Days` : `? Days`}
              </StyledText>
              <StyledText variant="body" colorMode={colorMode} isBold={true}>
                {formatCurrencyToString(phase.cost, "$0.00")}
              </StyledText>
            </View>
          </View>
        </StyledTouchableOpacity>

        {/* Body */}
        {
          this.isOpen && (
            <View style={styles.phaseBody}>
              <View style={styles.bodyContainer}>
                {this.milestoneItems}
                <StyledButton 
                  onPress={() => {}}
                  style={styles.addMilestoneButton}
                  variant={"secondary"}
                  text={"Add Milestone"}
                />
              </View>
            </View>
          )
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  phase: {
    justifyContent: "center",
    backgroundColor: Palette.Primary10Pct,
    border: "1px solid " + Palette.Primary25Pct,
    borderRadius: 4,
    minHeight: 56,
    zIndex: 1,
  },
  phaseBorderDark: {
    zIndex: 1,
    backgroundColor: Palette.Primary100Pct,
    border: "1px solid " + Palette.Primary50Pct,
  },
  phaseInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    justifyContent: "space-between",
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  phaseBody: {
    backgroundColor: Palette.White,
    border: "1px solid " + Palette.Primary50Pct,
    borderRadius: 4,
    top: -56,
    marginBottom: -56,
    zIndex: 0,
  },
  bodyContainer: {
    flexDirection: "column",
    gap: 8,
    justifyContent: "space-between",
    margin: 16,
    marginTop: 72,
  },
  addMilestoneButton: {
    marginTop: 8,
    width: "fit-content",
    paddingHorizontal: 35,
  },
});

const milestoneStyles = StyleSheet.create({
  milestoneArea: {
    justifyContent: "center",
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
    borderRadius: 4,
    minHeight: 56,
    flex: 1,
  },
  milestoneBorderDark: {
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary50Pct,
  },
  milestoneInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    justifyContent: "space-between",
  },
  iconText: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 4,
  },
});

export const InjectedManagementPhaseItem = withInjectedFactory(
  ManagementPhaseItemFactory
);
