import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import {
  StyleSheet,
  View,
} from "react-native";
import {
  BudgetMilestone,
  BudgetModel, BudgetPhase, Project,
} from "../../../data-model/";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString } from "../utils/Numbers";
import { StyledText } from "./controls";
import { LoadingIndicator } from "./LoadingIndicator";
import { InjectedManagementPhaseItem } from "./ManagementPhaseItem";
import { InjectedManagementSidebarView } from "./ManagementSidebarView";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { RenderModal } from "./RenderModal";
import { Palette } from "./styles";

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
}

interface CreateProps {
  project: Project;
}

export class ProjectMilestonesViewFactory {
  static inject: Inject<ProjectMilestonesViewFactory> = (injector) => {
    return () =>
      new ProjectMilestonesViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectMilestonesView {...this.props} {...props} />;
  }
}

@observer
class ProjectMilestonesView extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  @observable private _initialized: boolean = false;
  @observable private showEditModal: boolean = false;

  @computed public get milestoneHeader() {
    return (
      <View style={styles.milestoneHeader}>
        <StyledText variant="heading3" colorMode="dark" isBold={true}>
          {"Phases"}
        </StyledText>

        <View style={styles.headerRight}>
          <View style={styles.row}>
            <StyledText
              variant="heading3"
              colorMode="dark"
              style={{ marginBottom: 4 }}
            >{`Total Time: `}</StyledText>
            <StyledText variant="heading3" colorMode="dark" isBold={true}>
              {this.totalProjectTime
                ? `${this.totalProjectTime} Days`
                : "--"}
            </StyledText>
          </View>
          <View style={[styles.row, { marginLeft: 24 }]}>
            <StyledText
              variant="heading3"
              style={{ marginBottom: 4 }}
            >{`Total Cost: `}</StyledText>
            <StyledText variant="heading3" isBold={true}>
              {formatCurrencyToString(this.totalProjectPrice)}
            </StyledText>
          </View>
        </View>
      </View>
    );
  };

  @computed public get phaseItems() {
    const { projectDetailsFlow: { budget } } = this.props;
    return (
      budget?.phases.map((phase) => {
        const milestoneRows: Array<BudgetMilestone> = [];
        const milestoneIndices = phase.milestoneIndices;
        if (budget?.milestones && budget.milestones.length > 0) {
          for (let i = 0; i < milestoneIndices.length; i++) {
            let milestone = budget.milestones.find(milestone => 
              milestone.orderIndex === milestoneIndices[i]
            )
            if (milestone) milestoneRows.push(milestone);
          }
          return (
            <InjectedManagementPhaseItem
              key={'managementPhaseItem' + phase.orderIndex}
              milestones={milestoneRows}
              onAddMilestonePress={this.handleEditModalPress}
              phase={phase}
            />
          )
        }
        return undefined;
      })
    );
  };

  @computed public get totalProjectTime(): number {
    let totalDays: number = 0;
    this.props.projectDetailsFlow.budget?.phases.forEach((phase: BudgetPhase) => {
      totalDays += Number(phase.numDays);
    });
    return totalDays;
  }

  @computed public get totalProjectPrice(): number {
    let totalCost: number = 0;
    this.props.projectDetailsFlow.budget?.phases.forEach((phase: any) => {
      totalCost += Number(phase.cost);
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  }

  readonly componentDidMount = async () => {
    const { budgetModel, project, projectDetailsFlow, projectWidgetManager } = this.props;
    try {
      const budget = await budgetModel.getBudgetByProjectId(project.id);
      await projectWidgetManager.loadComments(project.id, "budget");
      projectDetailsFlow.budget = budget;
      projectDetailsFlow.selectedMilestone = undefined;
      projectDetailsFlow.selectedOrderIndex = -1;
      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  readonly renderBidEditModal = () => {
    return (
      <RenderModal
        onClick={() => (this.showEditModal = !this.showEditModal)}
        isOpen={this.showEditModal}
        isHorizontallyCentered={true}
        isVerticallyCentered={true}
      >
      </RenderModal>
    );
  };

  readonly handleEditModalPress = () => {
    this.showEditModal = true;
  }

  render() {
    const { project, projectDetailsFlow: { budget, selectedMilestone } } = this.props;
    if (!this._initialized) return <LoadingIndicator />;
    
    return (
      <View style={styles.container}>
        {/* Milestone Section */}
        <View style={styles.milestonesSection}>
          <View style={styles.milestonesContainer}>
            {this.milestoneHeader}
            {this.phaseItems}
          </View>
        </View>

        {/* Sidebar Section */}
        <View style={styles.sidebarSection}>
          {
            selectedMilestone && (
              <View style={styles.sidebarInner}>
                <InjectedManagementSidebarView
                  budget={budget!}
                  project={project}
                  selectedMilestone={selectedMilestone}
                />
              </View>
            )
          }
        </View>

        {/* Modal Section */}
        {this.renderBidEditModal()}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    marginTop: -65,
    marginRight: -84,
    marginBottom: -98,
  },
  milestonesSection: {
    flex: 2,
  },
  milestonesContainer: {
    flexDirection: "column",
    gap: 8,
    padding: 8,
    marginTop: 36,
    marginRight: 24,
    marginBottom: 98,
    backgroundColor: "#F4F6F7",
    border: "1px solid" + Palette.Primary10Pct,
    borderRadius: 8,
  },
  milestoneHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    marginVertical: 8,
  },
  sidebarSection: {
    flex: 1,
  },
  sidebarInner: {
    flex: 1,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
  },
  row: {
    flexDirection: "row",
  },
  headerRight: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: 5,
  },
});

export const InjectedProjectMilestonesView = withInjectedFactory(
    ProjectMilestonesViewFactory
);
