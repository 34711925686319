import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { PaymentPhase } from "../../../../data-model";
import { formatPaymentPhaseDateTextAndTag } from "../../utils/Budget";
import { formatCurrencyToString } from "../../utils/Numbers";
import {
  formatDateLongMonth,
} from "../../utils/Strings";
import { StyledButton, StyledText } from "../controls";
import { Tag } from "../controls/Tag";
import { Palette } from "../styles";
import { PaymentPhaseTabHeader } from "./PaymentPhaseTabHeader";

interface Props {
  phase: PaymentPhase | undefined;
  showFundPhaseButton: boolean;
  onFundPhaseClick: (id: string) => void;
}

export const PaymentNextPhasePreview: FunctionComponent<Props> = ({
  phase,
  showFundPhaseButton,
  onFundPhaseClick,
}) => {
  if (!phase) {
    return (
      <View style={styles.container}>
        <PaymentPhaseTabHeader
          title={"Upcoming payments"}
          onInfoClick={() => {}}
        />
        <View style={styles.phaseContainer}>
          <StyledText variant="body" colorMode="gray" style={styles.noPayments}>
            No upcoming payments
          </StyledText>
        </View>
      </View>
    );
  }

  const showFundButton = showFundPhaseButton && !phase.payment;
  const { dateTag, startsText, startsTextColor } =
    formatPaymentPhaseDateTextAndTag(phase);

  return (
    <View style={styles.container}>
      <PaymentPhaseTabHeader
        title={"Upcoming payments"}
        onInfoClick={() => {}}
      />
      <View style={styles.phaseContainer}>
        <StyledText isBold={true} style={styles.phaseHeader}>
          {phase.name}
        </StyledText>
        <StyledText
          variant="body"
          style={[
            styles.startsIn,
            {
              color: startsTextColor,
            },
          ]}
        >
          {startsText}
        </StyledText>
        <Tag type={dateTag.type} text={dateTag.text} />

        {/* Total */}
        <StyledText variant="body" style={styles.header2} isMedium>
          Estimated Total
        </StyledText>
        <StyledText isBold={true} style={styles.value}>
          {formatCurrencyToString(phase.cost)}
        </StyledText>

        {/* Start date */}
        <StyledText variant="body" style={styles.header2} isMedium>
          Start Date
        </StyledText>
        <StyledText isBold={true} style={styles.lastValue}>
          {formatDateLongMonth(phase.startDate)}
        </StyledText>

        {/* FundButton */}
        {showFundButton && (
          <StyledButton
            style={styles.fundButton}
            onPress={() => onFundPhaseClick(phase.id!)}
            variant="secondary"
            text="Fund Phase"
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    width: "100%",
    marginBottom: 16,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
  },
  noPayments: {
    fontSize: 18,
    alignSelf: "center",
  },
  phaseHeader: {
    fontSize: 22,
    marginBottom: 8,
  },
  header2: {
    marginTop: 32,
    marginBottom: 6,
  },
  value: {
    fontSize: 17,
    marginBottom: 12,
  },
  lastValue: {
    fontSize: 17,
  },
  phaseContainer: {
    marginTop: 16,
    flex: 1,
    backgroundColor: Palette.White,
    borderRadius: 8,
    padding: 16,
  },
  startsIn: {
    marginBottom: 5,
  },

  fundButton: {
    position: "absolute",
    width: 140,
    bottom: 16,
    right: 16,
  },
});
