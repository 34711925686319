import React from "react";
import { View, StyleSheet } from "react-native";

interface Props { }

// TODO: this screen component is meant for further development with SafeAreaInsetsContext capabilities, as needed. - EY
export const Screen: React.FunctionComponent<Props> = (props) => {
  return <View style={styles.main}>{props.children}</View>;
};

const styles = StyleSheet.create ({
  main : {
    flex: 1,
    flexDirection: "row",
  },
});