export type FormatNumberOptionsType = {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
};

export const formatCurrencyToString = (
  value: string | number | null | undefined,
  zeroPlaceholder: string = "$--"
): string => {
  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  };
  if (typeof value === "string") value = parsePriceInput(value);
  return value?.toLocaleString("en-US", formatOptions) ?? zeroPlaceholder;
};

export const sanitizePriceInput = (value: string): string => {
  if (!value.substring(0, 1).match(/[0-9]/)) return "";
  return value.replace(/^(\d+(\.\d{0,2})?).*/gm, "$1");
};

export const parsePriceInput = (value: string | number): number => {
  if (!value) return 0;
  if (typeof value === "number") return value;
  if (value.startsWith("$")) value = value.substring(1);
  const parsed = parseFloat(value);
  return isNaN(parsed) ? 0 : parsed;
};

export const parseSavedPriceToInput = (
  value: number | string | undefined | null
): string => {
  if (!value || value === 0 || value === "0") return "";
  return `${value}`;
};

export const isStringNumeric = (value: string) => {
  return isFinite(parseFloat(value));
};

export const romanize = (num: number) => {
  if (isNaN(num)) {
    return NaN;
  }
  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3;
  while (i--) {
    roman = (key[+digits.pop()! + i * 10] || "") + roman;
  }
  return Array(+digits.join("") + 1).join("M") + roman;
};

export const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
