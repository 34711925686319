import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";

export type NotificationType = "default" | "success" | "error";

interface NotificationProps {
	type?: NotificationType;
	message: string;
}

export class Notification {
	constructor() {
		makeObservable(this);
	}

	static inject: Inject<Notification> = singletonInject((injector) => {
		return () => new Notification();
	});

	@observable public notification: NotificationProps = {
		type: undefined,
		message: "",
	};

	resetNotification() {
		this.notification = { type: undefined, message: "" };
	}

	setNotification(type: NotificationType, message: string) {
		this.notification = { type, message };
	}
}
