import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { formatCurrencyToString } from "../utils/Numbers";
import {
  BudgetCategoryBody,
  BudgetCategoryBodyProps,
} from "./BudgetCategoryBody";
import {
  Icon,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import { Palette } from "./styles";

interface Props {
  index: number;
  isActive?: boolean;
  onClick: () => void;
  milestone: any;
  displayIndex: number;
}

type BudgetCategoryProps = Props & BudgetCategoryBodyProps;

@observer
export class BudgetCategory extends React.Component<BudgetCategoryProps> {
  @computed private get taskTotalPrice() {
    let total: number = 0;
    this.props.milestone.tasks?.forEach(
      (task: any) => (total += Number(task.budget))
    );
    return total;
  }

  @computed private get tasksComplete() {
    const descriptionFilled = this.props.milestone.tasks?.every(
      (task: any) => task.description !== ""
    );
    const costFilled = this.props.milestone.tasks?.every(
      (task: any) => task.budget > 0
    );
    return descriptionFilled && costFilled;
  }

  constructor(props: BudgetCategoryProps) {
    super(props);
    makeObservable(this);
  }

  readonly renderCategoryInput = () => {
    const { milestone, isActive, updateCategory } = this.props;
    return isActive ? (
      <View style={styles.row}>
        <StyledTextInput
          onChangeText={(value) => updateCategory(value, "csiCode")}
          placeholder="CSI Code"
          value={milestone.csiCode}
          style={{ flex: 1, marginRight: 8 }}
        />
        <StyledTextInput
          onChangeText={(value) => updateCategory(value, "name")}
          placeholder={milestone.name}
          style={{ flex: 3, marginRight: 16 }}
          value={milestone.name}
        />
      </View>
    ) : (
      <View style={styles.row}>
        <StyledText style={{ marginRight: 8 }}>{milestone.csiCode}</StyledText>
        <StyledText isBold={true}>{milestone.name}</StyledText>
      </View>
    );
  };

  render() {
    const { isActive } = this.props;
    return (
      <View style={isActive && styles.containerBorder}>
        <StyledTouchableOpacity
          style={styles.categoryContainer}
          onPress={(e) =>
            e.target.toString() !== "[object HTMLInputElement]" &&
            this.props.onClick()
          }
        >
          <View style={styles.leftColumn}>
            <Icon name={"move-vertical"} size={20} type="accent" />
            <StyledText
              isBold={true}
              colorMode="accent"
              style={{ marginRight: 12 }}
            >
              {this.props.displayIndex}
            </StyledText>
            {this.renderCategoryInput()}
          </View>
          <StyledText variant="body2">
            {formatCurrencyToString(this.taskTotalPrice)}
          </StyledText>
        </StyledTouchableOpacity>

        {this.props.isActive && (
          <BudgetCategoryBody {...this.props} isComplete={this.tasksComplete} />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  row: {
    flexDirection: "row",
  },
  containerBorder: {
    marginTop: 4,
    borderWidth: 2,
    borderColor: Palette.Primary10Pct,
  },
  categoryContainer: {
    // marginBottom: 4,
    paddingVertical: 10,
    paddingLeft: 8,
    paddingRight: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Palette.Primary5Pct,
  },
  leftColumn: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
});
