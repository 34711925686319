import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
      />
      <path
        stroke={color}
        d="M11.25 9.75a.75.75 0 100-1.5.75.75 0 000 1.5zM9 9.75a.75.75 0 100-1.5.75.75 0 000 1.5zM6.75 9.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
      />
    </svg>
  );
}

export default memo(Icon);
