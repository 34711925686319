
import React from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react";
import { TaskPrice } from "../../../data-model";
import { StyledText, StyledTextArea, StyledTextInput } from "./controls";

export interface MilestonePriceViewBodyProps {
  updateScope: (
    milestoneIndex: number,
    updatedScope: string
  ) => void;
  updateTask: (
    milestoneIndex: number,
    taskIndex: number,
    updatedPrice: string
  ) => void;
}

type Props = MilestonePriceViewBodyProps & {
  milestone: any;
  milestoneIndex: number;
}

@observer
export class MilestonePriceViewBody extends React.Component<Props> {

  renderTasks = () => {
    const { milestone, milestoneIndex, updateTask } = this.props;
    return milestone.tasks?.map((task: TaskPrice, index: number) => (
      <View key={index} style={[styles.row, styles.taskInputsContainer]}>
        <StyledText style={styles.taskInputCol}>{task.description}</StyledText>
        <StyledTextInput
          onChangeText={(text: string) => updateTask(milestoneIndex, index, text)}
          placeholder="$--"
          style={styles.budgetInputCol}
          value={task.budget ? task.budget.toString() : ""}
        />
      </View>
    ));
  };

  render() {
    const { milestone, milestoneIndex, updateScope } = this.props;
    return (
      <View style={[styles.container, styles.section]}>
        <View style={styles.sectionSmall}>
          <StyledText style={styles.header}>Scope of Work</StyledText>
          <StyledTextArea
            minHeight={88}
            onChangeText={(text: string) => updateScope(milestoneIndex, text)}
            placeholder="Enter a description of the scope of work for this milestone"
            value={milestone.scope}
          />
        </View>

        {
          milestone.tasks.length > 0 && (
            <View style={styles.section}>
              <StyledText style={styles.header} isBold={true}>Tasks</StyledText>
              <View style={[styles.row, styles.header]}>
                <StyledText variant="caption" style={styles.taskInputCol} isBold={true}>Task Description</StyledText>
                <StyledText variant="caption" style={styles.budgetInputCol} isBold={true}>Budget</StyledText>
              </View>
              {this.renderTasks()}
            </View>
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    marginHorizontal: 24,
  },
  row: {
    flexDirection: "row",
  },
  taskInputsContainer: {
    marginBottom: 8,
    alignItems: "center",
  },
  taskInputCol: {
    flex: 1,
  },
  budgetInputCol: {
    width: 88,
    marginLeft: 8,
  },
  header: {
    marginBottom: 8,
  },
  section: {
    marginBottom: 10,
  },
  sectionSmall: {
    marginBottom: 16,
  },
});
