import React from "react";
import { ImageStyle, StyleProp, ViewProps } from "react-native";
import { Icon } from "./controls";

interface Props extends ViewProps {
  isAlert?: boolean;
  isFilled?: boolean;
  onPress: () => void;
  style?: StyleProp<ImageStyle>;
}

export class CommentBubble extends React.Component<Props> {
  render() {
    const { isAlert, isFilled, onPress, style } = this.props;
    const iconName = isFilled ? "message-circle-filled" : ( isAlert ? "message-circle-alert" : "message-circle" );
    const type = isAlert ? "secondary" : "accent";
    return (
      <Icon
        name={iconName}
        type={type}
        size={16}
        style={style}
        onClick={onPress}
      />
    );
  }
}
