import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Route, Router, Switch } from "react-router-dom";
import { User, UserModel } from "../../../data-model";
import { ChangePasswordView } from "../components/ChangePasswordView";
import {
  Page,
  Screen,
  SideBar,
  StyledText,
  StyledTouchableOpacity
} from "../components/controls";
import { HeaderFactory } from "../components/Header";
import { PricingSettingsView } from "../components/PricingSettingsView";
import { ProfileViewFactory } from "../components/ProfileView";
import { SideBarMain } from "../components/SideBarMain";
import { Palette } from "../components/styles";
import { Authenticator } from "../exco-lib/exco-auth";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { InjectedFactoryComponent, withInjectedProps } from "../InjectorContext";
import { getRoute, Screen as ScreenType } from "../routes";

interface Props {
  history: History;
  authenticator: Authenticator;
  userModel: UserModel;
}

interface CreateProps {
  currentPath?: string;
}

const menuItems = ["profile", "pricingSettings"] as const;
type MenuItem = typeof menuItems[number];

interface MenuItemNav {
  title: string;
  path: MenuItem | "";
}
const menuItemNav: Record<MenuItem, MenuItemNav> = {
  profile: {
    path: "",
    title: "Profile",
  },
  pricingSettings: {
    path: "pricingSettings",
    title: "Pricing Settings",
  },
};

@observer
export class ProfileScreen extends React.Component<Props> {
  userObject = {}
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <ProfileScreen
        history={injector.get(HistoryInjectable)()}
        authenticator={injector.get(Authenticator)()}
        userModel={injector.get(UserModel)()}
      />
    );
  };
  // TODO: remove type assertion
  @observable currentUser: User = {} as User;

  readonly componentDidMount = async () => {
    this.currentUser= await this.props.userModel.getUser()
    this.userObject = toJS(this.currentUser)
  };


  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);

    const route = props.history.location.pathname;
    const routeParts = route.split("/");
    const pathname = routeParts[routeParts.length - 1] as MenuItem;
    if (menuItems.includes(pathname)) {
      this.selectedMenuItem = pathname;
    }
  }

  @observable selectedMenuItem: MenuItem = "profile";

  readonly onNavigateToScreen = (screen: ScreenType) => {
    const { history } = this.props;
    history.push(getRoute(screen).path);
  };

  readonly handleMenuClick = (item: MenuItem) => {
    const { history } = this.props;
    this.selectedMenuItem = item;
    history.push(
      `${getRoute("profile").path}/${menuItemNav[item].path}`
    );
  };

  readonly renderMenuItem = (item: MenuItem, index: number) => {
    const isSelected = this.selectedMenuItem === item;
    const menuItem = menuItemNav[item];
    return (
      (!(menuItem.title === "Pricing Settings") || this.currentUser.userType === "contractor") &&
      <StyledTouchableOpacity
        key={index}
        style={[styles.menuItem]}
        onPress={() => this.handleMenuClick(item)}
      >
        <StyledText isBold={true} colorMode={isSelected ? "accent" : "dark"}>
          {menuItem.title}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  render() {
    const { history } = this.props;
    return (
      <Screen>
        {/* Note: hiding "Invitation" from SideBar for V1. Reinstate it with footer="invitation" as props - EY */}
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedFactoryComponent factory={HeaderFactory} props={{}} />
          <View style={styles.row}>
            <View style={styles.menuItems}>
              {menuItems.map(this.renderMenuItem)}
            </View>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path={getRoute("profile").path}
                  render={props=> <InjectedFactoryComponent factory={ProfileViewFactory} props={{}} />}
                />
                <Route
                  exact
                  path={getRoute("changePassword").path}
                  component={ChangePasswordView}
                />
                {
                  this.currentUser.userType === "contractor" && (
                    <Route
                      exact
                      path={getRoute("pricingSettings").path}
                      component={withInjectedProps(PricingSettingsView)}
                    />
                  )
                }
              </Switch>
            </Router>
          </View>
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  menuItems: {
    flex: 1,
    alignItems: "flex-end",
    alignSelf: "baseline",
    paddingRight: 46,
    borderRightWidth: 1,
    borderRightColor: Palette.Primary10Pct,
    height: 328,
    maxWidth: 200, // arbitrary number; update when addressing mobile responsive styling - EY
  },
  menuItem: {
    marginTop: 24,
  },
});
