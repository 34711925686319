import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { Project } from "../../../../data-model";
import { capitalizeFirstLetter, structureAddress } from "../../utils/Strings";
import { StyledText } from "../controls";
import { SectionBlock } from "../SectionBlock";

interface Props {
  project?: Project;
}

export const ContractProjectDetail: FunctionComponent<Props> = ({ project }) => {
  return (
    <>
      <SectionBlock title="Project Address" style={styles.sectionSmall}>
        <StyledText style={styles.sectionText}>
          {project?.address?.line1 && structureAddress(project?.address)}
        </StyledText>
      </SectionBlock>

      <View style={[styles.row, styles.sectionSmall]}>
        <SectionBlock title="Location Type" style={styles.sectionBlockRowItem}>
          <StyledText style={styles.sectionText}>
            {project
              ? capitalizeFirstLetter(project!.locationType)
              : "Location Type"}
          </StyledText>
        </SectionBlock>
        <SectionBlock title="Work Type" style={styles.sectionBlockRowItem}>
          <StyledText style={styles.sectionText}>
            {project ? capitalizeFirstLetter(project!.workType) : "Work Type"}
          </StyledText>
        </SectionBlock>
        <SectionBlock title="Work Location" style={styles.sectionBlockRowItem}>
          <StyledText style={styles.sectionText}>
            {project
              ? capitalizeFirstLetter(project!.workLocation)
              : "Work Location"}
          </StyledText>
        </SectionBlock>
      </View>

      <SectionBlock title="Structure Description" style={styles.sectionSmall}>
        <StyledText style={styles.sectionText}>
          {`${
            project
              ? capitalizeFirstLetter(project!.description)
              : "Structure Description"
          }`}
        </StyledText>
      </SectionBlock>
    </>
  );
};

const styles = StyleSheet.create({
  sectionBlockRowItem: {
    flex: 1,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  sectionText: {
    lineHeight: 20,
  },
  row: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
});
