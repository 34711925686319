import { Inject } from "@not-the-droids/exco-ts-inject";
import { Contractor } from "./Contractor";
import { Verification } from "./Verification";

export interface UploadVerificationParams {
  contractorLicenseWaived: boolean;
  contractorLicenseUrls: string[];
  insuranceDocumentationUrls: string[];
  workersCompUrls: string[];
  cityBusinessLicenseUrls: string[];
  contractorLicenseBondWaived: boolean;
  contractorLicenseBondUrls: string[];
}

export interface ApproveContractorParams {
  userId: string;
}

export interface RejectContractorParams {
  userId: string;
}

export abstract class VerificationModel {
  static inject?: Inject<VerificationModel> = undefined;

  public abstract uploadVerification(
    params: UploadVerificationParams
  ): Promise<Contractor>;

  public abstract getAllVerifications(): Promise<Verification[]>;

  public abstract approveContractor(params: ApproveContractorParams): void;

  public abstract rejectContractor(params: RejectContractorParams): void;
}
