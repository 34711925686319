import React from "react";
import {
  StyleSheet,
  StyleProp,
  TextProps,
  View,
  ViewStyle,
} from "react-native";
import { Palette } from "../styles";

export type BackgroundVariant = "light" | "dark";

interface Props extends TextProps {
  variant?: BackgroundVariant;
  style?: StyleProp<ViewStyle>;
};

type Variants = Record<BackgroundVariant, StyleProp<ViewStyle>>;

export function Background(props: React.PropsWithChildren<Props>) {
  const { variant, style } = props;
  const variantStyle = variants[variant || "light"];

  return (
    <View style={[styles.main, variantStyle, style]}>
      {props.children}
    </View>
  );
}

const variants: Variants = {
  light: {
    backgroundColor: Palette.White,
  },
  dark: {
    backgroundColor: Palette.Primary100Pct,
  },
};

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
});