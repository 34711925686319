import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  Icon,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import { Palette } from "./styles";

interface Props { }

@observer
export class InvitationWidget extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <InvitationWidget />
    );
  };

  @observable private email: string = "";

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  render() {
    return (
      // Note: not making a styled text or styled text input variation for this widget because these css styles appears to be one-off - EY
      <View style={styles.container}>
        {/* TODO: Insert image here once finalized - EY */}
        <StyledText colorMode="light" style={styles.ctaCopy}>Invite Collaborators</StyledText>
        <View style={styles.inputFieldContainer}>
          <StyledTextInput
            variant="custom"
            onChangeText={(input) => this.email = input}
            placeholder="Enter Email Address"
            style={styles.inputField}
            textStyle={styles.inputFieldText}
            value={this.email}
          />
          {/* TODO: Implement button press - EY */}
          {/* Note: rewire email value to be managed from a Control View outside of class and inject as a singleton,
              or else re-routing to a different screen will reset widget. Ask me about implementation detail - EY */}
          <StyledTouchableOpacity style={styles.submitButton} onPress={() => {}}>
            <Icon name="send" type="white" size={14}/>
          </StyledTouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 76,
    paddingBottom: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
    backgroundColor: Palette.Primary90Pct,
  },
  ctaCopy: {
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 8,
    fontWeight: "bold",
  },
  inputFieldContainer: {
    height: 32,
    paddingLeft: 8,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Palette.Primary75Pct,
  },
  inputField: {
    flex: 1,
  },
  inputFieldText: {
    fontSize: 11,
    color: Palette.White,
  },
  submitButton: {
    width: 28,
    height: 28,
    borderRadius: 4,
    marginRight: 2,
    marginVertical: 2,
    backgroundColor: Palette.Secondary100Pct,
    alignItems: "center",
    justifyContent: "center",
  },
});
