import firebase from "firebase/app";
import "firebase/auth";
import { observable, computed, makeObservable } from "mobx";
import {
  Authenticator,
  AuthenticationProviderType,
  ThirdPartyAuthenticationProviderType,
  User,
 } from "../exco-auth";
import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { FirebaseWebAnonymousAuthenticationProvider } from "./FirebaseWebAnonymousAuthenticationProvider";
import {
  FirebaseWebAuthenticationProvider,
  FirebaseWebAuthenticationProviderDelegate,
  FirebaseWebThirdPartyAuthenticationProviders,
  FirebaseWebThirdPartyAuthenticationProvidersInjectable
} from "./FirebaseWebAuthenticationProvider";
import { FirebaseWebEmailLinkAuthenticationProvider } from "./FirebaseWebEmailLinkAuthenticationProvider";
import { FirebaseWebEmailAuthenticator } from "./FirebaseWebEmailAuthenticationProvider";

export class FirebaseWebAuthenticator extends Authenticator implements FirebaseWebAuthenticationProviderDelegate {
  static inject: Inject<FirebaseWebAuthenticator> = singletonInject((injector) => {
    return () => new FirebaseWebAuthenticator(
      firebase.auth(),
      injector.get(FirebaseWebThirdPartyAuthenticationProvidersInjectable)(),
    )
  });

  @observable private _initialized: boolean = false;
  @observable private _currentUser: User | undefined = undefined;

  @computed public get initialized(): boolean {
    return this._initialized;
  }

  @computed public get currentUser(): User | undefined {
    return this._currentUser;
  }

  public readonly anonymous: FirebaseWebAnonymousAuthenticationProvider;
  public readonly email: FirebaseWebEmailAuthenticator;
  public readonly emailLink: FirebaseWebEmailLinkAuthenticationProvider;
  public get facebook() { return this.thirdPartyProviders.facebook };
  public get apple() { return this.thirdPartyProviders.apple };
  public get google() { return this.thirdPartyProviders.google };
  public get twitter() { return this.thirdPartyProviders.twitter };
  public get phone() { return undefined };

  constructor(
    public readonly firebaseAuth: firebase.auth.Auth,
    private readonly thirdPartyProviders: FirebaseWebThirdPartyAuthenticationProviders
  ) {
    super();

    makeObservable(this);

    this.anonymous = new FirebaseWebAnonymousAuthenticationProvider();
    this.email = new FirebaseWebEmailAuthenticator();
    this.emailLink = new FirebaseWebEmailLinkAuthenticationProvider();

    const providers = new Array<FirebaseWebAuthenticationProvider<any>>(
      this.anonymous,
      this.email,
      this.emailLink,
    );

    for (const providerType in thirdPartyProviders) {
      const provider = thirdPartyProviders[providerType as ThirdPartyAuthenticationProviderType];
      if (provider) {
        providers.push(provider);
      }
    }
    providers.forEach(provider => provider.delegate = this);

    this.firebaseAuth.onAuthStateChanged(this.parseCurrentUser);
  }

  private readonly parseCurrentUser = (firebaseUser: firebase.User | null) => {
    if (firebaseUser) {
      this._currentUser = {
        id: firebaseUser.uid,
        providerData: {
          type: firebaseUser.providerData[0] ? parseAuthenticationProviderType(firebaseUser.providerData[0].providerId) : "anonymous",
          emailAddress: firebaseUser.email ?? undefined,
          displayName: firebaseUser.displayName ?? undefined,
          photoUrl: firebaseUser.photoURL ?? undefined,
          phoneNumber: firebaseUser.phoneNumber ?? undefined,
        }
      };
    } else {
      this._currentUser = undefined
    }
    if (!this._initialized) {
      this._initialized = true;
    }
  }

  public readonly signOut = () => {
    return this.firebaseAuth.signOut();
  }
}

function parseAuthenticationProviderType(firebaseProviderId: string): AuthenticationProviderType {
  switch (firebaseProviderId) {
    case firebase.auth.EmailAuthProvider.PROVIDER_ID:
      return "email";
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
      return "facebook";
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
      return "google";
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
      return "twitter";

  }
  throw new Error(`Unrecognized provider ID: ${firebaseProviderId}`);
}
