import React from "react";
import { View, StyleSheet } from "react-native";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHover } from "react-native-web-hooks";
import { computed, makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Authenticator } from "../exco-lib/exco-auth";
import {
  BidModel,
  BudgetMilestone,
  BudgetMilestoneTask,
  BudgetModel,
  BudgetPhase,
  Project,
  ProjectModel,
  UserModel,
} from "../../../data-model";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { withInjectedFactory } from "../InjectorContext";
import { Notification } from "../NotificationInjectable";
import {
  reindexBugetInput,
  sortOrderIndex,
  createBudgetInput,
} from "../utils/Budget";
import {
  formatCurrencyToString,
  isStringNumeric,
  parsePriceInput,
} from "../utils/Numbers";
import { UserViewModel } from "../viewModels/UserViewModel";
import { BudgetCategory } from "./BudgetCategory";
import { Task, WorkCategory } from "./BudgetCategoryBody";
import { CommentBubble } from "./CommentBubble";
import { Icon, StyledButton, StyledText, StyledTextInput } from "./controls";
import { DateInput } from "./controls/DateInput";
import { LoadingIndicator } from "./LoadingIndicator";
import { InjectedBudgetOptionsView } from "./BudgetOptionsView";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { Palette } from "./styles";
import { isEmptyString } from "../utils/Strings";

interface Props {
  authenticator: Authenticator;
  budgetModel: BudgetModel;
  history: History;
  projectDetailsFlow: ProjectDetailsFlow;
  projectWidgetManager: ProjectWidgetManager;
  projectModel: ProjectModel;
  notification: Notification;
  bidModel: BidModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  project: Project;
  changeBudgetView: () => void;
}

export type BudgetMilestoneTaskInput = Omit<BudgetMilestoneTask, "budget"> & {
  budget: string;
};

export type BudgetPhaseInput = BudgetPhase & {
  type: "phase";
  phaseIndex: number;
  renderIndex: number;
};

export type BudgetMilestoneInput = Omit<BudgetMilestone, "tasks" | "completion"> & {
  type: "milestone";
  milestoneIndex: number;
  renderIndex: number;
  tasks: BudgetMilestoneTaskInput[];
  workAreas?: string[];
};

export type BudgetInput = BudgetPhaseInput | BudgetMilestoneInput;

export class ProjectBudgetEditViewFactory {
  static inject: Inject<ProjectBudgetEditViewFactory> = (injector) => {
    return () =>
      new ProjectBudgetEditViewFactory({
        authenticator: injector.get(Authenticator)(),
        budgetModel: injector.get(BudgetModel)(),
        history: injector.get(HistoryInjectable)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        projectModel: injector.get(ProjectModel)(),
        notification: injector.get(Notification)(),
        bidModel: injector.get(BidModel)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBudgetEditView {...this.props} {...props} />;
  }
}

@observer
class ProjectBudgetEditView extends React.Component<CreateProps & Props> {
  @observable private _initialized: boolean = false;
  @observable public budgetInput: BudgetInput[] = [];
  @observable public dateInput: Date | null = null;
  @observable public isPreviewSubmitted: boolean = false;
  @observable public selectedPhaseIndex: number = -1;
  @observable public selectedMilestoneIndex: number = -1;
  @observable public milestonesToDelete: BudgetMilestoneInput[] = [];
  @observable public phasesToDelete: BudgetPhaseInput[] = [];
  @observable public tasksToDelete: BudgetMilestoneTask[] = [];

  @computed public get budgetId(): string {
    return this.props.projectDetailsFlow.budget!.id;
  }

  @computed public get totalProjectTime(): number {
    let totalDays: number = 0;
    this.budgetInput?.forEach((item: any) => {
      if (item.type === "phase") {
        totalDays += Number(item.numDays);
      }
    });
    return totalDays;
  }

  @computed public get totalProjectPrice(): number {
    let totalCost: number = 0;
    this.budgetInput?.forEach((item: any) => {
      if (item.type === "phase") {
        totalCost += item.cost;
      }
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    const {
      project,
      bidModel,
      projectModel,
      budgetModel,
      projectWidgetManager,
      projectDetailsFlow,
    } = this.props;
    try {
      const bid = await bidModel.getBidByProjectId(project.id);
      projectDetailsFlow.bid = bid;

      const budget = await budgetModel.getBudgetByProjectId(project.id);
      projectDetailsFlow.budget = budget;

      const workCategories = await projectModel.getWorkCategoriesByProjectId(
        project.id
      );

      this.budgetInput = createBudgetInput(budget, bid.lines, workCategories);
      this.dateInput = new Date(budget.startDate);
      this.calculatePhaseCost();

      await projectWidgetManager.loadComments(project.id, "budget", this.budgetId);

      // Open first milestone
      this.selectedMilestoneIndex = this.budgetInput.findIndex(
        (v) => v.type === "milestone"
      );

      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  readonly handleCommentBubblePress = (
    bubbleIndex: number,
    tagName: string,
    parentId: string
  ) => {
    const { projectWidgetManager } = this.props;
    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(tagName, parentId);
      this.props.projectWidgetManager.activeCommentIndex = bubbleIndex;
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  readonly appendMilestone = () => {
    // append to list.
    var milestones = this.budgetInput.filter((obj: any) => {
      return obj.milestoneIndex;
    });
    const newMilestone: BudgetMilestoneInput = {
      csiCode: "",
      name: "",
      scopeOfWork: "",
      tasks: [],
      files: [],
      orderIndex: this.budgetInput.length,
      renderIndex: this.budgetInput.length,
      milestoneIndex: milestones.length + 1,
      type: "milestone",
      budgetId: this.budgetId,
    };
    // Update and select it
    const newBudget = reindexBugetInput([...this.budgetInput, newMilestone]);
    this.budgetInput = newBudget;
    this.selectedMilestoneIndex = newBudget.length - 1;
  };

  readonly updateCategory = <K extends keyof WorkCategory>(
    text: WorkCategory[K],
    property: K
  ) => {
    // @ts-ignore FIXME: fix type
    this.budgetInput[this.selectedMilestoneIndex][property] = text;
  };

  readonly calculatePhaseCost: any = () => {
    let currentPhaseIndex: number = -1;
    let currentPhaseTotal: number = 0;
    let milestoneTotal: number = 0;

    for (let i = 0; i < this.budgetInput.length; i++) {
      if (this.budgetInput[i].type === "phase") {
        currentPhaseIndex = i;
        milestoneTotal = 0;
        if (currentPhaseTotal !== 0) {
          (this.budgetInput[currentPhaseIndex] as BudgetPhaseInput).cost =
            currentPhaseTotal;
          currentPhaseTotal = 0;
        }
      } else if (this.budgetInput[i].type === "milestone") {
        (this.budgetInput[i] as BudgetMilestoneInput).tasks?.forEach(
          // eslint-disable-next-line no-loop-func
          (task) => (milestoneTotal += parsePriceInput(task.budget))
        );
        (this.budgetInput[currentPhaseIndex] as BudgetPhaseInput).cost =
          milestoneTotal;
      }
    }
  };

  readonly updateCategoryTask = <K extends keyof Task>(
    text: Task[K],
    property: K,
    index: number
  ) => {
    (this.budgetInput[this.selectedMilestoneIndex] as any).tasks[index][
      property
    ] = text;
    if (property === "budget") this.calculatePhaseCost();
  };

  readonly addTask = () => {
    (this.budgetInput[this.selectedMilestoneIndex] as any).tasks.push({
      description: "",
      budget: 0,
    });
  };

  applyPredetermingPricing = async () => {
    const { userModel } = this.props;
    const milestonePrices = await userModel.getMilestonePrices();
    this.budgetInput.forEach((item) => {
      if (item.type === "milestone") {
        const foundPrice = milestonePrices.find(
          (milestonePrice) => milestonePrice.milestoneName === item.name
        );
        if (foundPrice) {
          item.scopeOfWork = foundPrice.scope
            ? `${foundPrice.scope} (${item.workAreas?.join(", ")})`
            : item.scopeOfWork;
          item.tasks?.forEach((task) => {
            const foundTask = foundPrice.tasks?.find(
              (milestoneTask) => milestoneTask.description === task.description
            );
            if (foundTask) {
              task.budget = foundTask.budget || task.budget;
            }
          });
        }
      }
    });
    this.calculatePhaseCost();
  };

  readonly selectCategory = (index: number) => {
    if (index === this.budgetInput.length - 1)
      this.props.projectDetailsFlow.userSawLastBudgetMilestone = true;

    // if clicked once open, close again
    if (this.selectedMilestoneIndex === index) {
      this.selectedMilestoneIndex = -1;
    } else {
      this.selectedMilestoneIndex = index;
    }
  };

  readonly deleteCategory = () => {
    this.milestonesToDelete.push(
      this.budgetInput[this.selectedMilestoneIndex] as any
    );
    this.budgetInput.splice(this.selectedMilestoneIndex, 1);
    this.selectedMilestoneIndex = -1;
    this.budgetInput = reindexBugetInput(this.budgetInput);
    this.calculatePhaseCost();
  };

  readonly deleteCategoryTask = (index: number) => {
    this.tasksToDelete.push(
      (this.budgetInput[this.selectedMilestoneIndex] as any).tasks[index]
    );
    (this.budgetInput[this.selectedMilestoneIndex] as any).tasks.splice(
      index,
      1
    );
    this.calculatePhaseCost();
  };

  readonly submitMilestone = () => {
    if (this.selectedMilestoneIndex < this.budgetInput.length - 1) {
      this.selectedMilestoneIndex++;
    } else {
      this.props.projectDetailsFlow.userSawLastBudgetMilestone = true;
      this.selectedMilestoneIndex = -1;
    }
  };

  readonly handleStartDateChange = (date: Date) => {
    this.dateInput = date;
  };

  readonly handleBudgetSubmit = async () => {
    const { budgetModel, project, notification, changeBudgetView } = this.props;

    const cleaned = this.budgetInput.map((step: any) => {
      if (!step.tasks) return step;
      return {
        ...step,
        tasks: step.tasks.map((task: any) => {
          return {
            ...task,
            budget: parsePriceInput(task.budget),
          };
        }),
      };
    });

    if (this.budgetId !== "") {
      const updateObject = {
        budgetId: this.budgetId,
        deletedMilestones: toJS(this.milestonesToDelete),
        deletedPhases: toJS(this.phasesToDelete),
        deletedTasks: toJS(this.tasksToDelete),
        budgetItems: toJS(cleaned),
        startDate: this.dateInput!,
      };

      await budgetModel.updateBudget(updateObject);
      notification.setNotification("success", "Budget Updated!");
      changeBudgetView();
    } else {
      const newBudgetObject = {
        projectId: toJS(project.id),
        budgetItems: toJS(cleaned),
        startDate: this.dateInput!,
      };
      await budgetModel.createBudget(newBudgetObject);
      notification.setNotification("success", "Budget Saved!");
      changeBudgetView();
    }
  };

  readonly handleOptionsViewChange = () => {
    if (!this.dateInput) {
      this.props.notification.setNotification("error", "Please select a start date");
      return;
    }

    if (this.dateInput < new Date()) {
      this.props.notification.setNotification("error", "Please select a start date in the future");
      return;
    }

    this.isPreviewSubmitted = true;
  }

  readonly handlePhaseAddOrEdit = (index: number) => {
    this.selectedPhaseIndex = index;
  };

  readonly handleSavePhase = (
    name: string,
    numDays: string,
    categoryStartIndex: number
  ) => {
    // if editing existing phase
    if (this.budgetInput[categoryStartIndex]?.type === "phase") {
      (this.budgetInput[categoryStartIndex] as any) = {
        ...this.budgetInput[categoryStartIndex],
        name,
        numDays: Number(numDays),
      };
      this.selectedPhaseIndex = -1;
      return;
    }

    const newPhase: BudgetPhaseInput = {
      name,
      numDays: Number(numDays),
      milestoneIndices: [],
      orderIndex: categoryStartIndex + 1,
      renderIndex: categoryStartIndex + 1,
      cost: 0,
      type: "phase",
      budgetId: "",
      phaseIndex: this.budgetInput.length,
    };

    const originalBudgetLength = this.budgetInput.length;
    const newBudget = this.budgetInput;
    newBudget.splice(categoryStartIndex + 1, 0, newPhase);
    this.budgetInput = reindexBugetInput(newBudget);
    if (
      categoryStartIndex + 1 === originalBudgetLength ||
      originalBudgetLength < 2
    ) {
      this.appendMilestone();
    }
    this.selectedPhaseIndex = -1;
    this.calculatePhaseCost();
  };

  readonly handlePhaseDelete = async (categoryStartIndex: number) => {
    this.phasesToDelete.push(this.budgetInput[categoryStartIndex] as any);
    const newBudget = this.budgetInput;
    newBudget.splice(categoryStartIndex, 1);
    this.budgetInput = reindexBugetInput(newBudget);
  };

  readonly handlePhaseMove = (index: number, direction: string) => {
    const newBudget = toJS(this.budgetInput);
    if (direction === "up") {
      if (
        newBudget[index - 1].type === "milestone" &&
        newBudget[index - 2].type !== "phase"
      ) {
        newBudget[index].renderIndex--;
        newBudget[index - 1].renderIndex++;
        if (newBudget[index - 1].type === "phase") {
          (newBudget[index] as any).phaseIndex--;
          (newBudget[index - 1] as any).phaseIndex++;
        }
      }
    } else if (direction === "down") {
      if (index + 2 < newBudget.length) {
        if (!!newBudget[index + 2] && newBudget[index + 2].type === "phase") {
          return;
        } else {
          newBudget[index].renderIndex++;
          newBudget[index + 1].renderIndex--;
        }
      }
    }
    this.selectedMilestoneIndex = -1;
    this.budgetInput = newBudget.sort(sortOrderIndex);
    this.calculatePhaseCost();
  };

  readonly createPhaseListItem = (categoryStartIndex?: number) => {
    let index = categoryStartIndex ? categoryStartIndex : 0;
    const isTouched = this.selectedPhaseIndex === index;
    if (isTouched) {
      return (
        <AddOrEditPhase
          index={index}
          handleSaveClick={this.handleSavePhase}
          handleCancelClick={() => (this.selectedPhaseIndex = -1)}
        />
      );
    } else {
      return <AddPhaseCTA onPress={() => (this.selectedPhaseIndex = index)} />;
    }
  };

  readonly isDeletable = (index: number) => {
    const firstItem =
      index - 1 > -1 && this.budgetInput[index - 1].type === "phase";
    if (
      firstItem &&
      (this.budgetInput.length === index + 1 ||
        this.budgetInput[index + 1]?.type === "phase")
    ) {
      return false;
    } else {
      return true;
    }
  };

  readonly reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  readonly getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 0,
    margin: 0,

    // change background colour if dragging
    background: "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  readonly getListStyle = (isDraggingOver: any) => ({
    background: "transparent",
    padding: 0,
    width: "100%",
  });

  readonly onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any[] = this.reorder(
      toJS(this.budgetInput),
      result.source.index,
      result.destination.index
    );
    let disableMove = false;
    items?.forEach((item: any, index: number) => {
      if (
        item.type === "phase" &&
        (!items[index + 1] || items[index + 1].type === "phase")
      ) {
        disableMove = true;
      }
    });

    if (!disableMove) {
      this.budgetInput = reindexBugetInput(items);
    }
    this.calculatePhaseCost();
  };

  renderBudgetCategory = (item: any, index: number) => {
    const isActive = this.selectedMilestoneIndex === index;
    return (
      <View style={[styles.milestone, styles.row]} key={index}>
        <View style={{ flex: 1 }}>
          <BudgetCategory
            key={item.renderIndex}
            milestone={item}
            addTask={this.addTask}
            category={item.csiCode}
            deleteCategory={this.deleteCategory}
            isDeletable={this.isDeletable(index)}
            deleteTask={this.deleteCategoryTask}
            index={item.renderIndex}
            displayIndex={item.milestoneIndex}
            isActive={isActive}
            onClick={() => this.selectCategory(index)}
            updateTask={this.updateCategoryTask}
            submitMilestone={this.submitMilestone}
            updateCategory={this.updateCategory}
          />
        </View>
        <CommentBubble
          isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(
            this.props.userViewModel.currentUser!.id,
            item.name
          )}
          isFilled={
            this.props.projectWidgetManager.activeCommentIndex === index
          }
          onPress={() =>
            this.handleCommentBubblePress(index, item.budgetId, item.name)
          }
          style={styles.commentBubble}
        />
      </View>
    );
  };

  renderItems = () => {
    if (!this.budgetInput || this.budgetInput.length === 0)
      return (
        <AddOrEditPhase
          index={0}
          handleSaveClick={this.handleSavePhase}
          handleCancelClick={() => (this.selectedPhaseIndex = -1)}
        />
      );

    return (
      <View>
        {this.budgetInput.map((item, index) => {
          if (item.type === "milestone") {
            if (this.selectedMilestoneIndex === item.renderIndex)
              return this.renderBudgetCategory(item, index);
            else
              return (
                <>
                  <Draggable
                    key={item.id}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {this.renderBudgetCategory(item, index)}
                      </div>
                    )}
                  </Draggable>
                  {this.createPhaseListItem(index)}
                </>
              );
          } else {
            if (this.selectedPhaseIndex === index) {
              return (
                <AddOrEditPhase
                  index={index}
                  name={item.name}
                  numDays={`${item.numDays ?? ""}`}
                  handleSaveClick={this.handleSavePhase}
                  handleCancelClick={() => (this.selectedPhaseIndex = -1)}
                />
              );
            }
            return (
              <Draggable
                key={item.id}
                draggableId={index.toString()}
                index={index}
                isDragDisabled={true}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <SavedPhase
                      isFirst={index === 0}
                      phase={item}
                      onDelete={() => this.handlePhaseDelete(index)}
                      onMoveDown={() => this.handlePhaseMove(index, "down")}
                      onMoveUp={() => this.handlePhaseMove(index, "up")}
                      onEditPress={() => this.handlePhaseAddOrEdit(index)}
                      indexToRender={item.phaseIndex}
                    />
                  </div>
                )}
              </Draggable>
            );
          }
        })}
      </View>
    );
  };

  renderBudgetEdit = () => {
    const { projectDetailsFlow } = this.props;

    if (!this._initialized) return <LoadingIndicator />;
    return (
      <>
        <View style={styles.sectionSmall}>
          <StyledText variant="heading3" isBold={true}>
            Budget
          </StyledText>
          <View
            style={[styles.row, styles.spaceBetween, { alignItems: "center" }]}
          >
            <StyledText style={{ maxWidth: 396 }}>
              The property owner has accepted the Rough Bid. You can create a
              detailed budget here.
            </StyledText>
            <StyledButton
              variant="secondary"
              style={{ right: 25 }}
              text="Apply predetermined pricing"
              onPress={this.applyPredetermingPricing}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.costCol}>
            <View style={styles.row}>
              {/* Start date input */}
              <DateInput
                value={
                  this.dateInput && this.dateInput > new Date()
                    ? this.dateInput
                    : null
                }
                onChange={this.handleStartDateChange}
                placeholder={"Start Date"}
              />

              {/* Total time */}
              <View style={styles.headerRight}>
                <View style={styles.row}>
                  <StyledText
                    variant="heading3"
                    colorMode="dark"
                    style={{ marginBottom: 4 }}
                  >{`Total Time: `}</StyledText>
                  <StyledText variant="heading3" colorMode="dark" isBold={true}>
                    {this.totalProjectTime
                      ? `${this.totalProjectTime} Days`
                      : "--"}
                  </StyledText>
                </View>

                {/* Total cost */}
                <View style={[styles.row, { marginLeft: 24 }]}>
                  <StyledText
                    variant="heading3"
                    style={{ marginBottom: 4 }}
                  >{`Total Cost: `}</StyledText>
                  <StyledText variant="heading3" isBold={true}>
                    {formatCurrencyToString(this.totalProjectPrice)}
                  </StyledText>
                </View>
              </View>
            </View>
            <View style={styles.sectionLarge}>
              <View style={styles.sectionSmall}>
                {/* TODO: implement drag and drop feature on category rows - EY */}
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <View
                        {...provided.droppableProps}
                        ref={provided.innerRef as any}
                        style={this.getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.renderItems()}
                        {provided.placeholder}
                      </View>
                    )}
                  </Droppable>
                </DragDropContext>
              </View>
              <StyledButton
                disabled={
                  this.selectedPhaseIndex > -1 ||
                  this.selectedMilestoneIndex > -1
                }
                onPress={
                  this.budgetInput?.length > 0
                    ? this.appendMilestone
                    : this.createPhaseListItem
                }
                text={
                  this.budgetInput?.length > 0 ? "Add Milestone" : "Add Phase"
                }
                variant="secondary"
                alignSelf={true}
              />
            </View>
            <StyledButton
              disabled={
                this.budgetInput?.length < 1 ||
                (!projectDetailsFlow.userSawLastBudgetMilestone &&
                  !projectDetailsFlow.budget?.submitted)
              }
              onPress={this.handleOptionsViewChange}
              text={"Save and Continue"}
              alignSelf={true}
            />
          </View>
        </View>
      </>
    );
  }

  render() {
    if (!this.isPreviewSubmitted) {
      return this.renderBudgetEdit();
    } else {
      return (
        <InjectedBudgetOptionsView 
          onSubmit={this.handleBudgetSubmit} 
          totalProjectPrice={this.totalProjectPrice}
          totalProjectTime={this.totalProjectTime}        
        />
      );
    }
  }
}

interface AddPhaseCTAProps {
  onPress: () => void;
}
export const AddPhaseCTA: React.FunctionComponent<AddPhaseCTAProps> = (
  props
) => {
  const { onPress } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  if (!isHover) {
    return <View ref={ref} style={styles.hoverableMargin} />;
  }
  return (
    <View ref={ref} style={[styles.row, styles.addPhaseCTA]}>
      <View style={[styles.phaseDivider, { marginRight: 16 }]} />
      <StyledButton
        iconLeft={{ name: "plus", type: "accent" }}
        onPress={onPress}
        text="Add Phase Here"
        variant="textOnly"
      />
      <View style={[styles.phaseDivider, { marginLeft: 16 }]} />
    </View>
  );
};

interface SavedPhaseProps {
  isFirst: boolean;
  phase: BudgetPhaseInput;
  onDelete: () => void;
  onMoveDown: () => void;
  onMoveUp: () => void;
  onEditPress: () => void;
  indexToRender: number;
}
export const SavedPhase: React.FunctionComponent<SavedPhaseProps> = (props) => {
  const {
    onDelete,
    onMoveDown,
    onMoveUp,
    onEditPress,
    phase,
    indexToRender,
    isFirst,
  } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  return (
    <View ref={ref}>
      <View style={[styles.row, styles.phaseRow]}>
        <StyledText colorMode="light" isBold={true} style={styles.phaseIndex}>
          {indexToRender}
        </StyledText>
        <View style={styles.phaseMainColumn}>
          <StyledText colorMode="light" isBold={true}>
            {phase.name}
          </StyledText>
          <StyledText colorMode="light" isBold={true}>
            {!phase.numDays || phase.numDays === 0 ? "--" : phase.numDays} Days
          </StyledText>
        </View>
        {isHover ? (
          <View style={styles.phaseRightColumn}>
            <View style={styles.phaseEditContainer}>
              <Icon
                name="edit"
                type="accent"
                size={14}
                style={
                  isFirst ? styles.phaseEditFirstButton : styles.phaseEditButton
                }
                onClick={onEditPress}
              />
              {!isFirst && (
                <>
                  <Icon
                    name="arrow-up"
                    type="accent"
                    size={14}
                    style={styles.phaseMoveUpButton}
                    onClick={onMoveUp}
                  />
                  <Icon
                    name="arrow-down"
                    type="accent"
                    size={14}
                    style={styles.phaseMoveDownButton}
                    onClick={onMoveDown}
                  />
                  <View style={styles.phaseButtonsDivider} />
                  <Icon
                    name="x"
                    type="warning"
                    size={14}
                    style={styles.phaseDeleteButton}
                    onClick={onDelete}
                  />
                </>
              )}
            </View>
          </View>
        ) : (
          <View style={styles.phaseRightColumn}>
            <StyledText colorMode="light" isBold={true}>
              {formatCurrencyToString(phase.cost)}
            </StyledText>
          </View>
        )}
      </View>
    </View>
  );
};

interface AddOrEditPhaseProps {
  index: number;
  name?: string;
  numDays?: string;
  handleSaveClick: (name: string, days: string, index: number) => void;
  handleCancelClick: (index: number) => void;
}

@observer
class AddOrEditPhase extends React.Component<AddOrEditPhaseProps> {
  constructor(props: AddOrEditPhaseProps) {
    super(props);
    makeObservable(this);
  }
  @observable name = this.props.name ?? "";
  @observable numDays = this.props.numDays ?? "";

  readonly handlePhaseDaysUpdate = (value: string) => {
    if (!value) return (this.numDays = "");
    if (!isStringNumeric(value)) return;
    this.numDays = value.replace(/[^0-9]/, "");
  };

  readonly handlePhaseNameUpdate = (value: string) => {
    this.name = value;
  };

  render() {
    return (
      <View style={[styles.row, styles.phaseRow]}>
        <StyledText colorMode="light" isBold={true} style={styles.phaseIndex}>
          {/* {this.props.index + 1} */}
        </StyledText>
        <View style={styles.phaseMainColumn}>
          <StyledTextInput
            placeholder="Add Phase Name"
            value={`${this.name}`}
            onChangeText={this.handlePhaseNameUpdate}
            style={[styles.phaseNameTextInput]}
          />
          <StyledTextInput
            placeholder="00 Days"
            value={this.numDays}
            keyboardType="numeric"
            onChangeText={this.handlePhaseDaysUpdate}
            style={styles.phaseDaysNumberInput}
          />
          <StyledButton
            text="Save"
            disabled={isEmptyString(this.name)}
            style={styles.phaseSaveButton}
            onPress={() =>
              this.props.handleSaveClick(
                this.name,
                this.numDays,
                this.props.index
              )
            }
          />
        </View>
        <Icon
          name="x"
          type="warning"
          size={24}
          style={[styles.phaseRowTotal, { marginTop: 1 }]}
          onClick={() => this.props.handleCancelClick(this.props.index)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  milestone: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
  },
  costCol: {
    flex: 2,
    marginRight: 24,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  sectionLarge: {
    marginBottom: 64,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  headerRight: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: 5,
  },

  addPhaseCTA: {
    paddingVertical: 4,
    alignItems: "center",
  },
  phaseDivider: {
    flex: 1,
    borderBottomWidth: 2,
    borderColor: Palette.Accent,
  },
  hoverableMargin: {
    height: 4,
  },
  // phases
  phaseRow: {
    height: 48,
    borderRadius: 4,
    marginVertical: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: Palette.Primary100Pct,
  },

  phaseRowTotal: {
    marginLeft: 24,
  },
  phaseIndex: {
    marginRight: 16,
  },
  phaseNameTextInput: {
    flex: 1,
    marginVertical: 8,
  },
  phaseDaysNumberInput: {
    width: 90,
    marginLeft: 8,
    marginVertical: 8,
  },
  phaseSaveButton: {
    height: 32,
    width: 90,
    minWidth: 0,
    marginLeft: 8,
    alignSelf: "center",
  },
  phaseMainColumn: {
    flex: 4,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  phaseRightColumn: {
    flex: 1,
    alignItems: "flex-end",
  },
  phaseEditContainer: {
    marginRight: -13,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Palette.White,
  },
  phaseEditFirstButton: {
    paddingLeft: 14,
    paddingRight: 14,
    paddingVertical: 14,
  },
  phaseEditButton: {
    paddingLeft: 14,
    paddingRight: 3,
    paddingVertical: 14,
  },
  phaseMoveUpButton: {
    paddingLeft: 14,
    paddingRight: 8,
    paddingVertical: 14,
  },
  phaseMoveDownButton: {
    paddingRight: 14,
    paddingLeft: 8,
    paddingVertical: 14,
  },
  phaseDeleteButton: {
    paddingVertical: 14,
    paddingHorizontal: 14,
  },
  phaseButtonsDivider: {
    height: 24,
    borderLeftWidth: 1,
    borderLeftColor: Palette.Primary10Pct,
  },
  commentBubble: {
    marginTop: 13,
    left: 10,
    flex: 0,
  },
  permitButtons: {
    flexDirection: "row",
    gap: 30,
  },
});

export const InjectedProjectBudgetEditView = withInjectedFactory(
  ProjectBudgetEditViewFactory
);
