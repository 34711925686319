import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createAppInjector } from "./AppInjector";
import { InjectorContext } from "./InjectorContext";
import { ignoreLogs } from "./utils/Console";

const injector = createAppInjector();

ignoreLogs([
  // React Scrollview
  "UNSAFE_componentWillMount",
  // React AnimatedComponent
  "UNSAFE_componentWillReceiveProps",
  // TextareaAutosize min-height is not supported but works
  "TextareaAutosize",
  // Droppable has multiple children without key prop
  "Droppable",
  // React Native only? For useNativeDriver
  "animated module is missing",
]);

ReactDOM.render(
  <React.StrictMode>
    <InjectorContext.Provider value={injector}>
      <App />
    </InjectorContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
