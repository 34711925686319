import { StyleSheet, View } from "react-native";
import React from "react";
import { StyledText } from "./controls";

interface Props {
  checked: boolean;
  value: string;
  onChange: () => void;
  textVisible?: boolean;
  disabled?: boolean;
}

export default class CheckBox extends React.Component<Props> {
  render() {
    const value = this.props.value ? <span>{this.props.value}</span> : null;
    const textVisible = typeof this.props.textVisible !== 'undefined' ? this.props.textVisible : true; 
    const checkboxStyle = {
      width: 20,
      height: 20,
    };
    return (
      <View style={styles.container}>
        {/* TODO: convert input component to StyledTouchable for styling access & include styledText as touchable interface - EY */}
        <input
          type="checkbox"
          checked={this.props.checked}
          onChange={this.props.onChange}
          id="category"
          name="category"
          value="Appliances"
          style={checkboxStyle}
          disabled={this.props.disabled}
        />
        {textVisible && <StyledText isBold={true} style={styles.text}>{value}</StyledText>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: 8,
  },
});
