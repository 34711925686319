import React from "react";
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from "react-native";
import {
  StyledText,
} from "./controls";

interface Props extends ViewProps {
  title: string;
  style?: StyleProp<ViewStyle>
}
export const SectionBlock: React.FunctionComponent<Props> = (props) => {
  const { style, title } = props;
  return (
    <View style={style}>
      <StyledText variant="body2" isBold={true} style={styles.title}>{title}</StyledText>
      {props.children}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 4,
    textTransform: "uppercase",
  },
});