import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 6V4.556C7 3.696 7.696 3 8.556 3h10.888C20.304 3 21 3.696 21 4.556v10.888c0 .86-.696 1.556-1.556 1.556H18"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.444 7H4.556C3.696 7 3 7.696 3 8.556v10.888C3 20.304 3.696 21 4.556 21h10.888c.86 0 1.556-.696 1.556-1.556V8.556C17 7.696 16.304 7 15.444 7z"
      />
    </svg>
  );
}

export default memo(Icon);

