import React, { useEffect, useState } from "react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import {
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import { Icon, StyledText } from "./controls";
import { Palette } from "./styles";
import { Project } from "../../../data-model/Project";
import { ContractorModel, ProjectModel, UserModel } from "../../../data-model/";
import { withInjectedFactory } from "../InjectorContext";

interface Props {
  contractorModel: ContractorModel;
  projectModel: ProjectModel;
  userModel: UserModel;
}

interface CreateProps {
  project: Project;
  currentUser: any;
}

export class ProjectDocumentsViewFactory {
  static inject: Inject<ProjectDocumentsViewFactory> = (injector) => {
    return () =>
      new ProjectDocumentsViewFactory({
        contractorModel: injector.get(ContractorModel)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectDocumentsView {...this.props} {...props} />;
  }
}

const ProjectDocumentsView: React.FunctionComponent<Props & CreateProps> = (
  props
) => {
  const { project, currentUser, projectModel } =
    props;
  const [documents, setDocuments] = useState<any[]>([]); // eslint-disable-next-line
  const [documentsFetched, setDocumentsFetched] = useState<boolean>(false);

  useEffect(() => {
    fetchDocuments(); // eslint-disable-next-line
  }, [currentUser, project]);

  const fetchDocuments = async () => {
    if (project.id) {
      let response = await projectModel.getFilesByProjectId(project.id);
      console.log(response);
      setDocuments(response);
      setDocumentsFetched(true);
    }
  };

  return (
    <View style={[styles.container, styles.row]}>
      <View style={styles.column}>
        <StyledText variant="heading3" isBold={true}>
          Documents
        </StyledText>
        <View style={styles.headerContainer}>
          <StyledText style={styles.leftText} isBold={true}>
            NAME
          </StyledText>
          <StyledText style={styles.rightText} isBold={true}>
            DATE
          </StyledText>
        </View>
        <View style={styles.fileListContainer}>
          {documents.length > 0 &&
            documents.map((item: any, index: number) => {
              return (
                <TouchableOpacity
                  style={styles.fileContainer}
                  onPress={() => window.open(item.fileUrl)}
                >
                  <View style={styles.fileNameContainer}>
                    <Icon name="file" size={24} />
                    <StyledText isBold={true} style={styles.fileNameText}>{item.fileName}</StyledText>
                  </View>
                  <View style={styles.rightText}>
                    <StyledText> {new Date(item.createdAt).toLocaleDateString()}</StyledText>
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: Palette.Primary25Pct,
    padding: 10,
    marginTop: 20,
  },
  fileListContainer: {
    flexDirection: "column",
  },
  fileContainer: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 20,
    padding:10,
    borderBottomWidth: 1,
    borderColor: Palette.Primary10Pct,
  },
  fileNameContainer: {
    flexDirection: "row",
    flex: 1,
    textAlign: "left",
  },
  fileNameText: {
    paddingTop:3,
    paddingLeft:20
  },
  leftText: {
    textAlign: "left",
    flex: 1,
  },
  rightText: {
    textAlign: "right",
    flex: 1,
  },
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 48,
  },
  lastColumn: {
    marginRight: 0,
  },
  section: {
    marginBottom: 24,
  },
  sectionSecondaryTitle: {
    justifyContent: "space-between",
  },
  editButtonContainer: {
    width: 48,
    height: 48,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Accent,
  },
  imageContainer: {
    flex: 1,
    maxWidth: 480,
    maxHeight: 270,
    marginBottom: 48,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  calendarIcon: {
    marginRight: 10,
  },
  sectionText: {
    lineHeight: 24,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    width: "30vh",
    maxWidth: 500,
    borderRadius: 8,
    padding: 35,
    alignItems: "center",
    elevation: 5,
    minWidth: 400,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalIcon: {},
  iconContainer: {
    padding: 20,
    borderRadius: 30,
    position: "absolute",
    top: -27,
    backgroundColor: Palette.White,
  },
  inviteSearch: {
    width: "100%",
  },
  inviteButton: {
    marginTop: 30,
  },
  selectedInviteContainer: {
    flexDirection: "row",
    width: "100%",
    marginTop: 20,
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  invitedIcon: {
    marginRight: 10,
  },
});

export const InjectedProjectDocumentsView = withInjectedFactory(ProjectDocumentsViewFactory);
