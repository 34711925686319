import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { Comment, CommentModel, CommentType, CreateCommentParams, GetCommentParams } from "../../../data-model";
import { UserViewModel } from "../viewModels/UserViewModel";
import { MenuItem } from "./ProjectWidget";

// Notes for the developers wiring the functionality to this ProjectWidgetManager:
// The idea is to query all comments associated with the bid/budget/any other tables that supports functions.
// When joining data together, ideally the bid category & budget milestone could all go by the alias of "group",
// since Comments all share the same properties.
// Going off of that thinking, I've created a tentative interface "Comment" data model.
// Other than that, feel free to update the ProjectWidgetManager logic as needed, since it should be whatever
// data model that makes the most sense that should drive how the UI is wired.
// Note: Need to take in account of customized categories / milestones as well.
// - EY

export type CommentsByTag = Record<string, TaggedCommentInfo>

interface TaggedCommentInfo {
  comments: Array<Comment>;
  isUnread: boolean;
  unreadCreatorIds: Set<string>;
}

export class ProjectWidgetManager {
  static inject: Inject<ProjectWidgetManager> = singletonInject((injector) => {
    return () => new ProjectWidgetManager(
      injector.get(CommentModel)(),
      injector.get(UserViewModel)(),
    );
  });

  @observable public isOpen: boolean = false;
  @observable public comments: Comment[] = [];
  @observable public activeCommentIndex: number = -1;
  @observable public activeCommentTag?: string;
  @observable public activeCommentType?: CommentType;
  @observable public activeParentId?: string;
  @observable public activeProjectId: string = '';
  @observable public selectedMenuItem: MenuItem = "details";

  @computed public get hasComments(): boolean {
    return !!this.comments.length;
  }

  @computed public get commentsByTag(): CommentsByTag {
    const commentsByTag: CommentsByTag = {};
    this.comments.forEach(comment => {
      const commentTag = comment.tag;

      if (commentTag) {
        if (commentsByTag[commentTag]) {
          commentsByTag[commentTag].comments.push(comment);
          commentsByTag[commentTag].isUnread = commentsByTag[commentTag].isUnread || comment.isUnread;
          if (comment.isUnread) {
            commentsByTag[commentTag].unreadCreatorIds.add(comment.creatorId);
          }
        } else {
          commentsByTag[commentTag] = {
            comments: [comment],
            isUnread: comment.isUnread,
            unreadCreatorIds: comment.isUnread ? new Set([comment.creatorId]) : new Set(),
          };
        }
      }
    })
    return commentsByTag;
  };

  @computed public get generalComments(): Array<Comment> {
    const comments: Array<Comment> = [];
    const recentlyReadComments: Array<string> = [];
    this.comments.forEach(comment => {
      recentlyReadComments.push(comment.id);
      const commentTag = comment.tag;
      if (!commentTag) {
        comments.push(comment);
      }
    });
    return comments;
  }

  constructor(
    private readonly commentModel: CommentModel,
    private readonly userViewModel: UserViewModel
  ) {
    makeObservable(this);
  }

  public readonly loadComments = async (projectId: string, commentType: CommentType, parentId?: string) => {
    this.activeProjectId = projectId;
    this.activeCommentType = commentType;
    let params: GetCommentParams = {
      projectId: projectId,
      type: commentType,
    };
    try {
      this.comments = await this.commentModel.getCommentsByType(params);
      this.setActiveCommentInfo(parentId);
    } catch (error) {
      this.comments = [];
    }
  }

  private readonly updateReadComments = async (currentUserId: string) => {
    if (!this.activeCommentTag) {
      return;
    }

    const updateComments: Array<Comment> = [];
    const updatedCommentIds: Array<string> = [];
    let currentComments: Array<Comment> = this.commentsByTag[this.activeCommentTag!]?.comments || [];

    currentComments.forEach((comment) => {
      if (comment.creatorId !== currentUserId && comment.isUnread) {
        updatedCommentIds.push(comment.id);
        updateComments.push(comment);
      }
    })

    try {
      if (updatedCommentIds.length > 0) {
        await this.commentModel.updateComments({
          readCommentIds: updatedCommentIds, 
          type: this.activeCommentType!,
        });
        updateComments.forEach((comment) => {
          comment.isUnread = false;
        })
      }
    } catch (error) {
      console.log("Console Update Failed");
    }
  }

  public readonly determineTaggedCommentsUnread = (creatorId: string, tag: string) => {
    const taggedComments: TaggedCommentInfo = this.commentsByTag[tag];
    if (taggedComments) {
      return taggedComments.isUnread && (!taggedComments.unreadCreatorIds.has(creatorId) || taggedComments.unreadCreatorIds.size > 1);
    } else {
      return false;
    }
  }

  public readonly submitComment = async (comment: string) => {
    if (this.activeCommentType && this.activeParentId) {
      let params: CreateCommentParams = { 
        comment: comment,
        tag: this.activeCommentTag,
        parentId: this.activeParentId,
        type: this.activeCommentType!,
      }
      const newComment = await this.commentModel.createComment(params);
      this.comments.push(newComment)
    }
  }

  public readonly setActiveCommentInfo = (parentId?: string, commentTag?: string) => {
    if (!commentTag) this.activeCommentIndex = -1;
    this.activeCommentTag = commentTag;
    this.activeParentId = parentId ? parentId : this.activeParentId;
  }

  public readonly openWidget = (menu: MenuItem) => {
    this.selectedMenuItem = menu;
    this.isOpen = true;
  }

  public readonly openChatWidget = (parentId?: string, commentTag?: string) => {
    this.setActiveCommentInfo(parentId, commentTag);
    this.selectedMenuItem = "chat";
    this.isOpen = true;
    this.updateReadComments(this.userViewModel.currentUser?.id!);
  }

  public readonly closeWidget = () => {
    this.activeCommentTag = undefined;
    this.selectedMenuItem = "details";
    this.isOpen = false;
    this.activeCommentIndex = -1;
  }

  public readonly clearAll = () => {
    this.comments = [];
    this.closeWidget();
  }
}
