import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { BudgetPhase } from "../../../../data-model";
import { formatPaymentPhaseDateTextAndTag } from "../../utils/Budget";
import { formatCurrencyToString } from "../../utils/Numbers";
import { StyledText } from "../controls";
import { Tag } from "../controls/Tag";
import { Palette } from "../styles";
import { PaymentPhaseTabHeader } from "./PaymentPhaseTabHeader";

type PaymentPhase = BudgetPhase & {
  payment: any | undefined;
  startDate: Date;
};

interface Props {
  phases: PaymentPhase[];
}

export const PaymentPhasesPreview: FunctionComponent<Props> = ({ phases }) => {
  return (
    <View style={styles.container}>
      <PaymentPhaseTabHeader title={"Project Phases"} onViewAllClick={() => {}} />
      {phases.map((phase) => {
        const { dateTag, fundingTag, startsText, startsTextColor } =
          formatPaymentPhaseDateTextAndTag(phase);
        return (
          <View style={styles.phaseContainer} key={phase.id}>
            <StyledText isBold style={styles.phaseTitle}>
              {phase.name}
            </StyledText>
            <StyledText
              variant="body"
              style={[
                styles.startsIn,
                {
                  color:startsTextColor
                },
              ]}
            >
              {startsText}
            </StyledText>
            <View style={styles.tagContainer}>
              <Tag
                type={fundingTag.type}
                text={fundingTag.text}
                style={styles.tag}
              />
              <Tag type={dateTag.type} text={dateTag.text} />
            </View>
            <StyledText variant="body" style={styles.cost}>
              {formatCurrencyToString(phase.cost)}
            </StyledText>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
    width: "100%",
    borderRadius: 8,
    padding: 16,
  },
  phaseContainer: {
    marginTop: 16,
    width: "100%",
    height: 108,
    backgroundColor: Palette.White,
    borderRadius: 8,
    padding: 16,
  },
  phaseTitle: {
    fontSize: 18,
    marginBottom: 4,
  },
  startsIn: {
    marginBottom: 12,
  },
  tagContainer: {
    flexDirection: "row",
  },
  tag: {
    marginRight: 6,
  },
  cost:{
    position: "absolute",
    top: 16,
    right: 16,
  }
});
