import { computed } from "mobx";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { User } from "./User";
import {
  AnonymousAuthenticationProvider,
  AuthenticationProvider,
  AuthenticationProviderType,
  EmailAuthenticationProvider,
  EmailLinkAuthenticationProvder,
  PhoneAuthenticationProvider,
  ThirdPartyAuthenticationProvider,
} from "./AuthenticationProvider";

type Providers = { readonly [T in AuthenticationProviderType]: AuthenticationProvider<T>|undefined };

export abstract class Authenticator implements Providers {
  static inject?: Inject<Authenticator> = undefined;

  public abstract get initialized(): boolean;
  public abstract get currentUser(): User|undefined;

  @computed public get isSignedIn(): boolean {
    return !!this.currentUser;
  }

  public abstract get anonymous(): AnonymousAuthenticationProvider|undefined;
  public abstract get email(): EmailAuthenticationProvider|undefined;
  public abstract get emailLink(): EmailLinkAuthenticationProvder|undefined;
  public abstract get phone(): PhoneAuthenticationProvider|undefined;
  public abstract get apple(): ThirdPartyAuthenticationProvider<"apple">|undefined;
  public abstract get facebook(): ThirdPartyAuthenticationProvider<"facebook">|undefined;
  public abstract get twitter(): ThirdPartyAuthenticationProvider<"twitter">|undefined;
  public abstract get google(): ThirdPartyAuthenticationProvider<"google">|undefined;

  public abstract get signOut(): () => Promise<void>;
}
