import React from "react";
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from "react-native";
import TextareaAutosize from "react-textarea-autosize";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { StyledText } from "./StyledText";
import { Palette } from "../styles";

interface Props extends TextInputProps {
  errorMessage?: string;
  minHeight?: number;
  style?: StyleProp<ViewStyle>;
}

@observer
export class StyledTextArea extends React.Component<Props> {
  @observable private inputFieldTouched: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  private textInputRef = React.createRef<TextInput>();

  readonly onBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    const { value, onBlur } = this.props;
    this.inputFieldTouched = !!value

    if (onBlur) {
      onBlur(event);
    }
  };

  readonly onFocus = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { onChangeText } = this.props;
    const value = event.target.value;
    if (onChangeText) {
      onChangeText(value);
    }
  }

  public focus = () => {
    this.textInputRef.current?.focus();
  }

  render() {
    const { errorMessage, minHeight, onChangeText, style, ...textInputProps } = this.props;
    return (
      <View style={style}>
        {/* @ts-ignore */}
        <TextareaAutosize
          // @ts-ignore
          onBlur={this.onBlur}
          // @ts-ignore
          onChange={this.onChange}
          // @ts-ignore
          onFocus={this.onFocus}
          // @ts-ignore
          style={{
            fontFamily: "inherit",
            fontSize: 15,
            // @ts-ignore
            "min-height": minHeight || 0,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            borderWidth: 1,
            borderRadius: 4,
            outlineStyle: "none",
            borderColor: Palette.Primary25Pct,
          }}
          {...textInputProps}
        />
        {
          (errorMessage && this.inputFieldTouched) && (
            <View style={styles.errorContainer}>
              <StyledText variant="body" colorMode="warning" style={styles.errorIcon}>!</StyledText>
              <StyledText variant="body" colorMode="warning" style={styles.errorMessage}>{errorMessage}</StyledText>
            </View>
          )
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  errorContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 14,
  },
  errorIcon: {
    width: 20,
    height: 20,
    fontSize: 12,
    marginRight: 10,
    borderRadius: 24,
    display: "flex",
    fontWeight: "900",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Warning25Pct,
  },
  errorMessage: {
    fontSize: 12,
  },
});
