import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { Project, ProjectModel, UserModel } from "../../../data-model";


export class ProjectsViewModel {
  static inject: Inject<ProjectsViewModel> = singletonInject((injector) => {
    return () => new ProjectsViewModel(
      injector.get(UserModel)(),
      injector.get(ProjectModel)(),
    );
  });

  constructor(
    private readonly userModel: UserModel,
    private readonly projectModel: ProjectModel,
  ) {
    makeObservable(this);
  }

  @observable private _initialized: boolean = false;
  @observable private _initializing: boolean = false;

  @computed public get initialized(): boolean {
    return this._initialized;
  }

  @observable private _projects: Array<Project> = [];

  @computed get projects(): ReadonlyArray<Project> {
    return this._projects;
  }

  public async initialize() {
    if (this._initialized || this._initializing) {
      return;
    }

    this._initializing = true;
    await this.tryFetchProjects();
    this._initialized = true;
    this._initializing = false;
  }

  public async refeshProjects() {
    if (this._initializing) {
      return;
    }

    return this.tryFetchProjects();
  }

  private async tryFetchProjects() {
    try {
      this._projects = await this.projectModel.getProjects();
    } catch (e) {
      console.error(e);
    }
    return this.projects;
  }
}
