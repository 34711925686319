import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { MilestonePrice } from "../../../data-model/UserModel";
import {
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { MilestonePriceViewBody, MilestonePriceViewBodyProps } from "./MilestonePriceViewBody";
import { Palette } from "./styles";
import { formatCurrencyToString } from "../utils/Numbers";

interface Props {
  isActive?: boolean;
  onClick: () => void;
  milestone: MilestonePrice;
  milestoneIndex:number;
}

type MilestonePriceViewProps = Props & MilestonePriceViewBodyProps;

@observer
export class MilestonePriceView extends React.Component<MilestonePriceViewProps> {
  @computed private get taskTotalPrice() {
    let total: number = 0;
    this.props.milestone.tasks?.forEach((task:any) => total += Number(task.budget));
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  constructor(props: MilestonePriceViewProps) {
    super(props);
    makeObservable(this);
  }

  readonly renderCategoryInput = () => {
    const { milestone, isActive } = this.props;
    return isActive ? (
      <View style={styles.row}>
        <StyledText style={styles.csiText} isBold={true}>
          {milestone.csiCode}
        </StyledText>
        <StyledText style={styles.milestoneText} isBold={true}>
          {milestone.milestoneName}
        </StyledText>
      </View>
    ) : (
      <View style={styles.row}>
        <StyledText style={{ marginRight: 8, width: 88 }}>{milestone.csiCode}</StyledText>
        <StyledText isBold={true}>
          {milestone.milestoneName}
        </StyledText>
      </View>
    );
  };

  render() {
    const { isActive, milestone } = this.props;
    return (
      <View style={isActive && styles.containerBorder}>
        <StyledTouchableOpacity style={styles.categoryContainer} onPress={this.props.onClick}>
          <View style={styles.leftColumn}>
            {this.renderCategoryInput()}
          </View>
          {
            milestone.tasks!.length > 0 && (
              <StyledText variant="body2">
                {formatCurrencyToString(this.taskTotalPrice)}
              </StyledText>
            )
          }
        </StyledTouchableOpacity>

        {this.props.isActive && (
          <MilestonePriceViewBody
            {...this.props}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width:"100%"
  },
  row: {
    flexDirection: "row",
  },
  containerBorder: {
    borderWidth: 2,
    borderColor: Palette.Primary10Pct,
  },
  categoryContainer: {
    paddingVertical: 10,
    paddingLeft:8,
    paddingRight:16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Palette.Primary5Pct,
  },
  leftColumn: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  csiText: {
    width: 88,
    marginRight: 8,
    marginTop: "auto",
    marginBottom: "auto",
  },
  milestoneText: {
    flex: 3,
    marginRight: 0,
    marginTop: "auto",
    marginBottom: "auto",
  },
});
