import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M61.5 7.5h-51v63h51v-63z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M40.5 58.5h-9v12h9v-12zM40.5 43.5h-9v9h9v-9zM55.5 43.5h-9v9h9v-9zM25.5 43.5h-9v9h9v-9zM40.5 28.5h-9v9h9v-9zM55.5 28.5h-9v9h9v-9zM25.5 28.5h-9v9h9v-9zM40.5 13.5h-9v9h9v-9zM55.5 13.5h-9v9h9v-9zM25.5 13.5h-9v9h9v-9zM55.5 1.5h-39l-6 6h51l-6-6zM1.5 70.5h69"
      />
    </svg>
  );
}

export default memo(Icon);
