import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";;
import { Comment } from "../Comment";
import { CommentModel, CreateCommentParams, GetCommentParams, UpdateCommentParams } from "../CommentModel"
import { RestClient } from "./RestClient";

export class RestCommentModel extends CommentModel {
  static inject: Inject<RestCommentModel> = singletonInject((injector) => {
    return () => new RestCommentModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public createComment(params: CreateCommentParams): Promise<Comment> {
    return this.rest.post(`/comment`, params);
  }

  public getCommentsByType(params: GetCommentParams): Promise<Comment[]> {
    const { projectId, type } = params;
    return this.rest.get(`/comment/${projectId}/type/${type}`);
  }

  public updateComments(params: UpdateCommentParams): Promise<void> {
    return this.rest.put(`/comment`, params);
  }
}
