import React from "react";
import { FullLayout } from "../layouts/FullLayout";
import { LoadingIndicator } from "./LoadingIndicator";

export const FullScreenLoadingView: React.FC = () => {
  return (
    <FullLayout>
      <LoadingIndicator />
    </FullLayout>
  );
};
