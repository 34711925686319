import React from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import { Spacing } from "../styles";
import { PageVariant, SidebarVariant } from "../../constants/Variants";

type Variant = PageVariant | SidebarVariant;

interface Props {
  variant: Variant;
  style?: StyleProp<ViewStyle>;
}

type Variants = Record<Variant, StyleProp<ViewStyle>>;

export const Margins: React.FunctionComponent<Props> = (props) => {
  const { variant, style } = props;
  const variantStyle = variants[variant];

  return <View style={[{ flex: 1 }, variantStyle, style]}>{props.children}</View>;
};
// sidebar: placeholder: need revision - EY
// sidebarLarge: placeholder: need revision - EY
const variants: Variants = {
  main: {
    paddingVertical: 48,
    paddingHorizontal: 84,
  },
  sidebar: {
    paddingVertical: Spacing.Sidebar,
    paddingHorizontal: Spacing.Sidebar,
  },
  sidebarLarge: {
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  splash: {
    paddingVertical: 175,
    paddingHorizontal: 190,
  },
};