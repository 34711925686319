import { Inject } from "@not-the-droids/exco-ts-inject";
import React, { useEffect, useState } from "react";
import {
  Image,
  StyleSheet,
  View,
} from "react-native";
import {
  ContractorModel,
  ProjectModel,
} from "../../../data-model/";
import { CurrentUser } from "../../../data-model/CurrentUser";
import { Project } from "../../../data-model/Project";
import { withInjectedFactory } from "../InjectorContext";
import { capitalizeFirstLetter, structureAddress } from "../utils/Strings";
import { StyledText } from "./controls";
import { InviteModal } from "./InviteModal";
import { SectionBlock } from "./SectionBlock";
import { Palette } from "./styles";
import { UserInfo } from "./UserInfo";

interface Props {
  contractorModel: ContractorModel;
  projectModel: ProjectModel;
}

interface CreateProps {
  project: Project;
  currentUser: CurrentUser;
}

export class ProjectDetailsViewFactory {
  static inject: Inject<ProjectDetailsViewFactory> = (injector) => {
    return () =>
      new ProjectDetailsViewFactory({
        contractorModel: injector.get(ContractorModel)(),
        projectModel: injector.get(ProjectModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectDetailsView {...this.props} {...props} />;
  }
}

const ProjectDetailsView: React.FunctionComponent<Props & CreateProps> = ({
  project,
  currentUser,
  projectModel,
  contractorModel,
}) => {
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const getUserType = async () => {
      !!currentUser?.owner && setUserType("owner");
      !!currentUser?.contractor && setUserType("contractor");
    };

    getUserType();
  }, [currentUser, project, contractorModel, projectModel]);

  return (
    <View style={[styles.container, styles.row]}>
      <View style={styles.column}>
        <View style={styles.imageContainer}>
          {project.media?.length > 0 && (
            <Image
              source={{
                uri: project.media[0].url,
              }}
              style={styles.image}
            />
          )}
        </View>
        <View style={styles.section}>
          <SectionBlock title="Structure Description">
            <StyledText style={styles.sectionText}>
              {project.description}
            </StyledText>
          </SectionBlock>
        </View>
        {userType === "owner" && (
          <View style={styles.section}>
            <SectionBlock title="Contractor">
              <InviteModal
                contractorModel={contractorModel}
                projectId={project.id}
                projectModel={projectModel}
              />
            </SectionBlock>
          </View>
        )}
      </View>
      <View style={[styles.column, styles.lastColumn]}>
        <View
          style={[styles.section, styles.row, styles.sectionSecondaryTitle]}
        >
          <UserInfo variant="name-email" user={project.creator} />
          {/*       <StyledTouchableOpacity
            style={styles.editButtonContainer}
            onPress={() => {}}
          >
            <Icon name="edit" mode="light" size={14} />
          </StyledTouchableOpacity>*/}
        </View>

        {/*TODO: implement logic to check if contractor created project and if owner is invited from contractor view */}
        {/*     {userType !== "owner" && (
          <View style={styles.section}>
            <SectionBlock title="Property Owner">
              <StyledText style={styles.sectionText}>Not Invited</StyledText>
              { TODO: match styling below when invites are implemented
             <StyledText style={styles.sectionText}>Nolan Rhiel Madsen</StyledText>
            <StyledText style={styles.sectionText}>888.555.0101</StyledText>}
            </SectionBlock>
          </View>
        )}*/}

        <View style={styles.section}>
          <SectionBlock title="Project Address">
            <StyledText style={styles.sectionText}>
              {project.address
                ? structureAddress(project.address)
                : "Not Submitted"}
            </StyledText>
          </SectionBlock>
        </View>
        <View style={[styles.section, styles.row]}>
          <View style={{ flex: 1 }}>
            <SectionBlock title="Work Type">
              <StyledText style={styles.sectionText}>
                {!!project.workType && capitalizeFirstLetter(project.workType)}
              </StyledText>
            </SectionBlock>
          </View>
          <View style={{ flex: 2 }}>
            <SectionBlock title="Work Location">
              <StyledText style={styles.sectionText}>
                {!!project.workLocation &&
                  capitalizeFirstLetter(project.workLocation)}
              </StyledText>
            </SectionBlock>
          </View>
        </View>
        {/*        <View style={styles.section}>
          <SectionBlock title="Work Areas">
            <StyledText style={styles.sectionText}>
              {returnWorkAreas()}
            </StyledText>
          </SectionBlock>
        </View>*/}
        <View style={styles.section}>
          <SectionBlock title="Work Categories">
            <StyledText style={styles.sectionText}>
              {project.workCategories
                ?.map((workCat) => workCat.workCategory)
                .join(", ")}
            </StyledText>
          </SectionBlock>
        </View>
        {/*<View style={styles.row}>
          <Icon
            name="calendar"
            mode="dark"
            size={14}
            style={styles.calendarIcon}
          />
          <StyledText style={styles.sectionText}>47 days left</StyledText>
        </View>*/}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    marginRight: 48,
  },
  lastColumn: {
    marginRight: 0,
  },
  section: {
    marginBottom: 24,
  },
  sectionSecondaryTitle: {
    justifyContent: "space-between",
  },
  editButtonContainer: {
    width: 48,
    height: 48,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.Accent,
  },
  imageContainer: {
    flex: 1,
    maxWidth: 480,
    maxHeight: 270,
    marginBottom: 48,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  calendarIcon: {
    marginRight: 10,
  },
  sectionText: {
    lineHeight: 24,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  selectedInviteStatusContainer: {
    marginTop: 5,
    flexDirection: "row",
  },
});

export const InjectedProjectDetailsView = withInjectedFactory(
  ProjectDetailsViewFactory
);
