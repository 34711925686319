import React from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { StyledText } from "./controls";
import { Palette } from "./styles";

interface Props {
  checked: boolean;
  onPress: () => void;
  text?: string;
  textVisible?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const RadioButton: React.FunctionComponent<Props> = (props) => {
  const { checked, children, onPress, style } = props;
  const checkedStyle = checked && styles.checkedBackground;

  return (
    <View style={[styles.radioButtonContainer, style]}>
      <TouchableOpacity activeOpacity={0.5} onPress={onPress} style={[styles.radioButton, checkedStyle]}>
        {checked ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity activeOpacity={1} onPress={onPress}>
        <StyledText style={styles.radioButtonText}>{children}</StyledText>
      </TouchableOpacity>
    </View>
  );
};


const styles = StyleSheet.create({
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 45,
  },
  radioButton: {
    height: 20,
    width: 20,
    backgroundColor: Palette.White,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Palette.Accent,
    alignItems: "center",
    justifyContent: "center",
  },
  checkedBackground: {
    backgroundColor: Palette.Accent,
  },
  radioButtonIcon: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: Palette.White,
  },
  radioButtonText: {
    marginLeft: 10,
  },
});
