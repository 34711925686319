import { FirebaseWebAuthenticationProvider } from "./FirebaseWebAuthenticationProvider";
import { EmailLinkAuthenticationProvder, User } from "../exco-auth";

export class FirebaseWebEmailLinkAuthenticationProvider
  extends FirebaseWebAuthenticationProvider<"emailLink">
  implements EmailLinkAuthenticationProvder {

  public get type(): "emailLink" { return "emailLink"; }


  public async verifyEmailLink(link: string, email: string): Promise<User> {
    if (this.delegate!.firebaseAuth.isSignInWithEmailLink(link) && email) {
      try {
        await this.delegate!.firebaseAuth.signInWithEmailLink(email, link);
      } catch(error) {
        throw new Error("Something went wrong signing in with the verification link");
      }
    }
    return this.delegate?.currentUser!;
  }

  public async sendVerificationEmail(emailAddress: string, url: string): Promise<void> {
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };
    try {
      await this.delegate!.firebaseAuth.sendSignInLinkToEmail(emailAddress, actionCodeSettings);
    } catch(error) {
      throw new Error("Something went wrong sending the verification email");
    }
  }
}

