import React from "react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import Dropzone from "react-dropzone";
import { StyleSheet, View, ActivityIndicator, Image } from "react-native";
import { makeObservable, observable, runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import { Icon, StyledButton, StyledText, StyledTextInput } from "./controls";
import { GooglePlacesInput, AddressParts } from "./GooglePlacesInput";
import {
  BidModel,
  BudgetModel,
  CreateBidParams,
  CreateBudgetParams,
  ProjectModel,
} from "../../../data-model";
import { ProjectStatus } from "../../../data-model";
import {
  BuildingType,
  buildingTypes,
  ProjectCrudFlow,
  SituatedAtType,
  situatedAtTypes,
  WorkType,
  workTypes,
  Questions,
} from "./ProjectCrudFlow";
import { ProjectOption } from "./ProjectOption";
import { Authenticator } from "../exco-lib/exco-auth";
import { QuestionProps } from "../constants/Questions";
import { Notification } from "../NotificationInjectable";
import { Loader } from "@googlemaps/js-api-loader";
import { uploadFromBlobAsync } from "../utils/Storage";
import { IconName, Palette } from "./styles";
import { structureAddress } from "../utils/Strings";

interface OptionDisplay {
  name: string;
  icon?: IconName;
}
const buildingTypeOptions: Record<BuildingType, OptionDisplay> = {
  apartment: { name: "Apartment", icon: "apartment-building" },
  condo: { name: "Condo", icon: "condo-building" },
  single: { name: "Single Family", icon: "single-family-building" },
  townhome: { name: "Townhome", icon: "townhome-building" },
};

const workTypeOptions: Record<WorkType, OptionDisplay> = {
  addition: { name: "Addition" },
  new: { name: "New Construction" },
  remodel: { name: "Remodel" },
};

const situatedAtTypeOptions: Record<SituatedAtType, OptionDisplay> = {
  both: { name: "Interior and Exterior" },
  interior: { name: "Interior" },
  exterior: { name: "Exterior" },
};

interface Props {
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
  bidModel: BidModel;
  budgetModel: BudgetModel;
  notification: Notification;
  authenticator: Authenticator;
}

interface CreateProps {
  projectId?: string;
}

const googleAPIKey = process.env.REACT_APP_FIREBASE_API_KEY ?? "";

const loader = new Loader({
  apiKey: googleAPIKey,
  version: "weekly",
  libraries: ["places"],
});

export class ProjectFlowDetailsViewFactory {
  static inject: Inject<ProjectFlowDetailsViewFactory> = (injector) => {
    return () =>
      new ProjectFlowDetailsViewFactory({
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        bidModel: injector.get(BidModel)(),
        budgetModel: injector.get(BudgetModel)(),
        notification: injector.get(Notification)(),
        authenticator: injector.get(Authenticator)(),
      });
  };

  constructor(private props: Props) {}

  public create(props: CreateProps) {
    return <ProjectFlowDetailsView {...this.props} {...props} />;
  }
}

@observer
class ProjectFlowDetailsView extends React.Component<Props> {
  @observable private currentQuestion: number = 0;
  @observable private googleAPILoaded: boolean = false;
  @observable private lastInputValue: string =
    this.props.projectCrudFlow.projectLocation.line1;
  @observable private isLoading: boolean = false;
  @observable private error: string = "";
  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    this.getCurrentQuestion();
    loader
      .load()
      .then((google) => {
        this.googleAPILoaded = true;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  private setCleared = () => {
    this.props.projectCrudFlow.clearAddressInput = false;
    this.lastInputValue = "";
  };

  private getCurrentQuestion = () => {
    const questions: string[] = [];
    const jsProjectCrudFlow = toJS(this.props.projectCrudFlow);
    Object.keys(jsProjectCrudFlow).forEach((key: string) => {
      if (
        //TODO: update any if possible
        Object.values(Questions).includes(key) &&
        !questions.includes(key) &&
        !!(jsProjectCrudFlow as Record<string, any>)[key]
      ) {
        questions.push(key);
      }
    });
    this.props.projectCrudFlow.currentQuestion = questions.length;
  };

  private onChangeAddressInput = (address: AddressParts, complete: any) => {
    const { projectCrudFlow } = this.props;
    projectCrudFlow.projectLocation = address;
    projectCrudFlow.projectLocationInput = this.validateProjectName(address);
    complete?.();
  };

  private readonly validateProjectName = (address: any) => {
    const addressString = structureAddress(address),
          addressSplit = addressString.split(",");

    if (addressSplit.length > 2) return addressSplit[0];

    return addressString;
  }

  onDrop = async (acceptedFiles: any) => {
    const { projectCrudFlow } = this.props;
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    projectCrudFlow.isFileUploading = true;

    this.error = "";
    let url: string;
    try {
      url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `/site-photos/${file.name}_${Date.now()}`,
      });
    } catch (e) {
      runInAction(() => {
        projectCrudFlow.isFileUploading = false;
      });
      this.error = e as string;
      return;
    }

    runInAction(() => {
      this.props.projectCrudFlow.media[0] = {
        fileName: acceptedFiles[0].name,
        type: "image",
        url: url,
      };
      projectCrudFlow.isFileUploading = false;
    });
  };


  renderDropZone = () => {
    return (
      <View style={styles.dropzone}>
        <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <input {...getInputProps()} />
              <View style={styles.dropIC}>
                <View style={styles.iconContainer}>
                  <Icon
                    style={styles.uploadIcon}
                    name={"upload"}
                    size={24}
                  />
                </View>
                <StyledText style={styles.dropzoneText}>
                  Drop files here or select to upload
                </StyledText>
              </View>
            </div>
          )}
        </Dropzone>
      </View>
    );
  };

  renderDropZoneOC() {
    const { projectCrudFlow } = this.props;
    return (
      <View>
        {!projectCrudFlow.isFileUploading &&
          projectCrudFlow.media.length === 0 &&
          this.renderDropZone()}

        {projectCrudFlow.isFileUploading && (
          <ActivityIndicator
            animating={true}
            color={Palette.Secondary100Pct}
            style={styles.indicator}
          />
        )}
        {projectCrudFlow.media.length > 0 && (
          <View style={styles.imageOC}>
            <Image
              style={styles.projectImage}
              source={{ uri: projectCrudFlow.media[0].url }}
            />
            <StyledText
              style={{ textAlign: "center" }}
              onPress={() => this.removeImage()}
            >
              Remove Image (X)
            </StyledText>
          </View>
        )}
      </View>
    );
  }

  removeImage = () => {
    this.props.projectCrudFlow.media.splice(0, 1);
  };

  private questions(): ReadonlyArray<QuestionProps> {
    const { projectCrudFlow } = this.props;
    return [
      {
        title: "Where is your project located?",
        renderOptions: (complete) => (
          <GooglePlacesInput
            handleChange={this.onChangeAddressInput}
            complete={complete!}
            setCleared={this.setCleared}
            location="project"
            clearInput={this.props.projectCrudFlow.clearAddressInput}
            newDefaultValue={
              this.lastInputValue !== "" ? this.lastInputValue : undefined
            }
          />
        ),
      },
      {
        title: "Great! What type of building is this?",
        renderOptions: (complete) => (
          <View style={styles.projectOptions}>
            {buildingTypes.map((type, index) => {
              const buildingType = buildingTypeOptions[type];
              const isSelected = projectCrudFlow.buildingType === type;
              return (
                <ProjectOption
                  key={index}
                  name={buildingType.name}
                  icon={buildingType.icon}
                  isSelected={isSelected}
                  onPress={() => {
                    projectCrudFlow.buildingType = type;
                    complete?.();
                  }}
                />
              );
            })}
          </View>
        ),
      },
      {
        title: "What work do you need done?",
        renderOptions: (complete) => (
          <View style={styles.projectOptions}>
            {workTypes.map((type, index) => {
              const workType = workTypeOptions[type];
              const isSelected = projectCrudFlow.workType === type;
              return (
                <ProjectOption
                  key={index}
                  name={workType.name}
                  isSelected={isSelected}
                  onPress={() => {
                    projectCrudFlow.workType = type;
                    complete?.();
                  }}
                />
              );
            })}
          </View>
        ),
      },
      {
        title: "And where will it take place?",
        renderOptions: (complete) => (
          <View style={styles.projectOptions}>
            {situatedAtTypes.map((type, index) => {
              const situatedAtType = situatedAtTypeOptions[type];
              const isSelected = projectCrudFlow.situatedAt === type;
              return (
                <ProjectOption
                  key={index}
                  name={situatedAtType.name}
                  isSelected={isSelected}
                  onPress={() => {
                    projectCrudFlow.situatedAt = type;
                    complete?.();
                  }}
                />
              );
            })}
          </View>
        ),
      },
      {
        title: "Can you describe the structure?",
        renderOptions: (complete) => (
          <StyledTextInput
            multiline={true}
            style={[styles.inputField, styles.textArea]}
            value={projectCrudFlow.structureDescription}
            variant="primary"
            onChangeText={(value) => {
              projectCrudFlow.structureDescription = value;
              complete?.();
            }}
          />
        ),
      },
      // TODO: Finish upload photo functionality
      {
        title: "Please upload a project site photo",
        renderOptions: (complete) => this.renderDropZoneOC(),
      },
    ];
  }

  // TODO: possbily refactor below into reusable functions for other project views (OOP) - EY
  readonly isQuestionVisible = (index: number) => {
    return index === 0 || index <= this.props.projectCrudFlow.currentQuestion;
  };

  readonly incrementQuestion = (index: number) => {
    if (
      this.props.projectCrudFlow.currentQuestion <= index &&
      this.props.projectCrudFlow.currentQuestion < this.questions().length - 1
    ) {
      this.props.projectCrudFlow.currentQuestion++;
      // TODO: auto scroll screen to new question - EY
    }
  };

  readonly renderQuestions = () => {
    return (
      <View>
        {this.googleAPILoaded &&
          this.questions().map(({ title, renderOptions }, index) => {
            return (
              this.isQuestionVisible(index) && (
                <View key={index} style={styles.section}>
                  <StyledText
                    variant="heading"
                    isBold={true}
                    style={styles.sectionTitle}
                  >
                    {title}
                  </StyledText>
                  {renderOptions(() => this.incrementQuestion(index))}
                </View>
              )
            );
          })}
      </View>
    );
  };

  readonly updateProject = async () => {
    this.validateData();
  };

  readonly validateData = async () => {
    const { notification, projectCrudFlow } = this.props;
    if (!projectCrudFlow.projectLocationInput) {
      notification.setNotification("error", "Location Required");
      return;
    }
    if (!projectCrudFlow.buildingType) {
      notification.setNotification("error", "Building type required");
      return;
    }
    if (!projectCrudFlow.workType) {
      notification.setNotification("error", "Work Type required");
      return;
    }
    if (!projectCrudFlow.situatedAt) {
      notification.setNotification("error", "Situated at required");
      return;
    }
    if (!projectCrudFlow.structureDescription) {
      notification.setNotification("error", "Description required");
      return;
    }
    if (projectCrudFlow.media.length < 1) {
      notification.setNotification("error", "Image required");
      return;
    }
    this.submitSave();
  };
  readonly submitSave = async () => {
    const projectCrudFlow = this.props.projectCrudFlow;

    try {
      const updateProjectParams = {
        name: projectCrudFlow.projectLocationInput,
        address: {
          line1: projectCrudFlow.projectLocation.line1,
          line2: projectCrudFlow.projectLocation.line2 || "",
          city: projectCrudFlow.projectLocation.city || "",
          state: projectCrudFlow.projectLocation.state || "",
          country: projectCrudFlow.projectLocation.country || "",
          zip: projectCrudFlow.projectLocation.zip || "",
          placeId: projectCrudFlow.projectLocation.placeId,
        },
        locationType: projectCrudFlow.buildingType,
        workType: projectCrudFlow.workType,
        workLocation: projectCrudFlow.situatedAt,
        status: "draft" as ProjectStatus,
        description: projectCrudFlow.structureDescription,
        media: toJS(projectCrudFlow.media),
      };

      if (this.props.projectCrudFlow.projectId) {
        await this.props.projectModel.updateProject({
          ...updateProjectParams,
          id: this.props.projectCrudFlow.projectId,
        });
      } else {
        const result = await this.props.projectModel.createProject(updateProjectParams);
        this.props.projectCrudFlow.projectId = result.id!;
      }

      // bid information
      const createBidParams: CreateBidParams = {
        projectId: this.props.projectCrudFlow.projectId,
      };

      projectCrudFlow.name = projectCrudFlow.projectLocationInput;

      // budget inforrmation
      const createBudgetParams: CreateBudgetParams = {
        projectId: this.props.projectCrudFlow.projectId,
        startDate: new Date(),
      };

      await this.props.bidModel.createBid(createBidParams);
      await this.props.budgetModel.createBudget(createBudgetParams);

      this.props.projectCrudFlow.incrementCurrentStep();
    } catch (e) {
      console.log(e);
      this.props.notification.setNotification("error", "Failed to save project");
    }
  };

  render() {
    return (
      <View>
        {this.renderQuestions()}
        <View style={styles.buttonContainer}>
          {/* TODO: update button with disabled functionality - EY */}
          <StyledButton
            disabled={this.props.projectCrudFlow.isFileUploading}
            onPress={this.updateProject}
            text="Save and Continue"
            style={styles.button}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    marginBottom: 32,
  },
  inputField: {
    maxWidth: 564,
    marginBottom: 24,
  },
  textInput: {
    fontSize: 20,
  },
  textArea: {
    height: 120,
  },
  projectOptions: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  buttonContainer: {
    marginTop: 24,
    alignContent: "flex-start",
  },
  inputText: {
    fontSize: 18,
  },
  dropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    minHeight: 100,
    height: "auto",
    padding: 10,
  },
  dropzoneOC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  dropzoneText: {
  },
  label: {
    color: "#757675",
    fontSize: 12,
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    width: "fit-content",
  },
  projectImage: {
    resizeMode: "contain",
    flex: 1,
    aspectRatio: 1,
    margin: 50,
  },
  indicator: {
    margin: 50,
  },
  iconContainer: {
    backgroundColor: Palette.Primary5Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginLeft: "41%",
    marginBottom:15,
  },
  dropIC: {
    flexDirection: "column",
    margin:40
     },
  uploadIcon: {
    alignSelf: "center",
    top:13
  },
  imageOC: {
    height:432
  }
});
