import { WorkAreas } from "./WorkAreas";
import { WorkCategories } from "./WorkCategories";

type WorkLocation = "Interior" | "Exterior" | "Both";

export interface WorkAreaDefinition {
  areaName: WorkAreas,
  duplicate: boolean,
  initialCategories: Array<WorkCategories>,
  location: WorkLocation,
}

export const workAreaDefinitions: WorkAreaDefinition[] = [
  {
    areaName: "Master Bedroom",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Bedroom",
    duplicate: true,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Master Bathroom",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Bathroom",
    duplicate: true,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Kitchen",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Windows",
      "Finishes",
      "Appliances",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Living Room",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Family Room",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Front Yard",
    duplicate: false,
    initialCategories: [
      "Administration",
    ],
    location: "Exterior",
  },
  {
    areaName: "Back Yard",
    duplicate: false,
    initialCategories: [
      "Administration",
    ],
    location: "Exterior",
  },
  {
    areaName: "Entryway",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Main Living Space",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Office",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Bonus Room",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Basement",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Appliances",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Garage",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Appliances",
      "Paint",
      "Interior Doors",
    ],
    location: "Both",
  },
  {
    areaName: "Pantry",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Closet System",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Dining Room",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Hallway",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Laundry",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Plumbing",
      "Framing",
      "Insulation",
      "Cabinetry",
      "Closet System",
      "Finishes",
      "Appliances",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Loft",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Windows",
      "Finishes",
      "Paint",
    ],
    location: "Interior",
  },
  {
    areaName: "Home Theater",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Drywall",
      "Electrical",
      "Framing",
      "Insulation",
      "Finishes",
      "Paint",
      "Interior Doors",
    ],
    location: "Interior",
  },
  {
    areaName: "Porch",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Paint",
    ],
    location: "Exterior",
  },
  {
    areaName: "Outdoor Kitchen",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Electrical",
      "Plumbing",
      "Framing",
      "Cabinetry",
      "Finishes",
      "Appliances",
      "Paint",
    ],
    location: "Exterior",
  },
  {
    areaName: "Deck",
    duplicate: false,
    initialCategories: [
      "Administration",
      "Flooring",
      "Paint",
    ],
    location: "Exterior",
  },
];
