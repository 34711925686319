import { FunctionComponent } from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { InvitedUser, User } from "../../../data-model";
import { StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";
import { UserThumbnailPhoto } from "./UserThumbnailPhoto";

type Variant = "name" | "name-email";

interface Props extends ViewProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  variant?: Variant;
  user?: User | InvitedUser;
  colorIndex?: number;
}

export const UserInfo: FunctionComponent<Props> = ({
  onPress,
  style,
  variant = "name-email",
  user,
  colorIndex,
}) => {
  const showEmail = variant === "name-email" && user?.email;
  const WrapperComponent = onPress ? StyledTouchableOpacity : View;

  return (
    <WrapperComponent style={[styles.container, style]} onPress={onPress}>
      <>
        <UserThumbnailPhoto
          photoURL={user?.photoUrl}
          name={user?.name}
          colorIndex={colorIndex}
        />
        <View style={{ marginLeft: 8 }}>
          <StyledText variant={showEmail ? "body2" : "body"} isBold={true}>
            {user?.name}
          </StyledText>
          {showEmail && <StyledText variant="body2">{user.email}</StyledText>}
        </View>
      </>
    </WrapperComponent>
  );
};

export const userInfoImageStyle = {
  width: 32,
  height: 48,
  borderRadius: 16,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: userInfoImageStyle.width,
    height: userInfoImageStyle.height,
    borderRadius: userInfoImageStyle.borderRadius,
    backgroundColor: Palette.White,
  },
});
