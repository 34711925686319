import React from "react";
import { View, StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useHover } from "react-native-web-hooks";
import { StyledTouchableOpacity } from ".";
import { Palette } from "../styles";
import { StyledText } from "./StyledText";

type Variant = "primary" | "secondary";
type Variants = Record<Variant, StyleProp<ViewStyle>>;

interface Props {
  headerLeft?: JSX.Element | string;
  headerRight?: JSX.Element;
  onPress?: () => void;
  style?: StyleProp<TextStyle>
  variant?: Variant;
}

export const Card: React.FunctionComponent<Props> = (props) => {
  const { headerLeft, headerRight, onPress, style, variant } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  const hasHeader = !!headerLeft || !!headerRight;
  const variantStyle = variantStyles[variant || "primary"];
  const hoverStyle = (isHover && !!onPress) && styles.hover;
  const WrapperComponent = !!onPress ? StyledTouchableOpacity : View;

  return (
    <View ref={ref} style={[styles.container, variantStyle, hoverStyle, style]}>
      <WrapperComponent onPress={onPress}>
        {
          hasHeader && (
            <View style={styles.cardHeader}>
              {headerLeft && typeof headerLeft === "string" ? <StyledText variant="body" isBold={true} style={styles.headerText}>{headerLeft}</StyledText> : headerLeft}
              {headerRight}
            </View>
          )
        }
        {props.children}
      </WrapperComponent>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 8,
  },
  cardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  headerText: {
    textTransform: "uppercase",
  },
  hover: {
    backgroundColor: Palette.White,
  },
});

const variantStyles: Variants = {
  primary: {
    borderWidth: 1,
    borderColor: Palette.Primary10Pct,
    backgroundColor: Palette.Primary5Pct,
  },
  secondary: {
    borderRadius: 4,
    backgroundColor: Palette.White,
  },
};