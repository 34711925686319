import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { ProjectInvite } from "../Project";
import { User } from "../User";
import { CreateUserParams, MilestonePrice, UserModel, CategoryPrice } from "../UserModel";
import { RestClient } from "./RestClient";

export class RestUserModel extends UserModel {
  static inject: Inject<RestUserModel> = singletonInject((injector) => {
    return () => new RestUserModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public async getUser(forceRefreshToken?: boolean): Promise<User> {
    return await this.rest.get("/user", {
      forceRefreshToken,
    });
  }

  public getUserById(id:string): Promise<User> {
    return this.rest.get(`/user/${id}`);
  }

  public getUserByFUID(id:string): Promise<User> {
    return this.rest.get(`/user/get-by-fuid/${id}`);
  }

  public createUser(params: CreateUserParams): Promise<User> {
    return this.rest.post("/user", params);
  }

  public updateUser(params: any): Promise<void> {
    const { userId, ...data } = params;
    return this.rest.put(`/user/${userId}`, data);
  }

  public updateImage(params: any): Promise<void> {
    const { userId, ...data } = params;
    return this.rest.put(`/user/${userId}/photo`, data);
  }

  public getInvites(): Promise<ProjectInvite[]> {
    return this.rest.get("/user/invites");
  }

  public getUserTypeById(): Promise<void> {
    return this.rest.get("/user/type");
  }

  public getCategoryPrices(): Promise<CategoryPrice[]> {
    return this.rest.get(`/user/category/prices`);
  }

  public setCategoryPrices(params: CategoryPrice[]): Promise<any> {
    return this.rest.put(`/user/category/prices`, params);
  }

  public getMilestonePrices(): Promise<MilestonePrice[]> {
    return this.rest.get(`/user/milestone/prices`);
  }

  public setMilestonePrices(params: MilestonePrice[]): Promise<void> {
    return this.rest.put(`/user/milestone/prices`, params);
  }
}
