import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { matchPath } from "react-router";
import { ProjectModel } from "../../../data-model";
import { History, HistoryInjectable } from "../HistoryInjectable";
import {
  InjectedFactoryComponent,
  withInjectedProps,
} from "../InjectorContext";
import { getRoute, Screen } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";
import { StyledButton } from "./controls";
import { ProjectCrudFlow } from "./ProjectCrudFlow";
import {
  MenuItem as DefaultMenuItem,
  SideBarMenuItemFactory,
} from "./SideBarMenuItem";

interface Props {
  projectCrudFlow: ProjectCrudFlow;
  history: History;
  projectModel: ProjectModel;
  userViewModel: UserViewModel;
}

interface MenuItem extends Omit<DefaultMenuItem, "isSelected"> {
  paths?: ReadonlyArray<Screen>;
}

@observer
class SideBarMainKlass extends React.Component<Props> {
  static inject: Inject<ReactElement> = (injector) => {
    return () => (
      <SideBarMainKlass
        history={injector.get(HistoryInjectable)()}
        projectModel={injector.get(ProjectModel)()}
        projectCrudFlow={injector.get(ProjectCrudFlow)()}
        userViewModel={injector.get(UserViewModel)()}
      />
    );
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  createNewProject = async () => {
    this.props.history.push(getRoute("newProject").path);
  };

  @observable menuItems: Array<MenuItem> = [
    // TODO: find way to highlight if a menu item is selected - EY
    {
      name: "Dashboard",
      icon: "home",
      screen: "dashboard",
      paths: ["dashboard"],
    },
    {
      name: "Projects",
      icon: "grid",
      screen: "projects",
      paths: ["projects", "project"],
    },
    {
      name: "Invitations",
      icon: "user-plus",
      screen: "invitations",
      paths: ["invitations"],
    },
    // { name: "Payments", icon: "dollar", screen: "dashboard" },
  ];
  @observable private userType: string = "";

  render() {
    // TODO: find way to highlight if a menu item is selected - EY
    // TODO: hide below menu items for V1 - EY
    // { name: "Payments", icon: "dollar", screen: "dashboard" },
    // { name: "Settings", icon: "setting", screen: "dashboard" },

    const currentPath = this.props.history.location.pathname;

    return (
      <>
        <View style={styles.menuItems}>
          {this.menuItems.map((props) => {
            return (
              <InjectedFactoryComponent
                factory={SideBarMenuItemFactory}
                key={props.name}
                props={{
                  ...props,
                  isSelected: props.paths?.some((screen) =>
                    matchPath(currentPath, getRoute(screen).path)
                  ),
                }}
              />
            );
          })}
        </View>

        <StyledButton
          text="New Project"
          variant="primary"
          iconRight={{ name: "plus", type: "white", size: 24 }}
          style={styles.newProjectButton}
          onPress={this.createNewProject}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  menuItems: {
    marginBottom: 48,
  },
  newProjectButton: {
    marginTop: 16,
  },
});

export const SideBarMain = withInjectedProps(SideBarMainKlass);
