import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 56 106"
    >
      <path
        fill="#EAEDEF"
        fillRule="evenodd"
        d="M56 25V0c0 13.807-11.193 25-25 25h-3C12.536 25 0 37.536 0 53s12.536 28 28 28h3c13.807 0 25 11.193 25 25V25z"
        clipRule="evenodd"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M27 58l-5-5 5-5M34 58l-5-5 5-5"
      />
    </svg>
  );
}

export default memo(Icon);
