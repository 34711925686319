import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon: FunctionComponent<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M21 11.96a8.123 8.123 0 0 1-.126 1.472 8.54 8.54 0 0 1-3.415 5.429 8.579 8.579 0 0 1-2.079 1.095 9.052 9.052 0 0 1-1.127.32 8.617 8.617 0 0 1-3.225.058 8.926 8.926 0 0 1-.956-.227 8.8 8.8 0 0 1-.925-.337 8.259 8.259 0 0 1-.447-.21l-.712.238-.713.237-.712.238-.713.237-.712.238-.713.237-.712.238L3 21.46l.238-.712.237-.713.238-.712.237-.713.238-.712.237-.713.238-.712.237-.713a8.55 8.55 0 0 1-.773-2.328 8.044 8.044 0 0 1-.114-.978 8.285 8.285 0 0 1 .069-1.669c.027-.194.061-.387.102-.578a8.419 8.419 0 0 1 1.414-3.205 8.618 8.618 0 0 1 .75-.904m12.277-.071c.184.185.264.281.429.48a8.424 8.424 0 0 1 1.503 2.678 8.587 8.587 0 0 1 .365 1.5c.038.256.064.515.078.775v.5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          stroke: color,
        }}
      />
      <path
        d="m10.398.74.326 9.653h3.315l.46-9.653h-4.101Z"
        style={{
          strokeLinejoin: "round",
          stroke: color,
          fill: color,
        }}
      />
      <circle
        style={{
          fill: color,
          stroke: color,
        }}
        cx={12.412}
        cy={14.572}
        r={2.54}
      />
    </svg>
  );
};

export default memo(Icon);
