import { AnonymousAuthenticationProvider, User } from "../exco-auth";
import { FirebaseWebAuthenticationProvider } from "./FirebaseWebAuthenticationProvider";

export class FirebaseWebAnonymousAuthenticationProvider
  extends FirebaseWebAuthenticationProvider<"anonymous">
  implements AnonymousAuthenticationProvider {

  public get type(): "anonymous" { return "anonymous"; }

  public async signIn(): Promise<User> {
    await this.delegate!.firebaseAuth.signInAnonymously();
    return this.delegate!.currentUser!;
  }
}
