import { observer } from "mobx-react";
import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { InjectorContext } from "../InjectorContext";
import { getRoute } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";

interface ProtectedRouteOptions {
  bypassUserVerification?: boolean;
  bypassUserSetup?: boolean;
}

interface ProtectedRouteProps {
  options?: ProtectedRouteOptions;
}

interface RenderRouteParams extends ProtectedRouteProps {
  userViewModel: UserViewModel;
  routeProps: RouteComponentProps;
}

@observer
class WrappedRoute extends React.Component<RenderRouteParams & RouteProps> {
  componentDidMount() {
    this.props.userViewModel.initialize();
  }

  render() {
    const { userViewModel, children, component, render, routeProps, options } =
      this.props;

    if (!userViewModel.initialized) {
      return null; // NOTE: `undefined` throws error, use `null` instead
    }

    const currentUser = userViewModel.currentUser;

    if (!currentUser) {
      return (
        <Redirect
          to={{
            pathname: getRoute("login").path,
            state: {
              from: routeProps.location,
            },
          }}
        />
      );
    }

    if (!options?.bypassUserVerification && !currentUser.isVerified) {
      return (
        <Redirect
          to={{
            pathname: getRoute("verifyEmail").path,
            state: {
              from: routeProps.location,
            },
          }}
        />
      );
    }

    if (!options?.bypassUserSetup && (
      (currentUser.userType === "contractor" && !currentUser.contractor) ||
      (currentUser.userType === "owner" && !currentUser.owner)
    )) {
      return (
        <Redirect
          to={{
            pathname: getRoute("signUp").path,
            state: {
              from: routeProps.location,
            },
          }}
        />
      );
    }

    if (component) {
      return React.createElement(component, routeProps);
    }

    if (render) {
      return render(routeProps);
    }

    if (typeof children === "function") {
      return children(routeProps);
    }

    return children;
  }
}

export class ProtectedRoute extends React.Component<ProtectedRouteProps & RouteProps> {
  render() {
    const { component, children, render, options, ...rest } = this.props;
    return (
      <InjectorContext.Consumer>
        {(injector) => (
          <Route
            {...rest}
            render={(routeProps) => (
              <WrappedRoute
                userViewModel={injector.get(UserViewModel)()}
                children={children}
                component={component}
                render={render}
                routeProps={routeProps}
                options={options}
              />
            )}
          />
        )}
      </InjectorContext.Consumer>
    );
  }
}
