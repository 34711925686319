import React, { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Project } from "../../../data-model";
import { withInjectedFactory } from "../InjectorContext";
import { InjectedProjectWidgetFactory, ProjectWidgetController } from "./ProjectWidget";
import { ProjectWidgetManager } from "./ProjectWidgetManager";


interface CreateProps {
  currentProject: Project;
}

interface Props {
  projectWidgetManager: ProjectWidgetManager;
}

export class ProjectWidgetLayoutFactory {
  static inject: Inject<ProjectWidgetLayoutFactory> = (injector) => {
    return () =>
      new ProjectWidgetLayoutFactory({
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectWidgetLayout {...this.props} {...props} />;
  }
}

@observer
class ProjectWidgetLayout extends React.Component<Props & CreateProps> {
  componentWillUnmount = () => {
    this.props.projectWidgetManager.closeWidget();
  }

  render(): React.ReactNode {
    const { projectWidgetManager } = this.props;
    return (
      <View style={[styles.projectWidgetContainer]}>
        <View style={{ flex: 1 }}>
          {this.props.children as JSX.Element & ReactNode}
        </View>
        {projectWidgetManager.isOpen ? (
          <View style={styles.widgetContainer}>
            <InjectedProjectWidgetFactory
              currentProject={this.props.currentProject}
            />
          </View>
        ) : (
          <View style={styles.controllerContainer}>
            <ProjectWidgetController
              onPress={() => projectWidgetManager.openWidget("details")}
            />
          </View>
        )}
      </View>
    );
  };
};

const styles = StyleSheet.create({
  projectWidgetContainer: {
    flex: 1,
    flexDirection: "row",
  },
  widgetContainer: {
    marginLeft: 40,
    marginTop: -64,
    marginRight: -84,
    // position: "absolute",
    // right: 0,
    // top: 80,
  },
  controllerContainer: {
    // position: "absolute",
    // right: -4,
  },
});

export const InjectedProjectWidgetLayout = withInjectedFactory(ProjectWidgetLayoutFactory);
