import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import {
  ApproveContractorParams,
  RejectContractorParams,
  UploadVerificationParams,
  VerificationModel,
} from "../VerificationModel";
import { Verification } from "../Verification";
import { Contractor } from "../Contractor";
import { RestClient } from "./RestClient";

export class RestVerificationModel extends VerificationModel {
  static inject: Inject<VerificationModel> = singletonInject((injector) => {
    return () => new RestVerificationModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public uploadVerification(
    params: UploadVerificationParams
  ): Promise<Contractor> {
    return this.rest.post("/verification", params);
  }

  public getAllVerifications(): Promise<Verification[]> {
    return this.rest.get("/verification/all");
  }

  public approveContractor(params: ApproveContractorParams): Promise<void> {
    return this.rest.post("/verification/approve", params);
  }

  public rejectContractor(params: RejectContractorParams): Promise<void> {
    return this.rest.post("/verification/reject", params);
  }
}
