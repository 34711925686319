import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.333 8H14M7.333 4H14M7.333 12H14M4.667 11.084l-1.834 1.833L2 12.084M4.667 7.083L2.833 8.918 2 8.084M4.667 3.083L2.833 4.918 2 4.084"
      ></path>
    </svg>
  );
}

export default memo(Icon);
