import { Inject } from "@not-the-droids/exco-ts-inject";
import { CheckoutResult, FundPhasesParams, PaymentIntent, TrustshareProject } from "Payment";

export abstract class PaymentModel {
  static inject?: Inject<PaymentModel> = undefined;

  public abstract getPaymentByProjectId(paymentId: string): Promise<TrustshareProject>;

  public abstract pay(clientSecret: string): Promise<CheckoutResult>;

  public abstract fundPhasesInProject(params: FundPhasesParams): Promise<PaymentIntent>;

}
