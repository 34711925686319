import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { SignatureModel } from "../SignatureModel";
import { RestClient } from "./RestClient";

export class RestSignatureModel extends SignatureModel {
  static inject: Inject<any> = singletonInject((injector) => {
    return () => new RestSignatureModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public generateDocument(params: any): Promise<any> {
    return this.rest.post("/signature/generate-document", params);
  }  

  public generateNewSignUrls(params: any): Promise<any> {
    return this.rest.post("/signature/generate-new-sign-urls", params);
  }  

  public getDocument(params: any): Promise<any> {
    return this.rest.post("/signature/get-document", params);
  }

  // public getUser(): Promise<User> {
  //   return this.rest.get("/user");
  // }

  // public getUserTypeById(): Promise<void> {
  //   return this.rest.get("/user/type");
  // }
}
