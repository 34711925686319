import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Card, Icon, StyledButton, StyledText } from "./controls";
import { Palette } from "./styles";
import { formatCurrencyToString } from "../utils/Numbers";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { SectionBlock } from "./SectionBlock";
import { SummaryLayout } from "./SummaryLayout";
import { capitalizeFirstLetter, structureAddress } from "../utils/Strings";
import { uploadFromBlobAsync } from "../utils/Storage";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import {
  BidModel,
  BudgetModel,
  InvitedUser,
  OwnerModel,
  Project,
  ProjectModel,
} from "../../../data-model";
import { FuzzySearch } from "./FuzzySearch";
import { UserInfo } from "./UserInfo";
import { Notification } from "../NotificationInjectable";
import { CommentBubble } from "./CommentBubble";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { withInjectedFactory } from "../InjectorContext";
import { UserViewModel } from "../viewModels/UserViewModel";
import { History, HistoryInjectable } from "../HistoryInjectable";

interface Props {
  projectDetailsFlow: ProjectDetailsFlow;
  bidModel: BidModel;
  budgetModel: BudgetModel;
  history: History;
  userViewModel: UserViewModel;
  ownerModel: OwnerModel;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
  notification: Notification;
}

interface CreateProps {
  changeBidView: () => void;
  currentProject: Project;
}

export class ProjectBidSummaryViewFactory {
  static inject: Inject<ProjectBidSummaryViewFactory> = (injector) => {
    return () =>
      new ProjectBidSummaryViewFactory({
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        bidModel: injector.get(BidModel)(),
        budgetModel: injector.get(BudgetModel)(),
        history: injector.get(HistoryInjectable)(),
        userViewModel: injector.get(UserViewModel)(),
        ownerModel: injector.get(OwnerModel)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBidSummaryView {...this.props} {...props} />;
  }
}

@observer
class ProjectBidSummaryView extends React.Component<Props & CreateProps> {
  @observable public inviteeEmail: string = "";
  @observable private error: string = "";
  @observable private disclosureFiles: any[] = [];
  @observable private isFileUploading: boolean = false;
  @observable private owners: any[] = [];
  @observable private invites: InvitedUser[] = [];
  @observable private addInvite: InvitedUser | undefined = undefined;
  @observable private acceptedOwners: InvitedUser[] = [];
  @observable private invitesRetrieved: boolean = false;
  @observable private inviteResubmitted: boolean = false;

  @computed public get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  }

  @computed public get bidSubmitted(): boolean {
    return (
      this.props.projectDetailsFlow.bid?.approved ||
      (this.props.userViewModel.isContractor &&
        !!this.props.projectDetailsFlow.bid?.submittedAt)
    );
  }

  @computed public get bidRejected(): boolean {
    return !!this.props.projectDetailsFlow.bid?.rejected;
  }

  @computed public get bidHasOwner(): boolean {
    return (
      !!this.addInvite ||
      !!this.acceptedOwners.length ||
      !!this.invites.length ||
      this.props.currentProject.creator.userType === "owner"
    );
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    const { currentProject, projectDetailsFlow, projectWidgetManager } =
      this.props;
    this.getInvites();
    this.getOwners();
    this.getBid();

    if (projectDetailsFlow.bid) {
      this.disclosureFiles = projectDetailsFlow.bid.files as [];
    }
    if (!!currentProject?.id) {
      await projectWidgetManager.loadComments(currentProject.id, "bid", projectDetailsFlow.bid?.id);
    }
  };

  getBid = async () => {
    const { projectDetailsFlow, bidModel, currentProject } = this.props;
    if (currentProject.id) {
      projectDetailsFlow.bid = await bidModel.getBidByProjectId(
        currentProject.id
      );
    }
  };

  getInvites = async () => {
    const { projectModel, currentProject } = this.props;
    if (currentProject.id) {
      this.invites = await projectModel.getInvitesByProjectId(
        currentProject.id
      );
      // FIXME: this is misleading, because it can contain contractors as well
      this.acceptedOwners = await projectModel.getRolesByProjectId(
        currentProject.id
      );
      this.invitesRetrieved = true;
    }
  };

  getOwners = async () => {
    let response = await this.props.ownerModel.getOwners();
    this.owners = response;
  };

  readonly renderProjectedTotal = (total: number) => {
    return (
      <View
        style={[
          styles.row,
          styles.spaceBetween,
          styles.divider,
          styles.section,
        ]}
      >
        <StyledText style={styles.projectTotal}>Projected Total</StyledText>
        <StyledText variant="heading2">
          {formatCurrencyToString(total)}
        </StyledText>
      </View>
    );
  };

  readonly handleCommentBubblePress = (bubbleIndex: number, tagName: string, parentId: string) => {
    const { projectWidgetManager } = this.props;
    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(parentId, tagName);
      this.props.projectWidgetManager.activeCommentIndex = bubbleIndex;
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  readonly renderBidLines = () => {
    const { projectDetailsFlow } = this.props;
    if (
      projectDetailsFlow?.bid?.lines &&
      projectDetailsFlow.bid.lines.length > 0
    ) {
      return projectDetailsFlow.bid.lines.map((bidLine, index) => {
        return (
          <View style={styles.categoryRow} key={index}>
            <View
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd,
                index === 0 ? styles.tableRowFirstChild : undefined,
                index === projectDetailsFlow.bid!.lines.length - 1
                  ? styles.tableRowLastChild
                  : undefined,
              ]}
            >
              <StyledText
                variant="body2"
                isBold={true}
                style={styles.categoryCol}
              >
                {bidLine.category}
              </StyledText>
              <StyledText variant="body2" style={styles.scopeCol}>
                {bidLine.scopeOfWork}
              </StyledText>
              <StyledText variant="body2" style={styles.costCol}>
                {formatCurrencyToString(bidLine.cost)}
              </StyledText>
            </View>
            <CommentBubble
              isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(this.props.userViewModel.currentUser!.id, bidLine.category)}
              isFilled={this.props.projectWidgetManager.activeCommentIndex === index}
              onPress={() => {
                this.handleCommentBubblePress(index, bidLine.category, bidLine.bidId);
              }}
              style={styles.commentBubble}
            />
          </View>
        );
      });
    }
  };

  onDrop = async (acceptedFiles: any) => {
    const { bidModel, projectDetailsFlow } = this.props;
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    this.isFileUploading = true;

    this.error = "";
    let url: string;
    try {
      url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `/bid-files/${file.name}_${Date.now()}`,
      });
    } catch (e) {
      runInAction(() => {
        this.isFileUploading = false;
      });
      this.error = e as string;
      return;
    }
    let newFile = {
      id: uuidv4(),
      fileName: acceptedFiles[0].name,
      type: "file",
      url: url,
    };
    runInAction(() => {
      if (projectDetailsFlow.bid) {
        bidModel.addFile(projectDetailsFlow.bid.id, newFile).then(() => {
          this.disclosureFiles.push(newFile);
        });

        this.isFileUploading = false;
      } else {
        this.isFileUploading = false;
      }
    });
  };
  renderDropZone = () => {
    return (
      <View style={styles.dropzone}>
        <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <input {...getInputProps()} />
              <View style={styles.dropIC}>
                <View style={styles.iconContainer}>
                  <Icon
                    style={styles.uploadIcon}
                    name={"upload"}
                    size={24}
                  />
                </View>
                <StyledText style={styles.dropzoneText}>
                  Drop files here or select to upload
                </StyledText>
              </View>
            </div>
          )}
        </Dropzone>
      </View>
    );
  };
  renderDropZoneOC() {
    return (
      <View>
        {!this.isFileUploading && this.renderDropZone()}

        {this.isFileUploading && (
          <ActivityIndicator
            animating={true}
            color={Palette.Secondary100Pct}
            style={styles.indicator}
          />
        )}
      </View>
    );
  }
  removeFile = (index: number) => {
    const { projectDetailsFlow } = this.props;
    let id = this.disclosureFiles[index].id;
    if (projectDetailsFlow.bid) {
      this.props.bidModel.deleteFile(projectDetailsFlow.bid.id, id).then(() => {
        this.disclosureFiles.splice(index, 1);
      });
    }
  };

  deleteInvite = (invite: InvitedUser, submitted: boolean) => {
    if (!submitted) {
      this.addInvite = undefined;
      return;
    }
    const { currentProject, projectModel } = this.props;
    projectModel.deleteInvitation({
      projectId: currentProject.id,
      userId: invite.userId,
    });
    this.invites = this.invites.filter((i) => i.userId !== invite.userId);
  };

  renderSelectedInvite = () => {
    const { currentProject } = this.props;
    let renderOwner: InvitedUser | undefined = undefined;
    const invited = !!this.invites.length && this.invites[0];
    const accepted = !!this.acceptedOwners.length && this.acceptedOwners[0];
    if (currentProject.creator?.userType === "owner") {
      renderOwner = currentProject.creator;
    } else if (invited) {
      renderOwner = invited;
    } else if (accepted) {
      renderOwner = accepted;
    } else if (this.addInvite) {
      renderOwner = this.addInvite;
    }

    if (renderOwner)
      return (
        <View style={styles.selectedInviteContainer} key={renderOwner.userId}>
          <UserInfo variant="name-email" user={renderOwner} />
          {!accepted && (
            <StyledText
              variant="body2"
              colorMode="warning"
              onPress={() => this.deleteInvite(renderOwner!, !!invited)}
              style={styles.selectedInviteRemove}
            >
              Remove
            </StyledText>
          )}
        </View>
      );

    return <></>;
  };

  renderInviteInput = () => {
    return (
      <View style={[styles.sectionSmall, styles.searchInputOC]}>
        <FuzzySearch
          onSelect={(inv) => {
            if (this.bidSubmitted) this.inviteResubmitted = true;
            this.addInvite = inv as any;
          }}
          searchList={this.owners}
          variant={"contractor"}
        />
      </View>
    );
  };

  submitBid = async () => {
    const {
      bidModel,
      projectModel,
      currentProject,
      notification,
      projectDetailsFlow,
    } = this.props;
    try {
      if (this.addInvite) {
        projectModel.inviteOwner({
          projectId: currentProject.id,
          ownerId: this.addInvite.userId,
        });
        this.invites.push(this.addInvite);
        this.addInvite = undefined;
      }
      if (this.inviteResubmitted) {
        this.inviteResubmitted = false;
        return;
      }
      await bidModel.submitBid(projectDetailsFlow.bid!.id);

      // Reflect changes from db
      projectDetailsFlow.bid = await bidModel.getBidById(
        projectDetailsFlow.bid!.id
      );
      notification.setNotification("success", "Bid Submitted!");
    } catch (e) {
      console.log(e);
      notification.setNotification("error", e as string);
    }
  };

  approveBid = async () => {
    const { bidModel, notification, projectDetailsFlow } = this.props;
    await bidModel.approveBid(projectDetailsFlow.bid!.id);
    projectDetailsFlow.bid!.approved = true;
    notification.setNotification("success", "Bid Approved!");
  };

  rejectBid = async () => {
    const { bidModel, notification, projectDetailsFlow } = this.props;
    await bidModel.rejectBid(projectDetailsFlow.bid!.id);
    projectDetailsFlow.bid!.rejected = true;  
    notification.setNotification("success", "Bid Rejected with Comments!");
    // this.props.history.push(`/projects`);
  };

  getSubmitText = () => {
    if (this.inviteResubmitted) {
      return "Send new invite";
    }

    if (this.props.projectDetailsFlow.bid?.approved) {
      return "Approved";
    }
    if (this.userType === "contractor") {
      if (!!this.props.projectDetailsFlow.bid?.submittedAt && !this.props.projectDetailsFlow.bid?.rejected)
        return "Submitted, awaiting approval";
      if (!!this.addInvite) return "Submit Bid and Invite Owner";
      return "Submit Bid";
    } else {
      return "Approve Bid";
    }
  };

  render(): React.ReactNode {
    const { projectDetailsFlow, changeBidView, currentProject } = this.props;
    return (
      <>
        <SummaryLayout
          title="Review Rough Bid"
          subtitle="Please take a moment to review your bid before submitting."
          onBackPress={() => changeBidView()}
          backButtonHidden={this.bidSubmitted || this.userType === "owner"}
          userType={this.userType}
        >
          {/* SECTION: Project Detail */}
          <StyledText
            variant="heading3"
            isBold={true}
            style={styles.cardHeader}
          >
            Project Details
          </StyledText>
          <View style={styles.section}>
            <View>
              <View style={[styles.sectionSmall, styles.row]}>
                <SectionBlock title="Property owner:" style={{ flex: 1 }}>
                  <StyledText variant="body2">Owner Name</StyledText>
                </SectionBlock>
                <SectionBlock title="Property name:" style={{ flex: 2 }}>
                  <StyledText variant="body2">{currentProject.name}</StyledText>
                </SectionBlock>
              </View>
              <View style={styles.sectionSmall}>
                <SectionBlock title="Project Address:">
                  <StyledText variant="body2">
                    {currentProject?.address?.line1 &&
                      structureAddress(currentProject?.address)}
                  </StyledText>
                </SectionBlock>
              </View>
              <View style={[styles.sectionSmall, styles.row]}>
                <SectionBlock title="Location Type:" style={{ flex: 1 }}>
                  <StyledText variant="body2">
                    {capitalizeFirstLetter(currentProject.locationType)}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Work Type:" style={{ flex: 1 }}>
                  <StyledText variant="body2">
                    {capitalizeFirstLetter(currentProject.workType)}
                  </StyledText>
                </SectionBlock>
                <SectionBlock title="Work Location:" style={{ flex: 1 }}>
                  <StyledText variant="body2">
                    {capitalizeFirstLetter(currentProject.workLocation)}
                  </StyledText>
                </SectionBlock>
              </View>
              <View style={styles.sectionSmall}>
                <SectionBlock title="Struction Description:">
                  <StyledText variant="body2">
                    {currentProject.description}
                  </StyledText>
                </SectionBlock>
              </View>
            </View>
          </View>

          {/* SECTION: Rough Bid */}
          <View style={styles.section}>
            <StyledText
              variant="heading3"
              isBold={true}
              style={styles.cardHeading}
            >
              Rough Bid
            </StyledText>
            <Card variant="secondary">
              <View style={styles.section}>
                <View style={styles.tableRow}>
                  <StyledText
                    variant="body2"
                    isBold={true}
                    style={[styles.tableColHeader, styles.categoryCol]}
                  >
                    Category
                  </StyledText>
                  <StyledText
                    variant="body2"
                    isBold={true}
                    style={[styles.tableColHeader, styles.scopeCol]}
                  >
                    Scope of Work
                  </StyledText>
                  <StyledText
                    variant="body2"
                    isBold={true}
                    style={[styles.tableColHeader, styles.costCol]}
                  >
                    Cost
                  </StyledText>
                </View>
                <View style={styles.table}>{this.renderBidLines()}</View>
              </View>

              {/*  <StyledText variant="body2" style={styles.sectionHeader}>
                Recommended additions to scope:
              </StyledText>*/}
              {/*   <View
                style={[
                  styles.tableRow,
                  styles.tableRowEven,
                  styles.tableRowFirstChild,
                  styles.tableRowLastChild,
                ]}
              >
                <StyledText
                  variant="body2"
                  isBold={true}
                  style={styles.categoryCol}
                >
                  Additional labor
                </StyledText>
                <StyledText variant="body2" style={styles.scopeCol}>
                  This will cover additional costs associated with construction,
                  including paperwork, permits, etc.
                </StyledText>
                <StyledText variant="body2" style={styles.costCol}>
                  $850.00
                </StyledText>
              </View>*/}
            </Card>
          </View>

          {/* SECTION: Projected Total */}
          <View
            style={[
              styles.row,
              styles.spaceBetween,
              styles.divider,
              styles.section,
            ]}
          >
            <StyledText style={styles.projectTotal}>Projected Total</StyledText>
            <StyledText variant="heading2">
              {formatCurrencyToString(projectDetailsFlow.projectedTotal)}
            </StyledText>
          </View>
          <View style={styles.section}>
            <StyledText
              variant="heading3"
              isBold={true}
              style={styles.sectionHeader}
            >
              Disclosures
            </StyledText>
            <StyledText variant="body2" style={styles.disclosureText}>
              {projectDetailsFlow.bid?.disclosure}
            </StyledText>
            {/*{this.userType !== "owner" && this.renderDropZoneOC()}*/}
            {/* {this.disclosureFiles.length > 0 &&
              this.disclosureFiles.map((item: any, index: number) => {
                return (
                  <View style={styles.fileContainer}>
                    <Icon
                      name="file"
                      mode="dark"
                      size={24}
                      onClick={() => window.open(item.url)}
                    />
                    <StyledText
                      style={styles.fileText}
                      onPress={() => window.open(item.url)}
                    >
                      {item.fileName}
                    </StyledText>
                    <Icon
                      name="x"
                      mode="dark"
                      size={14}
                      style={styles.removeIcon}
                      onClick={() => {
                        this.userType !== "owner" && this.removeFile(index);
                      }}
                    />
                  </View>
                );
              })}*/}
          </View>

          <View style={styles.section}></View>
          {this.userType !== "owner" && (
            <View style={styles.section}>
              <StyledText
                variant="heading3"
                isBold={true}
                style={styles.sectionHeader}
              >
                {this.acceptedOwners.length > 0
                  ? "Property Owner"
                  : "Invite property owner"}
              </StyledText>
              <StyledText variant="body2">
                The property owner will have a chance to review the bid to
                approve or request changes.
              </StyledText>
              <View style={styles.section}>
                {this.invitesRetrieved && this.bidHasOwner
                  ? this.renderSelectedInvite()
                  : this.renderInviteInput()}
              </View>
            </View>
          )}
        </SummaryLayout>
        <View style={styles.submitButtons}>
          <StyledButton
            style={styles.submitButton}
            disabled={
              (this.bidSubmitted || this.bidRejected || !this.bidHasOwner) && !this.inviteResubmitted
            }
            text={this.getSubmitText()}
            onPress={
              this.userType === "contractor" ? this.submitBid : this.approveBid
            }
          />
          {
            this.userType === "owner" && !this.props.projectDetailsFlow.bid?.approved &&  (
              <StyledButton
                style={styles.submitButton}
                disabled={this.bidRejected}
                text={"Reject Bid with Comments"}
                onPress={
                  this.rejectBid
                }
              />
            )
          }
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  cardHeader: {
    marginBottom: 8,
  },
  section: {
    marginBottom: 32,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  sectionHeader: {
    marginBottom: 4,
  },
  categoryCol: {
    width: 164,
    marginRight: 24,
  },
  scopeCol: {
    flex: 2,
    marginRight: 24,
  },
  costCol: {
    width: 100,
  },
  projectTotal: {
    fontSize: 32,
    lineHeight: 40,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary25Pct,
    marginTop: 16,
  },
  cardHeading: {
    marginBottom: 8,
  },
  table: {
    borderRadius: 4,
  },
  tableRow: {
    padding: 16,
    flexDirection: "row",
    flex: 1,
  },
  tableColHeader: {
    textTransform: "uppercase",
  },
  tableRowEven: {
    backgroundColor: Palette.Primary5Pct,
  },
  tableRowOdd: {
    backgroundColor: Palette.Primary10Pct,
  },
  tableRowFirstChild: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tableRowLastChild: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  submitButtons: {
    flexDirection: "row",
    gap: 20,
    left: 40,
    justifyContent: "center",
    marginTop: 20,
  },
  submitButton: {
    minWidth: 200,
  },
  dropIC: {
    flexDirection: "column",
    margin: 40,
  },
  uploadIcon: {
    alignSelf: "center",
    top: 13,
  },
  dropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    fontSize: 18,
    minHeight: 100,
    height: "auto",
    padding: 10,
  },
  dropzoneOC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  dropzoneText: {},
  indicator: {
    margin: 50,
  },
  fileContainer: {
    margin: 10,
    flexDirection: "row",
  },
  iconContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginLeft: "41%",
    marginBottom: 15,
  },
  disclosureText: {
    marginBottom: 10,
  },
  selectedInviteContainer: {
    marginTop: 20,
  },
  selectedInviteRemove: {
    marginTop: 5,
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    marginTop: 5,
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  searchInputOC: {
    marginTop: 20,
  },
  invitedIcon: {
    marginRight: 10,
  },
  fileText: {
    marginTop: 3,
    marginLeft: 5,
  },
  removeIcon: {
    margin: 5,
  },
  categoryRow: {
    display: "flex",
    flexDirection: "row",
  },
  commentBubble: {
    flex: 0,
    left: 60,
    marginTop: 15,
  },
});

export const InjectedProjectBidSummaryView = withInjectedFactory(
  ProjectBidSummaryViewFactory
);
