import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  StyledButton,
  StyledText,
  StyledTextInput,
} from "./controls";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute } from "../routes";
import { IconName } from "./styles";

interface Props {
  history: History;
}

@observer
export class ChangePasswordView extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <ChangePasswordView
        history={injector.get(HistoryInjectable)()}
      />
    );
  };
  @observable private currentPassword: string = "";
  @observable private newPassword: string = "";
  @observable private isNewPasswordVisible: boolean = false;

  @computed get passwordIcon(): IconName {
    if (this.isNewPasswordVisible) {
      return "eye-off";
    } else {
      return "eye";
    }
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  readonly togglePassword = () => {
    this.isNewPasswordVisible = !this.isNewPasswordVisible;
  }

  readonly handleSavePress = () => {
    // TODO: implement functionality - EY
  }

  readonly handleCancelPress = () => {
    const { history } = this.props;
    history.push(getRoute("profile").path);
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <StyledText variant="heading3" isBold={true} style={styles.sectionHeader}>Change Password</StyledText>
          <View style={styles.sectionSmall}>
            <StyledText variant="body2" isBold={true} style={styles.label}>Current Password</StyledText>
            <StyledTextInput
            variant="tertiary"
              placeholder="Enter current password"
              value={this.currentPassword}
              onChangeText={(value) => this.currentPassword = value}
            />
          </View>
          <View style={styles.sectionSmall}>
            <StyledText variant="body2" isBold={true} style={styles.label}>New Password</StyledText>
            <StyledTextInput
            variant="tertiary"
              placeholder="Enter new password"
              value={this.newPassword}
              secureTextEntry={!this.isNewPasswordVisible}
              onChangeText={(value) => this.newPassword = value}
              iconRight={{
                name: this.passwordIcon,
                type: "primary",
                size: 14,
                onClick: this.togglePassword,
              }}
            />
          </View>
        </View>

        <View style={styles.buttons}>
          <StyledButton text="Change Password" alignSelf={true} onPress={this.handleSavePress}/>
          <StyledButton text="Cancel" variant="link" style={styles.cancelButton} onPress={this.handleCancelPress}/>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 312,
    marginLeft: 82,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    marginBottom: 48,
  },
  sectionSmall: {
    marginBottom: 32,
  },
  sectionHeader: {
    marginBottom: 16,
  },
  label: {
    textTransform: "uppercase",
    marginBottom: 10,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
  },
  cancelButton: {
    marginLeft: 24,
  },
});
