import { ObservableList } from "@not-the-droids/exco-data-model";
import { RestClient, RestRequest } from "./RestClient";

export class RestObservableList<T, D> extends ObservableList<T> {
  constructor(
    private readonly client: RestClient,
    private readonly request: RestRequest<D>,
  ) {
    super();
  }

  protected onBecomeObserved(): void {
    this.initialize();
  }

  protected onBecomeUnobserved(): void {
  }

  public initialize(): Promise<readonly T[]> {
    return this.client.request(this.request);
  }

}
