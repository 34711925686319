import React from "react";
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import {
  StyledText,
} from "./controls";

interface ClauseProps extends ViewProps {
  heading: string;
  body: string;
  style?: StyleProp<ViewStyle>
};

export const Clause: React.FunctionComponent<ClauseProps> = (props) => {
  const { body, heading, style } = props;
  return (
    <View style={[styles.container, style]}>
      <StyledText style={styles.text}>
        <span style={{
          fontSize: 13,
          fontWeight: "bold",
          textTransform: "uppercase",
        }}>
          {heading}&nbsp;
        </span>{body}</StyledText>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 24,
  },
  text: {
    lineHeight: 20,
  },
});