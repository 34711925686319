import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M7.02 18.165c-.85 0-1.62-.23-2.31-.69-.69-.46-1.235-1.1-1.635-1.92-.39-.83-.585-1.785-.585-2.865 0-1.09.195-2.04.585-2.85.39-.82.925-1.455 1.605-1.905.68-.46 1.45-.69 2.31-.69.87 0 1.645.23 2.325.69.68.45 1.215 1.085 1.605 1.905.4.82.6 1.775.6 2.865 0 1.09-.2 2.045-.6 2.865-.39.82-.925 1.46-1.605 1.92-.67.45-1.435.675-2.295.675zm-.015-2.085c.44 0 .82-.125 1.14-.375.32-.26.565-.64.735-1.14.18-.51.27-1.13.27-1.86 0-.74-.09-1.36-.27-1.86-.17-.5-.415-.875-.735-1.125-.32-.26-.705-.39-1.155-.39-.43 0-.81.125-1.14.375-.32.25-.565.625-.735 1.125-.17.5-.255 1.12-.255 1.86s.085 1.36.255 1.86c.17.5.415.88.735 1.14.33.26.715.39 1.155.39zm10.005 2.085c-.85 0-1.62-.23-2.31-.69-.69-.46-1.235-1.1-1.635-1.92-.39-.83-.585-1.785-.585-2.865 0-1.09.195-2.04.585-2.85.39-.82.925-1.455 1.605-1.905.68-.46 1.45-.69 2.31-.69.87 0 1.645.23 2.325.69.68.45 1.215 1.085 1.605 1.905.4.82.6 1.775.6 2.865 0 1.09-.2 2.045-.6 2.865-.39.82-.925 1.46-1.605 1.92-.67.45-1.435.675-2.295.675zm-.015-2.085c.44 0 .82-.125 1.14-.375.32-.26.565-.64.735-1.14.18-.51.27-1.13.27-1.86 0-.74-.09-1.36-.27-1.86-.17-.5-.415-.875-.735-1.125-.32-.26-.705-.39-1.155-.39-.43 0-.81.125-1.14.375-.32.25-.565.625-.735 1.125-.17.5-.255 1.12-.255 1.86s.085 1.36.255 1.86c.17.5.415.88.735 1.14.33.26.715.39 1.155.39z"
      />
    </svg>
  );
}

export default memo(Icon);

