import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import {
  AddContractorToBidParams,
  BidModel,
  CreateBidParams,
  bidLinesParams,
  UpdateBidParams,
} from "../BidModel";
import { Bid, BidLine } from "../Bid";
import { RestClient } from "./RestClient";

export class RestBidModel extends BidModel {
  static inject: Inject<RestBidModel> = singletonInject((injector) => {
    return () => new RestBidModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public createBid(params: CreateBidParams): Promise<Bid> {
    return this.rest.post("/bid", params);
  }

  public getBidById(id: string): Promise<Bid> {
    return this.rest.get(`/bid/${id}`);
  }

  public updateBid(params: UpdateBidParams): Promise<void> {
    const { id, ...data } = params;
    return this.rest.put(`/bid/${id}`, data);
  }

  public submitBid(id: string): Promise<void> {
    return this.rest.post(`/bid/${id}/submit`);
  }  

  public approveBid(bidId: string): Promise<void> {
    return this.rest.post(`/bid/${bidId}/approve`);
  }

  public rejectBid(id: string): Promise<void> {
    return this.rest.post(`/bid/${id}/reject`);
  }

  public getBidLines(id: string): Promise<BidLine[]> {
    return this.rest.get(`/bid/${id}/lines`);
  }

  public updateBidLines(params: bidLinesParams): Promise<void> {
    const { ...data } = params;
    return this.rest.put(`/bid/lines`, data);
  }

  public deleteBidLines(params: BidLine[]): Promise<void> {
    // const { ...data} = params;
    return this.rest.post(`/bid/delete-lines`, params);
  }

  public getBidByProjectId(id: string): Promise<Bid> {
    return this.rest.get(`/bid/project/${id}`);
  }

  public getFilesById(params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.get(`/bid/${params.bidId}/file`);
  }

  public addFile(bidId: string, params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.post(`/bid/${bidId}/file/${params.id}`, data);
  }

  public deleteFile(bidId: string, id: string): Promise<void> {
    return this.rest.delete(`/bid/${bidId}/file/${id}`);
  }

  // Placeholder for now
  public addContractorToBid(params: AddContractorToBidParams): any {}
}
