import {
  StyleSheet,
  FlatList,
  View,
} from "react-native";
import { useEffect } from "react";
import { 
    StyledTextInput, 
    StyledTextInputProps,
    StyledText,
    StyledTouchableOpacity
} from "./controls";
import { Palette } from "./styles/Palette";
import { Hoverable } from "react-native-web-hooks";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

interface Props extends StyledTextInputProps {
  handleChange: (value: AddressParts, complete?: () => void) => void;
  complete?: (() => void | undefined);
  clearInput?: boolean;
  setCleared?: () => void;
  newDefaultValue?: string;
  location?: string;
}
        
export type AddressParts = {
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  placeId: string;
};

export const GooglePlacesInput = (props: Props) => {
  const {
    handleChange,
    complete,
    clearInput,
    newDefaultValue,
    setCleared,
    location
  } = props;

  let {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["us"] },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (clearInput) {
      setValue("");
      setCleared?.();
    } else if (newDefaultValue) {
      setValue(newDefaultValue);
      setCleared?.();
    } // eslint-disable-next-line
  }, [clearInput, newDefaultValue]);

  const handleInput = (value:string) => {
    setValue(value);
  };

  type Suggestion = google.maps.places.AutocompletePrediction;
  type PlaceResult = google.maps.places.PlaceResult;

  const parseAddress = (suggestion: PlaceResult) => {
    const map:Record<string, string> = {
      street_number: "streetNumber",
      route: "streetName",
      locality: "city",
      administrative_area_level_1: "state",
      postal_code: "zip",
      country: "country",
    };

    const usableAddress: Record<string, string | undefined> = {};
    (suggestion?.address_components || []).forEach((component) => {
      if (map.hasOwnProperty(component.types[0])) {
        usableAddress[map[component.types[0]]] = component.long_name
          ? component.long_name
          : "";
      }
    });
    usableAddress["line1"] =
      usableAddress.streetNumber + " " + usableAddress.streetName;
    usableAddress["placeId"] = suggestion.place_id;
    delete usableAddress["streetNumber"];
    delete usableAddress["streetName"];
    return usableAddress;
  };

  const handleSelect = (suggestion: Suggestion) => {
    const parameter = {
      placeId: suggestion.place_id,
      fields: [
        "address_components",
        "place_id",
        "formatted_address",
        "geometry",
      ],
    };
    //TODO remove type assertions
    getDetails(parameter)
      .then((details) => {
        const formattedAddress = (details as PlaceResult).formatted_address;
        setValue(formattedAddress as string, false);
        let parsedAddress = parseAddress(details as PlaceResult);
        if (complete) {
          handleChange(parsedAddress as any, complete);
        } else {
          handleChange(parsedAddress as any);
        }

        clearSuggestions();
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const renderItem = (item: Suggestion, index: number) => (
      <Hoverable>
        {(isHovered) => (
          <StyledTouchableOpacity
            style={[
              styles.suggestionContainer,
              isHovered && styles.suggestionHover,
            ]}
            onPress={() => handleSelect(item)}
          >
            <StyledText>{item.description}</StyledText>
          </StyledTouchableOpacity>
        )}
      </Hoverable>
  );

  const renderSuggestions = () => (
    <FlatList
      data={data as Suggestion[]}
      style={styles.flatList}
      renderItem={({ item, index }) => renderItem(item, index)}
      keyExtractor={(item) => item.place_id}
    />
  );

  return (
    <View style={styles.inputContainer}>
      <StyledTextInput
        iconRight={{ name: "search"}}
        placeholder="Enter your address..."
        style={location === "project" && styles.inputField}
        editable={ready}
        textStyle={location === "project" && styles.textInput}
        value={value}
        variant="tertiary"
        onChangeText={handleInput}
      />
      {status === "OK" && renderSuggestions()}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 48,
  },
  inputContainer: {
    maxWidth: 564,
    marginBottom: 24,
  },
  suggestionContainer: {
    padding: 24,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
  },
  suggestionHover: {
    backgroundColor: Palette.White,
  },
  flatList: {
    borderRadius: 5,
  },
  sectionTitle: {
    marginBottom: 32,
  },
  inputField: {
    marginBottom: 5,
    color: Palette.Primary100Pct,
  },
  textInput: {
    fontSize: 20,
  },
  textArea: {
    height: 120,
  },
  projectOptions: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  buttonContainer: {
    marginTop: 24,
  },
});
