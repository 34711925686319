import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { sanitizePriceInput } from "../utils/Numbers";
import { Icon, StyledButton, StyledText, StyledTextArea, StyledTextInput } from "./controls";
import { Palette } from "./styles";

export interface BudgetCategoryBodyProps {
  addTask: () => void;
  category: WorkCategory;
  deleteCategory: () => void;
  isDeletable?: boolean;
  deleteTask: (index: number) => void;
  updateCategory: <K extends keyof WorkCategory>(
    text: WorkCategory[K],
    property: K
  ) => void;
  updateTask: <K extends keyof Task>(
    text: Task[K],
    property: K,
    index: number
  ) => void;
  submitMilestone?: () => void;
}

type Props = BudgetCategoryBodyProps & {
  isComplete: boolean;
  milestone: any;
}

export interface WorkCategory {
  csiCode: string;
  name: string;
  scopeOfWork: string;
  tasks: Task[];
}

export interface Task {
  budget: number;
  description: string;
}

@observer
export class BudgetCategoryBody extends React.Component<Props> {

  // constructor(props: Props) {
  //   super(props);
  //   makeObservable(this);
  // }

  onNumberInputChange = (text: string, index: number) => {
    // TODO: Make sure this won't cause any issues
    // text should be a number based on type
    // @ts-ignore
    this.props.updateTask(sanitizePriceInput(text), "budget", index)
  }

  renderTasks = () => {
    const { milestone, updateTask } = this.props;
    return milestone.tasks?.map((task:any, index:number) => (
      <View key={index} style={[styles.row, styles.taskInputsContainer]}>
        <StyledTextInput
          onChangeText={(text) => updateTask(text, "description", index)}
          placeholder="Enter description of task"
          style={styles.taskInputCol}
          value={task.description}
        />
        <StyledTextInput
          onChangeText={(text: string) => this.onNumberInputChange(text, index)}
          placeholder="$--"
          style={styles.budgetInputCol}
          value={task.budget ? task.budget.toString() : ""}
        />
        <TouchableOpacity onPress={() => this.props.deleteTask(index)} style={styles.deleteIcon}>
          <Icon name="trash" type={"accent"} size={16} />
        </TouchableOpacity>
      </View>
    ));
  };

  render() {
    const { milestone, updateCategory, isDeletable } = this.props;
    return (
      <View style={[styles.container, styles.section]}>
        <View style={styles.sectionSmall}>
          <StyledText style={styles.header}>Scope of Work</StyledText>
          <StyledTextArea
            minHeight={88}
            onChangeText={(text) => updateCategory(text, "scopeOfWork")}
            placeholder="Enter a description of the scope of work for this milestone"
            value={milestone.scopeOfWork}
          />
        </View>

        <View style={styles.section}>
          <StyledText style={styles.header}>Tasks</StyledText>
          <View style={[styles.row, styles.header]}>
            <StyledText variant="caption" style={styles.taskInputCol}>Task Description</StyledText>
            <StyledText variant="caption" style={[styles.budgetInputCol, styles.budgetInputHeader]}>Budget</StyledText>
          </View>
          {this.renderTasks()}

          <StyledButton
            alignSelf={true}
            iconLeft={{ name: "plus", type: "accent" }}
            onPress={this.props.addTask}
            text="Add Task"
            variant="textOnly"
          />
        </View>

        <View style={[styles.row, styles.footerButtonsContainer]}>
        {  <StyledButton
            variant="secondary"
            text="Close and Continue"
            // disabled={!this.props.isComplete}
            iconRight={{ name: "arrow-down", type: "accent" }}
            onPress={this.props.submitMilestone}
          />}
          {isDeletable && <StyledButton
            variant="textOnly"
            text="Delete Milestone"
            textStyle={{ color: Palette.Warning100Pct }}
            iconRight={{ name: "trash", type: "warning" }}
            onPress={this.props.deleteCategory}
          />}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    marginHorizontal: 24,
  },
  row: {
    flexDirection: "row",
  },
  taskInputsContainer: {
    marginBottom: 8,
    alignItems: "center",
  },
  taskInputCol: {
    flex: 1,
  },
  budgetInputCol: {
    width: 88,
    marginLeft: 8,
  },
  budgetInputHeader: {
    left: -24, // To offset delete icon width
    position: "relative",
  },
  deleteIcon: {
    marginLeft: 12,
  },
  header: {
    marginBottom: 4,
  },
  section: {
    marginBottom: 24,
  },
  sectionSmall: {
    marginBottom: 16,
  },
  footerButtonsContainer: {
    justifyContent: "space-between",
  },
});
