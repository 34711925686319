import React from "react";
import Helmet from "react-helmet";
import { siteName } from "../constants/Global";

interface TitleComponentProps {
  title?: string;
}

const TitleComponent = (props: TitleComponentProps) => {
  const { title } = props;
  return (
    <Helmet>
      <title>{title ? title + " - " + siteName : siteName}</title>
    </Helmet>
  );
};

export function withTitle<T>(
  Component: React.ComponentType<T>,
  title?: string
): React.ComponentType<T> {
  return class ComponentWithTitle extends React.Component<T> {
    render() {
      return (
        <>
          <TitleComponent title={title} />
          <Component {...this.props} />
        </>
      );
    }
  };
}
