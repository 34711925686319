import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import {
  View,
  ScrollView,
  StyleSheet,
 } from "react-native";
import { useHover } from "react-native-web-hooks";
import { Palette } from "./styles";
import {
  Icon,
  StyledText,
  StyledTouchableOpacity,
 } from "./controls";


type ListItem = {
  name: string,
  value: string
}

interface Props {
  list: string[] | ListItem[] ;
  onSelect: (value: string) => void;
  value: string;
  placeholder?: string;
}


@observer
export class DropdownMenu extends React.Component<Props> {
  @observable private isDropdownMenuVisible: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  readonly toggleDropdownMenu = () => {
    this.isDropdownMenuVisible = !this.isDropdownMenuVisible;
  }

  readonly handleMenuItemPress = (item: string | ListItem) => {
    const { onSelect } = this.props;
    if (typeof item === 'string') {
      onSelect(item);
    } else {
      onSelect(item.name);
    }

    this.toggleDropdownMenu();
  }
  // Note: unable to find proper Typescript for this; marking as "any" for now - EY
  //--its NativeSyntheticEvent<TargetedEvent>, not sure what contains/related target are though - RM
  readonly handleBlur = (e: any) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    this.toggleDropdownMenu();
 };

 readonly getName = (item: string | ListItem) => {
    if (typeof item === 'string') {
      return item
    } else {
      return item.name
    }
 };

  render() {
    const { list, placeholder, value } = this.props;
    const highlightedStyle = this.isDropdownMenuVisible && styles.containerFocused;
    return (
      <View style={[styles.container, highlightedStyle]}>
        <StyledTouchableOpacity onPress={this.toggleDropdownMenu} onBlur={this.handleBlur}>
          <View style={styles.inputField}>
            <StyledText colorMode={!!value ? "dark" : "gray"}>{value || placeholder}</StyledText>
            <Icon name="chevron-down" size={14} />
          </View>
          {
            // remove type assertion
            this.isDropdownMenuVisible && (
              <ScrollView style={styles.dropdownListItemsContainer}>
                {
                  list?.map((listItem, index) => {
                    return (
                      <DropdownMenuItem key={index} name={this.getName(listItem)} onPress={this.handleMenuItemPress}/>
                    )
                  })
                }
              </ScrollView>
            )
          }
        </StyledTouchableOpacity>
      </View>
    )
  }
};

interface DropdownMenuItemProps {
  name: string;
  onPress: (value: string) => void;
}
const DropdownMenuItem: React.FunctionComponent<DropdownMenuItemProps> = (props) => {
  const { name, onPress } = props;
  const ref = React.useRef(null);
  const isHover = useHover(ref);
  const hoverStyle = isHover && styles.hover;

  return (
    <View ref={ref}>
      <StyledTouchableOpacity style={[styles.dropDownListItem, hoverStyle]} onPress={() => onPress(name)}>
        <StyledText>{name}</StyledText>
      </StyledTouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary100Pct,
  },
  containerFocused: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
  inputField: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropdownListItemsContainer: {
    maxHeight: 200, // TODO: number is arbitrary - EY
    width: "100%",
    position: "absolute",
    top: 40,
    overflow: "hidden",
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
  },
  dropDownListItem: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  hover: {
    backgroundColor: Palette.White,
  },
});
