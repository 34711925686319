import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { UserModel } from "../../../data-model";
import {
  Page,
  Screen,
  SideBar
} from "../components/controls";
import { HeaderFactory } from "../components/Header";
import { Invitation, InvitationCardFactory } from "../components/InvitationCard";
import { SideBarMain } from "../components/SideBarMain";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { InjectedFactoryComponent } from "../InjectorContext";

interface Props {
  history: History;
  userModel:UserModel
}

interface CreateProps {
  currentPath?: string;
}

export class InvitationsScreenFactory {
  static inject: Inject<InvitationsScreenFactory> = (injector) => {
    return () =>
      new InvitationsScreenFactory({
        history: injector.get(HistoryInjectable)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <InvitationsScreen {...this.props} {...props} />;
  }
}

@observer
class InvitationsScreen extends React.Component<Props & CreateProps> {
  @observable private invitations: any[] = []; // TODO: update with Invitation "any" type after Invitation is defined - EY

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    await this.loadInvitations();
  }

  readonly loadInvitations = async () => {
    const {userModel} = this.props
    this.invitations = await userModel.getInvites()
  };

  handleRemoveInvitation = (invitation:Invitation) => {
    const invitationIndex = this.invitations.findIndex(invite => invite.projectId === invitation.projectId);
    this.invitations.splice(invitationIndex,1)
  };

  render() {
    return (
      <Screen>
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedFactoryComponent
            factory={HeaderFactory}
            props={{
              // Note: Hiding invitation CTA in V1 - EY
              // leftElement: <HeaderCTA feature="invite" onPress={() => {}} />,
            }}
          />

          <View style={styles.cardsContainer}>
            {
              this.invitations.length > 0 && this.invitations.map((invitation, index) => {
                return (
                  <InjectedFactoryComponent factory={InvitationCardFactory} props={{
                    invitation:toJS(invitation),
                    removeInvitation:this.handleRemoveInvitation
                  }} />
                );
              })
            }
          </View>
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  cardsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
