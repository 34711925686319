import { FunctionComponent } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { StyledTouchableOpacity } from ".";
import { IconComponents, IconName, iconTypes, IconType } from "../styles";

export interface IconProps {
  width: number;
  height: number;
  color: string;
}

export type IconSize = 8 | 14 | 16 | 18 | 20 | 24 | 28 | 32 | 56 | 72 | 120;
const defaultSize = 24;

export interface IconComponentProps {
  name: IconName;
  type?: IconType;
  size?: IconSize;
  width?: IconSize;
  height?: IconSize;
  onClick?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const Icon: FunctionComponent<IconComponentProps> = ({
  name,
  type = "primary",
  size = defaultSize,
  width,
  height,
  onClick,
  style,
}) => {
  const IconComponent = IconComponents[name];
  const color = iconTypes[type];
  width = width ?? size;
  height = height ?? size;

  const WrapperComponent = onClick ? StyledTouchableOpacity : View;
  return (
    <WrapperComponent onPress={onClick} style={style}>
      <IconComponent {...{ width, height, color }} />
    </WrapperComponent>
  );
};
