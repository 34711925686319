import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { BudgetPhase } from "../../../../data-model";
import { calculatePhaseStartDates } from "../../utils/Budget";
import { formatCurrencyToString } from "../../utils/Numbers";
import { formatDateLongMonth } from "../../utils/Strings";
import { CommentBubble } from "../CommentBubble";
import { StyledText } from "../controls";

interface Props {
  activeComment: boolean;
  phases: BudgetPhase[];
  startDate: string;
  totalDays: number;
  totalCost: number;
  handleCommentBubblePress: () => void;
  unreadComment: boolean;
}

export const ContractPaymentSchedule: FunctionComponent<Props> = ({
  activeComment,
  phases,
  startDate,
  totalDays,
  totalCost,
  handleCommentBubblePress,
  unreadComment,
}) => {
  const phasesStartDate = calculatePhaseStartDates(startDate, phases);

  return (
    <>
      <View style={styles.row}>
        {/* Total and phase names */}
        <View style={styles.firstColContainer}>
          <StyledText
            isMedium
            style={[styles.contractTotalLine, styles.contractTotalHeader]}
          >
            Contract Total
          </StyledText>

          <StyledText isBold={true} style={styles.contractSectionHeader}>
            Proposed Payment Schedule
          </StyledText>

          <StyledText variant="body" isBold={true} style={styles.phaseHeader}>
            PHASE NAME
          </StyledText>

          {phases.map((phase, index) => (
            <StyledText style={styles.sectionText} isMedium key={index}>
              {`Phase ${index + 1}: ${phase.name}`}
            </StyledText>
          ))}
        </View>

        {/* Days */}
        <View style={styles.secondColContainer}>
          <StyledText
            variant="heading3"
            isBold={true}
            style={styles.contractTotalLine}
            numberOfLines={1}
          >
            {totalDays} Days
          </StyledText>

          <View style={styles.contractSectionHeader} />
          <StyledText
            variant="body"
            isBold={true}
            style={styles.phaseHeader}
            numberOfLines={1}
          >
            START DATES
          </StyledText>

          {phasesStartDate.map((date, index) => {
            return (
              <StyledText style={styles.sectionText} isMedium key={index}>
                {formatDateLongMonth(date)}
              </StyledText>
            );
          })}
        </View>

        {/* Price */}
        <View style={styles.thirdColContainer}>
          <StyledText
            variant="heading3"
            isBold={true}
            style={styles.contractTotalLine}
          >
            {formatCurrencyToString(totalCost, "$0.00")}
          </StyledText>

          <View style={styles.contractSectionHeader} />
          <StyledText variant="body" isBold={true} style={styles.phaseHeader}>
            TOTALS
          </StyledText>

          {phases.map((phase, index) => (
            <StyledText
              variant="body"
              style={styles.sectionText}
              isMedium
              key={index}
            >
              {formatCurrencyToString(phase.cost, "$0.00")}
            </StyledText>
          ))}
        </View>
      </View>
      {/* Fine print */}
      <StyledText style={styles.finePrint}>
        Change Orders will be added to the different invoices as they get
        approved.
      </StyledText>

      {/* Comment */}
      <CommentBubble
        isAlert={unreadComment}
        isFilled={activeComment}
        onPress={handleCommentBubblePress}
        style={styles.commentBubble}
      />
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  firstColContainer: {
    flex: 3,
  },
  secondColContainer: {
    flex: 1,
    alignItems: "flex-end",
  },
  thirdColContainer: {
    alignSelf: "flex-end",
    alignItems: "flex-end",
    marginLeft: "5%",
  },
  phaseHeader: {
    marginBottom: 10,
  },
  contractTotalHeader: {
    fontSize: 32,
  },
  contractTotalLine: {
    height: 40,
    marginBottom: 10,
    textAlignVertical: "bottom",
    fontSize: 24,
  },
  contractSectionHeader: {
    height: 24,
    marginBottom: 4,
  },
  sectionText: {
    lineHeight: 20,
    marginBottom: 8,
  },
  finePrint: {
    marginTop: 8,
    fontSize: 10,
    lineHeight: 13,
  },
  commentBubble: {
    position: "absolute",
    right: -58,
    top: 80,
  },
});
