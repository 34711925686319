import { Inject } from "@not-the-droids/exco-ts-inject";
import { CurrentUser } from "./CurrentUser";
import { ProjectInvite } from "./Project";
import { User } from "./User";

export interface CreateUserParams extends Partial<User> {}

export interface MilestonePrice {
  milestoneName: string;
  id?: string,
  csiCode?: string;
  scope?: string;
  tasks?: TaskPrice[];
}

export interface TaskPrice {
  description: string;
  budget: string;
  id?: string;
}

export interface CategoryPrice {
  category: string;
  id?: string;
  scope?: string;
  budget?: string;
}

export abstract class UserModel {
  static inject?: Inject<UserModel> = undefined;

  public abstract getUser(forceRefreshToken?: boolean): Promise<CurrentUser>;
  public abstract getUserById(id:string): Promise<User>;
  public abstract getUserByFUID(id:string): Promise<User>;
  public abstract getUserTypeById(): any;
  public abstract updateUser(params: any): Promise<void>;
  public abstract createUser(params: CreateUserParams): Promise<User>;
  public abstract getInvites(): Promise<ProjectInvite[]>;
  public abstract updateImage(params:any): Promise<any>;
  public abstract getCategoryPrices(): Promise<CategoryPrice[]>;
  public abstract setCategoryPrices(params: CategoryPrice[]): Promise<any>;
  public abstract getMilestonePrices(): Promise<MilestonePrice[]>;
  public abstract setMilestonePrices(params: MilestonePrice[]): Promise<any>;
}
