import { milestoneDefinitions } from "../constants/MilestoneDefinitions";

import { categoryDefinitions } from "../constants/CategoryDefinitions";
import {
  BudgetInput,
  BudgetMilestoneInput,
  BudgetPhaseInput,
} from "../components/ProjectBudgetEditView";
import {
  BidLine,
  Budget,
  BudgetMilestone,
  BudgetPhase,
  PaymentPhase,
  WorkCategoryAreas,
} from "../../../data-model";
import { parseSavedPriceToInput } from "./Numbers";
import { addDays, differenceInCalendarDays, formatDistanceToNowStrict, isPast } from "date-fns";
import { TrustshareProject } from "../../../data-model/Payment";
import { ProjectPayment } from "../components/ProjectDetailsFlow";
import { TagType } from "../components/controls/Tag";
import { Palette } from "../components/styles";

export const sortOrderIndex = (a: any, b: any) => {
  if (a.renderIndex < b.renderIndex) {
    return -1;
  }
  if (a.renderIndex > b.renderIndex) {
    return 1;
  }
  return 0;
};

export const parseBudgetPhasesToInput = (
  phases: BudgetPhase[],
  budgetId: string
): BudgetPhaseInput[] => {
  return phases.sort(sortOrderIndex).map((item, index) => ({
    ...item,
    type: "phase",
    phaseIndex: index + 1,
    renderIndex: item.orderIndex,
    budgetId,
  }));
};

export const parseBudgetMilestonesToInput = (
  milestones: BudgetMilestone[],
  budgetId: string
): BudgetMilestoneInput[] => {
  return milestones.sort(sortOrderIndex).map((item, index) => ({
    ...item,
    type: "milestone",
    milestoneIndex: index + 1,
    renderIndex: item.orderIndex,
    budgetId: budgetId,
    tasks: item.tasks.map((i) => ({
      ...i,
      budget: parseSavedPriceToInput(i.budget),
    })),
  }));
};

export const reindexBugetInput = (budget: BudgetInput[]): BudgetInput[] => {
  const newBudget: any = budget;
  let lastPhaseIndex: number = 0;
  let milestoneIndices: number[] = [];
  for (
    let i: number = 0, x: number = 0, y: number = 0;
    i < budget.length;
    i++
  ) {
    newBudget[i].renderIndex = i + 1;
    newBudget[i].orderIndex = i + 1;
    if (newBudget[i].type === "phase") {
      if (milestoneIndices.length > 0) {
        newBudget[lastPhaseIndex].milestoneIndices = milestoneIndices;
        milestoneIndices = [];
        lastPhaseIndex = i;
      }

      x++;
      newBudget[i].phaseIndex = x;
    } else if (newBudget[i].type === "milestone") {
      milestoneIndices.push(i + 1);
      y++;
      newBudget[i].milestoneIndex = y;
      if (i + 1 === newBudget.length) {
        newBudget[lastPhaseIndex].milestoneIndices = milestoneIndices;
      }
    }
  }
  return newBudget;
};

export const createBudgetInput = (
  budget: Budget,
  bidLines: BidLine[] | undefined,
  workCategories: WorkCategoryAreas[]
): BudgetInput[] => {
  const phases = parseBudgetPhasesToInput(budget.phases, budget.id);
  const milestones = parseBudgetMilestonesToInput(budget.milestones, budget.id);
  const sortedBudget = [...phases, ...milestones].sort(sortOrderIndex);

  if (sortedBudget.length > 0) {
    return reindexBugetInput(sortedBudget);
  } else {
    const budgetInput = createNewBudgetInput(bidLines, budget.id);
    mapWorkAreasInBudgetInput(budgetInput, workCategories);
    return budgetInput;
  }
};

export const createNewBudgetInput = (
  bidLines: BidLine[] | undefined,
  budgetId: string
): BudgetInput[] => {
  const phases: BudgetInput[] = [];
  const milestones: BudgetMilestoneInput[] = [];

  phases.push({
    type: "phase",
    phaseIndex: 1,
    renderIndex: 1,
    orderIndex: 0,
    name: "Phase 1",
    milestoneIndices: [],
    budgetId: budgetId,
    cost: 0,
    numDays: 0,
  });
  if (bidLines && bidLines.length > 0) {
    const getMilestoneDefinitionIndex = (category: string) => {
      return milestoneDefinitions.findIndex(
        (x: any) => x.milestoneName.toUpperCase() === category.toUpperCase()
      );
    };
    const getCategoryDefinitionIndex = (category: string) => {
      return categoryDefinitions.findIndex(
        (x: any) => x.category.toUpperCase() === category.toUpperCase()
      );
    };
    const createTasks = (tasks: string[]) => {
      let newTasks: any[] = [];
      for (let i = 0; i < tasks.length; i++) {
        newTasks.push({
          description: tasks[i],
          budget: 0,
        });
      }
      return newTasks;
    };
    let index: number = 0;
    for (let i = 0; i < bidLines.length; i++) {
      const categoryDefinition =
        categoryDefinitions[getCategoryDefinitionIndex(bidLines[i].category)];
      if (categoryDefinition) {
        for (let x = 0; x < categoryDefinition.milestones.length; x++) {
          const currentMilestone = categoryDefinition.milestones[x];
          const milestoneDefinition =
            milestoneDefinitions[getMilestoneDefinitionIndex(currentMilestone)];

          if (
            milestones.findIndex((item) => item.name === currentMilestone) ===
            -1
          ) {
            milestones.push({
              type: "milestone",
              milestoneIndex: index + 1,
              renderIndex: milestoneDefinition.orderIndex + 1,
              orderIndex: milestoneDefinition.orderIndex + 1,
              tasks: createTasks(milestoneDefinition.tasks),
              files: [],
              name: currentMilestone,
              scopeOfWork: milestoneDefinition.scope,
              csiCode: milestoneDefinition.csiCode,
              budgetId: budgetId,
            });
            (phases[0] as BudgetPhaseInput).milestoneIndices.push(index);
            index++;
          }
        }
      } else {
        milestones.push({
          type: "milestone",
          milestoneIndex: index + 1,
          renderIndex: index + 99,
          orderIndex: index + 99,
          tasks: [],
          files: [],
          name: bidLines[i].category,
          scopeOfWork: bidLines[i].scopeOfWork,
          budgetId: budgetId,
          csiCode: "",
        });
      }
    }
  }

  return reindexBugetInput([...phases, ...milestones].sort(sortOrderIndex));
};

export const mapWorkAreasInBudgetInput = (
  budget: BudgetInput[],
  workCategories: WorkCategoryAreas[]
) => {
  const milestoneWorkAreasMap: Map<string, string[]> = new Map();

  workCategories.forEach((workCat) => {
    const foundCategory = categoryDefinitions.find(
      (catDef) => catDef.category === workCat.workCategory
    );
    if (foundCategory) {
      foundCategory.milestones.forEach((milestone: string) => {
        milestoneWorkAreasMap.set(milestone, workCat.workAreas);
      });
    }
  });

  for (let i = 0; i < budget.length; i++) {
    if (budget[i].type === "milestone") {
      const workAreas = milestoneWorkAreasMap.get(budget[i].name);
      (budget[i] as BudgetMilestoneInput).workAreas = workAreas;
      const scopeOfWork = (budget[i] as BudgetMilestoneInput).scopeOfWork;
      if (workAreas) {
        (budget[i] as BudgetMilestoneInput).scopeOfWork = scopeOfWork
          ? scopeOfWork + ` (${workAreas?.join(", ")})`
          : workAreas.join(", ");
      }
    }
  }
};

export const calculatePhaseStartDates = (
  start: Date | string,
  phases: BudgetPhase[]
): Date[] => {
  let prev: Date = new Date(start);
  return phases.map((_, index) => {
    if (index === 0) {
      return prev;
    } else {
      const current = addDays(prev, phases[index - 1].numDays)
      prev = current;
      return current;
    }
  });
};

export const calculateRemamingProjectBalance = (
  phases: BudgetPhase[],
  trustshare: TrustshareProject
) => {
  const remainingBalance = phases.reduce((acc, phase) => {
    if (
      trustshare.settlements.find(
        (settlement) => settlement.metadata.id === phase.id
      )
    )
      return acc;
    return acc + Number(phase.cost);
  }, 0);
  return remainingBalance;
};

export const mapProjectPayments = (
  budget: Budget,
  trustshare: TrustshareProject
): ProjectPayment => {
  const startDates = calculatePhaseStartDates(budget.startDate, budget.phases);
  const phases = budget.phases.map((phase, index) => {
    const phaseStartDate = startDates[index];
    const phasePayment = trustshare.settlements.find(
      (settlement) => settlement.metadata.id === phase.id
    );
    return {
      ...phase,
      startDate: phaseStartDate,
      payment: phasePayment,
    };
  });

  // First phase that has not been payed and have a cost
  const nextPayment = phases.find((p) => !p.payment && p.cost > 0);

  // The first phase that either has not been payed or
  // has not start yet should be in the middle
  let phasesPreview = [];
  for (let i = 0; i < phases.length; i++) {
    const phase = phases[i];
    const prevPhase = phases[i - 1];
    if (
      prevPhase &&
      (new Date(prevPhase.startDate) > new Date() || !prevPhase.payment) &&
      i > 1
    ) {
      phasesPreview.push(phase);
      break;
    }
    phasesPreview.push(phase);
  }

  phasesPreview = phasesPreview.slice(-3);
  const payments = trustshare.settlements.map((settlement) => ({
    name: settlement.description,
    date: settlement.created_at,
    type: settlement.type,
    amount: settlement.amount,
  }));

  return {
    phases,
    payments,
    nextPayment,
    phasesPreview,
  };
};


type PaymentPhaseDateTextAndTag = {
  dateTag: {
    type: TagType
    text: string
  },
  fundingTag: {
    type: TagType
    text: string
  },
  startsText: string
  startsTextColor: string
}

export const formatPaymentPhaseDateTextAndTag = (phase: PaymentPhase):PaymentPhaseDateTextAndTag => {
  const phaseStartDate = new Date(phase.startDate);
  const isInPast = isPast(phaseStartDate);

  const startDistance = differenceInCalendarDays(phaseStartDate, new Date());
  const startDistanceText = formatDistanceToNowStrict(phaseStartDate, {
    unit: "day",
  });

  return {
    dateTag: {
      type: isInPast ? "dark" : "light",
      text: isInPast ? "Started" : "Not Started",
    },
    fundingTag: {
      type: !phase.payment
        ? "light"
        : phase.payment.status === "settled"
        ? "affirm"
        : "dark",
      text: !phase.payment
      ? "Not Funded"
      : phase.payment.status === "settled"
      ? "Funded"
      : "Processing funds",
    },
    startsText: isInPast
      ? `Started ${startDistanceText} ago`
      : `Starts in ${startDistanceText}`,
    startsTextColor:
      isInPast && (!phase.payment || phase.payment.status !== "settled")
        ? Palette.Warning100Pct
        : !isInPast && startDistance <= 10
        ? Palette.Secondary100Pct
        : Palette.Primary100Pct,
  };
};