import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { 
  Budget, 
  CreateBudgetParams, 
  DeleteBudgetMilestoneParams, 
  DeleteBudgetPhaseParams, 
  UpdateBudgetContractUrlsParams, 
  UpdateBudgetMilestoneParams, 
  UpdateBudgetPhasesParams, 
  UpdateBudgetSignatureParams, 
  UpdateDisclosureParams 
} from "../Budget";
import {
  BudgetModel,
} from "../BudgetModel";
import { RestClient } from "./RestClient";

export class RestBudgetModel extends BudgetModel {
  static inject: Inject<RestBudgetModel> = singletonInject((injector) => {
    return () => new RestBudgetModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getBudgetById(id: string): Promise<Budget> {
    return this.rest.get(`/budget/${id}`);
  }

  // FIXME: This shouldn't really be here and should be addressed when project is restrucutred.
  public getBudgetByProjectId(id: string): Promise<Budget> {
    return this.rest.get(`/budget/project/${id}`);
  }

  public createBudget(params: CreateBudgetParams): Promise<Budget> {
    return this.rest.post("/budget", params);
  }

  public updateBudget(params: any): Promise<any> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}`, data);
  }

  public updateBudgetSignature(params: UpdateBudgetSignatureParams): Promise<any> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/contract/sign`, data);
  }

  public updateBudgetContractUrls(params: UpdateBudgetContractUrlsParams): Promise<any> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/contract-urls`, data);
  }

  public updateBudgetContract(params: any): Promise<any> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/contract`, data);
  }

  public updateMilestone(params: UpdateBudgetMilestoneParams): Promise<void> {
    const { ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/milestone`, data);
  }

  public updateDisclosure(params: UpdateDisclosureParams): Promise<void> {
    const {budgetId, ...data } = params;
    return this.rest.put(`/budget/${params.budgetId}/disclosure`, data);
  }

  public completeContract(id:string): Promise<void> {
    return this.rest.put(`/budget/${id}/complete`);
  }

  public submitBudget (id:string): Promise<void> {
    return this.rest.put(`/budget/${id}/submit`);
  }

  public deleteMilestone(params: DeleteBudgetMilestoneParams): Promise<void> {
    return this.rest.delete(
      `/budget/${params.budgetId}/milestone/${params.orderIndex}`
    );
  }

  public getFilesById(params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.get(`/budget/${params.budgetId}/file`);
  }

  public addFile(budgetId:string, params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.post(`/budget/${budgetId}/file/${params.id}`, data);
  }

  public deleteFile(budgetId:string, id: string): Promise<void> {
    return this.rest.delete(`/budget/${budgetId}/file/${id}`);
  }

  public getMilestoneFilesById(milestoneId: string): Promise<void> {
    return this.rest.get(`/budget/milestone/${milestoneId}/file`);
  }

  public addMilestoneFile(milestoneId: string, params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.post(`/budget/milestone/${milestoneId}/file/${params.id}`, data);
  }

  public deleteMilestoneFile(milestoneId: string, id: string): Promise<void> {
    return this.rest.delete(`/budget/milestone/${milestoneId}/file/${id}`);
  }

  public updatePhases(params: UpdateBudgetPhasesParams): Promise<void> {
    const { budgetId, ...data } = params;
    return this.rest.put(`/budget/${budgetId}/phases`, data);
  }

  public deletePhase(params: DeleteBudgetPhaseParams): Promise<void> {
    const { budgetId, orderIndex } = params;
    return this.rest.delete(`/budget/${budgetId}/phase/${orderIndex}`);
  }
}
