import React from "react";
import { StyleSheet, View } from "react-native";

export class CenteredLayout extends React.Component {
  render() {
    return (
      <View style={localStyle.outer}>
        <View style={localStyle.inner}>{this.props.children}</View>
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  outer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
