import { FunctionComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { StyledTouchableOpacity, Icon, StyledText } from "../controls";

interface Props {
  onEditClick?: () => void;
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
}

export const SummaryBlock: FunctionComponent<Props> = ({
  onEditClick,
  title,
  containerStyle,
  children,
}) => {
  return (
    <View style={[styles.section, containerStyle]}>
      {/* TITLE */}
      {title && (
        <View style={styles.titleContainer}>
          <StyledText variant="heading3" isBold={true}>
            {title}
          </StyledText>
          {onEditClick && (
            <StyledTouchableOpacity
              onPress={onEditClick}
              style={styles.editIcon}
            >
              <Icon name="edit" type="accent" />
            </StyledTouchableOpacity>
          )}
        </View>
      )}
      {/* CONTENT */}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 32,
  },

  titleContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  editIcon: {
    marginLeft: 8,
  },
});
