import { List } from "@not-the-droids/exco-data-model/List";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Comment, CommentType } from "./Comment";

export interface CreateCommentParams {
  readonly comment: string;
  readonly tag?: string;
  readonly parentId?: string;
  readonly type: CommentType;
}

export interface GetCommentParams {
  readonly projectId: string;
  readonly type: CommentType;
}

export interface UpdateCommentParams {
  readonly readCommentIds: Array<string>;
  readonly type: CommentType;
}

export abstract class CommentModel {
  static inject?: Inject<CommentModel> = undefined;

  public abstract createComment(
    params: CreateCommentParams
  ): Promise<Comment>;

  public abstract getCommentsByType(
    params: GetCommentParams
  ): Promise<Comment[]>;

  public abstract updateComments(
    params: UpdateCommentParams
  ): Promise<void>;
}
