import React from "react";
import { StyleSheet, View } from "react-native";
import { CircleIcon, StyledText } from "./controls";
import { IconName } from "./styles";

type FeatureType = "addProject" | "invite";

interface Props {
  feature: FeatureType;
  onPress: () => void;
}

interface FeatureProps {
  name: string;
  icon: IconName;
}
const features: Record<FeatureType, FeatureProps> = {
  addProject: {
    name: "Projects",
    icon: "plus",
  },
  invite: {
    name: "Invite",
    icon: "user-plus",
  },
};

export const HeaderCTA: React.FunctionComponent<Props> = (props) => {
  const { feature, onPress } = props;
  const featureItem = features[feature];
  return (
    <View style={styles.container}>
      <StyledText variant="heading2">{featureItem.name}</StyledText>
      <CircleIcon
        backgroundColor="secondary"
        icon={featureItem.icon}
        iconSize={24}
        onPress={onPress}
        size={32}
        style={styles.circleIcon}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  circleIcon: {
    marginLeft: 16,
  },
});
