import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { ProjectModel, WorkCategoryAreas } from "../../../data-model";
import { ProjectCrudFlow } from "./ProjectCrudFlow";
import { StyledButton, StyledText } from "./controls";
import { Palette } from "./styles";
import { Notification } from "../NotificationInjectable";
import { WorkAreaOptionFactory, WorkAreaSearch, WorkCategoryLocation } from "./WorkAreaOption";
import { WorkAreaDefinition, workAreaDefinitions } from "../constants/WorkAreaDefinitions";
import { CategoryDefinition, categoryDefinitions } from "../constants/CategoryDefinitions";
import { InjectedFactoryComponent } from "../InjectorContext";
import { romanize } from "../utils/Numbers";


interface Props {
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
  notification: Notification;
}

interface CreateProps {
  projectId?: string; // TODO: remove project ID from CreateProps. Use ProjectCrudFlow variables instead - EY
}

export interface WorkAreaParams {
  areaName: string;
  duplicate?: boolean;
  exclusiveCategories: Array<WorkCategoryParams>;
  inclusiveCategories: Array<WorkCategoryParams>;
  isChecked: boolean;
  isOpen?: boolean;
  location: WorkCategoryLocation;
  numDuplicated: Array<number>;
  order: number;
}

export interface WorkCategoryParams {
  category: string;
  isCustom: boolean;
  isSelected: boolean;
  order: number;
  wasExcluded: boolean;
}

// const workAreaTemplate = new Set([
//   "Master Bedroom",
//   "Bedroom",
//   "Master Bathroom",
//   "Bathroom",
//   "Kitchen",
//   "Living Room",
//   "Family Room",
//   "Office",
//   "Basement",
//   "Dining Room",
//   "Laundry",
// ])

export class ProjectFlowWorkAreasViewFactory {
  static inject: Inject<ProjectFlowWorkAreasViewFactory> = (injector) => {
    return () =>
      new ProjectFlowWorkAreasViewFactory({
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectFlowWorkAreasView {...this.props} {...props} />;
  }
}

@observer
export default class ProjectFlowWorkAreasView extends React.Component<Props> {
  @observable isSearchVisible: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  };

  @computed private get workAreaOptions() {
    const { projectCrudFlow } = this.props;
    return (
      <View style={styles.workAreaContainer}>
        {this.props.projectCrudFlow.inclusiveWorkAreas.map((workArea, index) => {
          return (
            <View style={{zIndex: projectCrudFlow.inclusiveWorkAreas.length - index}}
              key={"WorkAreaOption" + workArea.areaName + index}
            >
              <InjectedFactoryComponent
                factory={WorkAreaOptionFactory}
                props={{
                  areaIndex: index,
                  categoryCount: projectCrudFlow.categoryCount,
                  duplicate: !!workArea.duplicate ? this.duplicate : undefined,
                  updateSelecteWorkAreaNum: this.updateSelecteWorkAreaNum,
                }}
              />
            </View>
          )
        })}
        <View style={!this.isSearchVisible && {marginTop: 32, zIndex: -1}}>
          {
            !this.isSearchVisible ? (
              <StyledButton 
                onPress={() => this.isSearchVisible = !this.isSearchVisible}
                style={styles.addAreaButton}
                variant={"secondary"}
                text={"Add an Area"}
              />
            ) : (
              <WorkAreaSearch
                searchList={this.props.projectCrudFlow.exclusiveWorkAreas}
                handleClose={() => this.isSearchVisible = !this.isSearchVisible}
                handleWorkAreaSelect={this.handleWorkAreaSelect}
              />
            )
          }
        </View>
      </View>
    )
  };

  async componentDidMount() {
    this.generateAreaAndCategories();
  };

  private handleWorkAreaSelect = (topic: any) => {
    const { projectCrudFlow } = this.props;
    const index = projectCrudFlow.exclusiveWorkAreas.findIndex(exArea => exArea.areaName === topic.areaName);
    if (index > -1) {
      const deletedArea = projectCrudFlow.exclusiveWorkAreas.splice(index, 1)[0];
      if (!!deletedArea) {
        projectCrudFlow.categoryCount.push(0);
        projectCrudFlow.inclusiveWorkAreas.push(deletedArea);
      }
    }
  };

  private generateAreaAndCategories = () => {
    const { projectCrudFlow } = this.props;

    if (projectCrudFlow.inclusiveWorkAreas.length > 0) {
      return;
    }

    const generateCategoryObjects = (workAreaCategories: string[]) => {
      const includedCategories: Array<WorkCategoryParams> = [];
      const excludedCategories: Array<WorkCategoryParams> = [];
      categoryDefinitions.forEach((categoryDef: CategoryDefinition, index: number) => {
        if (workAreaCategories.includes(categoryDef.category)) {
          includedCategories.push({
            category: categoryDef.category,
            isCustom: false,
            isSelected: false,
            order: index,
            wasExcluded: false,
          });
        } else {
          excludedCategories.push({
            category: categoryDef.category,
            isCustom: false,
            isSelected: false,
            order: index,
            wasExcluded: true,
          });
        }
      });
      return [excludedCategories, includedCategories];
    }

    const generateWorkAreaObjects = () => {
      const includedWorkAreas: Array<WorkAreaParams> = [];
      const excludedWorkAreas: Array<WorkAreaParams> = [];
      workAreaDefinitions.forEach((workArea: WorkAreaDefinition, index: number) => {
        const categoryObjects = generateCategoryObjects(workArea.initialCategories);
        // if (workAreaTemplate.has(workArea.areaName)) {
        if (
          projectCrudFlow.situatedAt === workArea.location.toLowerCase() ||
          projectCrudFlow.situatedAt === "both" ||
          workArea.location === "Both"
        ) {
          includedWorkAreas.push({
            areaName: workArea.areaName,
            duplicate: workArea.duplicate,
            exclusiveCategories: categoryObjects[0],
            inclusiveCategories: categoryObjects[1],
            isChecked: false,
            location: workArea.location,
            numDuplicated: [0],
            order: index,
          });
        } else {
          excludedWorkAreas.push({
            areaName: workArea.areaName,
            duplicate: workArea.duplicate,
            exclusiveCategories: categoryObjects[0],
            inclusiveCategories: categoryObjects[1],
            isChecked: false,
            location: workArea.location,
            numDuplicated: [0],
            order: index,
          });
        }
      });
      return [excludedWorkAreas, includedWorkAreas];
    }

    const crudWorkAreas = generateWorkAreaObjects()
    projectCrudFlow.exclusiveWorkAreas = crudWorkAreas[0];
    projectCrudFlow.inclusiveWorkAreas = crudWorkAreas[1];

    projectCrudFlow.categoryCount = new Array(crudWorkAreas[1].length).fill(0);
  };

  private duplicate = (areaIndex: number, currentWorkArea: WorkAreaParams) => {
    const { projectCrudFlow } = this.props;
    const numDuplicated = currentWorkArea.numDuplicated;
    numDuplicated[0] += 1;
    const duplicatedCategories: Array<WorkCategoryParams> = JSON.parse(JSON.stringify(currentWorkArea.inclusiveCategories));
    const duplicatedWorkArea: WorkAreaParams = JSON.parse(JSON.stringify(currentWorkArea))

    let previousName = projectCrudFlow.inclusiveWorkAreas[areaIndex].areaName;
    if (numDuplicated[0] === 1) {
      previousName += " I";
      projectCrudFlow.inclusiveWorkAreas[areaIndex].areaName = previousName;
    }
    const regnalIndex = previousName.lastIndexOf(" ");
    const originalName = previousName.slice(0, regnalIndex);

    duplicatedWorkArea.areaName = originalName + " " + (romanize(numDuplicated[0] + 1));
    duplicatedWorkArea.inclusiveCategories = duplicatedCategories;
    duplicatedWorkArea.numDuplicated = numDuplicated;

    projectCrudFlow.numSelectedWorkAreas += duplicatedWorkArea.isChecked ? 1 : 0;
    projectCrudFlow.inclusiveWorkAreas.splice(areaIndex + 1, 0, duplicatedWorkArea);
    projectCrudFlow.categoryCount.splice(areaIndex, 0, projectCrudFlow.categoryCount[areaIndex]);
  };

  readonly updateSelecteWorkAreaNum = (isChecked: boolean) => {
    const { projectCrudFlow } = this.props;
    if (isChecked) {
      projectCrudFlow.numSelectedWorkAreas += 1;
    } else {
      projectCrudFlow.numSelectedWorkAreas -= 1;
    }
  };

  readonly workAreasArray = () => {
    return this.props.projectCrudFlow.inclusiveWorkAreas.filter(incArea => incArea.isChecked);
  };

  readonly validateData = () => {
    const { notification } = this.props;
    const chosenWorkAreasObjectArray = this.workAreasArray()
    if (chosenWorkAreasObjectArray.length === 0) {
      notification.setNotification("error","Work area required")
    } else {
      this.updateProject();
    }
  };

  updateProject = async () => {
    const { projectCrudFlow, projectModel } = this.props;
    const categoryWorkAreaMap: Map<string, Array<string>> = new Map();
    projectCrudFlow.inclusiveWorkAreas.forEach(incArea => {
      if (incArea.isChecked) {
        incArea.inclusiveCategories.forEach(incCat => {
          if (incCat.isSelected) {
            const workAreaArr = categoryWorkAreaMap.get(incCat.category) || [];
            workAreaArr.push(incArea.areaName);
            categoryWorkAreaMap.set(incCat.category, workAreaArr);
          }
        })
      }
    })
    const workCategoryAreas: Array<WorkCategoryAreas> = []
    categoryWorkAreaMap.forEach((areas, category) => {
      workCategoryAreas.push({
        workCategory: category,
        workAreas: areas,
      });
    })
    try {
      await projectModel.updateWorkCategories({
        projectId: projectCrudFlow.projectId,
        workCategoryAreas: workCategoryAreas,
      });
      projectCrudFlow.incrementCurrentStep();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { projectCrudFlow } = this.props;
    const isButtonDisabled = projectCrudFlow.numSelectedWorkAreas === 0;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <StyledText
            variant="heading"
            isBold={true}
            style={styles.sectionTitle}
          >
            What work needs to be done and where?
          </StyledText>
        </View>

        {this.workAreaOptions}

        <View style={styles.buttonContainer}>
          <StyledButton
            disabled={isButtonDisabled}
            variant="primary"
            text="Save and Continue"
            onPress={this.validateData}
          />
          <StyledButton
            text="Previous"
            variant="textOnly"
            iconLeft={{ name: "chevron-left", type:"secondary" }}
            onPress={() => projectCrudFlow.decrementCurrentStep()}
            style={styles.previousButtonContainer}
            textStyle={styles.previousButton}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  workAreaContainer: {
    flexDirection: "column",
    width: 732,
    gap: 8,
  },
  sectionTitle: {
    marginBottom: 4,
    fontWeight: "600",
  },
  section: {
    marginBottom: 32,
  },
  buttonContainer: {
    marginTop: 108,
    width: 200,
    zIndex: -2,
  },
  previousButtonContainer: {
    marginTop: 32,
    marginRight: "auto",
  },
  previousButton: {
    fontSize: 13,
    color: Palette.Primary100Pct,
  },
  projectOptions: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  addAreaButton: {
    width: 150,
    height: 32,
  },
});
