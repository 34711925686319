export const abbreviatedStates = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

// Note: If admin will have access to CRUD, then move list to database - EY
export const contractorSpecialties = [
  "General Contractor",
  "Excavation",
  "Concrete",
  "Framing",
  "Steel",
  "Window and Door",
  "Electrical",
  "Plumbing",
  "HVAC",
  "Fire Alarm and Sprinkler",
  "Roofing",
  "Insulation",
  "Drywall",
  "Taping",
  "Plaster",
  "Flooring",
  "Finish Carpentry",
  "Painting",
  "Masonry",
  "Landscaping",
];

// Note: If admin will have access to CRUD, then move list to database - EY
export const milestones = [
  "Project Drawings",
  "Permits",
  "Pre Orders",
  "Pre Construction Utilites assessment/movement",
  "Tree Work",
  "Site Work",
  "Mobilization",
  "Hazardous Remediation - Asbestos",
  "Hazardous Remediation - Lead Base Paint",
  "Demo",
  "Pest Control",
  "Seawall",
  "House leveling",
  "Underpinning",
  "Foundation Repairs",
  "Concrete Foundation",
  "Under Floor Plumbing Rough-in",
  "Under Floor Electric Rough-in",
  "Under Floor Gas Rough-in",
  "Under Floor Mechanical Rough-in",
  "Concrete Slab",
  "Masonry Walls",
  "Metal Work",
  "Framing",
  "Framing Floor",
  "Framing Walls",
  "Framing Second Story Floor",
  "Framing Second Story Walls",
  "Frame Roof",
  "Roof Dry-in",
  "Windows",
  "Exterior Doors",
  "Rough in Plumbing",
  "Rough in Mechanical",
  "Rough in Gas",
  "Rough in Electric",
  "Rough in Fire/Sprinklers",
  "Rough-in Home Technology ",
  "Rough-In Fireplace ",
  "Elevator",
  "Pest Control Pipe System",
  "Rough in Solar ",
  "Roof Finalize",
  "Pool",
  "Finishes Pre Order",
  "Pre Drywall Insulation",
  "Hang Drywall",
  "Finish Drywall",
  "Interior First Coat of Paint",
  "Siding",
  "Mechanical Trim Out",
  "Tile",
  "Flooring",
  "Soffits",
  "Driveways/ Sidewalks",
  "Exterior Details",
  "Garage Door",
  "Cabinetry ",
  "Interior Doors",
  "Trim",
  "Closet System",
  "Handrails",
  "Stairs",
  "Post Drywall Insulation",
  "Exterior Paint",
  "Countertops",
  "Final Tile",
  "Glass Enclosures",
  "Fence",
  "Lawn Sprinklers",
  "Decks",
  "Docks",
  "Pavers",
  "Landscaping",
  "Appliances",
  "Finalize Fireplace",
  "Plumbing Trim out",
  "Gas Trim out",
  "Electric Trim out",
  "Fire/Sprinklers Trim out",
  "Home Technology Trim out",
  "Hardware",
  "Finishes Installs",
  "Rodent Protection",
  "Gutters",
  "Blinds/ Shutters",
  "Shelves",
  "Final Interior Paint",
  "Final Cleaning",
  "Other",
  "Solar Finalize Install",
"Job Administration",
];