import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
       width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}

        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
      />
    </svg>
  );
}

export default memo(Icon);

