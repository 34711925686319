import { toJS } from "mobx";
import { StyleSheet, View } from "react-native";
import { Budget, BudgetMilestone } from "../../../../data-model";
import { formatCurrencyToString } from "../../utils/Numbers";
import { CommentBubble } from "../CommentBubble";
import { InjectedContractMilestones } from "../ContractMilestones";
import { StyledText } from "../controls";

interface Props {
  activeCommentId?: string;
  budget: Budget;
  currentUserId: string;
  determineCommentsUnread: (creatorId: string, tag: string) => boolean;
  isActive: boolean;
  handleCommentBubblePress: (milestone?: BudgetMilestone) => void;
}
export function ContractPhases(props: Props) {
  const renderMilestoneRows = (indices: any[]) => {
    const milestoneRows: any[] = [];
    const indicesArray: number[] = indices;
    if (props.budget.milestones && props.budget.milestones.length > 0) {
      for (let i = 0; i < indicesArray.length; i++) {
        let milestoneIndex = props.budget.milestones.findIndex(
          (item) => item.orderIndex === indicesArray[i]
        );
        milestoneRows.push(
          <View style={styles.milestoneRow} key={i}>
            <View style={styles.milestone}>
              <InjectedContractMilestones
                milestone={props.budget.milestones[milestoneIndex]}
                isActive={props.isActive}
                key={i}
              />
            </View>
            <CommentBubble
              isAlert={props.determineCommentsUnread(
                props.currentUserId,
                props.budget.milestones?.[milestoneIndex].name
              )}
              isFilled={
                props.activeCommentId ===
                props.budget.milestones?.[milestoneIndex].name
              }
              onPress={() =>
                props.handleCommentBubblePress(
                  props.budget.milestones?.[milestoneIndex]
                )
              }
              style={styles.commentBubble}
            />
          </View>
        );
      }
    }

    return milestoneRows;
  };

  return (
    <>
      <View style={styles.row}>
        <StyledText variant="body2" isBold={true} style={styles.phaseCol}>
          PHASE
        </StyledText>
        <StyledText variant="body2" isBold={true} style={styles.timeCol}>
          TIME
        </StyledText>
        <StyledText variant="body2" isBold={true} style={styles.budgetCol}>
          BUDGET
        </StyledText>
      </View>
      {props.budget.phases?.map((phase, index) => (
        <View style={styles.phaseRowContainer} key={index}>
          <View style={styles.phaseRow}>
            <StyledText style={[styles.phaseText, styles.phaseCol]}>
              {phase.name}
            </StyledText>
            <StyledText style={[styles.phaseText, styles.timeCol]}>
              {phase.numDays !== null ? `${phase.numDays} Days` : `? Days`}
            </StyledText>
            <StyledText style={[styles.phaseText, styles.budgetCol]}>
              {formatCurrencyToString(phase.cost, "$0.00")}
            </StyledText>
          </View>
          {renderMilestoneRows(toJS(phase.milestoneIndices))}
        </View>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  phaseRowContainer: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  phaseRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 15,
    padding: 7,
  },
  milestoneRow: {
    display: "flex",
    flexDirection: "row",
  },
  milestone: {
    flex: 1,
  },
  commentBubble: {
    flex: 0,
    left: 60,
    marginTop: 15,
  },
  phaseText: {
    fontWeight: "bold",
  },
  phaseCol: {
    flex: 3,
  },
  timeCol: {
    flex: 1,
  },
  budgetCol: {
    marginLeft: 20,
    textAlign: "right",
  },
});
