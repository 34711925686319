import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { v4 as uuidv4 } from "uuid";
import { BudgetMilestone, BudgetModel } from "../../../data-model";
import { withInjectedFactory } from "../InjectorContext";
import { formatCurrencyToString } from "../utils/Numbers";
import { uploadFromBlobAsync } from "../utils/Storage";
import { UserViewModel } from "../viewModels/UserViewModel";
import { StyledText } from "./controls";
import { FileDropzone, FirebaseFile } from "./controls/FileDropzone";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { Palette } from "./styles";

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
  userViewModel: UserViewModel;
}

interface CreateProps {
  milestone: BudgetMilestone;
  isActive: boolean;
}

export class ContractMilestonesFactory {
  static inject: Inject<ContractMilestonesFactory> = (injector) => {
    return () =>
      new ContractMilestonesFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ContractMilestones {...this.props} {...props} />;
  }
}

@observer
class ContractMilestones extends React.Component<CreateProps & Props> {
  @observable private isActive: boolean = true;

  @computed get milestoneTotalCost() {
    if (this.props?.milestone?.tasks && this.props.milestone.tasks.length > 0) {
      return this.props.milestone.tasks.reduce(
        (previousValue, currentValue) =>
          Number(previousValue) + Number(currentValue.budget),
        0
      );
    } else {
      return 0;
    }
  }

  @computed get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  }

  @computed get isSignedByOwner(): boolean {
    return !!this.props.projectDetailsFlow.budget?.contract?.ownerSigned;
  }

  constructor(props: CreateProps & Props) {
    super(props);
    makeObservable(this);
  }

  readonly handleMilestoneFileDrop = async (
    files: File[],
    onUploadFinishedCallback: () => void
  ) => {
    const { budgetModel, milestone } = this.props;
    const newFiles: any[] = [];

    const uploadFile = async (file: File) => {
      try {
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `/budget-milestone-files/${file.name}_${Date.now()}`,
        });

        const newFile = {
          id: uuidv4(),
          fileName: file.name,
          type: "file",
          url: url,
        };

        await budgetModel.addMilestoneFile(milestone.id!, newFile);
        newFiles.push(newFile);
      } catch (e) {
        console.log(e);
      }
    };

    await Promise.all(files.map((file) => uploadFile(file)));

    if (!milestone.files)
      milestone.files = [...newFiles];
    else milestone.files.push(...newFiles);
    onUploadFinishedCallback();
  };

  readonly handleRemoveMilestoneFile = (file: FirebaseFile) => {
    const { budgetModel, milestone } = this.props;

    const newFiles = milestone.files.filter(
      (f) => f.id !== file.id
    )
    milestone.files = newFiles;
    budgetModel.deleteMilestoneFile(milestone.id!, file.id);
  };

  readonly renderDropdown = () => {
    return (
      <View style={styles.dropDown}>
        <View style={styles.dropDownIC}>
          <StyledText>
            {this.props.milestone?.scopeOfWork &&
              this.props.milestone.scopeOfWork}
          </StyledText>
          {this.props.milestone?.tasks.length > 0 && (
            <>
              <StyledText style={styles.tasksHeader}>Tasks</StyledText>
              {this.props.milestone?.tasks && this.renderTasks()}
            </>
          )}
          {
            this.props.projectDetailsFlow.arePermitsRequired === "Yes" && (
              <>
                <StyledText style={styles.tasksHeader}>Upload permits</StyledText>
                <FileDropzone 
                  style={{minHeight: 60}}
                  files={this.props.milestone.files}
                  onRemoveFile={this.handleRemoveMilestoneFile}
                  onDrop={this.handleMilestoneFileDrop}
                  editable={
                    this.userType === "contractor" && !this.isSignedByOwner
                  }
                />
              </>
            )
          }
        </View>
      </View>
    );
  };

  readonly renderTasks = () => {
    return this.props.milestone.tasks.map((task, index) => (
      <View style={styles.tasks} key={index}>
        <StyledText style={styles.taskBullet}>• {task.description}</StyledText>
      </View>
    ));
  };

  render() {
    return (
      <>
        <TouchableOpacity
          style={[styles.milestoneRow, this.isActive && styles.dropDown]}
          onPress={() => (this.isActive = !this.isActive)}
        >
          <View style={styles.rowContainer}>
            <StyledText style={styles.bullet}>•</StyledText>
            <StyledText style={styles.name}>
              {this.props.milestone?.name}
            </StyledText>
            <StyledText style={styles.budget}>
              {formatCurrencyToString(this.milestoneTotalCost)}
            </StyledText>
          </View>
        </TouchableOpacity>
        {this.isActive && this.renderDropdown()}
      </>
    );
  }
}

const styles = StyleSheet.create({
  milestoneRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  dropDown: {
    backgroundColor: "#FFFFFF",
  },
  dropDownIC: {
    borderColor: Palette.Primary25Pct,
    borderLeftWidth: 1,
    marginLeft: 15,
    padding: 10,
    bottom: 5,
  },
  rowContainer: {
    borderWidth: 2,
    borderRadius: 5,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    padding: 5,
    width: "100%",
    flexDirection: "row",
    marginTop: 3,
    marginBottom: 3,
  },
  tasks: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    marginTop: 3,
  },
  bullet: {
    alignSelf: "flex-start",
    marginLeft: 5,
    marginRight: 7.5,
    color: "#0D78CB",
  },
  name: {
    alignSelf: "flex-start",
    flex: 1,
    fontWeight: "500",
  },
  budget: {
    alignSelf: "flex-end",
    flex: 1,
    textAlign: "right",
  },
  tasksHeader: {
    marginTop: 20,
    marginBottom: 5,
  },
  taskBullet: {
    fontSize: 13,
    marginLeft: 8,
    marginRight: 8,
  },
});

export const InjectedContractMilestones = withInjectedFactory(
  ContractMilestonesFactory
);
