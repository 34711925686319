import { Authenticator } from "../exco-lib/exco-auth";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  BudgetMilestone,
  BudgetModel,
  Contractor,
  ContractorModel,
  Project,
  ProjectModel,
  SignatureModel,
  OwnerModel,
  UpdateBudgetContractParams,
  Budget,
  BudgetContract,
} from "../../../data-model";
import CheckBox from "./CheckBox";
import { Clause } from "./Clause";
import { Card, StyledButton, StyledText, StyledTextArea } from "./controls";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";
import { SummaryLayout } from "./SummaryLayout";
import { Palette } from "./styles";
import { uploadFromBlobAsync } from "../utils/Storage";
import { Notification } from "../NotificationInjectable";
import { v4 as uuidv4 } from "uuid";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import HelloSign from "hellosign-embedded";
import { capitalizeFirstLetter, structureAddress } from "../utils/Strings";
import { withInjectedFactory } from "../InjectorContext";
import { ContractProjectDetail } from "./Budget/ContractProjectDetail";
import { ContractPaymentSchedule } from "./Budget/ContractPaymentSchedule";
import { UserViewModel } from "../viewModels/UserViewModel";
import { SummaryBlock } from "./Budget/SummaryBlock";
import { ContractPhases } from "./Budget/ContractPhases";
import { LoadingIndicator } from "./LoadingIndicator";
import { FileDropzone, FirebaseFile } from "./controls/FileDropzone";

interface Props {
  authenticator: Authenticator;
  budgetModel: BudgetModel;
  contractorModel: ContractorModel;
  ownerModel: OwnerModel;
  history: History;
  projectDetailsFlow: ProjectDetailsFlow;
  projectModel: ProjectModel;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
  signatureModel: SignatureModel;
  notification: Notification;
}

interface CreateProps {
  changeBudgetView: () => void;
  project: Project;
}

export class ProjectBudgetSummaryViewFactory {
  static inject: Inject<ProjectBudgetSummaryViewFactory> = (injector) => {
    return () =>
      new ProjectBudgetSummaryViewFactory({
        authenticator: injector.get(Authenticator)(),
        budgetModel: injector.get(BudgetModel)(),
        contractorModel: injector.get(ContractorModel)(),
        ownerModel: injector.get(OwnerModel)(),
        history: injector.get(HistoryInjectable)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectModel: injector.get(ProjectModel)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
        signatureModel: injector.get(SignatureModel)(),
        notification: injector.get(Notification)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBudgetSummaryView {...this.props} {...props} />;
  }
}

@observer
class ProjectBudgetSummaryView extends React.Component<CreateProps & Props> {
  @observable private _initialized: boolean = false;
  @observable private contractor?: Contractor;
  @observable private owner?: any;
  @observable private isConfirmationChecked: boolean = false;
  @observable private error: string = "";
  @observable private disclosureInput: string = "";

  @computed get userType(): "contractor" | "owner" {
    return this.props.userViewModel.isContractor ? "contractor" : "owner";
  }

  @computed get budget(): Budget | undefined {
    return this.props.projectDetailsFlow.budget;
  }

  @computed get contract(): BudgetContract | undefined {
    return this.props.projectDetailsFlow.budget?.contract;
  }

  @computed get isBudgetSubmitted(): boolean {
    return !!this.props.projectDetailsFlow.budget?.submitted;
  }

  @computed get isSignedByContractor(): boolean {
    return !!this.props.projectDetailsFlow.budget?.contract?.contractorSigned;
  }

  @computed get isSignedByOwner(): boolean {
    return !!this.props.projectDetailsFlow.budget?.contract?.ownerSigned;
  }

  @computed get disclosureFiles(): any[] {
    return this.props.projectDetailsFlow.budget?.files || [];
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount = async () => {
    const {
      budgetModel,
      contractorModel,
      ownerModel,
      project,
      projectDetailsFlow,
      projectWidgetManager,
    } = this.props;
    try {
      this.contractor = await contractorModel.getContractorByProjectId(
        project.id
      );
      this.owner = await ownerModel.getOwnerByProjectId(project.id);

      projectDetailsFlow.budget = await budgetModel.getBudgetByProjectId(
        project.id
      );

      await projectWidgetManager.loadComments(project.id, "budget", projectDetailsFlow.budget.id);
      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  handleDisclosureFileDrop = async (
    files: File[],
    onUploadFinishedCallback: () => void
  ) => {
    const { budgetModel, projectDetailsFlow } = this.props;
    if (!projectDetailsFlow.budget) return;

    const newFiles: any[] = [];
    const budgetId = projectDetailsFlow.budget.id;

    const uploadFile = async (file: File) => {
      try {
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `/budget-files/${file.name}_${Date.now()}`,
        });

        const newFile = {
          id: uuidv4(),
          fileName: file.name,
          type: "file",
          url: url,
        };

        await budgetModel.addFile(budgetId, newFile);
        newFiles.push(newFile);
      } catch (e) {
        console.log(e);
      }
    };

    await Promise.all(files.map((file) => uploadFile(file)));

    if (!projectDetailsFlow.budget.files)
      projectDetailsFlow.budget.files = [...newFiles];
    else projectDetailsFlow.budget.files.push(...newFiles);
    onUploadFinishedCallback();
  };

  handleRemoveDisclosureFile = (file: FirebaseFile) => {
    const { budgetModel, projectDetailsFlow } = this.props;
    const newFiles = projectDetailsFlow.budget!.files.filter(
      (f) => f.id !== file.id
    );
    projectDetailsFlow.budget!.files = newFiles;
    budgetModel.deleteFile(projectDetailsFlow.budget!.id, file.id);
  };

  handleSubmitBudget = async () => {
    const { budgetModel, projectDetailsFlow, notification } = this.props;
    this.handleUpdateDisclosure();
    await budgetModel.submitBudget(projectDetailsFlow.budget!.id);
    projectDetailsFlow.budget!.submitted = true;
    notification.setNotification("success", "Budget Submitted");
  };

  handleUpdateDisclosure = () => {
    const { budgetModel, projectDetailsFlow } = this.props;
    budgetModel.updateDisclosure({
      budgetId: projectDetailsFlow.budget!.id,
      disclosure: this.disclosureInput,
    });
    projectDetailsFlow.budget!.disclosure = this.disclosureInput;
  };

  handleCommentBubblePress = (
    milestone?: BudgetMilestone,
    schedule?: boolean
  ) => {
    const { projectWidgetManager } = this.props;
    const parentId = this.budget!.id;
    let tagName = undefined;

    if (milestone) {
      tagName = milestone.name;
    } else if (schedule) {
      tagName = "Payment Schedule";
    }

    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(parentId, tagName);
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  handleSignDocument = async () => {
    const {
      signatureModel,
      projectDetailsFlow,
      budgetModel,
      notification,
      project,
    } = this.props;
    if (!this.budget) return;

    const generateWorkString = () => {
      let workString = "";
      projectDetailsFlow.budget?.milestones?.forEach(
        (item) => (workString += `${capitalizeFirstLetter(item.name)}, `)
      );
      return workString;
    };
    const client = new HelloSign();
    const clientOptions = {
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      skipDomainVerification: true,
    };

    const generateDocumentData = {
      address: structureAddress(project.address),
      work: generateWorkString(),
      contractor: this.contractor,
      owner: this.owner,
      budgetId: this.budget.id,
      projectId: project.id,
    };
    let ownerSignatureId: string;
    let ownerSignatureUrl: string;
    let contractorSignatureId: string;
    let contractorSignatureUrl: string;

    if (!this.contract) {
      const signatureInfo = await signatureModel.generateDocument(
        generateDocumentData
      );

      ownerSignatureId = signatureInfo.signatureIds.owner;
      ownerSignatureUrl = signatureInfo.ownerUrl;
      contractorSignatureId = signatureInfo.signatureIds.contractor;
      contractorSignatureUrl = signatureInfo.contractorUrl;

      const contract: UpdateBudgetContractParams = {
        budgetId: this.budget.id,
        signatureUrlExpiresAt: signatureInfo.signatureUrlExpiresAt,
        signatureRequestId: signatureInfo.signatureRequestId,
        projectId: project.id,
        ownerSignatureId,
        ownerSignatureUrl,
        contractorSignatureId,
        contractorSignatureUrl,
      };

      await budgetModel.updateBudgetContract(contract);
    } else {
      ownerSignatureId = this.contract.ownerSignatureId;
      ownerSignatureUrl = this.contract.ownerSignatureUrl;
      contractorSignatureId = this.contract.contractorSignatureId;
      contractorSignatureUrl = this.contract.contractorSignatureUrl;
    }

    if (this.userType === "contractor") {
      client.open(contractorSignatureUrl, clientOptions);
    } else {
      client.open(ownerSignatureUrl, clientOptions);
    }

    const regenerateDocumentUrls = async () => {
      const budgetContractUpdateObject: any = {
        budgetId: projectDetailsFlow.budget!.id,
      };
      let regenerateDocumentData: any = {};
      if (!this.isSignedByOwner && !this.isSignedByContractor) {
        regenerateDocumentData = await signatureModel.generateNewSignUrls({
          ownerSignatureId,
          contractorSignatureId,
        });
        budgetContractUpdateObject.ownerUrl =
          regenerateDocumentData.ownerUrl.embedded.sign_url;
        budgetContractUpdateObject.contractorUrl =
          regenerateDocumentData.contractorUrl.embedded.sign_url;
      } else {
        if (this.userType === "contractor") {
          regenerateDocumentData = await signatureModel.generateNewSignUrls({
            contractorSignatureId,
          });
          budgetContractUpdateObject.contractorUrl =
            regenerateDocumentData.contractorUrl.embedded.sign_url;
        } else {
          regenerateDocumentData = await signatureModel.generateNewSignUrls({
            ownerSignatureId,
          });
          budgetContractUpdateObject.ownerUrl =
            regenerateDocumentData.ownerUrl.embedded.sign_url;
        }
      }
      await budgetModel.updateBudgetContractUrls(budgetContractUpdateObject);
      projectDetailsFlow.budget = await budgetModel.getBudgetByProjectId(
        project.id
      );
    };

    client.on("error", (data) => {
      notification.setNotification(
        "error",
        "There was an error with the document signature!"
      );
      console.log(data);
      regenerateDocumentUrls();
    });

    client.on("cancel", () => {
      notification.setNotification("error", "The document has been cancelled!");
      regenerateDocumentUrls();
    });

    client.on("sign", async () => {
      budgetModel.updateBudgetSignature({
        budgetId: projectDetailsFlow.budget!.id,
        userType: this.userType,
      });
      projectDetailsFlow.budget = await budgetModel.getBudgetById(
        this.budget!.id
      );
      notification.setNotification("success", "The document has been signed!");
    });
  };

  renderSubmitButton = () => {
    let action = () => {};
    let text = "";
    let disabled = false;
    let showCheckBox = false;

    // Contractor
    if (this.userType === "contractor") {
      if (this.isSignedByContractor && this.isSignedByOwner) {
        text = "Contract signed by both parties";
        disabled = true;
      } else if (this.isSignedByOwner) {
        text = "Sign Contract";
        showCheckBox = true;
        action = this.handleSignDocument;
      } else if (this.isBudgetSubmitted) {
        text = "Budget Submitted, awaiting owner signature";
        disabled = true;
      } else {
        text = "Submit Budget";
        action = this.handleSubmitBudget;
      }
    }
    // Owner
    else {
      if (this.isSignedByContractor && this.isSignedByOwner) {
        text = "Contract signed by both parties";
        disabled = true;
      } else if (this.isSignedByOwner) {
        text = "Contract signed, awaiting contractor signature";
        disabled = true;
      } else {
        text = "Sign Contract";
        showCheckBox = true;
        action = this.handleSignDocument;
      }
    }

    return (
      <View style={styles.footerContainer}>
        <View style={styles.footer}>
          {showCheckBox && (
            <CheckBox
              checked={this.isConfirmationChecked}
              value="I have read and understood the contract and confirm it is ready for sending."
              onChange={() =>
                (this.isConfirmationChecked = !this.isConfirmationChecked)
              }
            />
          )}
          <StyledButton
            text={text}
            disabled={disabled || (showCheckBox && !this.isConfirmationChecked)}
            onPress={action}
            style={{ marginTop: 20 }}
          />
        </View>
      </View>
    );
  };

  render() {
    const {
      changeBudgetView,
      projectDetailsFlow,
      projectWidgetManager,
      project,
      userViewModel,
    } = this.props;
    if (!this._initialized) return <LoadingIndicator />;
    return (
      <>
        <SummaryLayout
          title="Review Contract"
          userType={this.userType}
          subtitle="Please take a moment to reivew your contract before sending."
          onBackPress={() => changeBudgetView()}
          backButtonHidden={this.isSignedByOwner || this.isSignedByContractor}
        >
          <SummaryBlock containerStyle={styles.row}>
            <View style={styles.sectionBlockRowItem}>
              <StyledText variant="heading3" isBold={true}>
                Contractor
              </StyledText>
              <StyledText style={styles.sectionText}>
                {this.contractor ? this.contractor!.name : "Contractor Name"}
              </StyledText>
              {/*<StyledText style={styles.sectionText}>{`\n`}</StyledText>*/}
              {/* <StyledText style={styles.sectionText}>
              Contractor Address
            </StyledText> */}
              {/* <StyledText style={styles.sectionText}>
              Contractor License Number
            </StyledText> */}
            </View>
            <View style={styles.sectionBlockRowItem}>
              <StyledText variant="heading3" isBold={true}>
                Property Owner
              </StyledText>
              <StyledText style={styles.sectionText}>
                {this.owner ? this.owner!.name : "Property Owner Name"}
              </StyledText>
              {/*<StyledText style={styles.sectionText}>
                  {this.project?.address && this.structureAddress(this.project.address)}
                </StyledText>*/}
              {/* <StyledText style={styles.sectionText}>
              Property License Number
            </StyledText> */}
            </View>
          </SummaryBlock>

          <SummaryBlock title="Project Details">
            <ContractProjectDetail project={project} />
          </SummaryBlock>

          <SummaryBlock
            title="Budget and Scope of Work"
            onEditClick={
              this.userType === "contractor" && !this.isSignedByOwner
                ? changeBudgetView
                : undefined
            }
            containerStyle={styles.zeroMargin}
          >
            <Card variant="secondary" style={styles.section}>
              {projectDetailsFlow.budget && (
                <ContractPhases
                  activeCommentId={projectWidgetManager.activeCommentTag}
                  budget={projectDetailsFlow.budget}
                  currentUserId={userViewModel.currentUser!.id}
                  determineCommentsUnread={
                    projectWidgetManager.determineTaggedCommentsUnread
                  }
                  isActive={true}
                  handleCommentBubblePress={this.handleCommentBubblePress}
                />
              )}
            </Card>
          </SummaryBlock>

          <SummaryBlock>
            {projectDetailsFlow.budget && (
              <ContractPaymentSchedule
                activeComment={
                  projectWidgetManager.activeCommentTag === "Payment Schedule"
                }
                unreadComment={this.props.projectWidgetManager.determineTaggedCommentsUnread(this.props.userViewModel.currentUser!.id, "Payment Schedule")}
                phases={projectDetailsFlow.budget.phases}
                startDate={projectDetailsFlow.budget.startDate}
                totalCost={projectDetailsFlow.phasesTotalCost}
                totalDays={projectDetailsFlow.phasesTotalDays}
                handleCommentBubblePress={() =>
                  this.handleCommentBubblePress(undefined, true)
                }
              />
            )}
          </SummaryBlock>

          {/*  Section: unknown acknowledgement */}
          {/*    <View style={styles.section}>
            <StyledText isBold={true} style={styles.contractSectionHeader}>
              Customer is aware of these Unknowns
            </StyledText>
            <StyledText style={styles.sectionText}>Lorem ipsum</StyledText>
            <StyledText style={styles.sectionText}>Lorem ipsum</StyledText>
            <StyledText style={styles.sectionText}>Lorem ipsum</StyledText>
            <StyledText style={styles.sectionText}>Lorem ipsum</StyledText>
            <StyledText style={styles.sectionText}>Lorem ipsum</StyledText>
            </View>*/}

          {/*  Section: covid clause */}
          <View style={styles.section}>
            <StyledText isBold={true} style={styles.contractSectionHeader}>
              COVID / Volatile Market Clause
            </StyledText>
            <StyledText style={styles.sectionText}>
              Due to manufacturing delays worldwide and high demand for
              construction, we can not guarantee pricing will not increase
              between time of contract signing to installation of construction
              install and materials purchases. The client is responsible to
              cover any overages of materials during the duration of this
              contract. Labor quote is only good for 30 days from the date of
              this contract.
            </StyledText>
          </View>

          {/* Section: Construction Agreement */}
          {/* <View>
            <StyledText isBold={true} style={styles.contractSectionHeader}>
              Construction Agreement
            </StyledText>
            <StyledText style={styles.sectionText}>
              Refer to Exhibit "A" for Design Selections
            </StyledText>
            <StyledText style={styles.sectionText}>
              Refer to Exhibit "B" for Project Budget and Allownaces
            </StyledText>

            <View style={{ marginTop: 12 }}>
              <ConstructionAgreementClause checked={true} text="Budget" />
              <ConstructionAgreementClause checked={false} text="Cost Plus" />
              <ConstructionAgreementClause
                percent={20}
                text={`margin on top of all materials and labor indicated in Exhibit "B". Plus any agreed upon management fees.`}
                style={{ marginLeft: 32 }}
              />
            </View>
          </View> */}

          {/* Section: Standard Terms and Conditions */}
          <View style={styles.sectionSmall}>
            <View style={styles.sectionLarge}>
              <StyledText
                variant="inputLabel"
                isBold={true}
                style={[styles.termsAndConditionText, styles.sectionSmall]}
              >
                Standard Terms and Conditions
              </StyledText>
              <StyledText style={[styles.sectionText, styles.sectionSmall]}>
                The following Standard Terms and Conditions govern and control
                the construction contract to which they are attached, which was
                entered into between CFP Home Construction, Inc. (the “Building
                Contractor”) and the property owner(s) identified therein
                (hereinafter, the “Customer”). The term “Project,” as used
                herein, shall mean the specific scope of work identified in the
                construction contract between the Building Contractor and
                Customer to which these Standard Terms and Conditions are
                attached (together that contract as well as these Standard Terms
                and Condition are collectively, the “Contract”).
              </StyledText>
              <Clause
                heading="1. Scope of work."
                body="The scope of work for the Project is specified in the Contract and any engineer sealed drawings are the absolute authority for what is to be constructed as part of the Project. All work is to be completed in accordance with the Contract and sealed engineer’s drawings/plans, and to the extent there is any conflict between the two, the engineers sealed plans/drawings shall control."
                style={styles.sectionSmall}
              />
              <Clause
                heading="2. Construction management."
                body="Building Contractor shall maintain sole discretion over the means, methods, techniques, sequences, schedules, and procedures employed for construction of the Project. Building Contractor will make every reasonable effort to negotiate for the best pricing while not affecting the quality of the Project. Building Contractor will manage all subcontractors and push for timely turnaround times as well as manage the Project schedule and quotes for work on the Project."
                style={styles.sectionSmall}
              />
              <StyledText isBold={true}>END.</StyledText>
            </View>

            {/* Section: Permits */}
            {/* <View style={styles.sectionLarge}>
            <View style={[styles.row, { marginBottom: 8 }]}>
              <StyledText variant="heading3" isBold={true}>
                Permits
              </StyledText>
              {this.userType === "contractor" && (
                <StyledTouchableOpacity
                  onPress={() => {}}
                  style={styles.editIcon}
                >
                  <Icon name="edit" mode="color" />
                </StyledTouchableOpacity>
              )}
            </View>
            <StyledText isBold={true} style={styles.sectionText}>
              Compensation Agreement: Fix Bid
            </StyledText>
          </View> */}

            {/* Section: Disclosures */}
            <SummaryBlock
              title="Disclosures"
              containerStyle={{ marginBottom: 0 }}
            >
              <>
                {(this.userType === "owner" || this.isSignedByOwner) &&
                  projectDetailsFlow.budget?.disclosure && (
                    <StyledText
                      style={[styles.sectionText, styles.disclosureText]}
                    >
                      {projectDetailsFlow.budget.disclosure}
                    </StyledText>
                  )}
                {this.userType === "contractor" && !this.isSignedByOwner && (
                  <>
                    <StyledText style={styles.sectionText}>
                      Write in disclosures here, or upload a PDF file containing
                      disclosures for this rough bid.
                    </StyledText>
                    <StyledTextArea
                      style={styles.disclosuresTextBox}
                      minHeight={100}
                      value={this.disclosureInput}
                      onChangeText={(value) => (this.disclosureInput = value)}
                    />
                    {this.disclosureInput === "" ||
                    this.disclosureInput ===
                      projectDetailsFlow.budget?.disclosure ? (
                      <View style={styles.disclosureButtonFiller}></View>
                    ) : (
                      <StyledButton
                        variant="secondary"
                        text={
                          this.disclosureInput ===
                          projectDetailsFlow.budget?.disclosure
                            ? "Change disclosure to submit"
                            : "Update disclosure"
                        }
                        onPress={this.handleUpdateDisclosure}
                        style={styles.disclosureButton}
                      />
                    )}
                  </>
                )}
                <FileDropzone
                  files={this.disclosureFiles}
                  onDrop={this.handleDisclosureFileDrop}
                  onRemoveFile={this.handleRemoveDisclosureFile}
                  editable={
                    this.userType === "contractor" && !this.isSignedByOwner
                  }
                />
              </>
            </SummaryBlock>

            {/* Section: Signature */}
            {/* <Signature title="Owner" />
              <Signature title="Owner" />
              <Signature title="Manager of CFP Home Construction" />*/}
          </View>
        </SummaryLayout>

        {this.renderSubmitButton()}
      </>
    );
  }
}

// interface SignatureProps {
//   title: string;
// }

// export const Signature: React.FunctionComponent<SignatureProps> = (props) => {
//   const { title } = props;
//   return (
//     <View style={styles.signatureContainer}>
//       <StyledText>{title}</StyledText>
//       <StyledText>Date</StyledText>
//     </View>
//   );
// };

// interface ConstructionAgreementClauseProps extends ViewProps {
//   text: string;
//   percent?: number;
//   checked?: boolean;
//   style?: StyleProp<ViewStyle>;
// }

// export const ConstructionAgreementClause: React.FunctionComponent<ConstructionAgreementClauseProps> =
//   (props) => {
//     const { checked, percent, style, text } = props;
//     const percentageStyle = percent
//       ? styles.constructionAgreementPercentContainer
//       : null;
//     return (
//       <View
//         style={[
//           styles.row,
//           styles.constructionAgreementContainer,
//           percentageStyle,
//           style,
//         ]}
//       >
//         <View style={styles.constructionAgreementInput}>
//           {checked ? <Icon name="x" mode="dark" size={14} /> : null}
//           {percent ? <StyledText>{percent}</StyledText> : null}
//         </View>
//         <StyledText>
//           {percent ? "% " : ""}
//           {text}
//         </StyledText>
//       </View>
//       <StyledText>
//         {percent ? "% " : ""}
//         {text}
//       </StyledText>
//     </View>
//   );
// };

const styles = StyleSheet.create({
  row: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    marginBottom: 32,
  },
  sectionLarge: {
    marginBottom: 48,
  },
  sectionSmall: {
    marginBottom: 24,
  },

  sectionText: {
    lineHeight: 20,
  },
  sectionBlockRowItem: {
    flex: 1,
  },
  contractSectionHeader: {
    lineHeight: 24,
    marginBottom: 4,
  },
  termsAndConditionText: {
    marginHorizontal: "auto",
  },
  zeroMargin: {
    marginBottom: 0,
  },
  footerContainer: {
    width: "100%",
    paddingLeft: 80,
    alignItems: "center",
  },
  footer: {
    flex: 1,
    marginTop: 20,
  },
  signatureContainer: {
    paddingTop: 2,
    marginTop: 50,
    borderTopWidth: 1,
    paddingHorizontal: 70,
    flexDirection: "row",
    justifyContent: "space-between",
    borderColor: Palette.Primary100Pct,
  },
  constructionAgreementContainer: {
    marginBottom: 4,
  },
  constructionAgreementInput: {
    width: 32,
    height: 20,
    marginRight: 4,
    marginBottom: 4,
    borderBottomWidth: 1,
    alignItems: "center",
    borderStyle: "dashed",
    borderColor: Palette.Primary100Pct,
  },
  constructionAgreementPercentContainer: {
    flexWrap: "nowrap",
    alignItems: "flex-start",
  },
  disclosuresTextBox: {
    marginTop: 10,
  },
  disclosureText: {
    marginTop: 10,
  },
  disclosureButton: {
    marginVertical: 10,
    maxWidth: 180,
  },
  disclosureButtonFiller: {
    marginVertical: 10,
    maxWidth: 180,
    height: 34,
  },
});

export const InjectedProjectBudgetSummaryView = withInjectedFactory(
  ProjectBudgetSummaryViewFactory
);
