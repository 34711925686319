import { FunctionComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Icon, StyledText, StyledTouchableOpacity } from "../controls";
import { Palette } from "../styles";

interface Props {
  title: string;
  style?: StyleProp<ViewStyle>;
  onInfoClick?: () => void;
  onViewAllClick?: () => void;
}

export const PaymentPhaseTabHeader: FunctionComponent<Props> = ({
  title,
  style,
  onInfoClick,
  onViewAllClick,
}) => {
  return (
    <View style={[styles.container, style]}>
      <StyledText variant="body" isUpperCase isBold style={styles.text}>
        {title}
      </StyledText>
      {onInfoClick && (
        <Icon
          name="info"
          size={20}
          style={styles.rightElement}
          onClick={onInfoClick}
        />
      )}
      {onViewAllClick && (
        <StyledTouchableOpacity style={styles.rightElement} onPress={onViewAllClick}>
          <StyledText variant="body" style={styles.viewAllText} isBold>
            View All
          </StyledText>
          <Icon
            name="chevron-right"
            size={20}
            type={"accent"}
          />
        </StyledTouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  text: {
    fontSize: 15,
  },
  rightElement: {
    position: "absolute",
    right: 0,
    flexDirection: "row",
  },
  viewAllText: {
    fontSize: 12,
    color: Palette.Accent,
  },
});
