import React from "react";
import { StyleSheet, View } from "react-native";
import { ToastFactory } from "../components/Toast";
import { InjectedFactoryComponent } from "../InjectorContext";

export class FullLayout extends React.Component {
  render() {
    return (
      <View style={localStyle.container}>
        {<InjectedFactoryComponent factory={ToastFactory} props={{}} />}
        {this.props.children}
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    minHeight: "100vh",
  },
});
