import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable, toJS } from "mobx";
import { Observer, observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Redirect, Route, RouteProps, Router, Switch } from "react-router-dom";
import {
  BidModel,
  BudgetModel,
  Project,
  ProjectModel,
  UserModel,
} from "../../../data-model";
import {
  Page,
  Screen,
  SideBar,
  StyledText,
  StyledTouchableOpacity,
} from "../components/controls";
import { InjectedHeader } from "../components/Header";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { InjectedProjectBidEditView } from "../components/ProjectBidEditView";
import { InjectedProjectBidSummaryView } from "../components/ProjectBidSummaryView";
import { InjectedProjectBudgetEditView } from "../components/ProjectBudgetEditView";
import { InjectedProjectBudgetSummaryView } from "../components/ProjectBudgetSummaryView";
import { ProjectDetailsFlow } from "../components/ProjectDetailsFlow";
import { InjectedProjectDetailsView } from "../components/ProjectDetailsView";
import { InjectedProjectDocumentsView } from "../components/ProjectDocumentsView";
import { InjectedProjectPaymentsConfirmationView } from "../components/ProjectPaymentsConfirmationView";
import { InjectedProjectPaymentsDashboardView } from "../components/ProjectPaymentsDashboardView";
import { InjectedProjectPaymentsPaymentView } from "../components/ProjectPaymentsPaymentView";
import { InjectedProjectMilestonesView } from "../components/ProjectMilestonesView";
import { ProjectViewControl } from "../components/ProjectViewControl";
import { InjectedProjectWidgetLayout } from "../components/ProjectWidgetLayout";
import { SideBarMain } from "../components/SideBarMain";
import { Palette } from "../components/styles";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { CenteredLayout } from "../layouts/CenteredLayout";
import { getRoute, Screen as ScreenType } from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";

interface Props {
  bidModel: BidModel;
  budgetModel: BudgetModel;
  history: History;
  projectDetailsFlow: ProjectDetailsFlow;
  projectId?: string;
  projectModel: ProjectModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  projectId: string;
}

const menuItems = [
  "details",
  "milestones",
  "bid",
  "budget",
  "documents",
  "payments",
] as const;
type MenuItem = typeof menuItems[number];

interface MenuItemNav {
  title: string;
  path: ScreenType;
  isVisible: boolean | (() => boolean);
  render: RouteProps["render"];
}

export class ProjectScreenFactory {
  static inject: Inject<ProjectScreenFactory> = (injector) => {
    return () =>
      new ProjectScreenFactory({
        bidModel: injector.get(BidModel)(),
        budgetModel: injector.get(BudgetModel)(),
        history: injector.get(HistoryInjectable)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectScreen {...this.props} {...props} />;
  }
}

@observer
export class ProjectScreen extends React.Component<Props & CreateProps> {
  @observable public currentProject: Project | undefined = undefined;
  @observable public budgetView: "edit" | "summary" = "edit";
  @observable public bidView: "edit" | "summary" = "edit";

  @observable private _initialized: boolean = false;
  @computed get initialized(): boolean {
    return this.props.userViewModel.initialized && this._initialized;
  }

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  @computed get userType() {
    return this.currentUser?.userType;
  }

  @computed get isOwner() {
    return this.userType === "owner";
  }

  @computed get isContractor() {
    return this.userType === "contractor";
  }

  @computed get isBidSubmitted(): boolean {
    return !!this.props.projectDetailsFlow.bid?.submittedAt;
  }

  @computed get isBidApproved(): boolean {
    return !!this.props.projectDetailsFlow.bid?.approved;
  }

  @computed get isBudgetSubmitted(): boolean {
    return !!this.props.projectDetailsFlow.budget?.submitted;
  }

  @computed get isContractSigned(): boolean {
    return !!(
      this.props.projectDetailsFlow.budget?.contract?.contractorSigned &&
      this.props.projectDetailsFlow.budget?.contract?.ownerSigned
    );
  }

  @computed get paymentView() {
    return this.props.projectDetailsFlow.paymentView;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
    this.initialize();
  }

  readonly menuItemNav: Record<MenuItem, MenuItemNav> = {
    details: {
      path: "projectDetails",
      title: "Details",
      isVisible: true,
      render: () => (
        <InjectedProjectDetailsView
          project={this.currentProject!}
          currentUser={this.currentUser!}
        />
      ),
    },
    milestones: {
      path: "milestones",
      title: "Milestones",
      isVisible: this.isContractSigned,
      render: () => (
        <InjectedProjectMilestonesView project={this.currentProject!}/>
      ),
    },
    bid: {
      path: "projectBid",
      title: "Rough Bid",
      isVisible: () =>
        this.isContractor || (this.isOwner && this.isBidSubmitted),
      render: () => (
        <Observer>
          {() => (
            <InjectedProjectWidgetLayout currentProject={this.currentProject!}>
              <ProjectViewControl
                isEditView={this.bidView === "edit"}
                userType={this.userType}
                submitted={this.isBidSubmitted}
                editView={
                  <InjectedProjectBidEditView
                    changeBidView={() => (this.bidView = "summary")}
                    projectId={this.props.projectId}
                  />
                }
                summaryView={
                  <InjectedProjectBidSummaryView
                    changeBidView={() => (this.bidView = "edit")}
                    currentProject={toJS(this.currentProject!)}
                  />
                }
              />
            </InjectedProjectWidgetLayout>
          )}
        </Observer>
      ),
    },
    budget: {
      path: "projectBudget",
      title: "Budget",
      isVisible: () =>
        (this.isOwner && this.isBudgetSubmitted) ||
        (this.isContractor && this.isBidApproved),
      render: () => (
        <Observer>
          {() => (
            <InjectedProjectWidgetLayout currentProject={this.currentProject!}>
              <ProjectViewControl
                isEditView={this.budgetView === "edit"}
                userType={this.userType}
                submitted={
                  this.props.projectDetailsFlow.budget?.contract?.ownerSigned
                }
                editView={
                  <InjectedProjectBudgetEditView
                    changeBudgetView={() => (this.budgetView = "summary")}
                    project={this.currentProject!}
                  />
                }
                summaryView={
                  <InjectedProjectBudgetSummaryView
                    changeBudgetView={() => (this.budgetView = "edit")}
                    project={this.currentProject!}
                  />
                }
              />
            </InjectedProjectWidgetLayout>
          )}
        </Observer>
      ),
    },
    documents: {
      path: "projectDocuments",
      title: "Documents",
      isVisible: true,
      render: (props) => (
        /* TODO: replace ProjectDetailsView with document screen once it's built - EY */
        <InjectedProjectDocumentsView
          project={this.currentProject!}
          currentUser={this.currentUser}
        />
      ),
    },
    payments: {
      path: "projectPayments",
      title: "Payments",
      isVisible: this.isContractSigned,
      render: () => (
        <Observer>
          {() => {
            if (this.paymentView === "confirmation")
              return (
                <InjectedProjectPaymentsConfirmationView
                  project={this.currentProject!}
                />
              );
            else if (this.paymentView === "make-payment")
              return (
                <InjectedProjectPaymentsPaymentView
                  project={this.currentProject!}
                />
              );
            else
              return (
                <InjectedProjectPaymentsDashboardView
                  project={this.currentProject!}
                />
              );
          }}
        </Observer>
      ),
    },
  };

  readonly initialize = async () => {
    const {
      projectDetailsFlow,
      budgetModel,
      bidModel,
      projectModel,
      projectId,
    } = this.props;

    try {
      this.currentProject = await projectModel.getProjectById(projectId);
      projectDetailsFlow.bid = await bidModel.getBidByProjectId(projectId);
      projectDetailsFlow.budget = await budgetModel.getBudgetByProjectId(
        projectId
      );
    } catch (e) {
      console.error(e);
    }

    this.budgetView = projectDetailsFlow.budget?.submitted ? "summary" : "edit";
    this._initialized = true;
  };

  readonly handleMenuClick = (item: MenuItem) => {
    const { history, projectId } = this.props;
    const screen = this.menuItemNav[item].path;
    const route = getRoute(screen);
    const path = "build" in route ? route.build({ projectId }) : route.path;

    history.push(path);
  };

  readonly filterMenuItem = (item: MenuItem): boolean => {
    const isVisible = this.menuItemNav[item].isVisible;
    if (typeof isVisible === "function") {
      return isVisible();
    }
    return isVisible;
  };

  readonly renderMenuItem = (item: MenuItem) => {
    const menuItem = this.menuItemNav[item];

    return (
      <Route key={item} path={getRoute(menuItem.path).path}>
        {({ match }) => {
          const isSelected = !!match;
          return (
            <StyledTouchableOpacity
              style={[styles.menuItem, isSelected && styles.selectedMenuItem]}
              onPress={() => this.handleMenuClick(item)}
            >
              <StyledText
                isBold={true}
                colorMode={isSelected ? "highlight" : "dark"}
              >
                {menuItem.title}
              </StyledText>
            </StyledTouchableOpacity>
          );
        }}
      </Route>
    );
  };

  renderMenuItemRoute = (item: MenuItem) => {
    const menuItem = this.menuItemNav[item];
    const path = getRoute(menuItem.path).path;

    return (
      <Route exact path={path} render={menuItem.render} key={menuItem.title} />
    );
  };

  renderMain() {
    const { history, projectId } = this.props;

    if (!this.initialized) return <LoadingIndicator />;

    if (!this.currentProject) {
      return (
        <CenteredLayout>
          <StyledText variant="heading">Project not found</StyledText>
        </CenteredLayout>
      );
    }

    const filteredMenuItems = menuItems.filter(this.filterMenuItem);

    return (
      <>
        <View style={[styles.projectHeader, styles.row]}>
          <View style={styles.row}>
            <StyledText variant="heading2" style={styles.projectName}>
              {this.currentProject!.name}
            </StyledText>
            <View style={styles.row}>
              {filteredMenuItems.map(this.renderMenuItem)}
              {/*edit available menu items here*/}
            </View>
          </View>
          {/*   <View style={styles.row}>
          <UserInfo variant="pictureOnly" currentUser={this.currentUser} />
        </View>*/}
        </View>
        <Router history={history}>
          <Switch>
            {filteredMenuItems.map(this.renderMenuItemRoute)}
            <Route
              render={() => {
                let path = getRoute("projectDetails").build({
                  projectId,
                });

                if (this.filterMenuItem("milestones")) {
                  path = getRoute("milestones").build({
                    projectId,
                  })
                } else if (this.filterMenuItem("budget")) {
                  path = getRoute("projectBudget").build({
                    projectId,
                  });
                } else if (this.filterMenuItem("bid")) {
                  path = getRoute("projectBid").build({
                    projectId,
                  });
                }

                return <Redirect to={path} />;
              }}
            />
          </Switch>
        </Router>
      </>
    );
  }

  render() {
    return (
      <Screen>
        {/* Note: hiding "Invitation" from SideBar for V1. Reinstate it with footer="invitation" as props - EY */}
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedHeader />
          {this.renderMain()}
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  projectHeader: {
    borderBottomWidth: 1,
    marginHorizontal: -84,
    paddingHorizontal: 84,
    marginBottom: 64,
    paddingBottom: 8,
    justifyContent: "space-between",
    borderBottomColor: Palette.Primary10Pct,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  projectName: {
    marginRight: 96,
  },
  menuItem: {
    height: 64,
    marginBottom: -9,
    paddingBottom: 9,
    paddingHorizontal: 20,
    justifyContent: "center",
  },
  selectedMenuItem: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
});
