import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 70.5h69M1.5 28.5l30-12M7.5 26.115V70.5"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 22.306V15h-6v10"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.5 55.5h-12v15h12v-15zM19.5 35h-6v6h6v-6zM19.5 41h-6v6h6v-6zM25.5 35h-6v6h6v-6zM25.5 41h-6v6h6v-6z"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M31.5 16.26V70.5"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M31.5 26.5l24-10M31.5 26.115V70.5"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M41 22.306V15h-6v10"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M49.5 55.5h-12v15h12v-15zM43.5 35h-6v6h6v-6zM43.5 41h-6v6h6v-6zM49.5 35h-6v6h6v-6zM49.5 41h-6v6h6v-6z"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M55.5 70.5V57M55.5 16.26V48.5"
      />
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M60 71V57M64 71V57M57.737 57C54.42 57 53 54.91 53 52.821c0-2.089 1.421-4.178 4.737-4.178 0 0 0-4.643 4.263-4.643s4.263 4.643 4.263 4.643C69.58 48.643 71 50.5 71 52.82 71 54.925 69.579 57 66.263 57h-8.526z"
      />
    </svg>
  );
}

export default memo(Icon);
