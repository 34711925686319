import firebase from "firebase/app";
import 'firebase/storage';
import { Authenticator } from "../exco-lib/exco-auth";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Linking, StyleSheet, Text, View } from "react-native";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { Verification } from "../../../data-model/Verification";
import { VerificationModel } from "../../../data-model/VerificationModel";
import { StyledButton } from "../components/controls";
import { CenteredLayout } from "../layouts/CenteredLayout";

interface Props {
  authenticator: Authenticator;
  history: History;
  verificationModel: VerificationModel;
}

interface VerificationDetails {
  name: string;
  email: string;
  isVerified: boolean;
  files: VerificationFile[];
}

interface VerificationFile {
  fileType: string;
  fileInfo: VerificationFileInfo[];
}

interface VerificationFileInfo {
  name: string;
  url: string;
}

@observer
export class VerifyContractorsScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <VerifyContractorsScreen
        authenticator={injector.get(Authenticator)()}
        history={injector.get(HistoryInjectable)()}
        verificationModel={injector.get(VerificationModel)()}
      />
    );
  };

  @observable loading: boolean = false;
  @observable verifications: Record<string, VerificationDetails> = {};
  @observable storageRef = firebase.storage().ref();

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    this.loading = true;
    const allVerifications =
      await this.props.verificationModel.getAllVerifications();
    await this._buildVerificationsList(allVerifications);
    this.loading = false;
  }

  async handleApproveContractor(userId: string) {
    this.verifications = {
      ...this.verifications,
      [userId]: {
        ...this.verifications[userId],
        isVerified: true,
      },
    };
    await this.props.verificationModel.approveContractor({
      userId,
    });
  }

  async handleRejectContractor(userId: string) {
    this.verifications = {
      ...this.verifications,
      [userId]: {
        ...this.verifications[userId],
        isVerified: false,
      },
    };
    await this.props.verificationModel.rejectContractor({
      userId,
    });
  }

  private _buildVerificationsList = async (
    allVerifications: Verification[]
  ) => {
    await Promise.all(
      allVerifications.map(async (verification: Verification) => {
        const userId = verification.id;
        const fileInfo = await this._buildVerificationFileDownloadInfo(
          verification.fileUrls
        );
        if (verification.id in this.verifications) {
          this.verifications[userId].files.push({
            fileType: verification.fileType,
            fileInfo,
          });
        } else {
          this.verifications[userId] = {
            name: verification.name,
            email: verification.email,
            isVerified: verification.isVerified,
            files: [
              {
                fileType: verification.fileType,
                fileInfo,
              },
            ],
          };
        }
        return;
      })
    );
  };

  private _buildVerificationFileDownloadInfo = async (fileUrls: string[]) => {
    return await Promise.all(
      fileUrls.map(async (fileUrl: string) => {
        const fileRef = this.storageRef.child(fileUrl);
        const downloadUrl = await fileRef.getDownloadURL();
        return { name: fileUrl.split("/")[1], url: downloadUrl };
      })
    );
  };

  renderContractorDetails = (verificationDetails: VerificationDetails) => (
    <>
      <span>
        Contractor Name:
        <span>
          <b>{verificationDetails.name}</b>
        </span>
      </span>
      <span>
        Email:
        <span>
          <b>{verificationDetails.email}</b>
        </span>
      </span>
      <span>
        Verification Status:
        {verificationDetails.isVerified ? (
          <span style={{ color: "green" }}>
            <b>Verified</b>
          </span>
        ) : (
          <span style={{ color: "red" }}>
            <b>Not Verified</b>
          </span>
        )}
      </span>
    </>
  );

  renderVerificationButtons = (userId: string) => (
    <>
      <StyledButton
        text="Approve"
        style={localStyle.acceptButton}
        onPress={this.handleApproveContractor.bind(this, userId)}
      />
      <StyledButton
        text="Reject"
        style={localStyle.rejectButton}
        onPress={this.handleRejectContractor.bind(this, userId)}
      />
    </>
  );

  renderFileDetails = (file: VerificationFile) => (
    <View key={file.fileType}>
      <ul>
        <li key={file.fileType}>{file.fileType}:</li>
        <ul>
          {file.fileInfo.map((fileInfo: any) => (
            <li key={fileInfo.url}>
              <Text
                style={{ color: "blue" }}
                onPress={async () => Linking.openURL(fileInfo.url)}
              >
                {fileInfo.name}
              </Text>
            </li>
          ))}
        </ul>
      </ul>
    </View>
  );

  renderVerificationList = () =>
    Object.entries(this.verifications).map(([userId, verificationDetails]) => {
      return (
        <View key={userId} style={localStyle.container}>
          {this.renderContractorDetails(verificationDetails)}
          {this.renderVerificationButtons(userId)}
          {verificationDetails.files.map((file: VerificationFile) =>
            this.renderFileDetails(file)
          )}
        </View>
      );
    });

  render() {
    return (
      <CenteredLayout>
        {this.loading ? <Text>Loading...</Text> : this.renderVerificationList()}
      </CenteredLayout>
    );
  }
}

const localStyle = StyleSheet.create({
  container: {
    borderStyle: "solid",
    borderWidth: 1,
    padding: "20px",
  },
  acceptButton: {
    margin: "10px",
    backgroundColor: "green",
  },
  rejectButton: {
    margin: "10px",
    backgroundColor: "indianred",
  },
});
