import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import fetch from "isomorphic-fetch";
import {
  RestClientConfiguration,
  RestClientConfigurationInjectable,
} from "./RestClientConfiguration";

export interface RestRequest<T> {
  method: string;
  path: string;
  data?: T;
  options?: RestRequestOptions;
}

export interface RestRequestOptions {
  forceRefreshToken?: boolean;
}

export class RestClient {
  static inject: Inject<RestClient> = singletonInject((injector) => {
    return () =>
      new RestClient(injector.get(RestClientConfigurationInjectable)());
  });

  constructor(private readonly config: RestClientConfiguration) {}

  public async request<T, D>({
    method,
    path,
    data,
    options,
  }: RestRequest<D>): Promise<T> {
    const baseUrl = this.config.baseUrl;
    const authToken = await this.config.getAuthToken(options?.forceRefreshToken);

    const headers: Record<string, any> = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    const result = await fetch(baseUrl + path, {
      method,
      headers,
      body: data !== undefined ? JSON.stringify(data) : undefined,
    });

    const status = result.status;
    const text = await result.text();
    if (Math.trunc(status / 100) !== 2) {
      let error: Error | undefined;
      try {
        const { error: message, stack } = JSON.parse(text);
        error = new Error(message);
        error.stack = stack;
      } catch {
        error = new Error(result.statusText);
      }
      throw error;
    }

    return text ? JSON.parse(text) : undefined;
  }

  public get<T>(path: string, options?: RestRequestOptions): Promise<T> {
    return this.request({
      method: "get",
      path,
      options,
    });
  }

  // public getAsList<T>(path: string): List<T> {
  //   return new RestObservableList(this, {
  //     method: "get",
  //     path,
  //   });
  // }

  public post<T, D>(path: string, data?: D, options?: RestRequestOptions): Promise<T> {
    return this.request({
      method: "post",
      path,
      data,
      options,
    });
  }

  public put<T, D>(path: string, data?: D, options?: RestRequestOptions): Promise<T> {
    return this.request({
      method: "put",
      path,
      data,
      options,
    });
  }

  public patch<T, D>(path: string, data?: D, options?: RestRequestOptions): Promise<T> {
    return this.request({
      method: "patch",
      path,
      data,
      options,
    });
  }

  public delete<T>(path: string, options?: RestRequestOptions): Promise<void> {
    return this.request({
      method: "delete",
      path,
      options,
    });
  }
}
