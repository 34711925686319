import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Project, ProjectModel, UserModel } from "../../../data-model";
import {
  Card,
  Icon,
  Page,
  Screen,
  SideBar,
  StyledText,
  StyledTouchableOpacity
} from "../components/controls";
import { HeaderFactory } from "../components/Header";
import { Invitation } from "../components/InvitationCard";
import { InvitationListItemFactory } from "../components/InvitationListItem";
import { ProjectsCardView } from "../components/ProjectsCardView";
import { SideBarMain } from "../components/SideBarMain";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { InjectedFactoryComponent } from "../InjectorContext";
import { getRoute, Screen as ScreenType } from "../routes";
import { ProjectsViewModel } from "../viewModels/ProjectsViewModel";
import { Palette } from "../components/styles";

// DUMMY DATA

interface Props {
  history: History;
  projectModel: ProjectModel;
  userModel: UserModel;
  projectsViewModel: ProjectsViewModel;
}

interface CreateProps {
  currentPath: string;
}

export class DashboardScreenFactory {
  static inject: Inject<DashboardScreenFactory> = (injector) => {
    return () =>
      new DashboardScreenFactory({
        history: injector.get(HistoryInjectable)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        projectsViewModel: injector.get(ProjectsViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <DashboardScreen {...this.props} {...props} />;
  }
}
@observer
class DashboardScreen extends React.Component<Props & CreateProps> {
  @observable private invitations: any[] = [];

  @computed private get projects(): ReadonlyArray<Project> {
    return this.props.projectsViewModel.projects;
  }

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    await this.props.projectsViewModel.initialize();
    this.refreshProjects()
    this.loadInvitations()
  }

  async refreshProjects() {
    await this.props.projectsViewModel.refeshProjects();
  }

  readonly loadInvitations = async () => {
    this.invitations = await this.props.userModel.getInvites();
  };

  handleRemoveInvitation = (invitation: Invitation) => {
    const invitationIndex = this.invitations.findIndex(
      (invite: any) => invite.projectId === invitation.projectId
    );
    this.invitations.splice(invitationIndex, 1);
    this.refreshProjects();
  };

  readonly onProjectClick = (id: string) => {
    this.props.history.push(getRoute("project").build({ projectId: id }));
  };

  readonly onNavigateToScreen = (screen: ScreenType) => {
    this.props.history.push(getRoute(screen).path);
  };

  readonly renderInvitationListItems = () => {
    return this.invitations.map((invite: any, index: number) => {
      return (
        <InjectedFactoryComponent
          key={index}
          factory={InvitationListItemFactory}
          props={{
            invitation: invite,
            isLastChild: index === this.invitations.length - 1,
            removeInvitation: this.handleRemoveInvitation,
          }}
        />
      );
    });
  };

  render() {
    return (
      <Screen>
        {/* Note: hiding "Invitation" from SideBar for V1. Reinstate it with footer="invitation" as props - EY */}
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedFactoryComponent factory={HeaderFactory} props={{}} />
          <View style={[styles.row, styles.cardContainer]}>
            <Card
              style={[styles.card, styles.projectCard]}
              headerLeft="Projects"
              headerRight={
                <ViewAllLink
                  onPress={() => this.onNavigateToScreen("projects")}
                />
              }
            >
              <ProjectsCardView
                projects={this.projects}
                onProjectClick={this.onProjectClick}
                onCreateNewProject={() => this.onNavigateToScreen("newProject")}
              />
            </Card>
            <Card
              style={[styles.card, styles.invitationCard]}
              headerLeft="Invitations"
              headerRight={
                <ViewAllLink
                  onPress={() => this.onNavigateToScreen("invitations")}
                />
              }
            >
              {this.invitations.length === 0 && (
                <View style={styles.noInvitesContainer}>
                  <StyledText
                    variant="body"
                    isBold={true}
                    style={styles.noInvitesText}
                  >
                    No invitations yet
                  </StyledText>
                </View>
              )}
              {this.invitations.length > 0 && this.renderInvitationListItems()}
            </Card>
          </View>
        </Page>
      </Screen>
    );
  }
}

interface ViewAllLinkProps {
  onPress: () => void;
}
const ViewAllLink = (props: ViewAllLinkProps) => {
  const { onPress } = props;
  return (
    <StyledTouchableOpacity style={styles.row} onPress={onPress}>
      <StyledText colorMode="highlight" style={styles.link}>
        View All
      </StyledText>
      <Icon name="chevron-right" type="secondary" style={styles.chevronRight} />
    </StyledTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  cardContainer: {
    alignItems: "flex-start",
  },
  card: {
    marginRight: 24,
    marginBottom: 24,
    paddingVertical: 18,
    paddingHorizontal: 32,
  },
  projectCard: {
    flexGrow: 2,
    flexShrink: 0,
    flexBasis: "auto",
    marginRight: 24,
    minWidth: 564,
  },
  invitationCard: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
  },
  lastCard: {
    marginRight: 0,
  },
  link: {
    fontSize: 11,
    lineHeight: 14,
    fontWeight: "600",
  },
  chevronRight: {
    width: 8,
    marginLeft: 8,
  },
  noInvitesContainer: {
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  noInvitesText: {
    color: Palette.Primary75Pct,
    marginBottom: 10,
  },
});
