import { Inject } from "@not-the-droids/exco-ts-inject";
import { StyleSheet, View } from "react-native";
import { ProjectModel, UserModel } from "../../../data-model";
import { Project } from "../../../data-model/Project";
import { withInjectedFactory } from "../InjectorContext";
import { structureAddress } from "../utils/Strings";
import { Card, StyledText } from "./controls";
import { SectionBlock } from "./SectionBlock";
import { UserInfo } from "./UserInfo";

interface Props {
  projectModel: ProjectModel;
  userModel: UserModel;
}

interface CreateProps {
  project: Project | undefined;
  onPress: () => void;
}

export class ProjectCardFactory {
  static inject: Inject<ProjectCardFactory> = (injector) => {
    return () =>
      new ProjectCardFactory({
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectCard {...this.props} {...props} />;
  }
}

const ProjectCard: React.FunctionComponent<Props & CreateProps> = (props) => {
  const { project, onPress } = props;
  const creator = project?.creator;

  return (
    <Card
      style={styles.card}
      headerLeft={<UserInfo variant="name" user={creator} />}
      //TODO: connect functionality
      // headerRight={<Icon name="ellipses" mode="dark" size={14}/>}
      onPress={onPress}
    >
      <View style={styles.sectionSmall}>
        <StyledText variant="heading3" isBold={true}>
          {project?.name}
        </StyledText>
        <StyledText variant="body2">
          {project?.address?.line1 && structureAddress(project?.address)}
        </StyledText>
      </View>
      <StyledText variant="body2" style={styles.section}>
        {project?.description && project.description.length > 100
          ? `${project?.description.substring(0, 70)}...`
          : project?.description}
      </StyledText>
      <View style={[styles.sectionBlocks, styles.section]}>
        <SectionBlock title="Work Type">
          <StyledText style={styles.sectionText}>
            {project?.workType}
          </StyledText>
        </SectionBlock>
        <SectionBlock title="Work Location">
          <StyledText style={styles.sectionText}>
            {project?.workLocation}
          </StyledText>
        </SectionBlock>
      </View>
      <View style={styles.sectionBlocks}>
        <View style={styles.row}>
          {/*   TODO: connect functionality
          <Icon name="calendar" mode="dark" size={14} style={styles.calendarIcon}/>
          <StyledText style={styles.sectionText}>47 days left</StyledText>*/}
        </View>

        {/* Add back in once profile pictures are available */}
        {/* <View style={styles.row}> */}
        {/* TODO: replace dummy data with array of User data - EY */}
        {/* <StackedUserImages users={[1, 2, 3, 4, 5, 6]} /> */}
        {/* </View> */}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    width: 312,
    padding: 24,
    marginRight: 24,
    marginBottom: 24,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionBlocks: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionText: {
    lineHeight: 20,
  },
  section: {
    marginBottom: 24,
  },
  sectionSmall: {
    marginBottom: 16,
  },
  calendarIcon: {
    marginRight: 10,
  },
});

export const InjectedProjectCard = withInjectedFactory(ProjectCardFactory);
