import React from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";

export function StyledTouchableOpacity(props: React.PropsWithChildren<TouchableOpacityProps>) {
  return (
    <TouchableOpacity activeOpacity={0.6} {...props}/>
  )
}