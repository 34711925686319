import AllertCircleIcon from "../../assets/icons/AlertCircleIcon";
import ApartmentBuildingIcon from "../../assets/icons/ApartmentBuildingIcon";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import ArrowUpIcon from "../../assets/icons/ArrowUpIcon";
import BellIcon from "../../assets/icons/BellIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ChatPullIcon from "../../assets/icons/ChatPullIcon";
import CheckCircleFilledIcon from "../../assets/icons/CheckCircleFilledIcon";
import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import Checklist from "../../assets/icons/Checklist";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import ChevronLeftIcon from "../../assets/icons/ChevronLeftIcon";
import ChevronUpIcon from "../../assets/icons/ChevronUpIcon";
import ChevroRightIcon from "../../assets/icons/ChevroRightIcon";
import ClockIcon from "../../assets/icons/ClockIcon";
import CollapseAllIcon from "../../assets/icons/CollapseAllIcon";
import CondoBuildingIcon from "../../assets/icons/CondoBuildingIcon";
import DollarSignIcon from "../../assets/icons/DollarSignIcon";
import DraggableIcon from "../../assets/icons/DraggableIcon";
import DuplicateIcon from "../../assets/icons/DuplicateIcon";
import EditIcon from "../../assets/icons/EditIcon";
import Ellipsis from "../../assets/icons/Ellipsis";
import ExpandAllIcon from "../../assets/icons/ExpandAllIcon";
import ExternalLinkIcon from "../../assets/icons/ExternalLinkIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import EyeOffIcon from "../../assets/icons/EyeOffIcon";
import FileIcon from "../../assets/icons/FileIcon";
import GridIcon from "../../assets/icons/GridIcon";
import HomeIcon from "../../assets/icons/HomeIcon";
import HorizontalDotsCircleIcon from "../../assets/icons/HorizontalDotsCircleIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import LayersIcon from "../../assets/icons/LayersIcon";
import ListIcon from "../../assets/icons/ListIcon";
import LoaderIcon from "../../assets/icons/LoaderIcon";
import LockIcon from "../../assets/icons/LockIcon";
import LoginIcon from "../../assets/icons/LoginIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import MailIcon from "../../assets/icons/MailIcon";
import MenuIcon from "../../assets/icons/MenuIcon";
import MessageCircleAlertIcon from "../../assets/icons/MessageCircleAlertIcon";
import MessageCircleFilledIcon from "../../assets/icons/MessageCircleFilledIcon";
import MessageCircleIcon from "../../assets/icons/MessageCircleIcon";
import MessageSquareIcon from "../../assets/icons/MessageSquareIcon";
import MinusIcon from "../../assets/icons/MinusIcon";
import MoreHorizontalIcon from "../../assets/icons/MoreHorizontalIcon";
import MoveHorizontalIcon from "../../assets/icons/MoveHorizontalIcon";
import MoveIcon from "../../assets/icons/MoveIcon";
import MoveVerticalIcon from "../../assets/icons/MoveVerticalIcon";
import NumberIcon from "../../assets/icons/NumberIcon";
import PhaseArrowDown from "../../assets/icons/PhaseArrowDown";
import PhaseArrowRight from "../../assets/icons/PhaseArrowRight";
import PlusIcon from "../../assets/icons/PlusIcon";
import PrinterIcon from "../../assets/icons/PrinterIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import SendIcon from "../../assets/icons/SendIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import SidebarIcon from "../../assets/icons/SidebarIcon";
import SingleFamilyBuildingIcon from "../../assets/icons/SingleFamilyBuildingIcon";
import TownhomeBuildingIcon from "../../assets/icons/TownhomeBuildingIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import UploadIcon from "../../assets/icons/UploadIcon";
import UserIcon from "../../assets/icons/UserIcon";
import UserPlusIcon from "../../assets/icons/UserPlusIcon";
import XCircleIcon from "../../assets/icons/XCircleIcon";
import XIcon from "../../assets/icons/XIcon";
import { Palette } from "./Palette";

export const IconComponents = {
  "alert-circle": AllertCircleIcon,
  "apartment-building": ApartmentBuildingIcon,
  "arrow-down": ArrowDownIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  "arrow-up": ArrowUpIcon,
  bell: BellIcon,
  calendar: CalendarIcon,
  "chat-pull": ChatPullIcon,
  "check-circle": CheckCircleIcon,
  "check-circle-filled": CheckCircleFilledIcon,
  check: CheckIcon,
  checklist: Checklist,
  "chevron-down": ChevronDownIcon,
  "chevron-left": ChevronLeftIcon,
  "chevron-up": ChevronUpIcon,
  "chevron-right": ChevroRightIcon,
  clock: ClockIcon,
  "collapse-all": CollapseAllIcon,
  "condo-building": CondoBuildingIcon,
  "dollar-sign": DollarSignIcon,
  draggable: DraggableIcon,
  duplicate: DuplicateIcon,
  edit: EditIcon,
  ellipsis: Ellipsis,
  "expand-all": ExpandAllIcon,
  "external-link": ExternalLinkIcon,
  eye: EyeIcon,
  "eye-off": EyeOffIcon,
  file: FileIcon,
  grid: GridIcon,
  home: HomeIcon,
  "horizontal-dots-circle": HorizontalDotsCircleIcon,
  info: InfoIcon,
  layers: LayersIcon,
  list: ListIcon,
  loader: LoaderIcon,
  lock: LockIcon,
  login: LoginIcon,
  logout: LogoutIcon,
  mail: MailIcon,
  menu: MenuIcon,
  "message-circle": MessageCircleIcon,
  "message-circle-alert": MessageCircleAlertIcon,
  "message-circle-filled": MessageCircleFilledIcon,
  "message-square": MessageSquareIcon,
  minus: MinusIcon,
  "more-horizontal": MoreHorizontalIcon,
  "move-horizontal": MoveHorizontalIcon,
  move: MoveIcon,
  "move-vertical": MoveVerticalIcon,
  number: NumberIcon,
  "phase-arrow-down": PhaseArrowDown,
  "phase-arrow-right": PhaseArrowRight,
  plus: PlusIcon,
  printer: PrinterIcon,
  search: SearchIcon,
  send: SendIcon,
  settings: SettingsIcon,
  sidebar: SidebarIcon,
  "single-family-building": SingleFamilyBuildingIcon,
  "townhome-building": TownhomeBuildingIcon,
  trash: TrashIcon,
  upload: UploadIcon,
  user: UserIcon,
  "user-plus": UserPlusIcon,
  "x-circle": XCircleIcon,
  x: XIcon,
};

export const iconTypes = {
  primary: Palette.Primary100Pct,
  gray: Palette.Primary50Pct,
  secondary: Palette.Secondary100Pct,
  accent: Palette.Accent,
  affirm: Palette.Affirm100Pct,
  warning: Palette.Warning100Pct,
  white: Palette.White,
}

export type IconType = keyof typeof iconTypes;

export type IconName = keyof typeof IconComponents;