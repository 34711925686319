export type Screen =
  | "changePassword"
  | "dashboard"
  | "editProject"
  | "forgotPassword"
  | "invitations"
  | "login"
  | "milestones"
  | "newProject"
  | "payments"
  | "pricingSettings"
  | "profile"
  | "project"
  | "projectBid"
  | "projectBudget"
  | "projectDetails"
  | "projectDocuments"
  | "projectPayments"
  | "projects"
  | "settings"
  | "signUp"
  | "verifyEmail"
  | "verifyContractors";

type ProjectRouteParams = {
  projectId: string;
};

type RouteBuilder<T extends Screen> = {
  path: string;
} & (T extends "editProject" | "milestones" | "project" | "projectBid" | "projectBudget" | "projectDetails" | "projectDocuments" | "projectPayments"
  ? {
      build: (params: ProjectRouteParams) => string;
    }
  : {});

export function getRoute<T extends Screen>(screen: T): RouteBuilder<T> {
  const path = simpleRouteMap[screen];
  let build: (params: ProjectRouteParams) => string = () => path;

  switch (screen) {
    case "editProject": build = (params) => `/projects/edit/${encodeURIComponent(params.projectId)}`; break;
    case "milestones": build = (params) => `/project/${encodeURIComponent(params.projectId)}/milestones`; break;
    case "project": build = (params) => `/project/${encodeURIComponent(params.projectId)}`; break;
    case "projectBid": build = (params) => `/project/${encodeURIComponent(params.projectId)}/bid`; break;
    case "projectBudget": build = (params) => `/project/${encodeURIComponent(params.projectId)}/budget`; break;
    case "projectDetails": build = (params) => `/project/${encodeURIComponent(params.projectId)}/details`; break;
    case "projectDocuments": build = (params) => `/project/${encodeURIComponent(params.projectId)}/documents`; break;
    case "projectPayments": build = (params) => `/project/${encodeURIComponent(params.projectId)}/payments`; break;
  }

  return {
    path,
    build,
  };
}

const simpleRouteMap: Record<Screen, string> = {
  changePassword: "/profile/changePassword",
  dashboard: "/dashboard",
  editProject: "/projects/edit/:id",
  forgotPassword: "/login/reset",
  invitations: "/invitations",
  login: "/login",
  milestones: "/project/:id/milestones",
  newProject: "/projects/new",
  payments: "/payments",
  pricingSettings: "/profile/pricingSettings",
  profile: "/profile",
  project: "/project/:id",
  projectBid: "/project/:id/bid",
  projectBudget: "/project/:id/budget",
  projectDetails: "/project/:id/details",
  projectDocuments: "/project/:id/documents",
  projectPayments: "/project/:id/payments",
  projects: "/projects",
  settings: "/settings",
  signUp: "/signup",
  verifyEmail: "/verify-email",
  verifyContractors: "/verify-contractors",
};
