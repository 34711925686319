import { Inject } from "@not-the-droids/exco-ts-inject";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { ProjectModel } from "../../../data-model";
import { Page, Screen, SideBar, StyledText } from "../components/controls";
import { HeaderFactory } from "../components/Header";
import { ProjectCrudFlow } from "../components/ProjectCrudFlow";
import { SideBarMain } from "../components/SideBarMain";
import { Authenticator } from "../exco-lib/exco-auth";
import { History, HistoryInjectable } from "../HistoryInjectable";
import {
  InjectedFactoryComponent
} from "../InjectorContext";

interface Props {
  authenticator: Authenticator;
  history: History;
  projectCrudFlow: ProjectCrudFlow;
  projectModel: ProjectModel;
}

interface CreateProps {
  projectId?: string;
}

export class ProjectCrudScreenFactory {
  static inject: Inject<ProjectCrudScreenFactory> = (injector) => {
    return () =>
      new ProjectCrudScreenFactory({
        authenticator: injector.get(Authenticator)(),
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
        projectModel: injector.get(ProjectModel)(),
        history: injector.get(HistoryInjectable)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectCrudScreen {...this.props} {...props} />;
  }
}

@observer
class ProjectCrudScreen extends React.Component<Props & CreateProps> {

  componentDidMount() {
    this.props.projectCrudFlow.projectId = this.props.projectId || "";
  }

  componentWillUnmount = () => {
    this.props.projectCrudFlow.clearProject();
  }

  render() {
    const { projectCrudFlow, projectId } = this.props;
    const isNewProject = !projectId;

    return (
      <Screen>
        {/* Note: hiding "Invitation" from SideBar for V1. Reinstate it with footer="invitation" as props - EY */}
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedFactoryComponent factory={HeaderFactory} props={{}} />
          {isNewProject && (
            <StyledText variant="heading2" style={styles.header}>
              Create New Project
            </StyledText>
          )}
          <View style={styles.progressStepsContainer}>
            {projectCrudFlow.progressStepsElement}
          </View>
          {projectCrudFlow.currentProjectStepElement}
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 24,
  },
  progressStepsContainer: {
    marginBottom: 72,
  },
});
