import firebase from "firebase/app";
import 'firebase/storage';

export const uploadFromBlobAsync = async ({ blobUrl, name }:{blobUrl:string, name:string}) => {
  if (!blobUrl || !name) return null

  try {
    const blob = await fetch(blobUrl).then((r) => r.blob())
    const snapshot = await firebase.storage().ref().child(name).put(blob)
    return await snapshot.ref.getDownloadURL()
  } catch (error) {
    throw error
  }
}
