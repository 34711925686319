import { Inject } from "@not-the-droids/exco-ts-inject";
import { format } from "date-fns";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import {
  Icon,
  StyledText,
  StyledTouchableOpacity,
} from "../components/controls";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { withInjectedFactory } from "../InjectorContext";
import * as routes from "../routes";
import { UserViewModel } from "../viewModels/UserViewModel";
import { Palette } from "./styles";
import { UserInfo } from "./UserInfo";

interface Props {
  history: History;
  userViewModel: UserViewModel;
}

interface CreateProps {
  leftElement?: JSX.Element;
}

export class HeaderFactory {
  static inject: Inject<HeaderFactory> = (injector) => {
    return () =>
      new HeaderFactory({
        history: injector.get(HistoryInjectable)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <Header {...this.props} {...props} />;
  }
}

@observer
class Header extends React.Component<Props & CreateProps> {
  private timeInterval?: NodeJS.Timeout;
  @observable private time: Date = new Date();
  @observable private isDropdownMenuToggled: boolean = false;

  @computed get currentUser() {
    return this.props.userViewModel.currentUser;
  }

  @computed get dateDisplay(): string {
    return format(this.time, "EEE MMM d, yyyy");
  }
  @computed get timeDisplay(): string {
    return format(this.time, "h:mmaa");
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    this.setNewInterval((58 - this.time.getSeconds()) * 1000);
  }

  componentWillUnmount() {
    this.resetInterval();
  }

  readonly resetInterval = () => {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  };

  readonly setNewInterval = (interval: number) => {
    this.resetInterval();
    this.timeInterval = setInterval(() => this.getTime(), interval);
  };

  readonly getTime = () => {
    const newTime = new Date();

    if (this.time.getMinutes() !== newTime.getMinutes()) {
      this.time = newTime;
      this.setNewInterval(58000);
    } else {
      this.setNewInterval(1000);
    }
  };

  readonly toggleDropdownMenu = () => {
    this.isDropdownMenuToggled = !this.isDropdownMenuToggled;
  };

  readonly handleNavigateToProfile = () => {
    const { history } = this.props;
    history.push(routes.getRoute("profile").path);
  };

  readonly handleSignOut = () => {
    this.props.userViewModel.signOut();
  };

  render() {
    const { leftElement } = this.props;
    return (
      <View
        style={[
          styles.container,
          styles.row,
          { justifyContent: leftElement ? "space-between" : "flex-end" },
        ]}
      >
        {leftElement}
        <View style={styles.row}>
          <View style={[styles.dateTime, styles.row]}>
            <Icon name="calendar" size={16} style={styles.dateTimeIcon} />
            <StyledText variant="body2">{this.dateDisplay}</StyledText>
          </View>
          <View style={[styles.dateTime, styles.row]}>
            <Icon name="clock" size={16} style={styles.dateTimeIcon} />
            <StyledText variant="body2">{this.timeDisplay}</StyledText>
          </View>

          {/* TODO: implement notifications for V2 - EY */}
          {/* <StyledTouchableOpacity style={styles.notificationContainer}>
            <View style={styles.newNotification}>
              <StyledText style={styles.newNotificationText}>{2}</StyledText>
            </View>
            <Icon name="bell" mode="dark" size={14} />
          </StyledTouchableOpacity> */}

          {/* TODO: pass in User document or User id as props - EY*/}
          <UserInfo
            variant="name-email"
            onPress={this.toggleDropdownMenu}
            user={this.currentUser}
            colorIndex={1}
          />
          {/* TODO: if the dropdown component is used again elsewhere in design, make this into reusable component - EY */}
          {this.isDropdownMenuToggled && (
            <View style={styles.dropdownMenu}>
              <StyledTouchableOpacity
                style={styles.dropdownMenuItem}
                onPress={this.handleNavigateToProfile}
              >
                <Icon name="user" size={14} style={styles.icon} />
                <StyledText variant="body2">Profile</StyledText>
              </StyledTouchableOpacity>
              <StyledTouchableOpacity
                style={styles.dropdownMenuItem}
                onPress={this.handleSignOut}
              >
                <Icon name="logout" size={14} style={styles.icon} />
                <StyledText variant="body2">Sign Out</StyledText>
              </StyledTouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    marginBottom: 58,
    zIndex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 32,
  },
  searchInput: {
    flex: 1,
    maxWidth: 312,
    marginRight: 32,
  },
  dateTime: {
    marginRight: 32,
  },
  dateTimeIcon: {
    marginRight: 10,
  },
  notificationContainer: {
    height: 32,
    width: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 24,
    marginLeft: 16,
    backgroundColor: Palette.Primary10Pct,
  },
  newNotification: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: Palette.Warning100Pct,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -4,
    right: -4,
  },
  newNotificationText: {
    fontSize: 10,
    lineHeight: 18,
    color: Palette.White,
  },
  dropdownMenu: {
    position: "absolute",
    top: 64,
    right: 0,
    width: 144,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Palette.Primary10Pct,
    backgroundColor: Palette.White,
  },
  dropdownMenuItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  icon: {
    marginRight: 8,
  },
});

export const InjectedHeader = withInjectedFactory(HeaderFactory);
