import React from "react";
import {
  Image,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import proofLogo from "../../assets/proof-logo.svg";
import { StyledText } from "../../components/controls";
import { SidebarVariant } from "../../constants/Variants";
import { InvitationWidget } from "../InvitationWidget";
import { Background } from "./Background";
import { Margins } from "./Margins";
import { StyledTouchableOpacity } from "./StyledTouchableOpacity";

export type Size = "default" | "large";


type SidebarSizes = Record<Size, SidebarVariant>;
const sidebarSizes: SidebarSizes = {
  default: "sidebar",
  large: "sidebarLarge",
}

type FooterType = "copyright" | "invitation";
interface Props {
  footer?: FooterType,
  isLogoHidden?: boolean,
  size?: Size,
}

type Sizes = Record<Size, StyleProp<ViewStyle>>;

export const SideBar: React.FunctionComponent<Props> = (props) => {
  const { footer, isLogoHidden, size } = props;
  const containerStyle = containerStyles[size || "default"];
  const logoStyle = logoStyles[size || "default"];
  const marginVariant: SidebarSizes[Size] = sidebarSizes[size || "default"];

  return (
    <View style={containerStyle}>
      <Background variant="dark">
        <Margins variant={marginVariant}>
          <View style={styles.sidebar}>
            { !isLogoHidden && (
              // TODO: route logo press to default home screen - EY
              <StyledTouchableOpacity style={logoStyle} onPress={() => {}}>
                <Image style={styles.logo} source={{ uri: proofLogo }} />
              </StyledTouchableOpacity>
            )}
            {props.children}
          </View>

          {renderFooter(footer)}

        </Margins>
      </Background>
    </View>
  )
};

const CopyrightText: React.FunctionComponent = (props) => {
  return (
    <View style={styles.copyrightContainer}>
      <StyledText variant="caption" colorMode="gray">Proof Payments © 2022 | </StyledText>
      <TouchableOpacity onPress={() => {}}>
        <StyledText variant="caption" colorMode="gray"> Privacy Policy </StyledText>
      </TouchableOpacity>
      <StyledText variant="caption" colorMode="gray"> | </StyledText>
      <TouchableOpacity onPress={() => {}}>
        <StyledText variant="caption" colorMode="gray"> Terms </StyledText>
      </TouchableOpacity>
    </View>
  )
}

const renderFooter = (footer?: FooterType) => {
  if (!footer) {
    return;
  } else if (footer === "copyright") {
    return <CopyrightText/>;
  } else if (footer === "invitation") {
    return <InvitationWidget/>;
  }
}

const styles = StyleSheet.create({
  sidebar: {
    flex: 1,
  },
  logo: {
    width: 88,
    height: 40,
    resizeMode: "contain",
  },
  copyrightContainer: {
    flexDirection: "row",
    marginHorizontal: "auto",
  },
});

const containerStyles: Sizes = {
  default: {
    width: 288,
  },
  large: {
    width: 540,
  },
};

const logoStyles: Sizes = {
  default: {
   marginBottom: 48,
  },
  large: {
    marginBottom: 96,
  },
};
