import { Identifiable } from "@not-the-droids/exco-data-model";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { Project, WorkArea, WorkCategoryAreas } from "./Project";

export interface UpdateProjectParams
  extends Identifiable,
    Partial<Omit<Project, "id" | "status">> {}

export interface CreateProjectParams
  extends Partial<
    Omit<Project, "workCategories" | "workAreas" | "inviteEmails">
  > {}

export interface UpdateWorkAreasParams {
  readonly projectId: string;
  readonly workAreas: ReadonlyArray<WorkArea>;
}

export interface UpsertMediaParams {
  readonly projectId: string;
  readonly name: string;
  readonly url: string;
}

export interface UpdateWorkCategoriesParams {
  readonly projectId: string;
  readonly workCategoryAreas: Array<WorkCategoryAreas>;
}

export interface InviteContractorParams {
  readonly projectId: string;
  readonly contractorId: string;
}

export interface InviteOwnerParams {
  readonly projectId: string;
  readonly ownerId: string;
}

export interface DeleteInviteParams {
  readonly projectId: string;
  readonly userId: string;
}

export interface InvitedUser {
  readonly email: string;
  readonly name: string;
  readonly userId: string;
  readonly photoUrl: string;
}

export abstract class ProjectModel {
  static inject?: Inject<ProjectModel> = undefined;

  public abstract getProjects(): Promise<Project[]>;
  public abstract getProjectsAsRole(): Promise<Project[]>;
  public abstract getProjectById(id: string): Promise<Project>;
  public abstract getInvitesByProjectId(id: string): Promise<InvitedUser[]>;
  public abstract createProject(
    params: CreateProjectParams
  ): Promise<CreateProjectParams>;
  public abstract updateProject(params: UpdateProjectParams): Promise<void>;
  public abstract updateProjectName(
    params: UpdateProjectParams
  ): Promise<Project>;
  public abstract getWorkCategoriesByProjectId(id: string): Promise<WorkCategoryAreas[]>;
  public abstract updateWorkCategories(
    params: UpdateWorkCategoriesParams
  ): Promise<void>;
  public abstract publishProject(id: string): Promise<void>;
  public abstract inviteContractor(
    params: InviteContractorParams
  ): Promise<void>;
  public abstract inviteOwner(params: InviteOwnerParams): Promise<void>;
  public abstract attachRole(params: any): Promise<any>;
  public abstract getRolesByProjectId(id: string): Promise<InvitedUser[]>;
  public abstract deleteInvitation(params: DeleteInviteParams): Promise<any>;
  public abstract rejectInvitation(params: DeleteInviteParams): Promise<any>;
  public abstract getFilesByProjectId(id: string): Promise<any[]>;
}
