const methods = ["error", "warn"];

export const ignoreLogs = (blacklist: string[]) => {
  methods.forEach((method) => {
    //   @ts-ignore
    const defaultConsole = console[method];
    //   @ts-ignore
    console[method] = (...args) => {
      try {
        if (
          blacklist.some((error) =>
            args.some((arg) => (arg.toString() as string).includes(error))
          )
        ) {
          return;
        }
      } catch (_) {}
      defaultConsole(...args);
    };
  });
};
