import React from "react";

interface Props {
  isEditView: boolean;
  editView: any;
  summaryView: any;
  userType?: string;
  submitted?: boolean;
  approved?: boolean;
}

export const ProjectViewControl: React.FunctionComponent<Props> = ({
  editView,
  isEditView,
  summaryView,
  userType,
  submitted,
}) => {
  return !submitted && isEditView && userType === "contractor"
    ? editView
    : summaryView;
};
