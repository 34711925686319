import firebase from "firebase/app";
import { FirebaseWebAuthenticationProvider } from "./FirebaseWebAuthenticationProvider";
import { EmailAuthenticationProvider, User } from "../exco-auth";

export class FirebaseWebEmailAuthenticator
  extends FirebaseWebAuthenticationProvider<"email">
  implements EmailAuthenticationProvider {

  public get type(): "email" { return "email"; }

  public signIn(emailAddress: string, password: string): Promise<User> {
    return this.credentialSignIn(emailAddress, password);
  }

  public async signUp(emailAddress: string, password: string): Promise<User> {
    return this.signUpWithEmailPassword(emailAddress, password);
  }

  private credentialSignIn(emailAddress: string, password: string): Promise<User> {
    const credential = firebase.auth.EmailAuthProvider.credential(emailAddress, password);
    return this.signInWithCrediential(credential);
  }

  public async changeEmail(emailAddress: string, password: string, newEmail: string): Promise<void> {
    const credential = firebase.auth.EmailAuthProvider.credential(emailAddress, password);
    const firebaseUser = this.delegate!.firebaseAuth.currentUser!;
    await firebaseUser.reauthenticateWithCredential(credential);
    await firebaseUser.updateEmail(newEmail);
  }

  public async changePassword(emailAddress: string, password: string, newPassword: string): Promise<void> {
    const credential = firebase.auth.EmailAuthProvider.credential(emailAddress, password);
    const firebaseUser = this.delegate!.firebaseAuth.currentUser!;
    await firebaseUser.reauthenticateWithCredential(credential);
    await firebaseUser.updatePassword(newPassword);
  }

  public async resetPassword(emailAddress: string): Promise<void> {
    await this.delegate!.firebaseAuth.sendPasswordResetEmail(emailAddress);
  }

  public async checkEmailVerified(): Promise<boolean> {
    await this.delegate!.firebaseAuth.currentUser?.reload();
    return this.delegate!.firebaseAuth.currentUser?.emailVerified ?? false;
  }

  public async sendEmailVerification(): Promise<void> {
    await this.delegate!.firebaseAuth.currentUser?.sendEmailVerification();
  }
}
