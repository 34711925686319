
import React from "react";
import { StyleSheet, View } from "react-native";
import { observer } from "mobx-react";
import { StyledText, StyledTextArea, StyledTextInput } from "./controls";

export interface CategoryPriceViewBodyProps {
  updateScope: (
    categoryIndex: number,
    updatedScope: string
  ) => void;
  updateBudget: (
    categoryIndex: number,
    updatedPrice: string
  ) => void;
}

type Props = CategoryPriceViewBodyProps & {
  category: any;
  categoryIndex: number;
}

@observer
export class CategoryPriceViewBody extends React.Component<Props> {
  render() {
    const { category, categoryIndex, updateScope, updateBudget } = this.props;
    return (
      <View style={[styles.container, styles.section]}>
        <View style={styles.sectionSmall}>
          <StyledText style={styles.header}>Scope of Work</StyledText>
          <StyledTextArea
            minHeight={88}
            onChangeText={(text: string) => updateScope(categoryIndex, text)}
            placeholder="Enter a description of the scope of work for this category"
            value={category.scope}
          />
        </View>
        <View style={[styles.row, styles.budgetContainer]}>
          <StyledText style={styles.budgetCol}>Budget</StyledText>
          <StyledTextInput
            onChangeText={(text: string) => updateBudget(categoryIndex, text)}
            placeholder="$--"
            style={styles.budgetInputCol}
            value={category.budget ? category.budget.toString() : ""}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    marginHorizontal: 24,
  },
  row: {
    flexDirection: "row",
  },
  budgetContainer: {
    marginBottom: 8,
    alignItems: "center",
  },
  budgetCol: {
    flex: 1,
  },
  budgetInputCol: {
    width: 88,
    marginLeft: 8,
  },
  header: {
    marginBottom: 8,
  },
  section: {
    marginBottom: 10,
  },
  sectionSmall: {
    marginBottom: 16,
  },
});
