import React from "react";
import {
  TextProps,
} from "react-native";

interface Props extends TextProps {
  condition: Boolean;
  wrapper: (children: React.ReactNode) => React.ReactElement;
}

export const ConditionalWrapper: React.FunctionComponent<Props> = (props) => {
  const { condition, wrapper, children } = props;
  return (
    condition ? wrapper(children) : <>{children}</>
  );
};