import { Inject } from "@not-the-droids/exco-ts-inject";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { BudgetModel, Project } from "../../../data-model";

import { StyledText } from "./controls";
import { ProjectDetailsFlow } from "./ProjectDetailsFlow";

import { withInjectedFactory } from "../InjectorContext";
import { LoadingIndicator } from "./LoadingIndicator";
import { UserViewModel } from "../viewModels/UserViewModel";
import { PaymentModel } from "../../../data-model/PaymentModel";
import { Palette } from "./styles";
import { PaymentPhaseTabHeader } from "./Payments/PaymentPhaseTabHeader";
import { PaymentNextPhasePreview } from "./Payments/PaymentNextPhasePreview";
import { formatCurrencyToString } from "../utils/Numbers";
import {
  calculateRemamingProjectBalance,
  mapProjectPayments,
} from "../utils/Budget";
import { PaymentPhasesPreview } from "./Payments/PaymentPhasesPreview";
import { PaymentTransactionPreview } from "./Payments/PaymentTransactionPreview";

interface Props {
  budgetModel: BudgetModel;
  projectDetailsFlow: ProjectDetailsFlow;
  paymentModel: PaymentModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  project: Project;
}

export class ProjectPaymentsDashboardViewFactory {
  static inject: Inject<ProjectPaymentsDashboardViewFactory> = (injector) => {
    return () =>
      new ProjectPaymentsDashboardViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        paymentModel: injector.get(PaymentModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectPaymentsDashboardView {...this.props} {...props} />;
  }
}

@observer
class ProjectPaymentsDashboardView extends React.Component<CreateProps & Props> {
  @observable private _initialized: boolean = false;
  @observable private remainingBalance: number = 0;
  @observable private fundsInEscrow: number = 0;

  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  readonly componentDidMount = async () => {
    const {
      project,
      paymentModel,
      budgetModel,
      projectDetailsFlow,
    } = this.props;
    try {
      const trustshare = await paymentModel.getPaymentByProjectId(project.id);
      const budget = await budgetModel.getBudgetByProjectId(project.id);
      this.remainingBalance = calculateRemamingProjectBalance(
        budget.phases,
        trustshare
      );
      this.fundsInEscrow = trustshare.balance;
      projectDetailsFlow.paymentData = mapProjectPayments(budget, trustshare);

      this._initialized = true;
    } catch (error) {
      console.log(error);
    }
  };

  private handleFundPhaseClick = async (id: string) => {
    const {
      projectDetailsFlow,
    } = this.props;
    projectDetailsFlow.selectedPaymentPhases = [id];
    projectDetailsFlow.paymentView = "make-payment";
  };

  render() {
    const {projectDetailsFlow} = this.props;
    if (!this._initialized) return <LoadingIndicator />;
    return (
      <ScrollView contentContainerStyle={styles.container}>
        {/* Left Side */}
        <View style={styles.leftSideContainer}>
          <PaymentNextPhasePreview
            phase={projectDetailsFlow.paymentData!.nextPayment}
            showFundPhaseButton={this.props.userViewModel.isOwner}
            onFundPhaseClick={this.handleFundPhaseClick}
          />
          <PaymentPhasesPreview phases={projectDetailsFlow.paymentData!.phasesPreview} />
        </View>
        {/* Right side */}
        <View style={styles.rightSideContainer}>
          {/* Top */}
          <View style={styles.rightSideTopContainer}>
            <View style={styles.balanceContainer}>
              <PaymentPhaseTabHeader
                title={"Remaining Project Balance"}
                onInfoClick={() => {}}
              />
              <StyledText style={styles.balanceText} isBold>
                {formatCurrencyToString(this.remainingBalance)}
              </StyledText>
            </View>
            <View style={[styles.balanceContainer, styles.escrowContainer]}>
              <PaymentPhaseTabHeader
                title={"Funds in Escrow"}
                onInfoClick={() => {}}
              />
              <StyledText style={styles.balanceText} isBold>
                {formatCurrencyToString(this.fundsInEscrow)}
              </StyledText>
            </View>
          </View>
          {/* Bottom */}
          <PaymentTransactionPreview transactions={projectDetailsFlow.paymentData!.payments} />
        </View>

        {/* <StyledButton
          text="Initialize funding"
          style={{ marginBottom: 20, width: 200 }}
          onPress={this.initializeFunding}
        />
        <StyledButton
          text="Pay"
          style={{ marginBottom: 20, width: 200 }}
          onPress={this.pay}
        />
        <StyledButton
          text="Re funding"
          style={{ marginBottom: 20, width: 200 }}
          onPress={this.releaseFunding}
        /> */}
      </ScrollView>
    );
  }
}

const CONTAINER_MARGIN = 16;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  leftSideContainer: {
    flex: 1,
    marginRight: CONTAINER_MARGIN,
  },
  rightSideContainer: {
    flex: 2,
  },
  rightSideTopContainer: {
    display: "flex",
    flexDirection: "row",
  },
  balanceContainer: {
    flex: 1,
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: CONTAINER_MARGIN,
    marginRight: CONTAINER_MARGIN,
    borderColor: Palette.Secondary25Pct,
    backgroundColor: Palette.Secondary10Pct,
    height: 132,
    padding: 24,
  },
  escrowContainer: {
    marginRight: 0,
    borderColor: Palette.Accent25Pct,
    backgroundColor: Palette.Accent10Pct,
  },
  balanceText: {
    fontSize: 28,
    lineHeight: 36,
    marginTop: 18,
  },
});

export const InjectedProjectPaymentsDashboardView = withInjectedFactory(
  ProjectPaymentsDashboardViewFactory
);
