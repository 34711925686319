import React from "react";
import { Injectable, Injector } from "@not-the-droids/exco-ts-inject";

export const InjectorContext = React.createContext<Injector>(new Injector());

export function withInjectedProps(
  component: Injectable<React.ReactElement>
): React.ComponentType {
  return class InjectedComponent extends React.Component {
    render() {
      return (
        <InjectorContext.Consumer>
          {(injector) => injector.get(component)()}
        </InjectorContext.Consumer>
      );
    }
  };
}

export interface InjectedComponentProps {
  component: Injectable<React.ReactElement>;
}

export class InjectedComponent extends React.Component<InjectedComponentProps> {
  render() {
    return (
      <InjectorContext.Consumer>
        {(injector) => injector.get(this.props.component)()}
      </InjectorContext.Consumer>
    );
  }
}

export interface ComponentFactory<P> {
  create(props: P): React.ReactElement;
}

export interface InjectedFactoryComponentProps<P> {
  factory: Injectable<ComponentFactory<P>>;
  props: P;
}

export class InjectedFactoryComponent<P> extends React.Component<
  InjectedFactoryComponentProps<P>
> {
  render() {
    const { factory, props } = this.props;
    return (
      <InjectorContext.Consumer>
        {(injector) => injector.get(factory)().create(props)}
      </InjectorContext.Consumer>
    );
  }
}

export function withInjectedFactory<P>(
  factory: Injectable<ComponentFactory<P>>
) {
  return class InjectedFactoryComponent extends React.Component<P> {
    render() {
      return (
        <InjectorContext.Consumer>
          {(injector) => injector.get(factory)().create(this.props)}
        </InjectorContext.Consumer>
      );
    }
  };
}
