import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { observer } from "mobx-react";
import { Palette } from "./styles";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { withInjectedFactory } from "../InjectorContext";
import { Icon, StyledButton, StyledText, StyledTextInput, StyledTouchableOpacity } from "./controls";
import { computed, makeObservable, observable } from "mobx";
import { Budget, BudgetMilestone, BudgetMilestoneTask, BudgetModel, MilestoneCompletionType, milestoneCompletionType, Project } from "../../../data-model";
import { Notification } from "../NotificationInjectable";
import { formatCurrencyToString } from "../utils/Numbers";
import { UserViewModel } from "../viewModels/UserViewModel";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { ProjectWidgetComment } from "./ProjectWidgetComment";
import { formatCompletionTagProps } from "../utils/Strings";
import { Tag } from "./controls/Tag";

const menuItems = ["Details", "Activity", "Comments", "Files"] as const;
type MenuItem = typeof menuItems[number];

const milestoneOptions = ["Edit", "Upload Files", "Mark Completed", "Delete"] as const;

interface Props {
  budgetModel: BudgetModel;
  notification: Notification;
  projectWidgetManager: ProjectWidgetManager;
  userViewModel: UserViewModel;
}

interface CreateProps {
  budget: Budget;
  project: Project;
  selectedMilestone: BudgetMilestone;
}

export class ManagementSidebarViewFactory {
  static inject: Inject<ManagementSidebarViewFactory> = (injector) => {
    return () =>
      new ManagementSidebarViewFactory({
        budgetModel: injector.get(BudgetModel)(),
        notification: injector.get(Notification)(),
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ManagementSidebarView {...this.props} {...props} />;
  }
}

@observer
class ManagementSidebarView extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  };

  @observable private commentInput: string = "";
  @observable private isCompletionOpen: boolean = false;
  @observable private isOptionOpen: boolean = false;
  @observable private selectedMenuItem: MenuItem = "Details";

  @computed private get completionDropdownMenu() {
    return (
      <View style={styles.completionDropdown}>
        {
          milestoneCompletionType.map((completionType) => {
            return (
              <TouchableOpacity key={"completionButton" + completionType} onPress={() => this.handleCompletionStatusSelect(completionType)}>
                <Tag {...formatCompletionTagProps(completionType)}/>
              </TouchableOpacity>
            );
          })
        }
      </View>
    );
  };

  @computed private get optionDropdownMenu() {
    return (
      <View style={styles.optionDropdown}>
        {
          milestoneOptions.map((milestoneOption, index) => {
            const optionStyle = index < milestoneOptions.length - 1 && styles.optionLineDropdown;
            return (
              <TouchableOpacity key={"optionButton" + milestoneOption} style={optionStyle} onPress={() => {}}>
                <View style={{marginHorizontal: 16}}>
                    <StyledText variant={"body"}>{milestoneOption}</StyledText>
                </View>
              </TouchableOpacity>
            );
          })
        }
      </View>
    );
  };

  @computed public get totalMilestonePrice(): number {
    const { selectedMilestone } = this.props; 
    let totalCost: number = 0;
    selectedMilestone?.tasks.forEach((task: BudgetMilestoneTask) => {
      totalCost += Number(task.budget);
    });
    return Math.round((totalCost + Number.EPSILON) * 100) / 100;
  };

  @computed public get commentsTab() {
    const { projectWidgetManager, selectedMilestone } = this.props;
    const activeCommentTag = selectedMilestone?.name;
    const comments = projectWidgetManager.commentsByTag[activeCommentTag!]?.comments || [];

    const renderCommentTime = (date: Date) => {
      const commentDate = new Date(date);
      return (
        <View style={{flexDirection: "column"}}>
          <StyledText variant="body">
            {
              (new Date(commentDate)).toLocaleDateString(undefined, {
                timeZone: "UTC",
                month: "long",
                day: "numeric",
              })
            }
          </StyledText>
          <StyledText variant="caption" colorMode={"gray"}>
            {
              (new Date(commentDate)).toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit'
            })
            }
          </StyledText>
        </View>
      );
    }

    return (
      <View style={{flexDirection: "column", justifyContent: "flex-end"}}>
      <View style={styles.commentBlock}>
        {comments.map((comment) => {
          return (
            <ProjectWidgetComment
              key={`sidebar-comment-${comment.id}`}
              comment={comment}
              alternateTime={renderCommentTime(comment.createdAt)}       
            />
          );
        })}
        <StyledTextInput
          iconRight={{ name: "send", type: "accent", size: 14, onClick: this.submitMessage }}
          onChangeText={(value) => this.commentInput = value}
          placeholder="Write your message here"
          value={this.commentInput}
        />
      </View>
      </View>
    );
  }

  @computed public get detailsTab() {
    const { selectedMilestone, userViewModel } = this.props;
    return (
      <>
        <View style={styles.textBlock}>
          <StyledText variant={"body"} isBold={true}>{"SCOPE OF WORK"}</StyledText>
          <StyledText variant={"body"}>{selectedMilestone?.scopeOfWork}</StyledText>
        </View>
        <View style={styles.taskBlock}>
        <StyledText variant={"body"} isBold={true}>{"TASKS"}</StyledText>
          {selectedMilestone?.tasks.map((task) => {
            const taskColor = task.completed ? "affirm" : "dark";
            const taskStyle = task.completed && styles.textLineThrough;
            return (
              <View key={"details" + task.orderIndex} style={styles.task}>
                <TouchableOpacity disabled={userViewModel.isOwner} activeOpacity={1} onPress={() => this.handleTaskClick(task)}>
                  {
                    task.completed ? (
                      <Icon name={"check-circle-filled"} type={"affirm"}/>
                    ) : (
                      <Icon name={"check-circle"} type={"gray"}/>
                    )
                  }
                </TouchableOpacity>
                <StyledText variant={"body"} colorMode={taskColor} style={taskStyle}>{task.description}</StyledText>
                {
                  userViewModel.isContractor && (
                    <StyledText variant={"body"} style={{marginLeft: "auto"}}>{formatCurrencyToString(task.budget)}</StyledText>
                  )
                }
              </View>
            );
          })}
        </View>
      </>
    );
  };

  @computed public get filesTab() {
    return (
      <View style={{flexDirection: "column", justifyContent: "space-between"}}>
        <View style={styles.temp_orig}>
          <View style={[{width: "calc(50% - 5px)", justifyContent: "space-between", borderRadius: 4}, styles.temp]}>
            <Image
              source={{uri: this.props.project.media[0].url}}
              style={{width: "100%", height: 200, borderRadius: 4}}
            />
            <View style={styles.bodyContainer}>
              <StyledText variant="body2" isBold>
                {this.props.project.media[0].fileName}
              </StyledText>
              <StyledText variant="caption" colorMode="gray">
                {"Apr 14, 2022"}
              </StyledText>
            </View>
          </View>
        </View>
        <StyledButton
          iconLeft={{name: "upload", type: "accent", size: 24}} 
          onPress={() => {}}
          style={{marginVertical: 24, backgroundColor: Palette.White}}
          variant={"secondary"}
          text={"Upload Files"}
        />
      </View>
    );
  }

  readonly componentDidUpdate = (prevProps: CreateProps & Props) => {
    if (prevProps.selectedMilestone.id !== this.props.selectedMilestone.id) {
      this.selectedMenuItem = "Details";
    }
  };

  readonly handleCompletionStatusSelect = async (completionStatus: MilestoneCompletionType) => {
    const { budgetModel, selectedMilestone } = this.props;

    const updatedMilestone = {
      ...selectedMilestone!,
      completion: completionStatus,
      tasks: [],
      files: [],
    }

    try {
      await budgetModel.updateMilestone(updatedMilestone);
      selectedMilestone!.completion = completionStatus;
    } catch (error) {
      console.log(error);
    }
  }

  readonly handleTaskClick = async (task: BudgetMilestoneTask) => {
    const { budgetModel, budget, selectedMilestone } = this.props;
    
    let completionStatus = selectedMilestone?.completion
    if (selectedMilestone?.completion !== "needs-review") {
      const newTaskCompletionTotal = selectedMilestone?.tasks.reduce((previousValue, currentTask) => previousValue + ((currentTask.id === task.id) !== currentTask.completed ? 1 : 0), 0);
      if (newTaskCompletionTotal === selectedMilestone?.tasks.length) {
        completionStatus = "completed"
      } else if (newTaskCompletionTotal === 0) {
        completionStatus = "not-started"
      } else {
        completionStatus = "in-progress"
      }
    } else {
      completionStatus = "needs-review"
    }

    const clonedTasks: Array<BudgetMilestoneTask> = JSON.parse(JSON.stringify(selectedMilestone?.tasks));
    const repeatedIndex = clonedTasks.findIndex(clonedTask => clonedTask.id === task.id);
    clonedTasks[repeatedIndex].completed = !task.completed;

    const budgetItem = [{
      ...selectedMilestone!,
      type: "milestone",
      renderIndex: selectedMilestone?.orderIndex,
      completion: completionStatus,
      tasks: [...clonedTasks]
    }]

    const updateObject = {
      budgetId: budget!.id,
      deletedMilestones: [],
      deletedPhases: [],
      deletedTasks: [],
      budgetItems: budgetItem,
      startDate: new Date(budget!.startDate),
    };

    try {
      await budgetModel.updateBudget(updateObject);
      task.completed = !task.completed;
      selectedMilestone!.completion = completionStatus;
    } catch (error) {
      console.log(error);
    }
  }

  readonly menuItemNav: Record<MenuItem, () => JSX.Element> = {
    Details: () => {return this.detailsTab;},
    Activity: () => {return this.detailsTab;},
    Comments: () => {
      const { budget, projectWidgetManager, selectedMilestone } = this.props;
      projectWidgetManager.setActiveCommentInfo(budget.id, selectedMilestone.name);
      return this.commentsTab;
    },
    Files: () => {return this.filesTab;},
  };

  readonly renderMenuItem = (item: MenuItem) => {
    const isSelected = this.selectedMenuItem === item;
    const selectedStyle = isSelected && styles.selectedMenuItem;
    return (
      <StyledTouchableOpacity
        key={"sidebarMenuItem" + item}
        style={[styles.menuItem, selectedStyle]}
        onPress={() => {
          this.commentInput = '';
          this.selectedMenuItem = item;
        }}
      >
        <StyledText
          isBold={true}
          variant={"body2"}
          colorMode={isSelected ? "accent" : "gray"}
        >
          {item}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  readonly submitMessage = () => {
    const { projectWidgetManager } = this.props;
    const commentInput = this.commentInput;
    this.commentInput = '';
    projectWidgetManager.submitComment(commentInput);
  }

  render() {
    const { selectedMilestone, userViewModel } = this.props;
    const completionStatus = selectedMilestone?.completion;
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerBar}>
            <TouchableOpacity 
              onPress={() => this.isCompletionOpen = !this.isCompletionOpen}
              onBlur={() => onBlurTimeout(() => {this.isCompletionOpen = false})}
              disabled={userViewModel.isOwner}
            >
              <Tag {...formatCompletionTagProps(completionStatus!)}/>
            </TouchableOpacity>
            <View style={styles.milestonePrice}>
              <StyledText variant="body" isBold={true}>{"BUDGET"}</StyledText>
              <StyledText>{formatCurrencyToString(this.totalMilestonePrice)}</StyledText>
            </View>
          </View>
          <View style={styles.headerBar}>
            <StyledText variant="heading3" isBold={true}>{selectedMilestone?.name}</StyledText>
            {
              userViewModel.isContractor && (
                <TouchableOpacity 
                  onPress={() => this.isOptionOpen = !this.isOptionOpen}
                  onBlur={() => onBlurTimeout(() => {this.isOptionOpen = false})}
                >
                  <Icon name={"ellipsis"} type={"accent"} size={24}/>
                </TouchableOpacity>
              )
            }
          </View>
          <View style={styles.menuBar}>
            {menuItems.map(this.renderMenuItem)}
          </View>
        </View>
        <View style={styles.body}>
          {this.menuItemNav[this.selectedMenuItem]()}
        </View>
        {this.isCompletionOpen && this.completionDropdownMenu}
        {this.isOptionOpen && this.optionDropdownMenu}
      </View>
    );
  };
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    // paddingBottom: 160,
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    flexDirection: "column",
    gap: 10,
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  headerBar: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  milestonePrice: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  menuBar: {
    flexDirection: "row",
    gap: 16,
    marginTop: 15,
  },
  menuItem: {
    paddingBottom: 12,
    justifyContent: "center",
  },
  selectedMenuItem: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Accent,
  },
  completionDropdown: {
    position: "absolute",
    border: "1px solid" + Palette.Primary10Pct,
    boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    flexDirection: "column",
    padding: 8,
    gap: 8,
    backgroundColor: Palette.White,
    top: 56,
    left: 16,
  },
  optionDropdown: {
    position: "absolute",
    border: "1px solid" + Palette.Primary10Pct,
    boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: 8,
    flexDirection: "column",
    paddingVertical: 12,
    gap: 12,
    backgroundColor: Palette.White,
    marginRight: "auto",
    top: 105,
    right: 16,
  },
  optionLineDropdown: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    paddingBottom: 12,
  },
  body: {
    flexDirection: "column",
    gap: 30,
    padding: 24,
  },
  textBlock: {
    flexDirection: "column",
    gap: 14,
  },
  taskBlock: {
    flexDirection: "column",
    gap: 10,
  },
  task: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    paddingBottom: 10,
  },
  textLineThrough: {
    textDecorationLine: "line-through",
  },
  commentBlock: {
    flexDirection: "column",
    gap: 14,
  },
  bodyContainer: {
    flexDirection: "column",
    backgroundColor: "transparent",
    // gap: 8,
    // justifyContent: "space-between",
    margin: 16,
    gap: 4,
    // marginTop: 72,
  },
  temp_orig: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
  temp: {
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
  },
});

const onBlurTimeout = (func: () => void) => {setTimeout(() => {func()}, 100);}

export const InjectedManagementSidebarView = withInjectedFactory(
  ManagementSidebarViewFactory
);