import { Inject } from "@not-the-droids/exco-ts-inject";
import { computed, makeObservable, } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Page, Screen, SideBar } from "../components/controls";
import { InjectedHeader } from "../components/Header";
import { HeaderCTA } from "../components/HeaderCTA";
import { InjectedProjectCard } from "../components/ProjectCard";
import { ProjectCrudFlow } from "../components/ProjectCrudFlow";
import { SideBarMain } from "../components/SideBarMain";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { getRoute, Screen as ScreenType } from "../routes";
import { ProjectsViewModel } from "../viewModels/ProjectsViewModel";

interface Props {
  history: History;
  projectCrudFlow: ProjectCrudFlow;
  projectsViewModel: ProjectsViewModel;
}

@observer
export class ProjectsScreen extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <ProjectsScreen
        history={injector.get(HistoryInjectable)()}
        projectCrudFlow={injector.get(ProjectCrudFlow)()}
        projectsViewModel={injector.get(ProjectsViewModel)()}
      />
    );
  }

  @computed get projects() {
    return this.props.projectsViewModel.projects;
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.props.projectsViewModel.initialize();
  }

  async componentDidMount() {
    await this.props.projectsViewModel.refeshProjects();
  }

  readonly onProjectPress = (id: string) => {
    this.props.history.push(getRoute("project").build({ projectId: id }));
  };

  readonly onNavigateToScreen = (screen: ScreenType) => {
    const { projectCrudFlow } = this.props;
    projectCrudFlow.clearProject();
    const { history } = this.props;
    history.push(getRoute(screen).path);
  };

  readonly createNewProject = () => {
    this.props.history.push(getRoute("newProject").path);
  };

  render() {
    return (
      <Screen>
        {/* Note: hiding "Invitation" from SideBar for V1. Reinstate it with footer="invitation" as props - EY */}
        <SideBar size="default">
          <SideBarMain />
        </SideBar>
        <Page variant="main">
          <InjectedHeader
            leftElement={
              <HeaderCTA
                feature="addProject"
                onPress={() => this.createNewProject()}
              />
            }
          />
          <View style={styles.cardsContainer}>
            {this.projects.map((project) => {
              return (
                <InjectedProjectCard
                  key={project.id}
                  project={project}
                  onPress={() => this.onProjectPress(project.id)}
                />
              );
            })}
          </View>
        </Page>
      </Screen>
    );
  }
}

const styles = StyleSheet.create({
  cardsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
