import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable } from "mobx";
import { observer } from "mobx-react";
import { CategoryPrice } from "../../../data-model/UserModel";
import {
  StyledText,
  StyledTouchableOpacity,
} from "./controls";
import { Palette } from "./styles";
import { CategoryPriceViewBody, CategoryPriceViewBodyProps } from "./CategoryPriceViewBody";
import { formatCurrencyToString } from "../utils/Numbers";

interface Props {
  isActive?: boolean;
  onClick: () => void;
  category: CategoryPrice;
  categoryIndex:number;
}

type CategoryPriceViewProps = Props & CategoryPriceViewBodyProps;

@observer
export class CategoryPriceView extends React.Component<CategoryPriceViewProps> {
  @computed private get totalPrice() {
    const total = Number(this.props.category.budget);
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  constructor(props: CategoryPriceViewProps) {
    super(props);
    makeObservable(this);
  }

  readonly renderCategoryInput = () => {
    const { category } = this.props;
    return (
      <View style={styles.row}>
        <StyledText style={styles.category} isBold={true}>
          {category.category}
        </StyledText>
      </View>
    );
  };

  render() {
    const { isActive } = this.props;
    return (
      <View style={isActive && styles.containerBorder}>
        <StyledTouchableOpacity style={styles.categoryContainer} onPress={this.props.onClick}>
          <View style={styles.leftColumn}>
            {this.renderCategoryInput()}
          </View>
          <StyledText variant="body2">
            {formatCurrencyToString(this.totalPrice)}
          </StyledText>
        </StyledTouchableOpacity>

        {this.props.isActive && (
          <CategoryPriceViewBody
            {...this.props}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width:"100%"
  },
  row: {
    flexDirection: "row",
  },
  containerBorder: {
    borderWidth: 2,
    borderColor: Palette.Primary10Pct,
  },
  categoryContainer: {
    paddingVertical: 10,
    paddingLeft:8,
    paddingRight:16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Palette.Primary5Pct,
  },
  leftColumn: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  category: {
    marginRight: 8,
    marginTop: "auto",
    marginBottom: "auto",
  },
});
