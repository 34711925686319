import React from "react";
import {
  StyleSheet,
  View,
} from "react-native";
import {
  StyledText,
  StyledTouchableOpacity,
} from "../components/controls";
import { Palette } from "./styles";

export interface CounterProps {
  number: number;
  onDecrement: () => void;
  onIncrement: () => void;
}
export const Counter: React.FunctionComponent<CounterProps> = (props) => {
  const { number, onDecrement, onIncrement } = props;
  return (
    <View style={styles.container}>
      <StyledTouchableOpacity onPress={onDecrement} style={styles.button}>
        <StyledText colorMode="accent" isBold={true}>-</StyledText>
      </StyledTouchableOpacity>
      <StyledText colorMode="accent" isBold={true}>{number}</StyledText>
      <StyledTouchableOpacity onPress={onIncrement} style={styles.button}>
        <StyledText colorMode="accent" isBold={true}>+</StyledText>
      </StyledTouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 80,
    marginLeft: 16,
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Palette.Primary5Pct,
  },
  button: {
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
});