import React from "react";
import { StyleSheet, View } from "react-native";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { BidModel } from "../../../data-model/BidModel";
import {
  Project,
  ProjectModel,
  UserModel,
  WorkCategoryAreas,
} from "../../../data-model";
import { formatCurrencyToString, sanitizePriceInput } from "../utils/Numbers";
import { CommentBubble } from "./CommentBubble";
import {
  Icon,
  StyledButton,
  StyledText,
  StyledTextArea,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import {
  BidInput,
  BidLineInput,
  mapBidInputToBid,
  mapBidToBidInput,
  ProjectDetailsFlow,
} from "./ProjectDetailsFlow";
import { ProjectWidgetManager } from "./ProjectWidgetManager";
import { Palette } from "./styles";
import { withInjectedFactory } from "../InjectorContext";
import { UserViewModel } from "../viewModels/UserViewModel";

type BidEditableColumnProperty = "scope" | "cost" | "category";

interface Props {
  projectWidgetManager: ProjectWidgetManager;
  projectDetailsFlow: ProjectDetailsFlow;
  bidModel: BidModel;
  projectModel: ProjectModel;
  userModel: UserModel;
  userViewModel: UserViewModel;
}

interface CreateProps {
  changeBidView: () => void;
  projectId: string;
}

export class ProjectBidEditViewFactory {
  static inject: Inject<ProjectBidEditViewFactory> = (injector) => {
    return () =>
      new ProjectBidEditViewFactory({
        projectWidgetManager: injector.get(ProjectWidgetManager)(),
        projectDetailsFlow: injector.get(ProjectDetailsFlow)(),
        bidModel: injector.get(BidModel)(),
        projectModel: injector.get(ProjectModel)(),
        userModel: injector.get(UserModel)(),
        userViewModel: injector.get(UserViewModel)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <ProjectBidEditView {...this.props} {...props} />;
  }
}

@observer
class ProjectBidEditView extends React.Component<Props & CreateProps> {
  readonly componentDidMount = async () => {
    const { projectId, projectDetailsFlow, projectWidgetManager } = this.props;
    await this.loadBids();
    if (!!projectId) {
      await projectWidgetManager.loadComments(projectId, "bid", projectDetailsFlow.bid?.id);
    }
  };

  readonly loadBids = async () => {
    const { projectDetailsFlow, projectId, projectModel, bidModel } =
      this.props;
    const project: Project = await projectModel.getProjectById(projectId);
    const bid = await bidModel.getBidByProjectId(projectId);
    const bidInputMapped = mapBidToBidInput(bid);
    const bidInput: BidInput = {
      ...bid,
      lines: bidInputMapped.lines,
    }
    const workCategories = [...project.workCategories];

    if (!bidInput.completed && bidInput.lines.length < 1) {
      // const workCategories = [...project.workCategories];
      workCategories
        .sort(this.sortOrderIndex)
        .forEach((workCategory: WorkCategoryAreas, index: number) => {
          bidInput.lines.push({
            bidId: bid.id,
            orderIndex: index,
            category: workCategory.workCategory,
            scopeOfWork: workCategory.workAreas.join(", "),
            cost: '',
            workAreas: workCategory.workAreas,
          });
        });
    } else {
      bidInputMapped.lines.forEach(line => {
        const foundCategory = workCategories.find(workCategory => workCategory.workCategory === line.category);
        if (foundCategory) {
          line.workAreas = foundCategory.workAreas;
        }
      })
    }
    

    projectDetailsFlow.bid = bid;
    projectDetailsFlow.bidInput = bidInput;
  };

  readonly sortOrderIndex = (a: WorkCategoryAreas, b: WorkCategoryAreas) => {
    if (a.workCategory < b.workCategory) {
      return -1;
    }
    if (a.workCategory > b.workCategory) {
      return 1;
    }
    return 0;
  };

  readonly handleCommentBubblePress = (bubbleIndex: number, tagName: string, parentId: string) => {
    const { projectWidgetManager } = this.props;
    if (projectWidgetManager.activeCommentTag !== tagName) {
      projectWidgetManager.openChatWidget(parentId, tagName);
      this.props.projectWidgetManager.activeCommentIndex = bubbleIndex;
    } else {
      projectWidgetManager.closeWidget();
    }
  };

  readonly resetProjectWidget = () => {
    const { projectWidgetManager } = this.props;
    projectWidgetManager.isOpen = false;
    projectWidgetManager.setActiveCommentInfo();
  };

  readonly handleChangeCellText = (
    id: number,
    value: string,
    type: BidEditableColumnProperty,
    mutableBidInput: BidLineInput[]
  ) => {
    if (type === "cost") {
      mutableBidInput[id].cost = sanitizePriceInput(value);
    } else if (type === "scope") {
      mutableBidInput[id].scopeOfWork = value;
    } else if (type === "category") {
      mutableBidInput[id].category = value;
    }
  };

  readonly handleRowDelete = (
    index: number,
    mutableBidInput: BidLineInput[],
    newlyAddedLine: boolean
  ) => {
    const { projectDetailsFlow } = this.props;
    if (!newlyAddedLine) {
      projectDetailsFlow.bidLinesDeleted.push(mutableBidInput[index]);
    }
    mutableBidInput.splice(index, 1);
  };

  readonly renderInputRows = (
    row: BidLineInput[],
    newlyAddedLines: boolean
  ) => {
    return row?.map((item: BidLineInput, index) => {
      return (
        <View key={index} style={[styles.row, styles.inputRow]}>
          <View
            style={[
              styles.column,
              styles.row,
              styles.categoryCol,
              styles.categoryTitle,
            ]}
          >
            <StyledTouchableOpacity
              onPress={() => {
                this.handleRowDelete(index, row, newlyAddedLines);
              }}
            >
              <Icon name="x" size={14} style={styles.deleteIcon} />
            </StyledTouchableOpacity>
            {newlyAddedLines ? (
              <StyledTextArea
                style={[styles.categoryColTextArea]}
                placeholder="category..."
                value={item.category}
                onChangeText={(value) =>
                  this.handleChangeCellText(index, value, "category", row)
                }
              />
            ) : (
              <StyledText>{item.category}</StyledText>
            )}
          </View>
          <View style={[styles.column, styles.scopeCol]}>
            <StyledTextArea
              placeholder={`${item.category} scope...`}
              value={item.scopeOfWork}
              onChangeText={(value) =>
                this.handleChangeCellText(index, value, "scope", row)
              }
            />
          </View>
          <View style={[styles.column, styles.columnLastChild, styles.costCol]}>
            <StyledTextInput
              placeholder="$00.00"
              keyboardType="numeric"
              value={`${item.cost}`}
              onChangeText={(value) =>
                this.handleChangeCellText(index, value, "cost", row)
              }
            />
          </View>
          {!newlyAddedLines ? (
            <CommentBubble
              isAlert={this.props.projectWidgetManager.determineTaggedCommentsUnread(this.props.userViewModel.currentUser!.id, item.category)}
              isFilled={this.props.projectWidgetManager.activeCommentIndex === index}
              onPress={() =>
                this.handleCommentBubblePress(index, item.category, item.bidId)
              }
              style={styles.commentBubble}
            />
          ) : (
            <View style={styles.noCommentBubble} />
          )}
        </View>
      );
    });
  };

  addNewCategory = async () => {
    const { projectDetailsFlow } = this.props;
    let newBidInput: BidLineInput = {
      bidId: projectDetailsFlow.bid?.id as string,
      scopeOfWork: "",
      category: "",
      cost: "",
      orderIndex: projectDetailsFlow.bidLinesAdded.length + 1,
    };
    projectDetailsFlow.bidLinesAdded.push(newBidInput);
  };

  applyPredetermingPricing = async () => {
    const { projectDetailsFlow, userModel } = this.props;
    const categoryPrices = await userModel.getCategoryPrices();
    projectDetailsFlow.bidInput?.lines.forEach((line) => {
      const foundPrice = categoryPrices.find(categoryPrice => categoryPrice.category === line.category);
      if (foundPrice) {
        line.scopeOfWork = foundPrice.scope ? `${foundPrice.scope} (${line.workAreas?.join(", ")})` : line.scopeOfWork;
        line.cost = foundPrice.budget || line.cost;
      }
    });
  };

  onDisclosureChangeText = (value: string) => {
    const { projectDetailsFlow } = this.props;
    if (projectDetailsFlow.bidInput) {
      projectDetailsFlow.bidInput.disclosure = value;
    }
  };

  saveRoughBid = async () => {
    const { bidModel, projectDetailsFlow } = this.props;
    if (projectDetailsFlow.bidInput === undefined) {
      return;
    }

    if (!projectDetailsFlow.bid?.approved) {
      let added = toJS(projectDetailsFlow.bidLinesAdded);

      // check if we have new input
      if (added.length > 0) {
        // TODO: this is a simple fix to prevent empty category fields from entering into the database.
        // Ideally move this concept to an existing field inside the BidInput that renders an error on a
        // conditional flag which is tied to input validation.
        added = added.filter((item: BidLineInput) => item.category.length > 0);
        projectDetailsFlow.bidLinesAdded = [];
      }

      // deleted lines...
      if (projectDetailsFlow.bidLinesDeleted.length > 0) {
        await bidModel.deleteBidLines(
          projectDetailsFlow.bidLinesDeleted as any
        );
        projectDetailsFlow.bidLinesDeleted = [];
      }

      // do update of bid.
      projectDetailsFlow.bidInput.lines = [
        ...added,
        ...projectDetailsFlow.bidInput.lines,
      ];

      await bidModel.updateBid(mapBidInputToBid(projectDetailsFlow.bidInput));

      // query on update.
      const updatedBid = await bidModel.getBidById(
        projectDetailsFlow.bidInput.id
      );

      projectDetailsFlow.bid = updatedBid;
      projectDetailsFlow.bidInput = mapBidToBidInput(updatedBid);

      this.props.changeBidView();
    } else {
      projectDetailsFlow.bid = await bidModel.getBidById(
        projectDetailsFlow.bidInput.id
      );
      this.props.changeBidView();
    }
  };

  render() {
    const { projectDetailsFlow } = this.props;
    return (
      <View>
        <View style={styles.sectionLarge}>
          <View style={styles.section}>
            <StyledText variant="heading3" isBold={true}>
              Rough Bid
            </StyledText>
            <View style={[styles.row, styles.spaceBetween]}>
              <StyledText>Start a rough bid here.</StyledText>
              {/* TODO: add prederminded pricing back in when we know how it works.  */}
              <StyledButton 
                variant="secondary"
                style={{right: 30}}
                text="Apply predetermined pricing"
                onPress={this.applyPredetermingPricing}
              />
            </View>
          </View>
          <View style={styles.row}>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.categoryCol]}
            >
              Category
            </StyledText>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.scopeCol]}
            >
              Scope of Work
            </StyledText>
            <StyledText
              variant="body2"
              isBold={true}
              style={[styles.columnTitle, styles.costCol]}
            >
              Cost
            </StyledText>
          </View>
          {projectDetailsFlow.bidInput &&
            this.renderInputRows(projectDetailsFlow.bidInput.lines, false)}
          {this.renderInputRows(projectDetailsFlow.bidLinesAdded, true)}
          <StyledButton
            variant="secondary"
            text="Add categories"
            onPress={this.addNewCategory}
          />
        </View>
        <View style={styles.sectionLarge}>
          <View
            style={[
              styles.row,
              styles.spaceBetween,
              styles.divider,
              styles.section,
            ]}
          >
            <StyledText style={styles.projectTotal}>
              Projected Total
            </StyledText>
            <StyledText variant="heading2">
              {formatCurrencyToString(projectDetailsFlow.projectedTotal)}
            </StyledText>
          </View>
          <StyledText
            variant="heading3"
            isBold={true}
            style={styles.sectionHeader}
          >
            Disclosures
          </StyledText>
          <StyledText style={styles.sectionBody}>
            Write in disclosures here, or upload a PDF file containing
            disclosures for this rough bid.
          </StyledText>

          <View style={styles.sectionSmall}>
            <StyledTextArea
              value={projectDetailsFlow.bidInput?.disclosure ?? ""}
              onChangeText={(value) => {
                this.onDisclosureChangeText(value);
              }}
            />
          </View>

          {/* TODO: insert file drop interface here - EY */}
          {/* TODO: Add fucntionalities below - EY */}
          {/* <View>
            <FileUploadItem onCancelPress={() => {}}/>
            <FileUploadItem onCancelPress={() => {}}/>
            <FileUploadItem onCancelPress={() => {}} isLastChild={true}/>
          </View> */}
        </View>
        <StyledButton text="Save and Preview" onPress={this.saveRoughBid} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  column: {
    marginRight: 24,
  },
  columnLastChild: {
    marginRight: 0,
  },
  section: {
    marginBottom: 32,
  },
  sectionLarge: {
    marginBottom: 64,
  },
  sectionSmall: {
    marginBottom: 24,
  },
  columnTitle: {
    marginBottom: 8,
    textTransform: "uppercase",
  },
  sectionHeader: {
    marginBottom: 4,
  },
  sectionBody: {
    marginBottom: 8,
  },
  categoryTitle: {
    marginTop: 6,
  },
  inputRow: {
    marginBottom: 16,
  },
  deleteIcon: {
    marginRight: 12,
    marginTop: 2,
  },
  categoryColTextArea: {
    width: 120,
    marginTop: -6,
  },
  categoryCol: {
    width: 164,
    marginRight: 24,
  },
  scopeCol: {
    flex: 2,
    marginRight: 24,
  },
  costCol: {
    width: 100,
  },
  projectTotal: {
    fontSize: 32,
    lineHeight: 40,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary25Pct,
    marginTop: 16,
  },
  commentBubble: {
    marginTop: 8,
    marginLeft: 18,
  },
  noCommentBubble: {
    width: 32,
    height: 1,
  },
});

export const InjectedProjectBidEditView = withInjectedFactory(ProjectBidEditViewFactory);
