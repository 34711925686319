import { ReactElement } from "react";
import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { computed, observable, makeObservable } from "mobx";
import { InjectedFactoryComponent } from "../InjectorContext";
import { ProjectFlowDetailsViewFactory } from "./ProjectFlowDetailsView";
import { ProjectFlowWorkAreasViewFactory, WorkAreaParams } from "./ProjectFlowWorkAreasView";
import { ProjectFlowConfirmProjectViewFactory } from "./ProjectFlowConfirmProjectView";
import { ProgressSteps } from "./ProgressSteps";
import { AddressParts } from "./GooglePlacesInput";

export enum ProjectStep {
  Details = 1,
  WorkAreas,
  // Categories,
  // WorkPerRoom,
  Finish,
}

export enum Questions {
  projectLocationInput = 1,
  buildingType,
  workType,
  situatedAt,
}

const projectSteps: Record<ProjectStep | string, string> = {
  [ProjectStep.Details]: "Project Details",
  [ProjectStep.WorkAreas]: "Work Areas",
  // [ProjectStep.Categories]: "Work Categories",
  // [ProjectStep.WorkPerRoom]: "Work Per Room",
  [ProjectStep.Finish]: "Finish",
};

export const buildingTypes = [
  "apartment",
  "condo",
  "single",
  "townhome",
] as const;
export type BuildingType = typeof buildingTypes[number];

export const workTypes = ["addition", "new", "remodel"] as const;
export type WorkType = typeof workTypes[number];

export const situatedAtTypes = ["both", "interior", "exterior"] as const;
export type SituatedAtType = typeof situatedAtTypes[number];

export class ProjectCrudFlow {
  static inject: Inject<ProjectCrudFlow> = singletonInject((injector) => {
    return () => new ProjectCrudFlow();
  });

  constructor(props?:any) {
    makeObservable(this)
  }

  // Flow Tracker
  @observable public projectId: string = "";
  @observable public currentStep: number = ProjectStep.Details;
  @observable public currentQuestion: number = ProjectStep.Details;
  @observable public clearAddressInput: boolean = false;

  // Step: Project Details
  @observable public projectLocationInput: string = "";
  //TODO: remove type assertion
  @observable public projectLocation:AddressParts = {} as any;// TODO: Update type as needed - EY
  @observable public buildingType?: BuildingType;
  @observable public workType?: WorkType;
  @observable public situatedAt?: SituatedAtType;
  @observable public structureDescription: string = "";
  @observable public media: any[] = [];
  @observable public name: string = "";
  @observable public isFileUploading: boolean = false;

  // Step: Work Areas
  @observable public inclusiveWorkAreas: Array<WorkAreaParams> = [];
  @observable public exclusiveWorkAreas: Array<WorkAreaParams> = [];
  @observable public categoryCount: Array<number> = [];
  @observable public numSelectedWorkAreas: number = 0;

  // Step: Work Categories
  @observable public selectedCategoryIds: Set<string> = new Set<string>();

  @computed public get progressSteps() {
    return Object.keys(projectSteps)
      .sort()
      .map((projectStep) => {
        const step = Number(projectStep);
        return {
          name: projectSteps[step],
          isActive: step === this.currentStep,
          isCompleted: step < this.currentStep, // TODO: implement logic to determine if step is completed - EY
          step,
        };
      });
  }

  @computed public get progressStepsElement(): ReactElement {
    return <ProgressSteps steps={this.progressSteps} />;
  }

  @computed public get currentProjectStepElement(): ReactElement {
    switch (this.currentStep) {
      case ProjectStep.Details:
        return (
          <InjectedFactoryComponent
            factory={ProjectFlowDetailsViewFactory}
            props={{}}
          />
        );
      case ProjectStep.WorkAreas:
        return (
          <InjectedFactoryComponent
            factory={ProjectFlowWorkAreasViewFactory}
            props={{}}
          />
        );
      // case ProjectStep.Categories:
      //   return (
      //     <InjectedFactoryComponent
      //       factory={ProjectFlowCategoriesViewFactory}
      //       props={{}}
      //     />
      //   );
      // case ProjectStep.WorkPerRoom:
      //   return (
      //     <InjectedFactoryComponent
      //       factory={ProjectFlowWorkPerRoomViewFactory}
      //       props={{}}
      //     />
      //   );
      case ProjectStep.Finish:
        return (
          <InjectedFactoryComponent
            factory={ProjectFlowConfirmProjectViewFactory}
            props={{projectId: this.projectId}}
          />
        );
      default:
        return (
          <InjectedFactoryComponent
            factory={ProjectFlowDetailsViewFactory}
            props={{}}
          />
        );
    }
  }

  readonly goToStep = (step:number) => {
    if (step) {
      this.currentStep = step
    }
  };  

  readonly incrementCurrentStep = (projectStep?: ProjectStep) => {
    if (projectStep) {
      this.currentStep = projectStep;
    } else if (this.currentStep < ProjectStep.Finish) {
      this.currentStep++;
    }
  };

  readonly decrementCurrentStep = (projectStep?: ProjectStep) => {
    if (projectStep) {
      this.currentStep = projectStep;
    } else if (this.currentStep >= ProjectStep.Details) {
      this.currentStep--;
    }
  };

//TODO: remove type assertion
  readonly clearProject = () => {
      this.currentStep= ProjectStep.Details;
      this.projectLocationInput= "";
      this.projectLocation={} as any;// TODO: Update type as needed - EY
      this.buildingType=undefined;
      this.workType=undefined;
      this.situatedAt=undefined;
      this.structureDescription= "";
      this.media=[];
      this.inclusiveWorkAreas=[];
      this.exclusiveWorkAreas=[];
      this.categoryCount=[];
      this.numSelectedWorkAreas=0;
  };
}
