import { Inject } from "@not-the-droids/exco-ts-inject";
import { Contractor } from "./Contractor";

export interface CreateContractorParams extends Partial<Contractor> {}

export abstract class ContractorModel {
  static inject?: Inject<ContractorModel> = undefined;

  public abstract createContractor(
    params: CreateContractorParams
  ): Promise<Contractor>;
  public abstract getContractorByEmail(
    contractorEmail: string
  ): Promise<Contractor>;
  public abstract getContractors(): Promise<Contractor[]>;
  public abstract getContractorByProjectId(
    projectId: string
  ): Promise<Contractor | undefined>;
  public abstract addFile(bidId:string, params:any): Promise<void>;
  public abstract getFilesById(params: any): Promise<void>;
  public abstract deleteFile(id:string): Promise<void>;
}
