import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableWithoutFeedbackProps,
  ViewStyle,
} from "react-native";
import { Icon } from "./Icon";
import { IconName, IconType, Palette } from "../styles";
import { StyledTouchableOpacity } from "./StyledTouchableOpacity";
import { IconSize } from ".";

type Variant =
  | "affirm"
  | "link"
  | "pillDark"
  | "pillLight"
  | "primary"
  | "secondary"
  | "textOnly"
  | "warning";

type ButtonIcon = {
  name: IconName;
  type?: IconType;
  size?: IconSize
}  

interface Props extends Omit<TouchableWithoutFeedbackProps, "onPress"> {
  alignSelf?: boolean;
  iconLeft?: ButtonIcon,
  iconRight?: ButtonIcon,
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  text: string;
  textStyle?: StyleProp<TextStyle>;
  variant?: Variant;
}

type Variants = Record<Variant, StyleProp<TextStyle>>;

export function StyledButton(props: Props) {
  const { alignSelf, iconLeft, iconRight, style, variant, text, textStyle, disabled, ...touchableProps } = props;
  const textVariantStyle = textStyles[variant || "primary"];
  const variantStyle = variants[variant || "primary"];
  const disabledVariantStyle = disabledVariants[variant || "primary"];
  const disabledTextStyle = disabledTextStyles[variant || "primary"];
  const withSmallMargins = ["pillDark", "pillLight", "textOnly"].includes(variant || "");
  const iconLeftStyle = withSmallMargins ? { marginRight: 4 } : { marginRight: 14 };
  const iconRightStyle = withSmallMargins ? { marginLeft: 4 } : { marginLeft: 14 };
  const alignSelfStyle = alignSelf && styles.alignSelf;

  return (
    <StyledTouchableOpacity
      style={[
        styles.container,
        alignSelfStyle,
        variantStyle,
        style,
        disabled && disabledVariantStyle,
      ]}
      disabled={disabled}
      {...touchableProps}
    >
      {!!iconLeft && <Icon name={iconLeft.name} size={iconLeft.size ?? 16} type={iconLeft.type} style={iconLeftStyle}/>}
      <Text style={[textVariantStyle, textStyle, disabled && disabledTextStyle]}>{text}</Text>
      {!!iconRight && <Icon name={iconRight.name} size={iconRight.size ?? 16} type={iconRight.type} style={iconRightStyle}/>}
    </StyledTouchableOpacity>
  );
}

const sharedButtonStyle: TextStyle = {
  alignItems: "center",
  borderRadius: 25,
  paddingHorizontal: 24,
};

const sharedTextStyles: TextStyle = {
  fontWeight: "bold",
};

const sharedSecondaryTextStyle: TextStyle = {
  fontSize: 15,
  lineHeight: 20,
  fontWeight: "bold",
  color: Palette.Accent,
};

const sharedPillStyles: TextStyle = {
  paddingVertical: 6,
  paddingHorizontal: 16,
};

const sharedPillTextStyles: TextStyle = {
  fontSize: 13,
  lineHeight: 20,
};

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  alignSelf: {
    alignSelf: "baseline",
  },
  disabledButton: {
    // TODO: implement disabled button for each button variant. -EY
  },
});

const variants: Variants = {
  affirm: {
    ...sharedButtonStyle,
    paddingVertical: 8,
    backgroundColor: Palette.Affirm25Pct,
  },
  link: {},
  pillDark: {
    ...sharedButtonStyle,
    ...sharedPillStyles,
    backgroundColor: Palette.Primary100Pct,
  },
  pillLight: {
    ...sharedButtonStyle,
    ...sharedPillStyles,
    backgroundColor: Palette.Primary10Pct,
  },
  primary: {
    ...sharedButtonStyle,
    minWidth: 200,
    paddingVertical: 12,
    backgroundColor: Palette.Secondary100Pct,
  },
  secondary: {
    ...sharedButtonStyle,
    borderWidth: 1,
    borderColor: Palette.Accent,
    paddingVertical: 6,
    paddingHorizontal: 24,
  },
  textOnly: {},
  warning: {
    ...sharedButtonStyle,
    paddingVertical: 8,
    backgroundColor: Palette.Warning25Pct,
  },
};

const disabledVariants: Variants = {
  affirm: {},
  link: {},
  primary: {
    backgroundColor: Palette.Primary10Pct,
  },
  pillDark: {},
  pillLight: {},
  secondary: {
    borderColor: Palette.Affirm25Pct,
  },
  textOnly: {},
  warning: {},
};

const textStyles: Variants = {
  affirm: {
    ...sharedTextStyles,
    fontSize: 13,
    lineHeight: 16,
    color: Palette.Affirm100Pct,
  },
  link: {
    color: Palette.Accent,
    fontSize: 15,
    fontWeight: "500",
    borderBottomWidth: 1,
    borderBottomColor: Palette.Accent,
  },
  pillDark: {
    ...sharedPillTextStyles,
    color: Palette.Primary5Pct,
  },
  pillLight: {
    ...sharedPillTextStyles,
    color: Palette.Primary100Pct,
  },
  primary: {
    ...sharedTextStyles,
    fontSize: 15,
    lineHeight: 24,
    color: Palette.White,
  },
  secondary: {
    ...sharedSecondaryTextStyle,
  },
  textOnly: {
    ...sharedSecondaryTextStyle,
  },
  warning: {
    ...sharedTextStyles,
    fontSize: 13,
    lineHeight: 16,
    color: Palette.Warning100Pct,
  },
};

const disabledTextStyles: Variants = {
  affirm: {},
  link: {},
  pillDark: {},
  pillLight: {},
  primary: {
    color: Palette.Primary50Pct,
  },
  secondary: {
    color: Palette.Primary25Pct,
  },
  textOnly: {},
  warning: {},
};
