import { TrustshareSettlement } from "Payment";

export const milestoneCompletionType = ["not-started", "in-progress", "needs-review", "completed"] as const;
export type MilestoneCompletionType = typeof milestoneCompletionType[number];

export interface BudgetPhase {
  id?: string;
  name: string;
  budgetId: string;
  numDays: number;
  cost: number;
  orderIndex: number;
  milestoneIndices: number[];
}

export type PaymentPhase = BudgetPhase & {
  payment: TrustshareSettlement;
  startDate: Date;
};

export interface BudgetMilestoneTask {
  description: string;
  budget: number;
  completed: boolean;
  id?: string;
  orderIndex?: number;
}

export interface BudgetMilestone {
  id?: string;
  name: string;
  budgetId: string;
  scopeOfWork: string;
  orderIndex: number;
  csiCode: string;
  completion: MilestoneCompletionType,
  tasks: BudgetMilestoneTask[];
  files: any[];
}

export interface Budget {
  id: string;
  projectId: string;
  contractorId: string;
  completed: boolean;
  submitted: boolean;
  milestones: BudgetMilestone[];
  phases: BudgetPhase[];
  files: any[];
  disclosure: string;
  contract: BudgetContract | undefined;
  startDate: string;
  trustshareId: string | undefined;
}

export interface BudgetContract {
  completed: boolean;
  contractorSignatureUrl: string;
  ownerSignatureUrl: string;
  contractorSigned: boolean;
  ownerSigned: boolean;
  documentUrl: string;
  ownerSignatureId: string;
  contractorSignatureId: string;
  signatureUrlExpiresAt: string;
  signatureRequestId: string;
}

export interface UpdateBudgetSignatureParams {
  budgetId: string;
  userType: "contractor" | "owner";
}

export interface UpdateBudgetContractParams {
  budgetId: string;
  projectId: string;
  completed? : boolean;
  contractorSigned?: boolean;
  ownerSigned?: boolean;
  contractorSignatureUrl?: string;
  ownerSignatureUrl?: string;
  signatureUrlExpiresAt?: string;
  contractorSignatureId?: string;
  ownerSignatureId?: string;
  documentUrl?: string;
  signatureRequestId?: string;
}

export interface UpdateBudgetContractUrlsParams {
  budgetId: string;
  ownerUrl?: string;
  contractorUrl?: string;
}

export interface UpdateDisclosureParams {
  budgetId: string;
  disclosure: string;
}

export interface UpdateBudgetParams {
  budgetId: string;
  deletedMilestones: any[];
  deletedPhases: any[];
  deletedTasks:  BudgetMilestoneTask[];
  budgetItems: any[];
  startDate: Date;
}

export interface CreateBudgetParams {
  readonly projectId: string;
  budgetItems?: any[];
  startDate: Date;
}

export interface UpdateBudgetMilestoneParams extends Partial<BudgetMilestone> {}

export interface UpdateBudgetPhasesParams {
  readonly budgetId: string;
  readonly phases: ReadonlyArray<BudgetPhase>;
}

export interface DeleteBudgetMilestoneParams {
  readonly budgetId: string;
  readonly orderIndex: number;
}

export interface DeleteBudgetPhaseParams {
  readonly budgetId: string;
  readonly orderIndex: number;
}
