import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 64.5h69M1.5 40.5l30-12M7.5 38.115V64.5M27 7.5l43.5 27"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M64.5 30.75V64.5M55.5 25.17V15h6v13.92"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M58.5 52.5h-21v12h21v-12zM48 52.5v12M42 36a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM25.5 49.5h-12v15h12v-15zM13.5 43.5h12"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="2"
        d="M31.5 10.26V64.5"
      />
    </svg>
  );
}

export default memo(Icon);
