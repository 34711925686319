import React from "react";
import { useFuzzy } from "react-use-fuzzy";
import {
  Icon,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
} from "./controls";
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { Palette } from "./styles";

interface Props {
  flatListStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  modifyResult?: (result: Array<any>, keyword: string) => any;
  onSelect: (topic: any) => void;
  placedholder?: string;
  searchKeys: Array<string>;
  searchList: Array<any>;
  variant?: string;
}

export const FuzzySearchModular: React.FunctionComponent<Props> = (props) => {
  const { result, keyword, search } = useModifyResult(props.searchKeys, props.searchList, props.modifyResult);

  const assembleDisplay = (result: any) => {
    const resultArr = props.searchKeys.map((key: string) => result[key])
    return resultArr.join("\n");
  }

  const handleSelect = (item: any) => {
    search("");
    props.onSelect(item);
  };

  const renderItem = (result: any) => {
    const { variant } = props;
    
    return (
      <Hoverable>
        {(isHovered) => (
          <StyledTouchableOpacity
            style={[
              styles.suggestionContainer,
              isHovered && styles.suggestionHover,
            ]}
            onPress={() => handleSelect(result)}
          >
            <View style={styles.itemContainer}>
              {variant === "contractor" && (
                <Icon
                  name="user"
                  size={24}
                  style={styles.inviteIcon}
                />
              )}
              <StyledText>
                {assembleDisplay(result)}
              </StyledText>
            </View>
          </StyledTouchableOpacity>
        )}
      </Hoverable>
    );
  };

  return (
    <View style={{flexDirection: "column"}}>
      <StyledTextInput
        iconLeft={{ name: "search"}}
        placeholder={props.placedholder || ""}
        variant="primary"
        value={keyword}
        style={props.inputStyle || styles.input}
        onChangeText={(text) => search(text)}
      />
      {!!keyword && (
        <FlatList
          data={result.slice(0, 5)}
          style={props.flatListStyle || styles.flatList}
          renderItem={({ item }) => renderItem(item)}
          keyExtractor={(item) => item.category}
        />
      )}
    </View>
  );
};

function useModifyResult(searchKeys: Array<string>, searchList: Array<any>, modifyResult?: Function) {
  let { result, keyword, search } = useFuzzy<any>(searchList, {
    keys: searchKeys,
  });

  if (!!modifyResult) {
    result = modifyResult(result, keyword);
  }

  return { result, keyword, search };
}

const styles = StyleSheet.create({
  item: {
    color: "red",
  },
  flatList: {
    borderRadius: 5,
    width: "100%",
  },
  input: {
    width: "100%",
    height: 48,
  },
  itemContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inviteIcon: {
    marginTop: 6,
    marginRight: 10,
  },
  suggestionContainer: {
    padding: 24,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
    borderWidth: 1,
  },
  suggestionHover: {
    backgroundColor: Palette.White,
  },
});
