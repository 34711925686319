import React, { useState } from "react";
import { ActivityIndicator, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { Icon } from "./Icon";
import { Palette } from "../styles";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { StyledText } from "./StyledText";

export type FirebaseFile = { id: string; fileName: string; type: string; url: string };

type FileDropEvent = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

type IFileDropzone = {
  files: FirebaseFile[];
  onRemoveFile: (file: FirebaseFile, index: number) => void;
  onDrop: (file: File[], onUploadFinishedCallback: () => void) => void;
  editable?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const FileDropzone: React.FunctionComponent<IFileDropzone> = ({
  onDrop,
  files,
  onRemoveFile,
  editable = true,
  style,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleFileDrop: FileDropEvent = (acceptedFiles: File[]) => {
    if (uploading) return;
    setUploading(true);
    onDrop(acceptedFiles, onUploadFinished);
  };

  const onUploadFinished = () => {
    setUploading(false);
  };

  const renderFileList = () => {
    return files.map((item, index) => (
      <View style={styles.file}>
        <Icon
          name="file"
          size={24}
          onClick={() => window.open(item.url)}
        />
        <StyledText
          style={styles.fileText}
          onPress={() => window.open(item.url)}
        >
          {item.fileName}
        </StyledText>
        <Icon
          name="x"
          size={16}
          style={styles.removeIcon}
          onClick={() => onRemoveFile(item, index)}
        />
      </View>
    ));
  };

  if(!editable){
    if(files.length > 0){
      return (
        <View style={styles.fileContainer}>
          {renderFileList()}
        </View>
      )
    } else {
      return null
    }
  }

  return (
    <View style={[editable ? styles.editableDropzone : styles.dropzone, style]}>
      <Dropzone onDrop={handleFileDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              flex: 1,
            }}
          >
            <input {...getInputProps()} />
            {uploading && (
              <View style={styles.loadingContainer}>
                <ActivityIndicator
                  color={Palette.Secondary100Pct}
                  size={"large"}
                />
              </View>
            )}

            {files.length > 0 && (
              <View
                style={{ ...styles.fileContainer, opacity: uploading ? 0 : 1 }}
              >
                {renderFileList()}
              </View>
            )}

            {files.length === 0 && !uploading && (
              <View style={styles.dropIC}>
                <View style={styles.iconContainer}>
                  <Icon
                    style={styles.uploadIcon}
                    name={"upload"}
                    size={24}
                  />
                </View>
                <StyledText>Drop files here or select to upload</StyledText>
              </View>
            )}
          </div>
        )}
      </Dropzone>
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  dropzone: {
    display: "flex",
    margin: 2,
    minHeight: 200,
    height: "auto",
    paddingVertical: 10,
  },
  editableDropzone: {
    display: "flex",
    borderColor: "#a9aaa9",
    borderStyle: "dashed",
    borderRadius: 3,
    borderWidth: 2,
    minHeight: 200,
    height: "auto",
    padding: 10,
  },
  dropIC: {
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  uploadIcon: {
    alignSelf: "center",
    top: 13,
  },
  iconContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: 50,
    height: 50,
    borderRadius: 25,
    marginBottom: 15,
  },
  fileContainer: {
    flex: 1,
    flexDirection: "column",
  },
  file: {
    flexDirection: "row",
    marginVertical: 5,
  },
  fileText: {
    marginTop: 3,
    marginLeft: 5,
  },
  removeIcon: {
    margin: 5,
  },
});
