import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { CategoryPrice, MilestonePrice, TaskPrice, UserModel } from "../../../data-model";
import { StyledButton, StyledText, StyledTouchableOpacity } from "../components/controls";
import { milestoneDefinitions } from "../constants/MilestoneDefinitions";
import { History, HistoryInjectable } from "../HistoryInjectable";
import { Notification } from "../NotificationInjectable";
import { sanitizePriceInput } from "../utils/Numbers";
import { Palette } from "./styles";
import { categoryDefinitions } from "../constants/CategoryDefinitions";
import { MilestonePriceView } from "./MilestonePriceView";
import { CategoryPriceView } from "./CategoryPriceView";

interface Props {
  history: History;
  notification: Notification;
  userModel: UserModel;
}

const menuItems = ["bid", "budget"] as const;
type MenuItem = typeof menuItems[number];

interface MenuItemNav {
  title: string;
  description: string;
}
const menuItemNav: Record<MenuItem, MenuItemNav> = {
  bid: {
    title: "Rough Bid",
    description: "Rough Bid",
  },
  budget: {
    title: "Budget",
    description: "Full Budget",
  },
};

@observer
export class PricingSettingsView extends React.Component<Props> {
  static inject: Inject<React.ReactElement> = (injector) => {
    return () => (
      <PricingSettingsView
        history={injector.get(HistoryInjectable)()}
        notification={injector.get(Notification)()}
        userModel={injector.get(UserModel)()}
      />
    );
  };

  @computed private get categories() {
    return (
      <View>
        <View style={styles.inputRow}>
          <StyledText variant="body2" isBold={true} style={[styles.columnLeft, {maxWidth: 555}]}>Category</StyledText>
          <StyledText variant="body2" isBold={true} style={styles.columnRight}>Cost</StyledText>
        </View>
        
        {
          this.categoryPricesCurrent.map((category, index) => { 
            const isActive = this.currentCategoryIndex === index;
            return (
              <View style={styles.column} key={index}>
                <CategoryPriceView 
                  isActive={isActive}
                  onClick={() => this.selectCategory(index)}
                  category={category}
                  categoryIndex={index} 
                  updateScope={this.updateCategoryScope} 
                  updateBudget={this.updateBudget}                    
                />
              </View>
            )
          })
        }
      </View>
    )
  }

  @computed private get milestones() {
    return (
      <View>
        <View style={styles.inputRow}>
          <StyledText variant="body2" isBold={true} style={styles.columnLeft}>CSI Code</StyledText>
          <StyledText variant="body2" isBold={true} style={styles.columnMiddle}>Milestone</StyledText>
          <StyledText variant="body2" isBold={true} style={styles.columnRight}>Cost</StyledText>
        </View>
        
        {
          this.milestonePricesCurrent.map((milestone, index) => { 
            const isActive = this.currentMilestoneIndex === index;
            return (
              <View style={styles.column} key={index}>
                <MilestonePriceView 
                  isActive={isActive}
                  onClick={() => this.selectMilestone(index)}
                  milestone={milestone}
                  milestoneIndex={index} 
                  updateScope={this.updateMilestoneScope} 
                  updateTask={this.updateTask}                    
                />
              </View>
            )
          })
        }
      </View>
    )
  }

  @observable private categoryPricesCurrent: Array<CategoryPrice> = [];
  @observable private milestonePricesCurrent: Array<MilestonePrice> = [];
  @observable private currentCategoryIndex: number = -1;
  @observable private currentMilestoneIndex: number = -1;
  @observable selectedMenuItem: MenuItem = "bid";
  private storedCategoryPrices: Array<CategoryPrice> = [];
  private storedMilestonePrices: Array<MilestonePrice> = [];

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount = async () => {
    const { userModel } = this.props;
    const categoryPrices = await userModel.getCategoryPrices();
    if (!!categoryPrices) {
      this.storedCategoryPrices = categoryPrices;
    }
    const milestonePrices = await userModel.getMilestonePrices();
    if (!!milestonePrices) {
      this.storedMilestonePrices = milestonePrices;
    }
    this.categoryPricesCurrent = this.loadCategoryPricings();
    this.milestonePricesCurrent = this.loadMilestonePricings();
  }

  readonly handleSavePress = async () => {
    this.selectedMenuItem === 'bid' ? this.saveBid() : this.saveBudget();
  }

  // Bid

  readonly selectCategory = (index: number) => {
    this.currentCategoryIndex = this.currentCategoryIndex !== index ? index : -1;
  };

  readonly loadCategoryPricings = (): Array<CategoryPrice> => {
    const categoryPricingArray: CategoryPrice[] = [];

    categoryDefinitions.forEach((category) => {
      const storedCategory = this.storedCategoryPrices.find(sCategory => sCategory.category === category.category);

      categoryPricingArray.push({
        category: category.category,
        scope: storedCategory?.scope ? storedCategory.scope : '',
        budget: storedCategory?.budget ? storedCategory.budget : '',
      });
    });

    return categoryPricingArray;
  }

  readonly updateBudget = (categoryIndex: number, updatedPrice: string) => {
    this.categoryPricesCurrent[categoryIndex].budget = sanitizePriceInput(updatedPrice);
  }

  readonly updateCategoryScope = (milestoneIndex: number, updatedScope: string) => {
    this.categoryPricesCurrent[milestoneIndex].scope = updatedScope;
  }

  private saveBid = async () => {
    const { notification, userModel } = this.props;
    var changedCategoryPrices: CategoryPrice[] = [];
    this.categoryPricesCurrent.forEach((category) => {
      const newScope = category.scope !== '' ? category.scope : undefined;

      if (newScope || category.budget!.length > 0) {
        const newObject: CategoryPrice = {
          category: category.category,
          scope: newScope,
          budget: category.budget,
        };

        changedCategoryPrices.push(newObject);
      }
    })

    try {
      await userModel.setCategoryPrices(changedCategoryPrices)
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    } finally {
      notification.setNotification("success", "Price Settings Updated!");
    }
  }

  // Budget

  readonly selectMilestone = (index: number) => {
    this.currentMilestoneIndex = this.currentMilestoneIndex !== index ? index : -1;
  };

  readonly loadMilestonePricings = (): Array<MilestonePrice> => {
    const milestonePricingArray: MilestonePrice[] = [];
    milestoneDefinitions.forEach((milestone) => {
      const storedMilestone = this.storedMilestonePrices.find(sMilestone => sMilestone.milestoneName === milestone.milestoneName);
      const taskPricingArray: TaskPrice[] = [];
      
      milestone.tasks.forEach((task: string) => {
        const storedTask = storedMilestone?.tasks?.find(sTask => sTask.description === task);
        if (storedTask) {
          taskPricingArray.push(storedTask);
        } else {
          taskPricingArray.push({
            description: task,
            budget: '',
          });
        }
      })

      milestonePricingArray.push({
        milestoneName: milestone.milestoneName,
        csiCode: milestone.csiCode,
        scope: storedMilestone?.scope ? storedMilestone.scope : milestone.scope,
        tasks: taskPricingArray,
      });
    });
    
    return milestonePricingArray;
  }

  readonly updateTask = (milestoneIndex: number, taskIndex: number, updatedPrice: string) => {
    this.milestonePricesCurrent[milestoneIndex].tasks![taskIndex].budget = sanitizePriceInput(updatedPrice);
  }

  readonly updateMilestoneScope = (milestoneIndex: number, updatedScope: string) => {
    this.milestonePricesCurrent[milestoneIndex].scope = updatedScope;
  }

  private saveBudget = async () => {
    const { notification, userModel } = this.props;
    var changedMilestonePrices: MilestonePrice[] = [];
    this.milestonePricesCurrent.forEach((milestone, milestoneIndex) => {
      const newScope = milestone.scope !== milestoneDefinitions[milestoneIndex].scope ? milestone.scope : undefined;
      const updatedTasks: Array<TaskPrice> = [];

      milestone.tasks?.forEach((task) => {
        if (task.budget !== '') {
          updatedTasks.push(task);
        }
      })

      const newObject: MilestonePrice = {
        milestoneName: milestone.milestoneName
      };

      if (newScope || updatedTasks.length > 0) {
        newObject.scope = newScope;
        newObject.tasks = updatedTasks;

        changedMilestonePrices.push(newObject);
      }
    })

    try {
      await userModel.setMilestonePrices(changedMilestonePrices)
    } catch (err: any) {
      notification.setNotification("error", err?.message);
    } finally {
      notification.setNotification("success", "Price Settings Updated!");
    }
  }

  // Menu

  readonly handleMenuClick = (item: MenuItem) => {
    this.selectedMenuItem = item;
  };

  readonly renderMenuItem = (item: MenuItem, index: number) => {
    const isSelected = this.selectedMenuItem === item;
    const menuItem = menuItemNav[item];
    const selectedStyle = isSelected && styles.selectedMenuItem;
    return (
      <StyledTouchableOpacity
        key={index}
        style={[styles.menuItem, selectedStyle]}
        onPress={() => this.handleMenuClick(item)}
      >
        <StyledText
          isBold={true}
          colorMode={isSelected ? "highlight" : "dark"}
        >
          {menuItem.title}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.menuRow}>
          {menuItems.map(this.renderMenuItem)}
        </View>

        <View style={styles.section}>
          <View style={styles.sectionSmall}>
            <StyledText variant="heading3" isBold={true} style={styles.sectionHeader}>Predetermined Pricing</StyledText>
            <StyledText variant="body2">
              {`Determine a set price to apply to your projects’ ${menuItemNav[this.selectedMenuItem].description}.`}
            </StyledText>
          </View>

          {
            this.selectedMenuItem === 'bid' ? (
              this.categories
            ) : (
              this.milestones
            )
          }
        </View>
        <StyledButton text="Save Pricing" alignSelf={true} onPress={this.handleSavePress}/>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 82,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    marginBottom: 48,
  },
  sectionSmall: {
    marginBottom: 32,
  },
  sectionHeader: {
    marginBottom: 16,
  },
  label: {
    textTransform: "uppercase",
    marginBottom: 10,
  },
  menuRow: {
    flexDirection: "row",
    right: 82,
    paddingLeft: 82,
    paddingRight: -82,
    borderBottomWidth: 1,
    borderBottomColor: Palette.Primary10Pct,
    marginBottom: 15,
  },
  columnLeft: {
    flex: 1,
    maxWidth: 100,
    marginLeft: 5,
  },
  columnMiddle: {
    flex: 1,
    maxWidth: 455,
  },
  columnRight: {
    maxWidth: 200,
  },
  column: {
    display: "flex",
    paddingVertical: 2,
    maxWidth: 600,
  },
  inputRow: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  menuItem: {
    height: 64,
    paddingHorizontal: 20,
    justifyContent: "center",
  },
  selectedMenuItem: {
    borderBottomWidth: 3,
    borderBottomColor: Palette.Secondary100Pct,
  },
});
