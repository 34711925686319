import { FunctionComponent } from "react";
import { StyleSheet, View } from "react-native";
import { formatCurrencyToString } from "../../utils/Numbers";
import { formatDateLongMonth } from "../../utils/Strings";
import { StyledText } from "../controls";
import { PaymentDetail } from "../ProjectDetailsFlow";
import { Palette } from "../styles";
import { PaymentPhaseTabHeader } from "./PaymentPhaseTabHeader";

interface Props {
  transactions: PaymentDetail[];
}

export const PaymentTransactionPreview: FunctionComponent<Props> = ({
  transactions,
}) => {
  return (
    <View style={styles.container}>
      <PaymentPhaseTabHeader
        title={"Recent Transactions"}
        onViewAllClick={() => {}}
        style={styles.header}
      />
      {transactions.length === 0 && (
        <View style={styles.noTranscationsContainer}>
          <StyledText variant="body" style={styles.noTransactions} isMedium>
            No transactions have been made yet.
          </StyledText>
        </View>
      )}
      {transactions
        .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
        .map((transaction, index) => {
          return (
            <View
              key={index}
              style={[
                styles.transactionContainer,
                {
                  backgroundColor:
                    index % 2 === 0
                      ? Palette.Primary10Pct
                      : Palette.Primary5Pct,
                },
              ]}
            >
              <StyledText isBold={true} style={styles.transactionName}>
                {transaction.name}
              </StyledText>
              <StyledText variant="body">
                {formatDateLongMonth(transaction.date)}
              </StyledText>
              <View style={styles.rightSide}>
                <StyledText variant="body" isBold>
                  {formatCurrencyToString(transaction.amount)}
                </StyledText>
                <StyledText variant="body">{transaction.type}</StyledText>
              </View>
            </View>
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: Palette.Primary5Pct,
    borderColor: Palette.Primary10Pct,
  },
  header: {
    margin: 16,
  },
  transactionContainer: {
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 24,
    height: 60,
  },
  transactionName: {
    marginBottom: 4,
  },
  rightSide: {
    alignSelf: "flex-end",
    position: "absolute",
  },
  noTranscationsContainer: {
    backgroundColor: Palette.Primary10Pct,
    width: "100%",
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  noTransactions: {
    fontSize: 16,
  },
});
