import { Inject, singletonInject } from "@not-the-droids/exco-ts-inject";
import { ContractorModel, CreateContractorParams } from "../ContractorModel";
import { Contractor } from "../Contractor";
import { RestClient } from "./RestClient";

export class RestContractorModel extends ContractorModel {
  static inject: Inject<RestContractorModel> = singletonInject((injector) => {
    return () => new RestContractorModel(injector.get(RestClient)());
  });

  constructor(private readonly rest: RestClient) {
    super();
  }

  public getContractors(): Promise<Contractor[]> {
    return this.rest.get("/contractor");
  }  

  public createContractor(params: CreateContractorParams): Promise<Contractor> {
    return this.rest.post("/contractor", params);
  }

  public getContractorByEmail(contractorEmail: string): Promise<Contractor> {
    return this.rest.get(
      `/contractor/search?email=${encodeURIComponent(contractorEmail)}`
    );
  }

  public getContractorByProjectId(projectId: string): Promise<Contractor | undefined> {
    return this.rest.get(
      `/contractor/${projectId}`
    )
  }


  public getFilesById(params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.get(`/contractor/${params.contractorId}/file`);
  }

  public addFile(contractorId: string, params: any): Promise<void> {
    const { ...data } = params;
    return this.rest.post(`/contractor/${contractorId}/file/${params.id}`, data);
  }

  public deleteFile(id: string): Promise<void> {
    return this.rest.delete(`/contractor/file/${id}`);
  }


}
