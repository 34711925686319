import React from "react";
import { StyleSheet, View } from "react-native";
import { computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import CheckBox from "./CheckBox";
import { Icon, StyledText, StyledTouchableOpacity } from "./controls";
import { Palette } from "./styles";
import { FuzzySearchModular } from "./FuzzySearchModular";
import { ProjectCrudFlow } from "./ProjectCrudFlow";
import { Inject } from "@not-the-droids/exco-ts-inject";
import { WorkAreaParams, WorkCategoryParams } from "./ProjectFlowWorkAreasView";

export type WorkCategoryLocation = "Interior" | "Exterior" | "Both";

interface Props {
  projectCrudFlow: ProjectCrudFlow;
}

interface CreateProps {
  areaIndex: number;
  categoryCount: Array<number>;
  duplicate?: (areaIndex: number, currentWorkArea: WorkAreaParams) => void;
  updateSelecteWorkAreaNum: (isChecked: boolean) => void;
}

export class WorkAreaOptionFactory {
  static inject: Inject<WorkAreaOptionFactory> = (injector) => {
    return () =>
      new WorkAreaOptionFactory({
        projectCrudFlow: injector.get(ProjectCrudFlow)(),
      });
  };

  constructor(private readonly props: Props) {}

  public create(props: CreateProps) {
    return <WorkAreaOption {...this.props} {...props} />;
  }
}

@observer
class WorkAreaOption extends React.Component<Props & CreateProps> {
  constructor(props: Props & CreateProps) {
    super(props);
    makeObservable(this);
  }

  @observable private currentWorkArea: WorkAreaParams = this.props.projectCrudFlow.inclusiveWorkAreas[this.props.areaIndex];
  @observable private isOpen: boolean = false;

  @computed private get workCategoryItems() {
    return (this.currentWorkArea.inclusiveCategories).map((workCategory, index) => {
      const isSelected = workCategory.isSelected;
      const onPressFunction = workCategory.wasExcluded ?  this.handleSearchedWorkCategoryItemPress : this.handleWorkCategoryItemPress
      return (
        <WorkCategoryItem
          key={"workCategory-" + this.currentWorkArea + workCategory.category}
          index={index}
          isSelected={isSelected}
          onPress={onPressFunction}
          workCategory={workCategory}
        />
      )
    })
  }

  componentDidUpdate = () => {
    this.currentWorkArea = this.props.projectCrudFlow.inclusiveWorkAreas[this.props.areaIndex];
  }

  readonly handleCheckBox = () => {
    const isChecked = !this.currentWorkArea.isChecked;
    this.currentWorkArea.isChecked = isChecked;
    this.props.updateSelecteWorkAreaNum(isChecked);
  }

  readonly handleDuplicate = () => {
    const { areaIndex, duplicate } = this.props;
    !!duplicate && duplicate(areaIndex, this.currentWorkArea)
  }

  private handleCategorySelect = (topic: any) => {
    const {areaIndex, categoryCount} = this.props;
    const index = this.currentWorkArea.exclusiveCategories.findIndex(exCat => exCat.category === topic.category);
    if (index > -1) {
      const deleteCategory = this.currentWorkArea.exclusiveCategories.splice(index, 1)[0];
      if (!!deleteCategory) {
        deleteCategory.isSelected = true;
        this.currentWorkArea.inclusiveCategories.push(deleteCategory);
      }
    } else if (!this.currentWorkArea.inclusiveCategories.find(inCat => inCat.category === topic.category)) {
      const customCategory = {
        category: topic.category,
        isCustom: true,
        isSelected: true,
        order: -1,
        wasExcluded: true,
      };
      this.currentWorkArea.inclusiveCategories.push(customCategory);
    }
    categoryCount[areaIndex] += 1;
    this.selectCurrentWorkArea();
  }

  readonly handleSearchedWorkCategoryItemPress = (index: number) => {
    const {areaIndex, categoryCount} = this.props;
    const deleteCategory = this.currentWorkArea.inclusiveCategories.splice(index, 1)[0];
    if (!!deleteCategory && !deleteCategory.isCustom) {
      deleteCategory.isSelected = false;
      const exIndex = this.currentWorkArea.exclusiveCategories.findIndex(exCat => exCat.order > deleteCategory.order)
      this.currentWorkArea.exclusiveCategories.splice(exIndex, 0, deleteCategory);
    }
    categoryCount[areaIndex] -= 1;
  }

  readonly handleWorkCategoryItemPress = (index: number) => {
    const { areaIndex, categoryCount } = this.props;
    const isSelected = !this.currentWorkArea.inclusiveCategories[index].isSelected;
    this.currentWorkArea.inclusiveCategories[index].isSelected = isSelected;
    if (isSelected) {
      this.selectCurrentWorkArea()
      categoryCount[areaIndex] += 1;
    } else {
      categoryCount[areaIndex] -= 1;
    }
  };

  // automatically select work area if category in it is selected
  readonly selectCurrentWorkArea = () =>{
    if(!this.currentWorkArea.isChecked){
      this.currentWorkArea.isChecked = true;
      this.props.updateSelecteWorkAreaNum(true);
    }
  }

  readonly modifySearchResult = (result: Array<any>, keyword: string) => {
    if (result.length === 0) {
      const newCategory = keyword.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      return [{
        category: newCategory
      }]
    }
    return result;
  }

  render() {
    const { areaIndex, categoryCount, duplicate } = this.props;
    return (
      <>
        {/* Head */}
        <StyledTouchableOpacity style={[styles.workArea, this.isOpen && styles.workAreaBorderDark]} onPress={() => this.isOpen = !this.isOpen}>
          <View style={styles.workAreaInfo}>
            {/* Left Container */}
            <View style={styles.leftContainer}>
              <span onClick={evt => evt.stopPropagation()}>
                <CheckBox
                  checked={this.currentWorkArea.isChecked}
                  value={""}
                  textVisible={false}
                  onChange={this.handleCheckBox}
                />
              </span>
              <StyledText variant="heading3" style={styles.sectionTitle}>
                {this.currentWorkArea.areaName}
              </StyledText>
            </View>
            
            {/* Right Container */}
            <View style={styles.rightContainer}>
              {
                !!duplicate && (
                  <StyledTouchableOpacity onPress={this.handleDuplicate}>
                    <Icon name="duplicate" type="accent" size={20} />
                  </StyledTouchableOpacity>
                )
              }
              <View style={styles.counter}>
                <StyledText variant="body" colorMode="dark" isBold={true}>
                  {categoryCount[areaIndex]}
                </StyledText>
              </View>
            </View>
          </View>
        </StyledTouchableOpacity>

        {/* Body */}
        {
          this.isOpen && (
            <View style={styles.workAreaBody}>
              <View style={styles.bodyContainer}>
                <View style={styles.bodyLeft}>
                  <StyledText variant="body" colorMode="dark" isBold={true}>
                    {`Typical ${this.currentWorkArea.areaName} Categories`}
                  </StyledText>
                  <StyledText
                    variant="body2"
                    colorMode="dark"
                    style={{ marginTop: 10 }}
                  >
                    {
                      "Select categories to add to this room or search with the text field below."
                    }
                  </StyledText>
                </View>
                <View style={styles.bodyRight}>
                  <View style={styles.categoriesArray}>
                    {this.workCategoryItems}
                  </View>
                  <View style={{marginTop: 24}}>
                    <FuzzySearchModular
                      flatListStyle={styles.flatList}
                      modifyResult={this.modifySearchResult}
                      onSelect={this.handleCategorySelect}
                      searchList={this.currentWorkArea.exclusiveCategories} 
                      searchKeys={["category"]}                    
                    />
                  </View>
                </View>
              </View>
            </View>
          )
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  sectionTitle: {
    marginBottom: 4,
    fontWeight: "600",
  },
  workArea: {
    justifyContent: "center",
    backgroundColor: Palette.Primary5Pct,
    border: "1px solid " + Palette.Primary10Pct,
    borderRadius: 4,
    minHeight: 56,
    zIndex: 1,
  },
  workAreaBorderDark: {
    zIndex: 1,
    border: "1px solid " + Palette.Primary25Pct,
  },
  workAreaInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 16,
    gap: 16,
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginRight: "auto",
  },
  rightContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginLeft: "auto",
  },
  counter: {
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    width: 41,
    backgroundColor: Palette.Primary10Pct,
    borderRadius: 25,
    marginLeft: "auto",
  },
  workAreaBody: {
    backgroundColor: Palette.White,
    border: "1px solid " + Palette.Primary25Pct,
    borderRadius: 4,
    top: -56,
    marginBottom: -56,
    zIndex: 0,
  },
  bodyContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 16,
    marginTop: 72,
  },
  bodyLeft: {
    flexBasis: "35%",
  },
  bodyRight: {
    flexBasis: "50%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  categoriesArray: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
  },
  flatList: {
    borderRadius: 5,
    width: "100%",
    position: "absolute",
    top: 48,
  },
});

/**************************/
/*   Work Category Item   */
/**************************/

interface WorkCategoryItemProps {
  index: number;
  isSelected: boolean;
  onPress: (index: number) => void;
  workCategory: WorkCategoryParams;
}

const WorkCategoryItem: React.FunctionComponent<WorkCategoryItemProps> = (props) => {
  const { index, isSelected, onPress, workCategory: { category },  } = props;
  const containerStyle = isSelected && itemStyles.workCategoryContainerSelected

  return (
    <StyledTouchableOpacity
      style={[itemStyles.workCategoryContainer, containerStyle]}
      onPress={() => onPress(index)}
    >
      <StyledText variant="body2" colorMode={isSelected ? "light" : "dark"} style={{marginBottom: 2}}>{category}</StyledText>
      {isSelected && (
        <View>
          <Icon name="x" type="white" size={14}/>
        </View>
      )}
    </StyledTouchableOpacity>
  );
};

const itemStyles = StyleSheet.create({
  workCategoryContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Palette.Primary10Pct,
    paddingHorizontal: 16,
    gap: 5,
    borderRadius: 25,
    height: 32,
  },
  workCategoryContainerSelected: {
    backgroundColor: Palette.Primary100Pct,
  },
})

/**************************/
/*    Work Area Search    */
/**************************/

interface SearchProps {
  handleClose: () => void;
  handleWorkAreaSelect: (topic: any) => void;
  searchList: WorkAreaParams[];
}

@observer
export class WorkAreaSearch extends React.Component<SearchProps> {
  readonly innerAreaSelect = (topic: any) => {
    this.props.handleClose();
    this.props.handleWorkAreaSelect(topic);
  }

  render() {
    const { handleClose, searchList } = this.props;
    return (
      <View style={[styles.workArea]}>
        <View style={styles.workAreaInfo}>
          {/* Left Container */}
          <View style={styles.leftContainer}>
            <FuzzySearchModular
              flatListStyle={searchStyles.flatList}
              inputStyle={searchStyles.searchBar}
              onSelect={this.innerAreaSelect}
              placedholder="Search Work Areas"
              searchList={searchList}
              searchKeys={["areaName"]}
            />
          </View>
          
          {/* Right Container */}
          <View style={styles.rightContainer}>
            <StyledTouchableOpacity onPress={handleClose} style={{marginRight: 10}}>
              <Icon name="x" type="warning" size={20} />
            </StyledTouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}


const searchStyles = StyleSheet.create({
  searchBar: {
    width: 320,
    left: -5,
  },
  flatList: {
    borderRadius: 5,
    width: "100%",
    position: "absolute",
    top: 38,
    right: 5,
  },
})