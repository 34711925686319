import { FunctionComponent, memo } from "react";
import { IconProps } from "../../components/controls/Icon";

const Icon:FunctionComponent<IconProps> = ({width, height, color}) =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
       width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}

        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 15l-6-6-6 6"
      />
    </svg>
  );
}

export default memo(Icon);

